import React, { useImperativeHandle, useState, useCallback, useEffect, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Dropdown } from "@/components/Dropdown";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoDropdownEstoques, MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const AcertoEstoque = ({ aposFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [tipoMovEstoque, setTipoMovEstoque] = useState([]);
    const { user } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            sku: null,
            unidade_medida: "",
            centro_estocagem: null,
            competencia: null,
            data_hora: new Date(),
            tipo_movimentacao: null,
            cubagem: 1,
            quantidade: 0,
            valor: 0,
            valor_unitario: 0,
            item_nota_fiscal: null,
            justificativa: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                centro_estocagem: Yup.object()
                    .typeError("Selecione um centro de estocagem.")
                    .required("Selecione um centro de estocagem."),
                competencia: Yup.number()
                    .typeError("Selecione uma competência de estoque.")
                    .required("Selecione uma competência de estoque."),
                data_hora: Yup.date().typeError("Informe uma data válida.").required("O campo 'data' é obrigatório"),
                tipo_movimentacao: Yup.number()
                    .typeError("Selecione um tipo de acerto.")
                    .required("Selecione um tipo de acerto."),
                cubagem: Yup.number().min(0.00001, "A dimensão não pode ser ZERO."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.permite_fracionamento === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
                valor: Yup.number().min(0.01, "O valor não pode ser ZERO.").required("O campo 'valor' é obrigatório."),
                justificativa: Yup.string().required("O campo 'justificativa' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { tipo } = tipoMovEstoque.find((mov) => mov.id === values.tipo_movimentacao);
            const acertoEstoque = {
                ...values,
                sku: values.sku.id,
                quantidade: tipo === "S" ? values.quantidade * -1 : values.quantidade,
                unidade_medida: values.unidade_medida.id,
                data_hora: dataToStr(values.data_hora, "yyyy-MM-dd hh:mm:ss"),
                estoque_empresa: values.centro_estocagem.estoque_empresa.id,
                centro_estocagem: values.centro_estocagem,
                responsavel: user.id,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Acerto de estoque lançado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                    },
                };

                showLoading();
                await httpPost({ url: "/produtos/acerto-estoque/", body: acertoEstoque }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Acerto de estoque alterado com sucesso!",
                            life: 1500,
                        });
                        fecharModal();
                    },
                };

                showLoading();
                await httpPut({ url: `/produtos/acerto-estoque//${values.id}/`, body: acertoEstoque }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (acertoEstoque) => {
        if (acertoEstoque) {
            const { centro_estocagem, tipo_movimentacao, quantidade, ...rest } = acertoEstoque;
            setValues({
                ...rest,
                quantidade: tipo_movimentacao.tipo === "S" ? quantidade * -1 : quantidade,
                tipo_movimentacao: tipo_movimentacao.id,
                centro_estocagem: centro_estocagem.id,
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
        resetForm();
        if (typeof aposFechar === "function") aposFechar();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const verificarTiposMovimentacoes = useCallback(
        (tiposMovimentacoes) => {
            if (!visible) return;
            if (!tiposMovimentacoes.length) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Você não possui tipo de movimentação de estoque para ACERTO.",
                    life: 5000,
                });
            }
            return tiposMovimentacoes;
        },
        [visible, showWarning]
    );

    useEffect(() => {
        if (formik.values.sku && formik.values.sku instanceof Object) {
            const { unidade_padrao } = formik.values.sku;
            setFieldValue("unidade_medida", unidade_padrao);
        }
    }, [formik.values.sku, setFieldValue]);

    useEffect(() => {
        if (formik.values.quantidade > 0 && formik.values.valor > 0) {
            const valorUnitatio = (formik.values.valor / formik.values.quantidade) * formik.values.cubagem;
            setFieldValue("valor_unitario", valorUnitatio);
        }
    }, [formik.values.quantidade, formik.values.valor, formik.values.cubagem, setFieldValue]);

    return (
        <Dialog
            header={formik.values.id ? "Manutenção de acerto de estoque" : "Novo lançamento de acerto de estoque"}
            visible={visible}
            onHide={fecharModal}
            blockScroll
            style={{ width: "70vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    disabledBusca={formik.values.processado}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade-medida">Unidade de medida</label>
                        <InputText
                            id="unidade-medida"
                            name="unidade_medida"
                            disabled
                            value={
                                formik.values.unidade_medida instanceof Object ? formik.values.unidade_medida.nome : ""
                            }
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="centro-estocagem">Centro de estocagem *</label>
                        <MakoDropdownEstoques
                            id="centro-estocagem"
                            name="centro_estocagem"
                            optionValue="id"
                            empresaId={empresaSelecionadaId}
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            disabled={formik.values.processado}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="competencia">Competência de estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia"
                            name="competencia"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data">Data *</label>
                        <MakoCalendar
                            id="data"
                            name="data_hora"
                            value={formik.values.data_hora}
                            onChange={formik.handleChange}
                            showTime
                            className={classNames({ "p-invalid": formik.errors.data_hora })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.data_hora && <small className="p-error">{formik.errors.data_hora}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo-acerto">Tipo de acerto *</label>
                        <Dropdown
                            id="tipo-acerto"
                            name="tipo_movimentacao"
                            url="/produtos/tipos-movimentacoes-estoques?classe_movimentacao=1&limit=20"
                            setObjects={setTipoMovEstoque}
                            aposBuscar={verificarTiposMovimentacoes}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.tipo_movimentacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_movimentacao })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.tipo_movimentacao && (
                            <small className="p-error">{formik.errors.tipo_movimentacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="cubagem">Dimensão *</label>
                        <InputNumber
                            inputId="cubagem"
                            name="cubagem"
                            mode="decimal"
                            disabled={
                                !(formik.values.sku && formik.values.sku.movimenta_cubagem) || formik.values.processado
                            }
                            minFractionDigits={3}
                            maxFractionDigits={6}
                            value={formik.values.cubagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cubagem })}
                        />
                        {formik.errors.cubagem && <small className="p-error">{formik.errors.cubagem}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <InputNumber
                            id="quantidade"
                            name="quantidade"
                            mode="decimal"
                            minFractionDigits={formik.values.sku && formik.values.sku.permite_fracionamento ? 3 : 0}
                            maxFractionDigits={formik.values.sku && formik.values.sku.permite_fracionamento ? 3 : 0}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor *</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-unitario">Valor unitário</label>
                        <MakoInputMoeda
                            id="valor-unitario"
                            name="valor_unitario"
                            disabled
                            valueMoeda={formik.values.valor_unitario}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="doc-fiscal-vinculado">Documento fiscal vinculado</label>
                        <InputText
                            id="doc-fiscal-vinculado"
                            name="item_nota_fiscal"
                            value={formik.values.item_nota_fiscal}
                            onChange={formik.handleChange}
                            disabled={formik.values.processado}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="justificativa">Justificativa *</label>
                        <InputText
                            id="justificativa"
                            name="justificativa"
                            value={formik.values.justificativa}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.justificativa })}
                            disabled={formik.values.processado}
                        />
                        {formik.errors.justificativa && (
                            <small className="p-error">{formik.errors.justificativa}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    {!formik.values.processado && (
                        <Button
                            label="Gravar"
                            icon="pi pi-save"
                            type="submit"
                            disabled={!(tipoMovEstoque?.length > 0)}
                            className="p-button-info p-mr-2"
                        />
                    )}
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => fecharModal()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalAcertoEstoque = forwardRef(AcertoEstoque);
