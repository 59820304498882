import React, { useCallback, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { Checkbox } from "primereact/checkbox";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const PartesContratoPage = () => {
    const [edicao, setEdicao] = useState(false);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPatch } = useHttp();
    const listagemRef = useRef();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            identificador: "",
            obrigatorio: false,
            predecessor: null,
            descricao_visivel: "",
            numera_clausula: false,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificador: Yup.string().required("O campo é obrigatório."),
                descricao_visivel: Yup.string().required("O campo é obrigatório."),
                obrigatorio: Yup.boolean().required("O campo é obrigatório."),
                numera_clausula: Yup.boolean().required("O campo é obrigatório."),
                ativo: Yup.boolean().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Parte de contrato jurídico alterada com sucesso!",
                        life: 1500,
                    });
                    listagemRef.current?.buscarDados();
                    fecharEdicao();
                },
            };

            showLoading();
            await httpPatch({ url: `/servicos/partes-contrato/${values.id}/`, body: values }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_CADASTROS_PARTES_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    onClick={() => {
                        setEdicao(true);
                        setValues(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Ativo" },
                        { value: false, label: "Desativado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "identificador", header: "Identificador", style: { minWidth: "200px" } },
        { field: "descricao_visivel", header: "Descrição Visível", style: { minWidth: "200px" } },
        {
            field: "obrigatorio",
            header: "Obrigatório",
            action: ({ obrigatorio }) => {
                if (obrigatorio) return "Sim";
                return "Não";
            },
        },
        {
            field: "numera_clausula",
            header: "Numera Cláusula",
            action: ({ numera_clausula }) => {
                if (numera_clausula) return "Sim";
                return "Não";
            },
        },
        {
            field: "ativo",
            header: "Ativo",
            action: ({ ativo }) => {
                if (ativo) return "Sim";
                return "Não";
            },
        },
        {
            field: "ativo",
            header: "Situação",
            style: { minWidth: "50px" },
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            filter: true,
            filterElement: StatusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { minWidth: "10%" },
        },
    ];

    const fecharEdicao = () => {
        setEdicao(false);
        resetForm();
    };

    const aposBuscar = useCallback(
        (dados) => {
            return dados.filter(({ id }) => id !== formik.values.id);
        },
        [formik.values.id]
    );

    return (
        <>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo={
                        <div className="p-d-flex p-justify-between">
                            <span>Partes de Contrato Jurídico</span>
                            {
                                <Button
                                    severity="secondary"
                                    outlined
                                    icon="pi pi-question"
                                    rounded
                                    tooltip="Os itens da listagem são partes possíveis de um contrato jurídico pré definidas pelo sistema, é possível fazer a edição e personalizar as partes conforme a situação de cada usuário."
                                    tooltipOptions={{ position: "bottom" }}
                                />
                            }
                        </div>
                    }
                    colunas={colunas}
                    urlPesquisa="/servicos/partes-contrato/"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
                <Dialog
                    header="Edição de Parte de Contrato"
                    visible={edicao}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "55vw" }}
                    onHide={() => fecharEdicao()}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <Label htmlFor="identificador" label="Identificação" obrigatorio />
                                <InputText
                                    id="identificador"
                                    name="identificador"
                                    value={formik.values.identificador}
                                    autoComplete="off"
                                    disabled
                                    readOnly
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <Label htmlFor="descricao_visivel" label="Descrição visível" />
                                <InputText
                                    id="descricao_visivel"
                                    name="descricao_visivel"
                                    value={formik.values.descricao_visivel}
                                    onInput={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao_visivel })}
                                    autoComplete="off"
                                />
                                {formik.errors.descricao_visivel && (
                                    <small className="p-error">{formik.errors.descricao_visivel}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <Label htmlFor="predecessor" label="Predecessor" />
                                <Dropdown
                                    id="predecessor"
                                    name="predecessor"
                                    placeholder="Selecione..."
                                    url="/servicos/partes-contrato/"
                                    optionValue="id"
                                    optionLabel="identificador"
                                    aposBuscar={aposBuscar}
                                    value={formik.values.predecessor}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.predecessor })}
                                />
                                {formik.errors.predecessor && (
                                    <small className="p-error">{formik.errors.predecessor}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid p-justify-center">
                            <div className="p-field-checkbox p-mt-2 p-col-4 p-md-3">
                                <Checkbox
                                    inputId="obrigatorio"
                                    name="obrigatorio"
                                    checked={formik.values.obrigatorio}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="obrigatorio" label="Obrigatório?" />
                            </div>
                            <div className="p-field-checkbox p-mt-2 p-col-4 p-md-3">
                                <Checkbox
                                    inputId="numera_clausula"
                                    name="numera_clausula"
                                    checked={formik.values.numera_clausula}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="numera_clausula" label="Numera cláusula?" />
                            </div>
                            <div className="p-field-checkbox p-mt-2 p-col-4 p-md-3">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="ativo" label="Ativo?" />
                            </div>
                        </div>
                        <CamposObrigatorios />
                        <MakoActionsButtons>
                            <Button
                                label="Gravar"
                                icon={MAKO_ICONS.GRAVAR}
                                type="submit"
                                className="p-button-info"
                                loading={loading}
                            />
                            <Button
                                label="Cancelar"
                                type="reset"
                                icon={MAKO_ICONS.CANCEL}
                                onClick={() => fecharEdicao()}
                                className="p-button-danger"
                                loading={loading}
                            />
                        </MakoActionsButtons>
                    </form>
                </Dialog>
            </PageBase>
        </>
    );
};
