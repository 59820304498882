import React, { useCallback, useEffect, useState } from "react";
import { PickList } from "primereact/picklist";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { dataToStr, parseData } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const OPCOES_FILTRO = [
    { value: 1, label: "Categoria" },
    { value: 2, label: "Busca avançada" },
    { value: 3, label: "Todos" },
];

const OPCOES_BUSCA_PRODUTOS = [
    { value: "todos", label: "Todos os produtos cadastrados" },
    { value: "todos-sem-preco", label: "Todos os produtos sem preço base" },
    { value: "todos-sem-custo", label: "Todos os produtos sem custo base" },
];

export const CustoProdutoPage = () => {
    const [filtro, setFiltro] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [produtoBuscaPersonalizada, setProdutoBuscaPersonalizada] = useState(null);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const [filtroBuscaTodos, setFiltroBuscaTodos] = useState(null);
    const [novaVigencia, setNovaVigencia] = useState(null);
    const [atualizarPrecoBase, setAtualizarPrecoBase] = useState(true);
    const [labelBotaoBusca, setLabelBotaoBusca] = useState("Buscar");
    const [pagina, setPagina] = useState(0);
    const { httpGet, httpPut } = useHttp();
    const { showSuccess, showInfo, showWarning, showError } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    async function processarPrecos() {
        const skus = [...new Set(produtosSelecionados.map((prod) => prod.id))];
        const body = {
            skus,
            atualizar_preco_base: atualizarPrecoBase,
            nova_vigencia_preco: novaVigencia,
        };
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Os preços estão sendo calculados, você será notificado quando finalizar.",
                    life: 1500,
                });
            },
            error: ({ status, err }) => {
                if (status <= 499 && err.msg) {
                    showWarning({
                        summary: "Opsss",
                        detail: err.msg,
                        life: 2000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível recalcular os preços dos produtos.",
                        life: 3000,
                    });
                }
            },
        };
        await httpPut({ url: "/produtos/calcular-preco-produtos/", body }, handlers);
    }

    const renderizarBuscaProdutos = () => {
        switch (filtro) {
            case 1:
                return (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <Label htmlFor="categoria" label="Selecione uma categoria" obrigatorio />
                            <MakoDropdownCategoriasHierarquicas
                                id="categoria"
                                buscarTodas
                                categoriaTituloSelecionavel
                                value={categoriaSelecionada}
                                onChange={(e) => setCategoriaSelecionada(e.value)}
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <>
                        <MakoBuscaSkuPersonalizada
                            skuValue={produtoBuscaPersonalizada}
                            skuChange={(e) => setProdutoBuscaPersonalizada(e)}
                        />
                        <Button
                            type="button"
                            icon="pi pi-plus"
                            label="Adicionar"
                            severity="success"
                            onClick={() => {
                                if (!(produtoBuscaPersonalizada instanceof Object)) return;
                                const { id, codigo, descricao_reduzida } = produtoBuscaPersonalizada;
                                const existe = produtosSelecionados.some((el) => el.codigo === codigo);
                                if (existe) return;
                                setProdutosSelecionados((old) => [...old, { id, codigo, descricao_reduzida }]);
                                setProdutoBuscaPersonalizada(null);
                            }}
                            className="p-mb-3"
                        />
                    </>
                );
            case 3:
                return (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <Label htmlFor="todos" label="Campo de busca" obrigatorio />
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="todos"
                                    options={OPCOES_BUSCA_PRODUTOS}
                                    value={filtroBuscaTodos}
                                    onChange={(e) => {
                                        setFiltroBuscaTodos(e.value);
                                        setLabelBotaoBusca("Buscar");
                                        setPagina(0);
                                    }}
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-search"
                                    loading={loading}
                                    label={labelBotaoBusca}
                                    onClick={buscarProdutos}
                                />
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const itemTemplate = (item) => {
        return <span>{`${item.codigo} - ${item.descricao_reduzida}`}</span>;
    };

    const buscarProdutosComPaginacao = async (urlBase) => {
        const handlers = {
            200: ({ data }) => {
                const { next, results } = data;
                const produtos = results.map(({ id, codigo, descricao_reduzida }) => ({
                    id,
                    codigo,
                    descricao_reduzida,
                }));
                setProdutos((old) => [...old, ...produtos]);
                setPagina((old) => old + 1);
                if (pagina + 1 >= 1) setLabelBotaoBusca("Buscar mais");
                if (next) {
                    showInfo({
                        summary: "Info",
                        detail: 'Clique em "Buscar" para trazer os próximos 50 produtos.',
                        life: 1500,
                    });
                }
            },
        };
        const LIMIT = 50;
        const url = `${urlBase}&limit=${LIMIT}&offset=${LIMIT * pagina}`;
        showLoading();
        await httpGet({ url }, handlers);
        hideLoading();
    };

    const buscarProdutos = async () => {
        if (filtro !== 3) return;
        if (filtroBuscaTodos === "todos") {
            const url = `/produtos/sku/?status=F&query={id,codigo,descricao_reduzida}`;
            await buscarProdutosComPaginacao(url);
        }
        if (filtroBuscaTodos?.startsWith("todos-sem")) {
            const filtroPreco = filtroBuscaTodos === "todos-sem-preco" ? "preco_base" : "custo_base";
            const url = `/produtos/sku/?precosku__${filtroPreco}=0&status=F&query={id,codigo,descricao_reduzida}`;
            await buscarProdutosComPaginacao(url);
        }
    };

    const buscarProdutosPorCategoria = useCallback(async () => {
        if (filtro === 1 && categoriaSelecionada) {
            const url = `/produtos/sku-busca-personalizada/?tipo_busca=0&busca=a&status=F&categoria=${categoriaSelecionada}`;
            const handlers = {
                200: ({ data }) => {
                    const produtos = data.results.map(({ id, codigo, descricao_reduzida }) => ({
                        id,
                        codigo,
                        descricao_reduzida,
                    }));
                    setProdutos(produtos);
                },
            };
            await httpGet({ url }, handlers);
        }
    }, [filtro, categoriaSelecionada, httpGet]);

    useEffect(() => {
        buscarProdutosPorCategoria();
    }, [buscarProdutosPorCategoria]);

    const vigenciaOpcaoTemplate = (option) => {
        const { id, descricao, data_inicio, data_fim } = option;
        const datas = [dataToStr(data_inicio, "dd/MM/yyyy")];
        if (data_fim) datas.push(dataToStr(data_fim, "dd/MM/yyyy"));
        return <span>{`${id} - ${datas.join(" - ")} ${descricao}`}</span>;
    };

    const vigenciaSelecionadaTemplate = (option, props) => {
        if (option) return vigenciaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const filtrarVigenciasAtivas = useCallback((vigencias) => {
        return vigencias.filter(({ data_inicio, data_fim }) => {
            const dataInicio = parseData(data_inicio);
            const dataFim = parseData(data_fim);
            return dataInicio.getTime() <= new Date().getTime() && (dataFim?.getTime() >= new Date().getTime() || true);
        });
    }, []);

    return (
        <PageBase>
            <h5>Processamento de custo e preço de venda</h5>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="filtro" label="Filtro" obrigatorio />
                    <Dropdown id="filtro" options={OPCOES_FILTRO} value={filtro} onChange={(e) => setFiltro(e.value)} />
                </div>
            </div>
            {renderizarBuscaProdutos()}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <PickList
                        source={produtos}
                        target={produtosSelecionados}
                        sourceHeader={`Produtos filtrados (${produtos.length})`}
                        targetHeader={`Produtos selecionados (${produtosSelecionados.length})`}
                        showSourceControls={false}
                        showTargetControls={false}
                        itemTemplate={itemTemplate}
                        onChange={(e) => {
                            setProdutos(e.source);
                            setProdutosSelecionados(e.target);
                        }}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid p-justify-end p-align-end">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="atualizar-preco-base" label="Atualizar preço base" />
                    <SelectButton
                        id="atualizar-preco-base"
                        options={SIM_NAO_BOOLEAN}
                        optionValue="id"
                        value={atualizarPrecoBase}
                        onChange={(e) => setAtualizarPrecoBase(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="nova-vigencia" label="Nova vigência" />
                    <Dropdown
                        id="nova-vigencia"
                        url="/produtos/precos-vigencia/"
                        aposBuscar={filtrarVigenciasAtivas}
                        optionValue="id"
                        optionLabel="descricao"
                        valueTemplate={vigenciaSelecionadaTemplate}
                        itemTemplate={vigenciaOpcaoTemplate}
                        value={novaVigencia}
                        onChange={(e) => setNovaVigencia(e.value)}
                    />
                </div>
                <div className="p-md-2">
                    <Button
                        type="button"
                        label="Processar"
                        icon="pi pi-bolt"
                        disabled={!produtosSelecionados.length}
                        onClick={processarPrecos}
                    />
                </div>
            </div>
        </PageBase>
    );
};
