import React, { useMemo } from "react";

import classNames from "classnames";

import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Calendar as MakoCalendarExperimental } from "@/components/Calendar";
import { Label } from "@/components/Label";

export const MakoInputPeriodo = ({
    label,
    nameInicio,
    valueInicio,
    errorInicio,
    onChangeInicio,
    nameFinal,
    valueFinal,
    errorFinal,
    onChangeFinal,
    disabled = false,
    obrigatorio = false,
    withTime = false,
    makoDate = false, // utiliza o componente novos de datas EXPERIMENTAL
}) => {
    const Calendar = useMemo(() => {
        if (!withTime) return !makoDate ? MakoCalendar : MakoCalendarExperimental;
        return MakoCalendarTime;
    }, [withTime, makoDate]);

    return (
        <>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor={nameInicio} label={label} obrigatorio={obrigatorio} />
                <Calendar
                    id={nameInicio}
                    name={nameInicio}
                    valueCalendar={valueInicio}
                    onChange={onChangeInicio}
                    className={classNames({ "p-invalid": errorInicio })}
                    disabled={disabled}
                />
                {errorInicio && <small className="p-error">{errorInicio}</small>}
            </div>
            <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                <Calendar
                    id={nameFinal}
                    name={nameFinal}
                    valueCalendar={valueFinal}
                    onChange={onChangeFinal}
                    className={classNames({ "p-invalid": errorFinal })}
                    disabled={disabled}
                />
                {errorFinal && <small className="p-error">{errorFinal}</small>}
            </div>
        </>
    );
};
