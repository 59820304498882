import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import AppSubmenu from "../AppSubmenu";
import { InputFiltro } from "./inputFiltro";

import useAuth from "@/hooks/useAuth";
import useMenuFavoritos from "@/hooks/useMenuFavoritos";

const AppMenu = (props) => {
    const { favoritos, handler: handlerFavoritos } = useMenuFavoritos();
    const [search, setSearch] = useState("");
    const [menu, setMenu] = useState([]);

    const { verifyPermission, user } = useAuth();

    const doubleClickTimeoutRef = useRef(null);
    const rightClickTimeRef = useRef(null);

    const verificarMenu = useCallback(
        (array) => {
            let i = array.length;
            while (i--) {
                array[i].items && verificarMenu(array[i].items);

                if (array[i].permissions) {
                    let remover = verifyPermission(array[i].permissions);

                    if (array[i].permissions && !remover) {
                        array.splice(i, 1);
                        continue;
                    }
                }
            }

            return array;
        },
        [verifyPermission]
    );

    const formatarMenu = useCallback((array) => {
        let i = array.length;
        while (i--) {
            array[i].items && formatarMenu(array[i].items);
            if (array[i].items && array[i].items.length === 0) {
                array.splice(i, 2);
                continue;
            }
        }

        return array;
    }, []);

    const filtredMenu = useMemo(() => {
        const verificarSearch = (value, pesquisa) => {
            if (!value.to) return value.label.toLowerCase().includes(pesquisa);
            return value.label.toLowerCase().includes(pesquisa) || value.to.includes(pesquisa);
        };

        function pesquisar(menu) {
            const result = [];

            function buscarDentro(menu) {
                if (menu.separator) return false;
                if (verificarSearch(menu, search.toLowerCase())) return true;
                if (menu.items) {
                    return menu.items.some((item) => {
                        if (verificarSearch(item, search.toLowerCase())) return true;
                        if (item.items) return buscarDentro(item);
                        return false;
                    });
                }
            }
            menu.forEach((item) => {
                if (buscarDentro(item)) {
                    result.push(item);
                }
            });
            return result;
        }
        let data = menu;
        if (search.length > 2) {
            return pesquisar(menu);
        }
        const _favoritos = Object.keys(favoritos || {}).map((key) => favoritos[key]);
        if (!!_favoritos?.at(0)?.items?.length) return [..._favoritos, ...data];
        return data;
    }, [menu, search, favoritos]);

    const onRightClick = useCallback(
        (e, item) => {
            const currentTime = new Date().getTime();
            if (rightClickTimeRef.current && currentTime - rightClickTimeRef.current < 300) {
                clearTimeout(doubleClickTimeoutRef.current);
                e.preventDefault();
                rightClickTimeRef.current = null;
                handlerFavoritos(item);
            } else {
                rightClickTimeRef.current = currentTime;
                doubleClickTimeoutRef.current = setTimeout(() => {
                    rightClickTimeRef.current = null;
                }, 500);
            }
        },
        [handlerFavoritos]
    );

    const onDragEnd = useCallback(
        (e, item) => {
            e?.preventDefault();
            handlerFavoritos(item);
        },
        [handlerFavoritos]
    );

    useEffect(() => {
        if (props.model) {
            let menuProvisorio = verificarMenu(props.model);
            menuProvisorio = formatarMenu(menuProvisorio);
            setMenu(menuProvisorio);
        }
    }, [props.model, verificarMenu, formatarMenu, user]);

    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <img
                    id="app-logo"
                    className="logo-image"
                    src={`${process.env.PUBLIC_URL}/assets/layout/images/logos-mako/logo_sysmako_ROSA_BRANCO.png`}
                    style={{ width: "120px", height: "80px" }}
                    alt="mako layout"
                />
            </Link>
            <div className="layout-menu-container">
                <InputFiltro value={search} onChange={({ target }) => setSearch(target.value)} />
                <AppSubmenu
                    items={filtredMenu}
                    menuMode={props.menuMode}
                    parentMenuItemActive
                    menuActive={props.active}
                    mobileMenuActive={props.mobileMenuActive}
                    root
                    onMenuitemClick={props.onMenuitemClick}
                    onRootMenuitemClick={props.onRootMenuitemClick}
                    onRightClick={onRightClick}
                    onDragEnd={onDragEnd}
                />
            </div>
        </div>
    );
};

export default AppMenu;
