import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const url = "/compras/categorias-ordens-compra/";

export const CategoriaOcForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();

    const categoriaVazia = {
        id: "",
        nome: "",
        requer_financeiro: true,
    };

    const formik = useFormik({
        initialValues: props.location && props.location.state ? props.location.state.categoria : categoriaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Categoria cadastrada com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Categoria alterada com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltar = () => {
        formik.resetForm();
        history.push("/compras/ordens-compra/categorias-ordens-compra");
    };

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Nova categoria de ordem de compras"
                    : "Manutenção de categoria de ordem de compras"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="requer_financeiro" label="Requer financeiro" obrigatorio />
                        <SelectButton
                            id="requer_financeiro"
                            name="requer_financeiro"
                            value={formik.values.requer_financeiro}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={voltar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
