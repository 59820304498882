import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { ListagemModal } from "./listagem";
import { Label } from "@/components/Label";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { InputNumber } from "primereact/inputnumber";
import * as Yup from "yup";
import { dataToStr } from "@/assets/util/datas";

const SaldoRegulador = ({ onFechar, transferenciaId }, ref) => {
    const [visible, setVisible] = useState(false);
    const [necessidadeEstoque, setNecessidadeEstoque] = useState([]);
    const listagemRef = useRef(null);
    const { httpPost } = useHttp();
    const { showSuccess, showError, showInfo } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const { resetForm, ...formik } = useFormik({
        initialValues: {
            estoque_ate: null,
            objetivo: "maximo",
            media_dias: null,
        },
    });

    async function inserirItens() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Itens inseridos com sucesso.",
                        life: 1500,
                    });
                    if (typeof onFechar === "function") onFechar();
                    fecharModal();
                },
            };

            showLoading();
            await httpPost(
                { url: `/transferencias/itens-necessidade-estoque/${transferenciaId}/`, body: necessidadeEstoque },
                handlers
            );
            hideLoading();
        } catch {
            showError();
        }
    }

    async function calcularNecessidade(values) {
        try {
            const formSchema = Yup.object().shape({
                estoque_ate: Yup.date().required("O campo é obrigatório.").typeError("Informe uma data válida."),
                objetivo: Yup.string().required("O campo é obrigatório."),
                media_dias: Yup.number().required("O campo é obrigatório.").typeError("Informe um número válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const handlers = {
                200: ({ data }) => {
                    const { results } = data;
                    if (results?.length === 0)
                        showInfo({
                            summary: "Informação",
                            detail: "Nenhum registro necessidade de estoque regulador encontrada.",
                            life: 1500,
                        });
                    setNecessidadeEstoque(results);
                },
            };

            showLoading();
            await httpPost(
                {
                    url: `/transferencias/necessidade-estoque-regulador/${transferenciaId}/`,
                    body: { ...values, estoque_ate: dataToStr(values.estoque_ate, "yyyy-MM-dd") },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Adicionando itens conforme estoque regulador"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "80vw" }}
            breakpoints={{ "1080px": "90vw" }}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="estoque_ate" label="Estoque até o dia" />
                        <MakoCalendar
                            id="estoque_ate"
                            name="estoque_ate"
                            value={formik.values.estoque_ate}
                            onChange={formik.handleChange}
                            minDate={new Date()}
                            className={classNames({ "p-invalid": formik.errors.estoque_ate })}
                        />
                        {formik.errors.estoque_ate && <small className="p-error">{formik.errors.estoque_ate}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="media_dias" label="Média de vendas" />
                        <InputNumber
                            id="media_dias"
                            name="media_dias"
                            maxFractionDigits={0}
                            suffix=" dias"
                            value={formik.values.media_dias}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.media_dias })}
                        />
                        {formik.errors.media_dias && <small className="p-error">{formik.errors.media_dias}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="objetivo" label="Objetivo" />
                        <div className="p-inputgroup">
                            <Dropdown
                                id="objetivo"
                                name="objetivo"
                                options={[
                                    { value: "maximo", label: "Saldo máximo" },
                                    { value: "minimo", label: "Saldo mínimo" },
                                ]}
                                value={formik.values.objetivo}
                                onChange={formik.handleChange}
                                showClear={false}
                                className={classNames({ "p-invalid": formik.errors.objetivo })}
                            />
                            <Button
                                type="button"
                                label="Calcular"
                                icon="pi pi-plus"
                                loading={loading}
                                severity="success"
                                className="p-ml-3"
                                onClick={() => calcularNecessidade(formik.values)}
                            />
                        </div>
                        {formik.errors.objetivo && <small className="p-error">{formik.errors.objetivo}</small>}
                    </div>
                </div>
            </form>
            <ListagemModal
                listagemRef={listagemRef}
                dados={necessidadeEstoque}
                onClickBotaoRemover={(id) => {
                    setNecessidadeEstoque(necessidadeEstoque.filter(({ item }) => item.id !== id));
                }}
            />
            <div className="p-grid p-justify-end p-mt-3 p-mr-2">
                <Button
                    type="button"
                    label="Inserir"
                    icon="pi pi-plus"
                    loading={loading}
                    severity="info"
                    onClick={() => inserirItens()}
                />
            </div>
        </Dialog>
    );
};

export const ModalSaldoRegulador = forwardRef(SaldoRegulador);
