import React, { useCallback, useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { NATUREZA_PLANO_CONTAS, SIM_NAO_BOOLEAN, TIPO_INDICADOR_CONTA_CONTABIL } from "@/assets/constants/constants";
import classNames from "classnames";
import * as Yup from "yup";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { MakoInputCodigoAleatorio } from "@/components/MakoInputs";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { SelectButton } from "primereact/selectbutton";

const urlvoltar = "/contabil/plano-contas";
const urlPlanoContas = "/financeiro/planos-contas-contabeis/?conta_titulo=true";

export const ContabilPlanoContasForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { empresaSelecionada } = useEmpresa();
    const [planos, setPlanos] = useState([]);
    const { showSuccess } = useToast();
    const { httpPost, httpPatch, httpGet } = useHttp();

    const history = useHistory();

    const planoVazio = {
        codigo: "",
        descricao: "",
        natureza: "",
        nivel: "",
        estrutura: "",
        conta_titulo: false,
        versao: null,
        data_criacao: new Date(),
        data_alteracao: null,
        indicador: "A",
        ativo: true,
    };

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: props.location.state?.planContas
            ? { ...props.location.state.planContas.data, versao: props.location.state.planContas.data.versao.id }
            : planoVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const nivel = values.estrutura.split(".");
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                natureza: Yup.string().required("O campo 'natureza' é obrigatório."),
                estrutura: Yup.string().required("O campo 'estrutura' é obrigatório."),
                plano_conta_contabil: Yup.number()
                    .when("conta_titulo", {
                        is: (val) => !val,
                        then: Yup.number().required("Necessário selecionar uma conta pai."),
                    })
                    .nullable(),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de contas cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push({
                            pathname: urlvoltar,
                            state: { versaoId: props.location.versaoId },
                        });
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: "/financeiro/planos-contas-contabeis/",
                        body: {
                            ...values,
                            empresas: empresaSelecionada.id,
                            nivel: nivel.length,
                            versao: props.location.versaoId,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de contas alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push({
                            pathname: urlvoltar,
                            state: { versaoId: values.versao },
                        });
                    },
                };
                showLoading();
                await httpPatch(
                    {
                        url: `/financeiro/planos-contas-contabeis/${values.id}/`,
                        body: {
                            ...values,
                            data_alteracao: new Date(),
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            }
        }
    }

    const verificaContaPài = useCallback(
        async (conta) => {
            if (!conta) return;
            const handlers = {
                200: ({ data }) => {
                    setFieldValue("natureza", data.natureza);
                    setFieldValue("plano_conta_contabil", data.id);
                },
            };
            showLoading();
            await httpGet({ url: `/financeiro/planos-contas-contabeis/${conta}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, setFieldValue, httpGet]
    );

    const getPlanoContas = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setPlanos(data.results),
        };
        showLoading();
        await httpGet({ url: urlPlanoContas }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, httpGet]);

    function optionValidate(value) {
        return value.natureza !== formik.values.natureza;
    }

    const cancelar = () => {
        resetForm();
        history.push(urlvoltar);
    };

    const onGerarCodigo = useCallback((codigo) => setFieldValue("codigo", codigo), [setFieldValue]);

    useEffect(() => {
        getPlanoContas();
        if (props.location?.contaId) verificaContaPài(props.location.contaId);
        else if (props.location?.state?.planContas?.data)
            verificaContaPài(props.location?.state?.planContas?.data.plano_conta_contabil);
    }, [setFieldValue, getPlanoContas, verificaContaPài, props.location?.contaId, props.location?.state?.planContas]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Inserir plano de contas" : "Manutenção de plano de contas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="natureza" label="Natureza" obrigatorio />
                        <Dropdown
                            id="natureza"
                            name="natureza"
                            placeholder="D/C"
                            options={NATUREZA_PLANO_CONTAS}
                            optionValue="id"
                            optionLabel="label"
                            autoFocus
                            value={formik.values.natureza}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza })}
                        />
                        {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="plano_conta_contabil" label="Conta pai" obrigatorio />
                        <Dropdown
                            id="plano_conta_contabil"
                            name="plano_conta_contabil"
                            value={formik.values.plano_conta_contabil}
                            onChange={formik.handleChange}
                            filter
                            filterBy="descricao"
                            placeholder="Selecione uma conta pai"
                            optionLabel="descricao"
                            optionValue="id"
                            optionDisabled={optionValidate}
                            disabled={formik.values.natureza ? false : true}
                            options={planos}
                            className={classNames({ "p-invalid": formik.errors.plano_conta_contabil })}
                        />
                        {formik.errors.plano_conta_contabil && (
                            <small className="p-error">{formik.errors.plano_conta_contabil}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="conta_titulo" label="Conta título" />
                        <SelectButton
                            id="conta_titulo"
                            name="conta_titulo"
                            value={formik.values.conta_titulo}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <MakoInputCodigoAleatorio
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onInput={formik.handleChange}
                            onGerarCodigo={onGerarCodigo}
                            apenasNumeros={false}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            tooltip="Descricao que será dado ao Plano de Contas."
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="estrutura" label="Estrutura" obrigatorio />
                        <InputText
                            id="estrutura"
                            name="estrutura"
                            value={formik.values.estrutura}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estrutura })}
                            tooltip="Estrutura que será dada ao plano de contas. (Ex: 1.1.1)"
                            autoComplete="off"
                        />
                        {formik.errors.estrutura && <small className="p-error">{formik.errors.estrutura}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="indicador" label="Indicador" />
                        <Dropdown
                            id="indicador"
                            name="indicador"
                            options={TIPO_INDICADOR_CONTA_CONTABIL}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            value={formik.values.indicador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador })}
                        />
                        {formik.errors.indicador && <small className="p-error">{formik.errors.indicador}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_criacao" label="Data criação" />
                        <MakoCalendarTime
                            id="data_criacao"
                            name="data_criacao"
                            valueCalendar={formik.values.data_criacao}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_alteracao" label="Data alteração" />
                        <MakoCalendarTime
                            id="data_alteracao"
                            name="data_alteracao"
                            valueCalendar={formik.values.data_alteracao}
                            disabled
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
