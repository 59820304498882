import React, { useMemo, useRef, useState } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    NumeroFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { OPCOES_PESQUISA_BOOLEAN } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/produtos/formacoes-precos/";

export const FormacaoPrecoPage = () => {
    const [filtro, setFiltro] = useState(1);

    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FORMACAOPRECO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de formação de preços"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/vendas/cadastros/formacao-precos/form",
                        state: { formacao: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url="/produtos/formacoes-precos"
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="formação de preços"
                    ativarOptions={{ disabled: rowData.ativo }}
                    desativarOptions={{ disabled: !rowData.ativo }}
                    onAction={() => listagemRef.current?.buscarDados()}
                    visualizarOptions={false}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                {"Deseja realmente excluir a formação de preços "}
                                <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A formação de preços não pôde ser excluída.",
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const url = useMemo(() => {
        if (filtro === 0) return BASE_URL;
        return `${BASE_URL}?${gerarFiltrosUrl({ ativo: filtro === 1 })}`;
    }, [filtro]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_FORMACAOPRECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/vendas/cadastros/formacao-precos/form"}
            />
            <Dropdown
                value={filtro}
                options={OPCOES_PESQUISA_BOOLEAN}
                placeholder="Selecione"
                showClear={false}
                onChange={({ value }) => setFiltro(value)}
            />
        </>
    );

    const PorcentagemFiltroTemplate = (options) => {
        return (
            <NumeroFiltroTemplate
                inputProps={{
                    mode: "decimal",
                    suffix: " %",
                    min: 0,
                    maxFractionDigits: 5,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "15%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "margem_lucro",
            header: "Margem lucro",
            style: { width: "10%" },
            percent: true,
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "encargo_alvo",
            header: "Encargo mensal",
            style: { width: "10%" },
            percent: true,
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "usar_markup",
            header: "Usa Markup",
            style: { width: "10%" },
            filter: true,
            action: ({ usar_markup }) => (usar_markup ? "Sim" : "Não"),
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "indice_markup",
            header: "Indice Markup",
            style: { width: "10%" },
            percent: true,
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de formação de preços"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    margem_lucro: { value: null, matchMode: "equals" },
                    encargo_alvo: { value: null, matchMode: "equals" },
                    indice_markup: { value: null, matchMode: "equals" },
                    usar_markup: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
