import React, { useState, useRef } from "react";
import { Button } from "primereact/button";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";
import { BUTTON_LISTAGEM, BUTTON_LISTAGEM_OPCOES } from "@/assets/constants/presets";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonMenuPopup } from "@/components/ButtonMenuPopup";
import { Delete } from "@/components/Delete";
import useAuth from "@/hooks/useAuth";

import { ModalComissaoMontagem } from "./modal-comissao-montagem";

export const ComissaoMontagemPage = () => {
    const [montagemSelecionada, setMontagemSelecionada] = useState(null);
    const listagemRef = useRef();
    const modalComissaoRef = useRef();
    const deleteRef = useRef();
    const { verifyPermission } = useAuth();

    const itens = [
        {
            label: "Editar comissão",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_MONTAGEM_EDITAR),
            command: async () => await modalComissaoRef.current?.abrirModal(montagemSelecionada),
        },
        {
            label: "Alterar valor",
            disabled: true || !verifyPermission(permissoes.VENDAS_COMISSAO_MONTAGEM_EDITAR),
            // command: () => setDialogConhecimento("210210"),
        },
        {
            label: "Excluir comissão",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_MONTAGEM_EXCLUIR),
            command: () => deleteRef.current?.handleClick(),
        },
    ];

    const pagoBodyTemplate = (rowData) => {
        return rowData.pago ? <span>Sim</span> : <span>Não</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button {...BUTTON_LISTAGEM} icon="pi pi-eye" severity="help" />
                <ButtonMenuPopup
                    {...BUTTON_LISTAGEM_OPCOES}
                    menu={{ model: itens }}
                    aria-controls="menu-popup-nfe"
                    onClick={() => setMontagemSelecionada(rowData)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "item_entregue.venda_id", header: "Venda", style: { width: "6%" } },
        { field: "devolucao_id", header: "Devolução", style: { width: "6%" } },
        { field: "datahora_montagem", header: "Data", dateFormat: "dd/MM/yyyy", style: { width: "8%" } },
        { field: "item_entregue.sku.codigo", header: "Produto", style: { width: "8%" } },
        { field: "item_entregue.sku.descricao_reduzida", header: "Descrição" },
        { field: "executor.nome", header: "Montador" },
        { field: "valor_comissao", header: "Valor", money: true, style: { width: "10%" } },
        { field: "pago", header: "Pago", style: { width: "3%" }, action: pagoBodyTemplate },
        { field: "actions", header: "Ações", action: actionBodyTemplate },
    ];

    const rowClass = (comissao) => {
        return {
            "table-recebimentos-pending": comissao.pago,
            "table-recebimentos-overdue": comissao.valor < 0,
        };
    };

    const cabecalhoTabela = (
        <>
            <Button
                icon={MAKO_ICONS.NOVO}
                label="Nova"
                severity="success"
                className="p-mr-2"
                onClick={() => modalComissaoRef.current?.abrirModal()}
            />
            <Button icon={MAKO_ICONS.FILTRAR} disabled label="Filtro Avançado" severity="help" />
        </>
    );

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Comissão sobre montagem"
                urlPesquisa="/vendas/montagens/"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                configTabela={{ lazy: true, paginator: true, rowClassName: rowClass }}
            />
            <ModalComissaoMontagem ref={modalComissaoRef} onGravar={() => listagemRef.current?.buscarDados()} />
            <Delete
                ref={deleteRef}
                url="/vendas/montagens/"
                objetoId={montagemSelecionada?.id}
                exigeConfirmacao
                msgConfirmacao={<span>Deseja realmente deletar este registro de montagem?</span>}
                msgToastErroExclusao="O registro de montagem não pôde ser excluído."
                onDelete={() => listagemRef.current?.buscarDados()}
            />
        </PageBase>
    );
};
