import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { SIM_NAO_BOOLEAN, TIPO_PRECO_SERVICO } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";

const url = "/vendas/servicos-vendas/";

export const VendasServicosForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: props.location?.state?.servico
            ? props.location.state.servico
            : {
                  id: "",
                  nome: "",
                  descricao: "",
                  incorporado_sku: false,
                  tipo_preco_servico: "F",
                  valor: 0,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                valor: Yup.number()
                    .min(0, "O campo 'valor' não pode ser ZERO.")
                    .required("O campo 'valor' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrado com sucesso!",
                            life: 1500,
                        });
                        history.push("/vendas/cadastros/servicos");
                    },
                    400: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        console.log("teste");
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço alterado com sucesso!",
                            life: 1500,
                        });
                        history.push("/vendas/cadastros/servicos");
                    },
                    400: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
        }
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Serviço" : "Manutenção de Serviço"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="descricao" label="Descrição" />
                        <InputTextarea
                            id="descricao"
                            name="descricao"
                            rows={3}
                            cols={20}
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="incorporado_sku" label="Incoporado ao preço do produto" obrigatorio />
                        <Dropdown
                            id="incorporado_sku"
                            name="incorporado_sku"
                            value={formik.values.incorporado_sku}
                            options={SIM_NAO_BOOLEAN}
                            placeholder="Selecione..."
                            optionLabel="label"
                            optionValue="id"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.incorporado_sku })}
                            tooltip="Marque essa opção como 'Sim', se o serviço for oferecido
                                    somente com um produto agregado."
                        />
                        {formik.errors.incorporado_sku && (
                            <small className="p-error">{formik.errors.incorporado_sku}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo-preco-servico" label="Tipo de precificação do serviço" obrigatorio />
                        <Dropdown
                            id="tipo-preco-servico"
                            name="tipo_preco_servico"
                            options={TIPO_PRECO_SERVICO}
                            optionValue="id"
                            optionLabel="label"
                            placeholder="Selecione"
                            value={formik.values.tipo_preco_servico}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_preco_servico })}
                        />
                        {formik.errors.tipo_preco_servico && (
                            <small className="p-error">{formik.errors.tipo_preco_servico}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label
                            htmlFor="valor"
                            label={formik.values.tipo_preco_servico === "P" ? "Percentual (%)" : "Valor (R$)"}
                            obrigatorio
                        />
                        {formik.values.tipo_preco_servico === "P" ? (
                            <InputNumber
                                id="valor"
                                name="valor"
                                suffix="%"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                min={0}
                                value={formik.values.valor}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                        ) : (
                            <MakoInputMoeda
                                id="valor"
                                name="valor"
                                onChangeMoeda={formik.handleChange}
                                valueMoeda={formik.values.valor}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                                tooltip="Definir Preço Medida caso o valor do serviço for calculado posteriormente com as medidas do produto."
                            />
                        )}
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={() => history.push("/vendas/cadastros/servicos")}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
