import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const ConsultarOrdemCompraPage = () => {
    const [mostrarPesquisa, setMostrarPesquisa] = useState(false);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [nome, setNome] = useState(null);
    const [data, setData] = useState([]);
    const { showWarning, showSuccess, showError } = useToast();
    const { empresaSelecionada } = useEmpresa();
    const { httpGet } = useHttp();

    async function handleSearch() {
        try {
            let filtro = "?";
            let multiFiltro = false;
            let data_inicio, data_final, inicio, fim;

            if (dataInicio) data_inicio = dataToStr(dataInicio, "yyyy-MM-dd");
            if (dataFinal) data_final = dataToStr(dataFinal, "yyyy-MM-dd");

            if (dataInicio && dataFinal) {
                inicio = data_inicio.split("/");
                fim = data_final.split("/");
                inicio = inicio[1] + inicio[0];
                fim = fim[1] + fim[0];
            }

            if (dataInicio && dataFinal && fim < inicio) {
                showWarning({
                    summary: "Mako",
                    detail: "Datas inválidas! Informe um período de nascimento válido.",
                    life: 4000,
                });
            } else {
                if (nome) {
                    filtro = `${filtro}${multiFiltro ? "&" : ""}fornecedor__contains='${nome}'`;
                    multiFiltro = true;
                }
                if (dataInicio) {
                    filtro = `${filtro}${multiFiltro ? "&" : ""}data_pedido__gte=${data_inicio}`;
                    multiFiltro = true;
                }
                if (dataFinal) {
                    filtro = `${filtro}${multiFiltro ? "&" : ""}data_pedido__lte=${data_final}`;
                    multiFiltro = true;
                }

                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Relatório gerado com sucesso!",
                            life: 1500,
                        });
                        setData(data.results);
                        setMostrarPesquisa(true);
                    },
                };

                await httpGet({ url: `/compras/ordens-compra/${filtro}` }, handlers);
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const limparFiltro = () => {
        setNome(null);
        setDataInicio(null);
        setDataFinal(null);
    };

    const print = () => {
        window.print();
    };

    const resultadoPessoas = data.map((pessoa) => {
        const { id, nome, tipo_pessoa, identificacao, telefoneperfil_set, emailperfil_set } = pessoa;

        return (
            <tr key={id}>
                <td>{id}</td>
                <td>{nome}</td>
                <td>{tipo_pessoa}</td>
                <td>{identificacao}</td>
                <td>{telefoneperfil_set.length > 0 ? telefoneperfil_set[0].telefone : "N/A"}</td>
                <td>{emailperfil_set.length > 0 ? emailperfil_set[0].email : "N/A"}</td>
            </tr>
        );
    });

    return (
        <div>
            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <h5>Filtro para emissão</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-5 p-md-2">
                                <label htmlFor="data_inicio">Nascimento entre</label>
                                <MakoCalendar
                                    id="data_inicio"
                                    name="data_inicio"
                                    value={dataInicio}
                                    autoFocus
                                    onChange={(e) => setDataInicio(e.target.value)}
                                />
                            </div>
                            <h6 className="p-mt-8">à</h6>
                            <div className="p-field p-col-5 p-md-2 p-mt-5">
                                <MakoCalendar
                                    id="data_final"
                                    name="data_final"
                                    value={dataFinal}
                                    onChange={(e) => setDataFinal(e.target.value)}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="nome">Nome {/*contém*/}</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    keyfilter={/^[a-zA-Z ]/}
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button
                                label="Pesquisar"
                                icon="pi pi-search"
                                type="button"
                                onClick={() => handleSearch()}
                                className="p-button-success p-mr-2 p-mb-6"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => limparFiltro()}
                                className="p-button-warning p-mr-2 p-mb-6"
                            />
                            <Button
                                type="button"
                                label="Imprimir"
                                icon="pi pi-print"
                                onClick={print}
                                disabled={!mostrarPesquisa}
                                className="p-button-info p-mr-2 p-mb-6"
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>

            {mostrarPesquisa ? (
                <div>
                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card">
                                <div id="invoice-content">
                                    <div className="invoice">
                                        <div className="invoice-header">
                                            <div className="invoice-title">
                                                Ordens de compras
                                                <h6>Listagem simples</h6>
                                            </div>
                                            <div className="invoice-company">
                                                <img
                                                    id="invoice-logo"
                                                    className="logo-image"
                                                    src={"/assets/layout/images/logos-mako/logo_mako_200x112.png"}
                                                    alt="diamond-layout"
                                                />
                                                <div className="company-name">
                                                    {empresaSelecionada.nome.toUpperCase()}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="invoice-to">
                                            <div className="bill-to">Filtros utilizados</div>
                                            <div className="invoice-to-info">
                                                <div>{`${tipoPessoa ? tipoPessoa : ""}`}</div>
                                            </div>
                                        </div> */}
                                        <div className="invoice-items">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Fornecedor</th>
                                                        <th>Representante</th>
                                                        <th>Valor total</th>
                                                        <th>Data pedido</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{resultadoPessoas ? resultadoPessoas : null}</tbody>
                                            </table>
                                        </div>
                                        <div className="invoice-summary">
                                            <div>
                                                <div className="invoice-details" style={{ width: "300px" }}>
                                                    <div className="invoice-label">
                                                        Total de registros: {data.length}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
