import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { ModalReplicarPlanoContas } from "./modalReplicarPlanoContas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { MakoButton as Button } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

export const ContabilPlanoContasPage = ({ location }) => {
    const [planoSelecionado, setPlanoSelecionado] = useState(null);
    const [versaoPlanoContas, setVersaoPlanoContas] = useState(location.state?.versaoId);
    const listagemRef = useRef(null);
    const modalReplicarPlanoContasRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_PLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de contas"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/contabil/plano-contas/form",
                            state: { planContas: rowData, versaoId: versaoPlanoContas },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/planos-contas-contabeis"}
                    objetoId={rowData.data.id}
                    objetoLabel={rowData.data.descricao}
                    prefixo="o"
                    entidade="plano de contas"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o plano de contas <b>{rowData.data.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao:
                            "O plano de contas não pode ser excluído, o mesmo está sendo utilizado por outras informações no sistema.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="versoes"
                name="versoes"
                style={{ width: "25rem" }}
                url="/financeiro/versoes-planos-contas-contabeis/"
                placeholder="Selecione uma versão do plano de contas"
                optionValue="id"
                optionLabel="descricao"
                showClear
                value={versaoPlanoContas}
                onChange={(e) => setVersaoPlanoContas(e.value)}
                className="p-mr-2"
            />
            <MakoControleAcesso
                permissao={[permissoes.CONTABIL_CONTABIL_PLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                disabled={!!!versaoPlanoContas}
                className="p-button-success p-mr-2"
                to={{
                    pathname: "/contabil/plano-contas/form",
                    versaoId: versaoPlanoContas,
                    contaId: planoSelecionado,
                }}
            />
            <Button
                label="Replicar"
                icon="pi pi-copy"
                disabled={!!!versaoPlanoContas}
                className="p-button-help p-mr-2"
                onClick={() => modalReplicarPlanoContasRef.current?.abrirModal(versaoPlanoContas)}
            />
        </>
    );

    const verificaTitulo = (titulo, info) => {
        if (titulo) return <b>{info}</b>;
        return info;
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            expander: true,
            style: { width: "150px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.codigo),
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "200px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.descricao),
        },
        { field: "natureza", header: "Natureza", style: { width: "70px" } },
        { field: "estrutura", header: "Estrutura", style: { width: "70px" } },
        { field: "nivel", header: "Nível", style: { width: "70px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "120px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                titulo="Plano de contas contábil"
                urlPesquisa={
                    versaoPlanoContas &&
                    `/financeiro/planos-contas-contabeis-estruturas/?id_versao=${versaoPlanoContas}`
                }
                colunas={colunas}
                cabecalho={cabecalhoTabela}
                tabelaConfig={{
                    selectionMode: "single",
                    selectionKeys: planoSelecionado,
                    selection: planoSelecionado,
                    onSelectionChange: (e) => setPlanoSelecionado(e.value),
                    scrollable: true,
                }}
            />
            <ModalReplicarPlanoContas ref={modalReplicarPlanoContasRef} />
        </PageBase>
    );
};
