import React, { createContext, useCallback, useState } from "react";
import useEmpresa from "@/hooks/useEmpresa";
import * as pd from "@/assets/util/persistenciaDjango";
import useHttp from "@/hooks/useHttp";

const NotaFiscalContext = createContext({});

export const NotaFiscalProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [notaFiscal, setNotaFiscal] = useState({});
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { empresaSelecionada } = useEmpresa();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const handleDados = useCallback(
        (dados) => {
            setNotaFiscal({
                ...notaFiscal,
                ...dados,
            });
            setSubmit(true);
        },
        [notaFiscal, setNotaFiscal]
    );

    const handleDadosBasicos = useCallback(
        async (values) => {
            let status = 200,
                json = {};

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        setSubmit(true);
                        setDadosBasicos(data);
                        json = data;
                    },
                    400: ({ err }) => {
                        status = 400;
                        json = err;
                    },
                };

                await httpPost(
                    {
                        url: "/fiscal/notas-fiscais/",
                        body: {
                            ...values,
                            natureza_operacao: values.cfop.codigo,
                            cfop: values.cfop.id,
                            destinatario: values.destinatario.id,
                            municipio_ocorrencia: values.municipio_ocorrencia.id,
                            emitente: empresaSelecionada.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });
                if (diffProd.cfop)
                    diffProd = {
                        ...diffProd,
                        natureza_operacao: diffProd.cfop?.codigo,
                        cfop: diffProd.cfop?.id,
                    };
                if (diffProd.transportador?.id)
                    diffProd = {
                        ...diffProd,
                        transportador: diffProd.transportador?.id,
                    };
                if (diffProd.destinatario?.id)
                    diffProd = {
                        ...diffProd,
                        destinatario: diffProd.destinatario?.id,
                    };

                if (Object.keys(diffProd).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            status = 200;
                            json = values;
                            setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
                        },
                        400: ({ err }) => {
                            status = 400;
                            json = err;
                        },
                    };

                    await httpPatch(
                        {
                            url: `/fiscal/notas-fiscais/${values.id}/`,
                            body: diffProd,
                        },
                        handlers
                    );
                }

                return { status, data: json };
            }
        },
        [dadosBasicos, empresaSelecionada, httpPatch, httpPost]
    );

    const handleItem = useCallback(
        async (values, op) => {
            if (op === pd.OP_CRUD_DJANGO.novo) {
                let status = 400,
                    json = {};

                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                    400: ({ err }) => {
                        status = 400;
                        json = err;
                    },
                };

                await httpPost(
                    {
                        url: "/fiscal/itens-notas-fiscais/",
                        body: {
                            ...values,
                            item: values.item?.id,
                            nota_fiscal: dadosBasicos?.id,
                            codigo_item: values.item?.codigo,
                            codigo_barras: values.codigo_barras || "SEM GTIN",
                            descricao_reduzida: values.item?.descricao_reduzida,
                            descricao_derivada: values.item?.descricao_derivada || values.item?.descricao_complementar,
                            municipio_incidencia: values.municipio_incidencia.id,
                            municipio_issqn: values.municipio_issqn.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                let status = 400,
                    json = {};

                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                    400: ({ err }) => {
                        status = 400;
                        json = err;
                    },
                };

                await httpPatch(
                    {
                        url: `/fiscal/itens-notas-fiscais/${values.id}/`,
                        body: {
                            ...values,
                            codigo_barras: values.codigo_barras || "SEM GTIN",
                            municipio_incidencia: values.municipio_incidencia.id,
                            municipio_issqn: values.municipio_issqn.id,
                            item: values.item?.id,
                            cst_pis: values.cst_pis || null,
                            cst_cofins: values.cst_cofins || null,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [dadosBasicos?.id, httpPatch, httpPost]
    );

    const handleCobranca = useCallback(
        async (url, values) => {
            let status = 400,
                json = {};

            const handlers = {
                201: ({ data }) => {
                    status = 201;
                    json = data;
                },
                400: ({ err }) => {
                    status = 400;
                    json = err;
                },
            };

            await httpPost(
                {
                    url: url,
                    body: {
                        nota_fiscal: dadosBasicos.id,
                        ...values,
                    },
                },
                handlers
            );

            return { status, data: json };
        },
        [dadosBasicos?.id, httpPost]
    );

    const handlePreencherNotaFiscal = useCallback(
        async (idNotaFiscal) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos(data);
                    setSubmit(true);
                },
            };

            await httpGet({ url: `/fiscal/notas-fiscais/${idNotaFiscal}/` }, handlers);
        },
        [httpGet]
    );

    return (
        <NotaFiscalContext.Provider
            value={{
                submit,
                dadosBasicos,
                notaFiscal,
                activeTab,
                setActiveTab,
                setNotaFiscal,
                setSubmit,
                handleDados,
                handleDadosBasicos,
                handleItem,
                handleCobranca,
                handlePreencherNotaFiscal,
            }}
        >
            {children}
        </NotaFiscalContext.Provider>
    );
};

export default NotaFiscalContext;
