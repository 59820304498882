import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";

import { OPCOES_PESQUISA_BOOLEAN, TIPO_ESCOPO_COMISSAO_CHOICE } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    NumeroFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/vendas/comissoes-vendas/";

export const ComissaoVendasPage = () => {
    const [filtro, setFiltro] = useState(0);
    const [urlAPI, setUrlAPI] = useState(url);

    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_COMISSAOVENDAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/vendas/cadastros/comissao-vendas/form",
                        state: { comissaoVendas: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/vendas/comissoes-vendas"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="tipo de entrada"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja mesmo excluir o tipo de entrada <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O tipo de entrada não pôde ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        setFiltro(status.value);
        if (status.value === 0) {
            setUrlAPI(url);
        } else if (status.value === 1) {
            setUrlAPI(`${url}?ativo=True`);
        } else {
            setUrlAPI(`${url}?ativo=False`);
        }
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_COMISSAOVENDAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/vendas/cadastros/comissao-vendas/form"}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={OPCOES_PESQUISA_BOOLEAN}
                placeholder="Selecione"
                onChange={filtroStatus}
            />
        </>
    );

    const PorcentagemFiltroTemplate = (options) => {
        return (
            <NumeroFiltroTemplate
                inputProps={{
                    mode: "decimal",
                    prefix: "%",
                    min: 0,
                    minFractionDigits: 2,
                    maxFractionDigits: 5,
                }}
                options={options}
            />
        );
    };

    const EscopoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_ESCOPO_COMISSAO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        { field: "percent_mercadoria", header: "% Mercadoria", filter: true, filterElement: PorcentagemFiltroTemplate },
        { field: "percent_servico", header: "% Serviço", filter: true, filterElement: PorcentagemFiltroTemplate },
        { field: "percent_encargo", header: "% Encargo", filter: true, filterElement: PorcentagemFiltroTemplate },
        { field: "vigencia_final", header: "Vigência final", filter: true, filterElement: DateFiltroTemplate },
        {
            field: "escopo.descricao",
            header: "Escopo",
            filter: true,
            filterElement: EscopoFiltroTemplate,
            filterField: "escopo",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de comissões de vendas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={urlAPI}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    percent_mercadoria: { value: null, matchMode: "equals" },
                    percent_servico: { value: null, matchMode: "equals" },
                    percent_encargo: { value: null, matchMode: "equals" },
                    vigencia_final: { value: null, matchMode: "equals" },
                    escopo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
