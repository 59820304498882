import React, { useState, useCallback, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import { COMPRAS_ENTRADA_CATEGORIA_CLASSIFICATORIA } from "@/assets/constants/parametros";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Calendar } from "primereact/calendar";

export const MakoInputParametro = ({ chaveParametro, value, onChangeParametro, disabled = false, ...props }) => {
    const [componente, setComponente] = useState(null);
    const [propsComponente, setPropsComponente] = useState(props);

    const construirComponente = useCallback(async () => {
        const { chave, url, campo_valor_api, campo_label_api, tipo_valor } = chaveParametro || {};
        if (chave === COMPRAS_ENTRADA_CATEGORIA_CLASSIFICATORIA) {
            setComponente(MakoDropdownCategoriasHierarquicas);
            setPropsComponente((state) => ({
                ...state,
                categoriaTituloSelecionavel: true,
                apenasTitulo: true,
                disabled,
                onChange: (e) => onChangeParametro(e.value),
            }));
        } else if (url) {
            setComponente(Dropdown);
            setPropsComponente((state) => ({
                ...state,
                url,
                optionValue: campo_valor_api,
                optionLabel: campo_label_api,
                filter: true,
                showClear: true,
                disabled,
                onChange: (e) => onChangeParametro(e.value),
            }));
        } else {
            switch (tipo_valor) {
                case "T":
                    setComponente(InputText);
                    setPropsComponente((state) => ({
                        ...state,
                        disabled,
                        onChange: (e) => onChangeParametro(e.target.value),
                    }));
                    break;
                case "I":
                    setComponente(InputNumber);
                    setPropsComponente((state) => ({
                        ...state,
                        mode: "decimal",
                        useGrouping: false,
                        disabled,
                        onValueChange: (e) => onChangeParametro(e.value),
                    }));
                    break;
                case "F":
                    setComponente(InputNumber);
                    setPropsComponente((state) => ({
                        ...state,
                        mode: "decimal",
                        minFractionDigits: 2,
                        disabled,
                        onValueChange: (e) => onChangeParametro(e.value),
                    }));
                    break;
                case "B":
                    setComponente(Dropdown);
                    setPropsComponente((state) => ({
                        ...state,
                        options: [
                            { value: "1", label: "Sim / Verdadeiro" },
                            { value: "0", label: "Não / Falso" },
                        ],
                        optionValue: "value",
                        optionLabel: "label",
                        filter: true,
                        showClear: true,
                        disabled,
                        onChange: (e) => onChangeParametro(e.value),
                    }));
                    break;
                case "H":
                    setComponente(Calendar);
                    setPropsComponente((state) => ({
                        ...state,
                        showIcon: true,
                        icon: "pi pi-clock",
                        placeholder: "hh:mm",
                        timeOnly: true,
                        hourFormat: "24",
                        disabled,
                        onChange: (e) => onChangeParametro(e.value),
                    }));
                    break;
                case "D":
                    setComponente(MakoCalendar);
                    setPropsComponente((state) => ({
                        ...state,
                        value: value,
                        disabled,
                        onChange: (e) => onChangeParametro(e.value),
                    }));
                    break;
                default:
                    break;
            }
        }
    }, [chaveParametro, onChangeParametro, disabled]);

    useEffect(() => {
        construirComponente();
    }, [construirComponente]);

    return (
        <>
            {componente && (
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_PARAMETROS_MANUTENCAO, permissoes.OUTROS_PARAMETROS_EDITARVALOR]}
                    componente={componente}
                    value={value}
                    {...propsComponente}
                />
            )}
        </>
    );
};
