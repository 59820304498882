import React, { useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { TipoDadoModal } from "./modal/modalTipoDado";
import { InputNumber } from "primereact/inputnumber";
import * as Yup from "yup";
import classNames from "classnames";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

export const CriterioCrediarioForm = ({ regra }) => {
    const listagemRef = useRef(null);
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();
    const tipoRef = useRef();

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        initialValues: {
            id: "",
            descricao: "",
            padrao_criterio: null,
            limite: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                padrao_criterio: Yup.object()
                    .required("O campo 'padrão de critério' é obrigatório.")
                    .typeError("Informe um 'padrão' válido."),
                limite: formik.values.padrao_criterio?.requer_valor
                    ? Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido.")
                    : null,
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Critério cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        listagemRef.current?.buscarDados();
                    },
                };

                await httpPost(
                    {
                        url: `/crediario/criterio-crediario/`,
                        body: {
                            ...values,
                            regra: regra,
                            padrao_criterio: values.padrao_criterio.id,
                        },
                    },
                    handlers
                );
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Critério alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        listagemRef.current?.buscarDados();
                    },
                };

                await httpPatch(
                    {
                        url: `/crediario/criterio-crediario/${values.id}/`,
                        body: {
                            descricao: values.descricao,
                        },
                    },
                    handlers
                );
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        if (formik.values.padrao_criterio?.gera_componente) setFieldValue("limite", null);
    }, [setFieldValue, formik.values.padrao_criterio?.gera_componente]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro do parâmetro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => setValues(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EDITAR]}
                    componente={BotaoDelete}
                    url={"/crediario/criterio-crediario/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o critério <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O critério de crediário não pode ser excluído."
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Critério deletado com sucesso!",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };
    const colunas = [
        {
            field: "padrao_criterio.chave",
            header: "Chave do Padrão",
            style: { minWidth: "200px" },
        },
        {
            field: "padrao_criterio.descricao",
            header: "Descrição do Padrão",
            style: { minWidth: "200px" },
        },
        {
            field: "descricao",
            header: "Descrição do Critério",
            style: { minWidth: "200px" },
        },
        {
            field: "limite",
            header: "Valor",
            style: { minWidth: "100px" },
        },
        { field: "action", header: "Ações", style: { minWidth: "100px" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-mt-2">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-grid p-col-12 p-md-3">
                        <Button
                            label="Visualizar Tipos de Dados"
                            type="button"
                            icon="pi pi-eye"
                            className="p-button-text p-button-help p-mr-2 p-mb-2"
                            onClick={() => tipoRef.current?.setVisivel(true)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="padrao_criterio" label="Padrão de critério" obrigatorio />
                        <Dropdown
                            id="padrao_criterio"
                            name="padrao_criterio"
                            placeholder="Selecione um padrão"
                            url={"/crediario/criterio-padrao?limit=3000"}
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            value={formik.values.padrao_criterio}
                            onChange={formik.handleChange}
                            disabled={formik.values.id}
                            className={classNames({ "p-invalid": formik.errors.padrao_criterio })}
                        />
                        {formik.values.padrao_criterio?.id ? (
                            <div>
                                {!formik.values.padrao_criterio?.gera_componente ? (
                                    <b>
                                        <u>Esse critério não solicita dados durante o preenchimento do crediário.</u>
                                    </b>
                                ) : null}
                                <br />
                                <b>{`Chave: ${formik.values.padrao_criterio.chave} (${formik.values.padrao_criterio.tipo_dado.descricao})`}</b>
                                <br />
                                {formik.values.padrao_criterio.orientacoes ? (
                                    <div>
                                        <b>Orientações:</b> {`${formik.values.padrao_criterio.orientacoes}`}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : null}
                        {formik.errors.padrao_criterio && (
                            <small className="p-error">{formik.errors.padrao_criterio}</small>
                        )}
                    </div>
                    <div
                        className={`p-field p-col-12 ${
                            formik.values.padrao_criterio?.requer_valor ? "p-md-5" : "p-md-8"
                        }`}
                    >
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    {formik.values.padrao_criterio?.requer_valor ? (
                        <div className="p-field p-col-12 p-md-3">
                            <Label
                                htmlFor="limite"
                                label={`${formik.values.padrao_criterio?.descricao_valor}` || "Valor"}
                                obrigatorio
                            />
                            <InputNumber
                                id="limite"
                                name="limite"
                                min={0}
                                value={formik.values.limite}
                                onValueChange={formik.handleChange}
                                disabled={formik.values.id}
                                className={classNames({ "p-invalid": formik.errors.limite })}
                            />
                            {formik.errors.limite && <small className="p-error">{formik.errors.limite}</small>}
                        </div>
                    ) : null}
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label={formik.values.id ? "Gravar" : "Adicionar"}
                        type="submit"
                        icon={formik.values.id ? "pi pi-save" : "pi pi-plus"}
                        className="p-button-success"
                    />
                </MakoActionsButtons>
            </form>
            <MakoListagem
                ref={listagemRef}
                titulo="Critérios"
                colunas={colunas}
                urlPesquisa={`/crediario/criterio-crediario?regra=${regra}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <TipoDadoModal ref={tipoRef} />
        </>
    );
};
