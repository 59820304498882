import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import MakoListagem from "@/components/MakoListagem";
import { parseMoeda } from "@/assets/util/util";

const FILTROS_VIEWSET = {
    empresa: "conta_financeira__perfil_id",
    conta: "conta_financeira__id",
    data_start: "data_movimentacao__date__gte",
    data_end: "data_movimentacao__date__lte",
};

const BASE_URL = "/relatorios/movimentacao-conta-financeira";

export const RelatorioMovimentacaoContaFinanceira = () => {
    const { solicitarRelatorio } = useRelatorio();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState("");
    const listagemRef = useRef();

    const empresaSelecionada = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef);

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["data_start", "data_end"],
            label: "Período",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "conta",
            label: "Conta",
            path: "descricao",
        },
    ];

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false);
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                conta: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .required("O campo 'conta' é obrigatório.")
                    .typeError("Selecione uma 'conta' válida"),
                data_start: Yup.date().nullable().typeError("Informe um 'data  inicial' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                empresa: Yup.number().nullable().typeError("Informe uma 'empresa' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");
            if (dadosValidados.conta) dadosValidados.conta = dadosValidados.conta.id;

            if (buscarListagem === false) {
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                    });
                }

                solicitarRelatorio({
                    chave: RELATORIO_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA,
                    emails,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                    corpo_email,
                    versao,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url);
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "data", header: "Data", action: (e) => dataToStr(e.data, "dd/MM/yyyy") },
        { field: "historico_lancamento", header: "Historico do lançamento" },
        { field: "entrada", header: "Entradas", action: (e) => parseMoeda(e.entrada) },
        { field: "saida", header: "Saidas", action: (e) => parseMoeda(e.saida) },
        { field: "saldo", header: "Saldo", action: (e) => parseMoeda(e.saldo) },
    ];

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    return (
        <R.Wrapper titulo={"movimentação financeira"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        data_start: null,
                        data_end: null,
                        empresa: null,
                        conta: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Conta",
                        inputId: "conta",
                        inputName: "conta",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            url: "/financeiro/contas-financeiras/",
                            optionLabel: "descricao",
                            filter: true,
                            filterBy: "descricao",
                            placeholder: "Selecione uma conta...",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Periodo Vencimento: ",
                        inputId: "data_start",
                        inputName: "data_start",
                        component: MakoCalendar,
                        fieldSize: 2,
                    },
                    {
                        inputId: "data_end",
                        inputName: "data_end",
                        component: MakoCalendar,
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                ]}
            ></MakoFormGerador>
            <R.Buttons.Wrapper>
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </R.Buttons.Wrapper>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </R.Wrapper>
    );
};
