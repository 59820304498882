import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { RELATORIO_ESTOQUE_TRANSFERENCIAFILIAL } from "@/assets/constants/relatorios";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useRelatorio from "@/hooks/useRelatorio";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

export const RelatorioTransferenciaFilial = () => {
    const [listaCentros, setListaCentros] = useState([]);
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpGet } = useHttp();

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            filiais: null,
            data_start: null,
            data_end: null,
            tipo: null,
            valor_gerencial: true,
        },
        onSubmit: handleSubmit,
    });

    const TIPOS_VALORES = [
        { value: false, label: "Valor Fiscal" },
        { value: true, label: "Valor Gerencial" },
    ];

    const filtrosTemplate = [
        {
            key: ["data_start", "data_end"],
            label: "Periodo",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "tipo",
            label: "Tipo do produto de origem",
            optionLabel: "label",
            optionKey: "value",
            options: TIPO_SKU_ESTOCADO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "valor_gerencial",
            label: "Valor a ser exibido",
            optionLabel: "label",
            optionKey: "value",
            options: TIPOS_VALORES,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_start: Yup.date().nullable().typeError("Informe um 'data  inicial' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                tipo: Yup.string().nullable().typeError("Informe um 'tipo' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_ESTOQUE_TRANSFERENCIAFILIAL,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const listarCentrosEstocagem = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setListaCentros(data.results);
            },
        };

        showLoading();
        await httpGet({ url: "/produtos/centros-estocagem?limit=1000" }, handlers);
        hideLoading();
    }, [httpGet, hideLoading, showLoading]);

    useEffect(() => {
        listarCentrosEstocagem();
    }, [listarCentrosEstocagem]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Emissão de relatório de transferência entre filial</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="filiais">Centro de Estocagem Origem</label>
                            <MultiSelect
                                id="filiais"
                                name="filiais"
                                placeholder={
                                    !loading ? "Selecione um centro de estocagem" : "Carregando centros de estocagem..."
                                }
                                disabled={loading}
                                options={listaCentros}
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                selectedItemsLabel="{0} centros selecionados"
                                value={formik.values.filiais}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data })}
                            />
                            {formik.errors.filiais && <small className="p-error">{formik.errors.filiais}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="tipo">Tipo do produto de origem *</label>
                            <Dropdown
                                id="tipo"
                                name="tipo"
                                placeholder="Selecione..."
                                options={TIPO_SKU_ESTOCADO}
                                optionValue="value"
                                optionLabel="label"
                                value={formik.values.tipo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.tipo })}
                            />
                            {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="data_start">Periodo:</label>
                            <MakoCalendar
                                id="data_start"
                                name="data_start"
                                value={formik.values.data_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_start })}
                            />
                            {formik.errors.data_start && <small className="p-error">{formik.errors.data_start}</small>}
                        </div>
                        <div className="p-field p-col-2 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="data_end"
                                name="data_end"
                                value={formik.values.data_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_end })}
                            />
                            {formik.errors.data_end && <small className="p-error">{formik.errors.data_end}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_gerencial">Valor a ser exibido *</label>
                            <Dropdown
                                id="valor_gerencial"
                                name="valor_gerencial"
                                placeholder="Selecione..."
                                options={TIPOS_VALORES}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.valor_gerencial}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_gerencial })}
                            />
                            {formik.errors.valor_gerencial && (
                                <small className="p-error">{formik.errors.valor_gerencial}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
