import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import classNames from "classnames";
import { addDays, differenceInDays } from "date-fns";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { parseData } from "@/assets/util/datas";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";

const Modal = ({ parcelas, dataPedido, onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const msgRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            numero_parcela: 0,
            dia_vencimento: 1,
            valor_parcela: 0,
            forma_pagamento: null,
            alterar_todas: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const minVencimento =
                values.rowIndex === 0
                    ? parseData(dataPedido)
                    : addDays(parseData(dataPedido), parseInt(parcelas[values.rowIndex - 1].dias_apos_pedido));
            const formSchema = Yup.object().shape({
                valor_parcela: Yup.number()
                    .required("O campo 'valor da parcela' é obrigatório.")
                    .min(0.01, "O valor da parcela não pode ser ZERO."),
                vencimento: Yup.date()
                    .required("O campo 'dia do vencimento' é obrigatório.")
                    .min(
                        minVencimento,
                        "O vencimento não pode ser inferior a data do pedido ou ao vencimento da parcela anterior."
                    )
                    .typeError("Informe uma data válida"),
                forma_pagamento: Yup.string()
                    .required("O campo 'forma de pagamnto' é obrigatório.")
                    .typeError("Selecione uma forma de pagamento."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const parcela = {
                ...values,
                dias_apos_pedido: differenceInDays(values.vencimento, parseData(dataPedido)),
                _editada: true,
            };
            if (typeof onSuccess === "function") onSuccess(parcela);
            fecharModal();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (parcela) => {
        if (parcela) {
            const { data_pedido } = parcela;
            const data = typeof data_pedido === "string" ? parseData(data_pedido) : data_pedido;
            formik.setValues({
                ...parcela,
                vencimento: addDays(data, parseInt(parcela.dias_apos_pedido)),
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    const footerDialog = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.EDITAR}
                    onClick={fecharModal}
                    className="p-button-text p-button-danger"
                />
                <Button
                    label="Confirmar"
                    type="submit"
                    icon={MAKO_ICONS.GRAVAR}
                    onClick={formik.handleSubmit}
                    autoFocus
                />
            </div>
        );
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            visible={visible}
            header="Alterar parcela"
            footer={footerDialog}
            style={{ width: "50vw" }}
            onHide={fecharModal}
            draggable={false}
        >
            <Messages ref={msgRef} />
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="num-parcela" label="Nº da parcela" />
                        <InputNumber
                            inputId="num-parcela"
                            name="numero_parcela"
                            disabled
                            value={formik.values.numero_parcela}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vencimento" label="Vencimento" />
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            value={formik.values.vencimento}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor-parcela" label="Valor da parcela" />
                        <MakoInputMoeda
                            id="valor-parcela"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_parcela })}
                        />
                        {formik.errors.valor_parcela && (
                            <small className="p-error">{formik.errors.valor_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="forma-pagamento" label="Forma de pagamento" obrigatorio />
                        <Dropdown
                            id="forma-pagamento"
                            name="forma_pagamento"
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12">
                        <Checkbox
                            id="alterar-todas"
                            name="alterar_todas"
                            checked={formik.values.alterar_todas}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="alterar-todas" label="Alterar em todas as parcelas?" />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEditarParcela = forwardRef(Modal);
