import React, { useImperativeHandle, forwardRef, useCallback } from "react";

import classNames from "classnames";
import { compareAsc } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Label } from "@/components/Label";

import { parseData } from "@/assets/util/datas";

const INITIAL_VALUES = {
    index: 0,
    vencimento: null,
    valor: 0,
    historico_original: "",
    paga_comissao_recebimento: false,
    previsao: false,
    alterar_proximas: false,
};

const Component = ({ parcela, parcelas = [], successCallback = () => {} }, ref) => {
    const { setValues, ...formik } = useFormik({
        initialValues: parcela || INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const validarVencimento = useCallback(
        (data, rowIndex) => {
            if (rowIndex === 0) {
                const prox = parcelas[rowIndex + 1].vencimento;
                const proxVencimento = prox instanceof Date ? prox : parseData(prox);
                return compareAsc(proxVencimento, data) > 0;
            } else if (rowIndex === parcelas.length - 1) {
                const ant = parcelas[rowIndex - 1].vencimento;
                const antVencimento = ant instanceof Date ? ant : parseData(ant);
                return compareAsc(data, antVencimento) > 0;
            } else {
                const prox = parcelas[rowIndex + 1].vencimento;
                const proxVencimento = prox instanceof Date ? prox : parseData(prox);
                const ant = parcelas[rowIndex - 1].vencimento;
                const antVencimento = ant instanceof Date ? ant : parseData(ant);
                return compareAsc(data, antVencimento) > 0 && compareAsc(proxVencimento, data) > 0;
            }
        },
        [parcelas]
    );

    function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .test({
                        test: (e) => validarVencimento(e, values.index),
                        message: "A data de vencimento não é válida para esse recebimento.",
                    })
                    .typeError("Informe uma data válida."),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser ZERO."),
                historico_original: Yup.string().required("O campo 'histórico' é obrigatório."),
                paga_comissao_recebimento: Yup.boolean().default(false),
                previsao: Yup.boolean().default(false),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            formik.resetForm();
            if (!values.alterar_proximas) {
                successCallback(dadosValidos, values.index);
            } else {
                successCallback({ valor: dadosValidos.valor }, values.index, true);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useImperativeHandle(ref, () => ({ setValues }));

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="vencimento" label="Vencimento" />
                    <MakoCalendar
                        id="vencimento"
                        name="vencimento"
                        value={formik.values.vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento })}
                    />
                    {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        min={0}
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <Label htmlFor="historico-original" label="Histórico" />
                    <InputText
                        id="historico-original"
                        name="historico_original"
                        value={formik.values.historico_original}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico_original })}
                    />
                    {formik.errors.historico_original && (
                        <small className="p-error">{formik.errors.historico_original}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field-checkbox p-col-12 p-md-3">
                    <Checkbox
                        id="alterar_proximas"
                        name="alterar_proximas"
                        checked={formik.values.alterar_proximas}
                        onChange={formik.handleChange}
                    />
                    <Label htmlFor="alterar_proximas" label="Alterar valor das proximas?" />
                </div>
            </div>
            <div className="p-grid p-col-12 p-md-2">
                <Button type="submit" icon="pi pi-pencil" label="Gravar" />
            </div>
        </form>
    );
};

export const Editar = forwardRef(Component);
