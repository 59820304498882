import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/crediario/tipo-renda/";

export const CrediarioTipoRendaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CREDIARIO_TIPORENDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de fonte de renda"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/tipos-renda/form",
                            state: { tipoRenda: rowData },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/crediario/tipo-renda"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="tipo de renda"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                {`Deseja realmente excluir o tipo de renda `}
                                <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CREDIARIO_TIPORENDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/crediario/tipos-renda/form")}
            />
        </>
    );

    const escopoBodyTemplate = (rowData) => {
        if (rowData.escopo === 1) return <Tag severity="info" value="VENDEDOR" />;
        else if (rowData.escopo === 2) return <Tag severity="success" value="ANALISTA DE CRÉDITO" />;
        return <Tag severity="warning" value="QUALQUER" />;
    };

    const escopoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: 1, label: "Vendedor" },
                    { value: 2, label: "Analista de crédito" },
                    { value: 3, label: "Qualquer" },
                ]}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                ]}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    const autoValidadaBodyTemplate = (rowData) => {
        if (rowData.auto_validada) return <Tag severity="success" value="SIM" />;
        return <Tag severity="danger" value="NÃO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            style: { minWidth: "100px" },
        },
        { field: "descricao", header: "Descrição", filter: true, style: { minWidth: "300px" } },
        {
            field: "escopo",
            header: "Escopo",
            filter: true,
            filterElement: escopoFiltroTemplate,
            action: (e) => escopoBodyTemplate(e),
            style: { minWidth: "150px" },
        },
        {
            field: "auto_validada",
            header: "Auto validada?",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => autoValidadaBodyTemplate(e),
            style: { minWidth: "150px" },
        },
        {
            field: "ativo",
            header: "Situação",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
            style: { minWidth: "150px" },
        },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
            style: { minWidth: "100px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipos de Renda"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    escopo: { value: null, matchMode: "equals" },
                    auto_validada: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
        </PageBase>
    );
};
