import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import { Label } from "@/components/Label";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useToast from "@/hooks/useToast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/galerias-imagens-sku/";

export const ProdutoGaleriaImgSkuForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();
    const history = useHistory();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.GaleriaImg
            : {
                  id: "",
                  nome: "",
                  largura: 0,
                  altura: 0,
                  exibir_na_busca: false,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Galeria de imagem cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/galerias-imagens");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Galeria de imagem alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/galerias-imagens");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/galerias-imagens");
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova galeria de imagens sku" : "Manutenção de galeria de imagens sku"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="altura" label="Altura" />
                        <InputNumber
                            id="altura"
                            name="altura"
                            value={formik.values.altura}
                            onValueChange={formik.handleChange}
                            min={0}
                            showButtons
                            className={classNames({ "p-invalid": formik.errors.altura })}
                            autoComplete="off"
                        />
                        {formik.errors.altura && <small className="p-error">{formik.errors.altura}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="Largura" label="Largura" />
                        <InputNumber
                            id="largura"
                            name="largura"
                            value={formik.values.largura}
                            onValueChange={formik.handleChange}
                            min={0}
                            showButtons
                            className={classNames({ "p-invalid": formik.errors.largura })}
                            autoComplete="off"
                        />
                        {formik.errors.largura && <small className="p-error">{formik.errors.largura}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="exibir_na_busca" label="Exibir na busca" />
                        <SelectButton
                            id="exibir_na_busca"
                            name="exibir_na_busca"
                            value={formik.values.exibir_na_busca}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>

                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
