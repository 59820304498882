import React, { useCallback, useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";

import { InputTextarea } from "primereact/inputtextarea";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_INVENTARIOPRODUTO } from "@/assets/constants/relatorios";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import MakoListagem from "@/components/MakoListagem";

const { ExportCSV } = R.Buttons;

const BASE_URL = "/relatorios/inventario-produtos/";

const FILE_NAME = gerarFileName("Inventario Produtos");

const FILTROS_VIEWSET = {
    data: "data",
    marca: "marca",
    categoria: "categoria",
    lote: "lote",
    fornecedor: "fornecedor",
    descricao: "descricao",
    ncm: "ncm",
    destinacao: "destinacao",
    saldo_logico_start: "saldo_logico_start",
    saldo_logico_end: "saldo_logico_end",
    saldo_fisico_start: "saldo_fisico_start",
    saldo_fisico_end: "saldo_fisico_end",
};

const FILTROS_TEMPLATES = [
    {
        key: "marca",
        label: "Marca",
        path: "nome",
    },
    {
        key: "data",
        label: "Data de referência",
        type: TIPOS_FILTROS_TEMPLATE.DATE,
    },
    {
        key: ["saldo_fisico_start", "saldo_fisico_end"],
        label: "Saldo lógico entre",
        type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
    },
    {
        key: ["saldo_logico_start", "saldo_logico_end"],
        label: "Saldo físico entre",
        type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
    },
    {
        key: "categoria",
        label: "Categoria",
        path: "descricao",
    },
    {
        key: "fornecedor",
        label: "Fornecedor",
        type: TIPOS_FILTROS_TEMPLATE.PESSOA,
    },
    {
        key: "lote",
        label: "Lote",
        path: "motivo_inventario.descricao",
    },
    {
        key: "descricao",
        label: "Descrição",
    },
    {
        key: "destinacao",
        label: "Destinação",
        optionKey: "value",
        optionLabel: "label",
        options: TIPO_SKU_ESTOCADO,
        type: TIPOS_FILTROS_TEMPLATE.CHOICE,
    },
    {
        key: "ncm",
        label: "Ncm",
        path: "codigo",
    },
];

const COLUNAS = [
    { field: "codigo", header: "Código", style: { width: "8%" } },
    { field: "descricao", header: "Descrição" },
    { field: "unidade_medida", header: "Un", style: { width: "8%" }, align: "center" },
    { field: "lote", header: "Lote", style: { width: "8%" }, align: "center" },
    { field: "saldo_logico", header: "S. Lógico", style: { width: "8%" }, align: "right", decimal: true },
    { field: "saldo_fisico", header: "S. Físico", style: { width: "8%" }, align: "right", decimal: true },
    { field: "diferenca", header: "Diferença", style: { width: "8%" }, align: "right", decimal: true },
    { field: "categoria", header: "Categoria", align: "center" },
];

export const RelatorioInventarioProdutos = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [requerRefencia, setRequerReferencia] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(categoriaRef, formRef, botaoCsv);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
        if (dadosValidados.lote) dadosValidados.lote = dadosValidados.lote.id;
        if (dadosValidados.data) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
        if (dadosValidados.saldo_fisico_start)
            dadosValidados.saldo_fisico_start = dataToStr(dadosValidados.saldo_fisico_start, "yyyy-MM-dd");
        if (dadosValidados.saldo_fisico_end)
            dadosValidados.saldo_fisico_end = dataToStr(dadosValidados.saldo_fisico_end, "yyyy-MM-dd");
        if (dadosValidados.saldo_logico_start)
            dadosValidados.saldo_logico_start = dataToStr(dadosValidados.saldo_logico_start, "yyyy-MM-dd");
        if (dadosValidados.saldo_logico_end)
            dadosValidados.saldo_logico_end = dataToStr(dadosValidados.saldo_logico_end, "yyyy-MM-dd");
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                marca: Yup.object().nullable().default(null).typeError("Informe uma 'marca' válida."),
                categoria: Yup.number().nullable().default(null).typeError("Informe uma 'categoria' válido."),
                fornecedor: Yup.object().nullable().default(null).typeError("Informe um 'fornecedor' válida."),
                destinacao: Yup.string().nullable().default(null).nullable(),
                lote: Yup.object().nullable().default(null).typeError("Seleciona um 'lote' válido."),
                data: Yup.date()
                    .when({
                        is: () => requerRefencia,
                        then: Yup.date().nullable().required("O campo 'data referência' é obrigatório"),
                        otherwise: Yup.date().nullable(),
                    })
                    .default(null)
                    .typeError("Seleciona um 'data referência' válido."),
                saldo_fisico_start: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Selecione um 'inicio de saldo fisico' válido."),
                saldo_fisico_end: Yup.date()
                    .when("saldo_fisico_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .nullable()
                            .required("O campo 'saldo fisico final' é obrigatório.")
                            .min(
                                values.saldo_fisico_start || new Date(),
                                "O 'saldo fisico final' não pode ser anterior ao inicial"
                            ),
                        otherwise: Yup.date().nullable(),
                    })
                    .default(null)
                    .typeError("Seleciona um 'saldo fisico final' válido."),
                saldo_logico_start: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Selecione uma 'inicio de saldo logico' válido."),
                saldo_logico_end: Yup.date()
                    .when("saldo_logico_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.saldo_logico_start || new Date(),
                                "O 'saldo logico final' não pode ser anterior ao inicial"
                            )
                            .nullable()
                            .required("O campo 'logico fisico final' é obrigatório."),
                        otherwise: Yup.date().nullable(),
                    })
                    .default(null)
                    .typeError("Seleciona um 'saldo logico final' válido."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(FILTROS_TEMPLATES, {
                ...dadosValidados,
                categoria: dadosValidados.categoria ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.lote) dadosValidados.lote = dadosValidados.lote.id;
            if (dadosValidados.data) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
            if (dadosValidados.saldo_fisico_start)
                dadosValidados.saldo_fisico_start = dataToStr(dadosValidados.saldo_fisico_start, "yyyy-MM-dd");
            if (dadosValidados.saldo_fisico_end)
                dadosValidados.saldo_fisico_end = dataToStr(dadosValidados.saldo_fisico_end, "yyyy-MM-dd");
            if (dadosValidados.saldo_logico_start)
                dadosValidados.saldo_logico_start = dataToStr(dadosValidados.saldo_logico_start, "yyyy-MM-dd");
            if (dadosValidados.saldo_logico_end)
                dadosValidados.saldo_logico_end = dataToStr(dadosValidados.saldo_logico_end, "yyyy-MM-dd");

            if (!buscarListagem) {
                let filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_PRODUTOS_INVENTARIOPRODUTO,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const onChangeData = (e) => {
        setFieldValue("saldo_fisico_start", null);
        setFieldValue("saldo_fisico_end", null);
        setFieldValue("saldo_logico_start", null);
        setFieldValue("saldo_logico_end", null);
    };

    const onChangePeriodo = (e) => {
        setFieldValue("data", null);
        formRef.current?.handleChange(e);
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PRODUTOS_INVENTARIOPRODUTO}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const watchValues = useCallback((data = {}) => {
        const {
            saldo_fisico_start = null,
            saldo_fisico_end = null,
            saldo_logico_start = null,
            saldo_logico_end = null,
        } = data;
        setRequerReferencia(() => {
            if (!!saldo_fisico_start || !!saldo_fisico_end || !!saldo_logico_start || !!saldo_logico_end) return false;
            return true;
        });
    }, []);

    return (
        <R.Wrapper titulo={"de extrato de inventário de produtos"}>
            <MakoFormGerador
                ref={formRef}
                watchValues={watchValues}
                formikProps={{
                    initialValues: {
                        marca: null,
                        data: null,
                        saldo_fisico_start: null,
                        saldo_fisico_end: null,
                        saldo_logico_start: null,
                        saldo_logico_end: null,
                        categoria: null,
                        fornecedor: null,
                        lote: null,
                        descricao: "",
                        destinacao: null,
                        ncm: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Marca IE",
                        inputId: "marca",
                        inputName: "marca",
                        component: MakoAutoComplete,
                        componentProps: {
                            placeholder: "Digite para pesquisar... (min 4 caracteres)",
                            urlSearch: "/produtos/marcas/?search=",
                            key: "id",
                            field: "nome",
                        },
                        fieldSize: 4,
                    },
                    {
                        inputId: "saldo_logico",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Saldo lógico entre",
                            nameInicio: "saldo_logico_start",
                            nameFinal: "saldo_logico_end",
                            valueInicio: "saldo_logico_start",
                            valueFinal: "saldo_logico_end",
                            errorInicio: "saldo_logico_start",
                            errorFinal: "saldo_logico_end",
                            onChangeInicio: onChangePeriodo,
                            onChangeFinal: onChangePeriodo,
                            makoDate: true,
                        },
                        fieldSize: 5,
                    },
                    {
                        inputId: "saldo_fisico",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Saldo físico entre",
                            nameInicio: "saldo_fisico_start",
                            nameFinal: "saldo_fisico_end",
                            valueInicio: "saldo_fisico_start",
                            valueFinal: "saldo_fisico_end",
                            errorInicio: "saldo_fisico_start",
                            errorFinal: "saldo_fisico_end",
                            disabled: requerRefencia,
                            makoDate: true,
                        },
                        fieldSize: 5,
                    },
                    {
                        label: "Data de referência",
                        inputId: "data",
                        inputName: "data",
                        component: MakoCalendar,
                        disabled: !requerRefencia,
                        required: requerRefencia,
                        componentProps: {
                            getOnChange: onChangeData,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Destinação",
                        inputId: "destinacao",
                        inputName: "destinacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            options: TIPO_SKU_ESTOCADO,
                            optionLabel: "label",
                            optionValue: "value",
                            filter: true,
                            filterBy: "value",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Lote",
                        inputId: "lote",
                        inputName: "lote",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um lote...",
                            url: "/produtos/lotes-inventario/",
                            optionLabel: "motivo_inventario.descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 4,
                    },
                    {
                        label: "NCM",
                        inputId: "ncm",
                        inputName: "ncm",
                        component: MakoInputNcm,
                        componentEspecial: COMPONENTES_ESPECIAIS.NCM,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Descrição contém",
                        inputId: "descricao",
                        inputName: "descricao",
                        component: InputTextarea,
                        componentProps: {
                            autoResize: true,
                            rows: 3,
                            maxLength: 100,
                        },
                        fieldSize: 12,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_PRODUTOS_INVENTARIOPRODUTO}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    colunas={COLUNAS}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
