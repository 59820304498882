import React, { useState, useRef } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { RegistroNecessidadeCompraForm } from "./form";

import permissoes from "@/assets/constants/permissoes";

import { FiltroAvancadoNecessidadeCompra } from "./filtroAvancado";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const BASE_URL = "/compras/registro-necessidade-compra";

export const RegistroNecessidadeCompraPage = () => {
    const [showModal, setShowModal] = useState(null);
    const [registroNecessidade, setRegistroNecessidade] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const [filtroAvancado, setFiltroAvancado] = useState(false);

    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setRegistroNecessidade(rowData);
                        setShowModal(true);
                    }}
                />
                <ButtonListagemOpcoes
                    url={BASE_URL}
                    objetoId={rowData.id}
                    objetoLabel={rowData.id}
                    prefixo="o"
                    entidade="registro de necessidade de compra"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente deletar este registro de necessidade de compra:
                                <b>{rowData.id}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O registro de necessidade de compra não pôde ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const fecharDialog = () => {
        setShowModal(false);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                onClick={() => setShowModal(true)}
            />
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button-help"
                onClick={() => setFiltroAvancado(true)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "5%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "solicitante", header: "Solicitante", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descritivo", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data",
            header: "Data",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "preco_venda",
            header: "Preço Venda",
            money: true,
            style: { width: "10%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            align: "center",
            style: { width: "10%" },
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const onSubmit = (isEdit = false) => {
        listagemRef?.current.buscarDados();
        if (isEdit) setRegistroNecessidade(null);
    };

    const onCancel = () => {
        setRegistroNecessidade(null);
        setShowModal(false);
    };

    const fecharFiltro = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = (newUrl) => {
        setUrl(newUrl);
        listagemRef.current?.buscarDados();
        fecharFiltro();
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Registros de Necessidade de Compra"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    solicitante: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    descritivo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    data: { value: null, matchMode: "equals" },
                    preco_venda: { value: null, matchMode: "equals" },
                    ativo: { value: true, matchMode: "equals" },
                }}
            />
            <Dialog
                header={`${registroNecessidade ? "Editar" : "Cadastrar"} necessidade de compra`}
                visible={showModal}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "80vw" }}
                onHide={fecharDialog}
            >
                <RegistroNecessidadeCompraForm onSubmit={onSubmit} registro={registroNecessidade} onClose={onCancel} />
            </Dialog>
            <Dialog
                header="Filtro avançado"
                visible={filtroAvancado}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw" }}
                onHide={fecharFiltro}
            >
                <FiltroAvancadoNecessidadeCompra onConfirm={onConfirmFiltro} onCancel={fecharFiltro} url={BASE_URL} />
            </Dialog>
        </PageBase>
    );
};
