import React, { useState, useRef } from "react";

import { Dropdown } from "primereact/dropdown";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const BASE_URL = "/configuracoes/api-servicos/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: "true" },
    { id: "inativos", label: "Inativos", value: "false" },
];

export const ApiServicosPage = () => {
    const [filtro, setFiltro] = useState("");

    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.ROOT]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de api de serviços"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/configuracoes/api-servicos/form",
                        state: { apiServico: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/configuracoes/api-servicos"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="api de serviços"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                {"Deseja realmente excluir a api de serviços "}
                                <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "exact");
        setFiltro(status.value);
    };

    const valorFixo = (values) => {
        if (values.valor_fixo) return "Sim";
        return "Não";
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ROOT]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/configuracoes/api-servicos/form"}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "8%" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "20%" } },
        { field: "provedor.nome", header: "Provedor", style: { minWidth: "20%" } },
        { field: "tipo_servico.nome", header: "Tipo de serviço", style: { minWidth: "20%" } },
        { field: "data_inicio", header: "Data início", dateFormat: "dd/MM/yyyy", style: { minWidth: "10%" } },
        { field: "valor_fixo", header: "Valor fixo", action: (e) => valorFixo(e), style: { minWidth: "10%" } },
        { field: "valor_mensal", header: "Valor mensal", money: true, style: { minWidth: "10%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { minWidth: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="API de Serviços"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL}
                responsiva
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
            />
        </PageBase>
    );
};
