import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PageBase } from "@/components/PageBase";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

const URL_API = "/vendas/tipos-estagios-vendas/";

export const TipoEstagioForm = (props) => {
    const history = useHistory();
    const { httpPost, httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            ativo: true,
            baixa_estoque: null,
            gera_financeiro: null,
            gera_documento_fiscal: null,
            reserva_estoque: null,
            gera_comissao: null,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state.tipoEstagio);
        }
    }, [props.location.state, setValues]);

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Tipo de estágio alterado.",
                    life: 1500,
                });
                setTimeout(() => voltarParaListagem(), 2000);
            },
            201: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Novo tipo de estágio cadastrado.",
                    life: 1500,
                });
                formik.resetForm();
            },
            400: ({ err }) => handleErrors(err),
        };
        if (!values.id) {
            showLoading();
            await httpPost({ url: URL_API, body: values }, handlers);
            hideLoading();
        } else {
            showLoading();
            await httpPut({ url: `${URL_API}${values.id}/`, body: values }, handlers);
            hideLoading();
        }
    }

    function voltarParaListagem() {
        history.push("/vendas/cadastros/tipos-estagios");
    }

    const statusTipoEstagio = (value) => {
        if (value) return "Sim";
        else if (value === false) return "Não";
        return "Mantém";
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <h3>{!formik.values.id ? "Novo tipo de estágio" : "Manutenção de tipo de estágio"}</h3>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-4">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">{formik.values.ativo ? "Ativo" : "Inativo"}</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                        <TriStateCheckbox
                            id="baixa-estoque"
                            name="baixa_estoque"
                            value={formik.values.baixa_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.baixa_estoque })}
                        />
                        <label htmlFor="baixa-estoque">
                            Baixa estoque: <b>{statusTipoEstagio(formik.values.baixa_estoque)}</b>
                        </label>
                        {formik.errors.baixa_estoque && (
                            <small className="p-error">{formik.errors.baixa_estoque}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                        <TriStateCheckbox
                            id="gera-financeiro"
                            name="gera_financeiro"
                            value={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-financeiro">
                            Gera financeiro: <b>{statusTipoEstagio(formik.values.gera_financeiro)}</b>
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="gera-doc-fiscal"
                            name="gera_documento_fiscal"
                            value={formik.values.gera_documento_fiscal}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-doc-fiscal">
                            Gera doc. fiscal: <b>{statusTipoEstagio(formik.values.gera_documento_fiscal)}</b>
                        </label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="reserva-estoque"
                            name="reserva_estoque"
                            value={formik.values.reserva_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.reserva_estoque })}
                        />
                        <label htmlFor="reserva-estoque">
                            Reserva estoque: <b>{statusTipoEstagio(formik.values.reserva_estoque)}</b>
                        </label>
                        {formik.errors.reserva_estoque && (
                            <small className="p-error">{formik.errors.reserva_estoque}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="gera-comissao"
                            name="gera_comissao"
                            value={formik.values.gera_comissao}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-comissao">
                            Gera comissão: <b>{statusTipoEstagio(formik.values.gera_comissao)}</b>
                        </label>
                    </div>
                </div>
                <CamposObrigatorios singular />
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        icon="pi pi-save"
                        label="Gravar"
                        loading={loading}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        type="button"
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        loading={loading}
                        className="p-button-danger p-mr-2"
                        onClick={voltarParaListagem}
                    />
                </MakoActionsButtons>
                <div className="p-grid p-col-12 p-md-6"></div>
            </form>
        </PageBase>
    );
};
