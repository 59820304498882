import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { InputText } from "primereact/inputtext";
import { BotaoDelete } from "@/components/BotaoDelete";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { Dropdown } from "@/components/Dropdown";
import {
    SIM_NAO_BOOLEAN,
    TIPO_BENEFICIO_MUNICIPAL,
    TIPO_CST_PIS_COFINS,
    TIPO_IMUNIDADE_ISSQN,
    TIPO_RETENCAO_ISSQN,
    TIPO_TRIBUTACAO_ISSQN,
} from "@/assets/constants/constants";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const ServicosForm = ({ opFiscal }) => {
    const [visivel, setVisivel] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const listagemRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            servico_municipio: null,
            tributacao_issqn: 4,
            codigo_issqn: "",
            imunidade_issqn: null,
            aliquota_issqn: null,
            retencao_issqn: 1,
            cst_pis_cofins: "00",
            aliquota_pis: null,
            aliquota_cofins: null,
            retem_pis_cofins: false,
            per_tributos_federais: null,
            per_tributos_estaduais: null,
            per_tributos_municipais: null,
            aliquota_total_simples: null,
            tipo_beneficio: null,
            identificador_beneficio: null,
            empresa: empresaSelecionadaId,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                servico_municipio: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um serviço válido."),
                tributacao_issqn: Yup.number().required("O campo é obrigatório."),
                retencao_issqn: Yup.number().required("O campo é obrigatório."),
                aliquota_issqn: Yup.number().required("O campo é obrigatório."),
                cst_pis_cofins: Yup.string().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço da operação inserido com sucesso!",
                            life: 1500,
                        });
                        listagemRef.current?.buscarDados();
                        resetForm();
                        setVisivel(false);
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/itens-operacao-fiscal-servico/",
                        body: {
                            ...values,
                            servico_municipio: values.servico_municipio.id,
                            operacao_servico: opFiscal?.id,
                            empresa: empresaSelecionadaId,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço da operação alterado com sucesso!",
                            life: 1500,
                        });
                        listagemRef.current?.buscarDados();
                        resetForm();
                        setVisivel(false);
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `/servicos/itens-operacao-fiscal-servico/${values.id}/`,
                        body: {
                            ...values,
                            servico_municipio: values.servico_municipio.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const esconderDialog = async () => {
        resetForm();
        setVisivel(false);
    };

    const exibirEditar = (servico) => {
        setValues(servico);
        setVisivel(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro"
                    onClick={() => exibirEditar(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EDITAR]}
                    componente={BotaoDelete}
                    url="/servicos/itens-operacao-fiscal-servico/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir o serviço da operação?</span>}
                    classNames="p-mb-1"
                    tooltip="Deletar serviço"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O serviço não pode ser removido da operação."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setVisivel(true)}
            />
        </>
    );

    const colunas = [
        { field: "servico_municipio.codigo", header: "Código", style: { width: "10%" } },
        { field: "servico_municipio.descricao", header: "Descrição" },
        {
            field: "servico_municipio.municipio",
            header: "Município",
            action: (e) => formataMunicipio(e.servico_municipio.municipio),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    const formataMunicipio = (cidade) => {
        return `${cidade.nome} - ${cidade.estado.uf}`;
    };

    const autoCompleteTemplate = (servico) => {
        return `${servico.codigo} - ${servico.descricao} (${servico.municipio.nome})`;
    };

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                titulo="Serviços da Operação"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/servicos/itens-operacao-fiscal-servico?operacao_servico=${opFiscal?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header={"Serviço da Operação fiscal"}
                visible={visivel}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw" }}
                onHide={() => esconderDialog()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Label htmlFor="servico_municipio" label="Serviço" />
                            <MakoAutoComplete
                                id="servico_municipio"
                                name="servico_municipio"
                                value={formik.values.servico_municipio}
                                onChange={({ value }) => {
                                    setFieldValue("servico_municipio", value);
                                    setFieldValue("descricao", value.descricao);
                                }}
                                itemTemplate={autoCompleteTemplate}
                                field="descricao"
                                urlSearch={`/servicos/servicos-municipio?search=`}
                                placeholder="Busque por serviço ou seu respectivo município..."
                                className={classNames({ "p-invalid": formik.errors.servico_municipio })}
                            />
                            {formik.errors.servico_municipio && (
                                <small className="p-error">{formik.errors.servico_municipio}</small>
                            )}
                        </div>
                    </div>
                    {formik.values.servico_municipio?.id ? (
                        <div>
                            <Divider align="center">
                                <b>Dados do Serviço</b>
                            </Divider>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <Label htmlFor="codigo" label="Código" />
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        value={formik.values.servico_municipio.codigo}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-9">
                                    <Label htmlFor="descricao" label="Descrição" />
                                    <InputText
                                        id="descricao"
                                        name="descricao"
                                        value={formik.values.servico_municipio.descricao}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-5">
                                    <Label htmlFor="municipio" label="Município" />
                                    <InputText
                                        id="municipio"
                                        name="municipio"
                                        value={`${formik.values.servico_municipio.municipio.nome} - ${formik.values.servico_municipio.municipio.estado.uf}`}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <Label htmlFor="aliquota" label="Alíquota" />
                                    <InputNumber
                                        id="aliquota"
                                        name="aliquota"
                                        prefix="%"
                                        mode="decimal"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        value={formik.values.servico_municipio.aliquota}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <Label htmlFor="cnae" label="Cnae" />
                                    <InputText
                                        id="cnae"
                                        name="cnae"
                                        value={formik.values.servico_municipio.cnae || "-"}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <Divider align="center">
                        <b>Tributação</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="tributacao_issqn" label="Tributação ISSQN" obrigatorio />
                            <Dropdown
                                id="tributacao_issqn"
                                name="tributacao_issqn"
                                placeholder="Selecione..."
                                options={TIPO_TRIBUTACAO_ISSQN}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.tributacao_issqn}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.tributacao_issqn })}
                            />
                            {formik.errors.tributacao_issqn && (
                                <small className="p-error">{formik.errors.tributacao_issqn}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="codigo_issqn" label="Código ISSQN" />
                            <InputText
                                id="codigo_issqn"
                                name="codigo_issqn"
                                value={formik.values.codigo_issqn}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo_issqn })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.codigo_issqn && (
                                <small className="p-error">{formik.errors.codigo_issqn}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="imunidade_issqn" label="Tipo imunidade ISSQN" />
                            <Dropdown
                                id="imunidade_issqn"
                                name="imunidade_issqn"
                                placeholder="Selecione..."
                                options={TIPO_IMUNIDADE_ISSQN}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.imunidade_issqn}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.imunidade_issqn })}
                            />
                            {formik.errors.imunidade_issqn && (
                                <small className="p-error">{formik.errors.imunidade_issqn}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_issqn" label="Alíq. ISSQN" />
                            <InputNumber
                                id="aliquota_issqn"
                                name="aliquota_issqn"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_issqn}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_issqn })}
                            />
                            {formik.errors.aliquota_issqn && (
                                <small className="p-error">{formik.errors.aliquota_issqn}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="retencao_issqn" label="Tipo retenção ISSQN" obrigatorio />
                            <Dropdown
                                id="retencao_issqn"
                                name="retencao_issqn"
                                placeholder="Selecione..."
                                options={TIPO_RETENCAO_ISSQN}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.retencao_issqn}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.retencao_issqn })}
                            />
                            {formik.errors.retencao_issqn && (
                                <small className="p-error">{formik.errors.retencao_issqn}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="cst_pis_cofins" label="CST PIS/Cofins" obrigatorio />
                            <Dropdown
                                id="cst_pis_cofins"
                                name="cst_pis_cofins"
                                placeholder="Selecione..."
                                options={TIPO_CST_PIS_COFINS}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.cst_pis_cofins}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cst_pis_cofins })}
                            />
                            {formik.errors.cst_pis_cofins && (
                                <small className="p-error">{formik.errors.cst_pis_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_pis" label="Alíq. PIS" />
                            <InputNumber
                                id="aliquota_pis"
                                name="aliquota_pis"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_pis}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                            />
                            {formik.errors.aliquota_pis && (
                                <small className="p-error">{formik.errors.aliquota_pis}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_cofins" label="Alíq. Cofins" />
                            <InputNumber
                                id="aliquota_cofins"
                                name="aliquota_cofins"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cofins}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                            />
                            {formik.errors.aliquota_cofins && (
                                <small className="p-error">{formik.errors.aliquota_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="retem_pis_cofins" label="Retém PIS/Cofins" />
                            <Dropdown
                                id="retem_pis_cofins"
                                name="retem_pis_cofins"
                                placeholder="Selecione..."
                                options={SIM_NAO_BOOLEAN}
                                optionValue="id"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.retem_pis_cofins}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.retem_pis_cofins })}
                            />
                            {formik.errors.retem_pis_cofins && (
                                <small className="p-error">{formik.errors.retem_pis_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="per_tributos_federais" label="% Tributos Federais" />
                            <InputNumber
                                id="per_tributos_federais"
                                name="per_tributos_federais"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.per_tributos_federais}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.per_tributos_federais })}
                            />
                            {formik.errors.per_tributos_federais && (
                                <small className="p-error">{formik.errors.per_tributos_federais}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="per_tributos_estaduais" label="% Tributos Estaduais" />
                            <InputNumber
                                id="per_tributos_estaduais"
                                name="per_tributos_estaduais"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.per_tributos_estaduais}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.per_tributos_estaduais })}
                            />
                            {formik.errors.per_tributos_estaduais && (
                                <small className="p-error">{formik.errors.per_tributos_estaduais}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="per_tributos_municipais" label="% Tributos Municipais" />
                            <InputNumber
                                id="per_tributos_municipais"
                                name="per_tributos_municipais"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.per_tributos_municipais}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.per_tributos_municipais })}
                            />
                            {formik.errors.per_tributos_municipais && (
                                <small className="p-error">{formik.errors.per_tributos_municipais}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_total_simples" label="Alíq. Simples" />
                            <InputNumber
                                id="aliquota_total_simples"
                                name="aliquota_total_simples"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_total_simples}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_total_simples })}
                            />
                            {formik.errors.aliquota_total_simples && (
                                <small className="p-error">{formik.errors.aliquota_total_simples}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_inss" label="Alíq. Inss" />
                            <InputNumber
                                id="aliquota_inss"
                                name="aliquota_inss"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_inss}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_inss })}
                            />
                            {formik.errors.aliquota_inss && (
                                <small className="p-error">{formik.errors.aliquota_inss}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_cpp" label="Alíq. CPP" />
                            <InputNumber
                                id="aliquota_cpp"
                                name="aliquota_cpp"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cpp}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cpp })}
                            />
                            {formik.errors.aliquota_cpp && (
                                <small className="p-error">{formik.errors.aliquota_cpp}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_outras_retencoes" label="Alíq. outras retenções" />
                            <InputNumber
                                id="aliquota_outras_retencoes"
                                name="aliquota_outras_retencoes"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_outras_retencoes}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_outras_retencoes })}
                            />
                            {formik.errors.aliquota_outras_retencoes && (
                                <small className="p-error">{formik.errors.aliquota_outras_retencoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="aliquota_total_tributos" label="Alíq. total tributos" />
                            <InputNumber
                                id="aliquota_total_tributos"
                                name="aliquota_total_tributos"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_total_tributos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_total_tributos })}
                            />
                            {formik.errors.aliquota_total_tributos && (
                                <small className="p-error">{formik.errors.aliquota_total_tributos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="tipo_beneficio" label="Benefício" />
                            <Dropdown
                                id="tipo_beneficio"
                                name="tipo_beneficio"
                                placeholder="Selecione..."
                                options={TIPO_BENEFICIO_MUNICIPAL}
                                optionValue="value"
                                optionLabel="label"
                                showClear={false}
                                value={formik.values.tipo_beneficio}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.tipo_beneficio })}
                            />
                            {formik.errors.tipo_beneficio && (
                                <small className="p-error">{formik.errors.tipo_beneficio}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="identificador_beneficio" label="Identificador benefício" />
                            <InputText
                                id="descricao"
                                name="identificador_beneficio"
                                value={formik.values.identificador_beneficio}
                                onChange={formik.handleChange}
                                maxLength={20}
                                className={classNames({ "p-invalid": formik.errors.identificador_beneficio })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.identificador_beneficio && (
                                <small className="p-error">{formik.errors.identificador_beneficio}</small>
                            )}
                        </div>
                    </div>
                    <MakoActionsButtons>
                        <Button
                            icon={formik.values.id ? MAKO_ICONS.GRAVAR : MAKO_ICONS.NOVO}
                            type="submit"
                            label={formik.values.id ? "Salvar" : "Inserir"}
                            className="p-button-success"
                        />
                        <Button
                            icon={MAKO_ICONS.CANCEL}
                            type="reset"
                            label="Fechar"
                            className="p-button-danger"
                            onClick={() => esconderDialog()}
                        />
                    </MakoActionsButtons>
                </form>
            </Dialog>
        </>
    );
};
