import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN, TIPO_CAMPO_PERSONALIZADO_CHOICE } from "@/assets/constants/constants";
import { CONTENT_TYPES } from "@/assets/constants/contentTypes";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import { SelectButton } from "primereact/selectbutton";

const urlvoltar = "/configuracoes/campos-personalizados";

export const CamposPersonalizadosForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPatch, httpPost } = useHttp();

    const campoVazio = {
        name: "",
        label: "",
        mask: "",
        default_value: "",
        field_choices: "",
        field_type: "t",
        is_required: false,
        content_type: null,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.campos : campoVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                name: Yup.string().max(150).required("O campo 'nome' é obrigatório."),
                label: Yup.string().max(150).required("O campo 'label' é obrigatório."),
                content_type: Yup.number().required("O campo 'tipo de conteúdo' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Campo personalizado cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url: `/custom-fields/fields/`, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Campo personalizado alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPatch({ url: `/custom-fields/fields/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo campo personalizado" : "Manutenção de campo personalizado"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="name" label="Nome" obrigatorio />
                        <InputText
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.name })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.name && <small className="p-error">{formik.errors.name}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="label" label="Label" obrigatorio />
                        <InputText
                            id="label"
                            name="label"
                            value={formik.values.label}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.label })}
                            autoComplete="off"
                        />
                        {formik.errors.label && <small className="p-error">{formik.errors.label}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="content_type" label="Modelo atribuído" obrigatorio />
                        <Dropdown
                            id="content_type"
                            name="content_type"
                            url={`/custom-fields/content-types?limit=1000&model__in=${CONTENT_TYPES.map(
                                (el) => el.split(".")[1]
                            ).join(",")}`}
                            filter
                            filterBy="model"
                            optionValue="id"
                            optionLabel="model"
                            value={formik.values.content_type}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="field_type" label="ipo de campo" obrigatorio />
                        <Dropdown
                            id="field_type"
                            name="field_type"
                            options={TIPO_CAMPO_PERSONALIZADO_CHOICE}
                            filter
                            filterBy="label"
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.field_type}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="default_value" label="Valor padrão" />
                        <InputText
                            id="default_value"
                            name="default_value"
                            value={formik.values.default_value}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.default_value })}
                            autoComplete="off"
                        />
                        {formik.errors.default_value && (
                            <small className="p-error">{formik.errors.default_value}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="mask" label="Máscara" />
                        <InputText
                            id="mask"
                            name="mask"
                            value={formik.values.mask}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.mask })}
                            autoComplete="off"
                        />
                        {formik.errors.mask && <small className="p-error">{formik.errors.mask}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="field_choices" label="Opções do campo" />
                        <InputText
                            id="field_choices"
                            name="field_choices"
                            value={formik.values.field_choices}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.field_choices })}
                            autoComplete="off"
                        />
                        {formik.errors.field_choices && (
                            <small className="p-error">{formik.errors.field_choices}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="is_required" label="Campo obrigatório?" />
                        <SelectButton
                            id="is_required"
                            name="is_required"
                            value={formik.values.is_required}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
