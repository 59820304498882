import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useImpressaoTermica from "@/hooks/useImpressaoTermica";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const BASE_URL = "/relatorios/impressora-termica/";

export const ImpressoraTermicaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { solicitarImpressao } = useImpressaoTermica();

    async function testarImpressora(impressora) {
        const body = {
            chave: "impressora.testar",
            impressora,
            filtros: { id: impressora },
        };
        await solicitarImpressao(body);
    }

    const empresasBodyTemplate = (rowData) => {
        const qtdEmpresas = rowData.empresas.length;
        return (
            <>
                <Tooltip
                    target={`.custom-target-${rowData.id}`}
                    content={rowData.empresas.map((e) => e.nome).join("\n")}
                />
                <Tag
                    value={`${qtdEmpresas} empresa${qtdEmpresas > 1 ? "s" : ""}`}
                    className={`custom-target-${rowData.id}`}
                />
            </>
        );
    };

    const ativaBodyTemplate = (rowData) => {
        if (!rowData.ativa) return <Tag value="NÃO" severity="danger" />;
        return <Tag value="SIM" severity="success" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_IMPRESSORATERMICA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    onClick={() =>
                        history.push({
                            pathname: "/configuracoes/dispositivos/impressoras-termicas/form",
                            state: rowData,
                        })
                    }
                />
                <Button
                    icon="pi pi-print"
                    onClick={() => testarImpressora(rowData.id)}
                    className="p-button-rounded p-button-info"
                />
                <ButtonListagemOpcoes
                    url={"/relatorios/impressora-termica"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="a"
                    entidade="impressora térmica"
                    attr="ativa"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja mesmo excluir a impressora térmica <b>{rowData.nome}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A impressora térmica não pode ser excluída.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OUTROS_IMPRESSORATERMICA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/configuracoes/dispositivos/impressoras-termicas/form")}
            />
        </>
    );

    const colunas = [
        { field: "nome", header: "Nome" },
        { field: "empresas", header: "Empresas", style: { width: "15%" }, action: empresasBodyTemplate },
        { field: "ativa", header: "Ativa?", style: { width: "10%" }, action: ativaBodyTemplate },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Impressoras térmicas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
