import React, { useMemo } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { ESCOPO_FORMA_RECEB_PGTO_CHOICE, SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { SelectButton } from "primereact/selectbutton";
import { MakoButton as Button } from "@/components/MakoButton";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/financeiro/formas-recebimentos/";
const urlvoltar = "/financeiro/cadastros/formas-recebimentos";

const INITIAL_VALUES = {
    codigo: "",
    descricao: "",
    tipo: null,
    utilizar_entrada: false,
    viacaixa: true,
    escopo: "X",
    parcelas_valores_iguais: true,
    recebe_crediario: false,
};

export const FinanceiroFormaRecebimentoForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();
    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: !state?.formaRecebimento
            ? INITIAL_VALUES
            : { ...state?.formaRecebimento, tipo: state.formaRecebimento.tipo.id },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                tipo: Yup.number()
                    .required("O campo 'tipo' é obrigatório")
                    .typeError("Escolha um tipo para a forma de recebimento."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Forma de recebimento cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url: `${url}`, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Forma de recebimento alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const escopoRecebimento = useMemo(() => {
        return ["R", "X"].includes(formik.values.escopo);
    }, [formik.values.escopo]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova forma de recebimento" : "Manutenção de forma de recebimento"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo" label="Tipo" obrigatorio />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione um tipo"
                            autoFocus
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="escopo" label="Escopo" obrigatorio />
                        <Dropdown
                            id="escopo"
                            name="escopo"
                            placeholder="Selecione um escopo"
                            options={ESCOPO_FORMA_RECEB_PGTO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.escopo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.escopo })}
                        />
                        {formik.errors.escopo && <small className="p-error">{formik.errors.escopo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="parcelas_valores_iguais" label="Parcelas com valores iguais" />
                        <SelectButton
                            id="parcelas_valores_iguais"
                            name="parcelas_valores_iguais"
                            value={formik.values.parcelas_valores_iguais}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                    {escopoRecebimento && (
                        <>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="utilizar_entrada" label="Pode ser utilizada como entrada?" />
                                <SelectButton
                                    id="utilizar_entrada"
                                    name="parcelas_valores_iguais"
                                    value={formik.values.utilizar_entrada}
                                    onChange={formik.handleChange}
                                    options={SIM_NAO_BOOLEAN}
                                    optionLabel="label"
                                    optionValue="id"
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="viacaixa" label="Via caixa" />
                                <SelectButton
                                    id="viacaixa"
                                    name="viacaixa"
                                    value={formik.values.viacaixa}
                                    onChange={formik.handleChange}
                                    options={SIM_NAO_BOOLEAN}
                                    optionLabel="label"
                                    optionValue="id"
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="recebe_crediario" label="Recebe crediário" />
                                <SelectButton
                                    id="recebe_crediario"
                                    name="recebe_crediario"
                                    value={formik.values.recebe_crediario}
                                    onChange={formik.handleChange}
                                    options={SIM_NAO_BOOLEAN}
                                    optionLabel="label"
                                    optionValue="id"
                                />
                            </div>
                        </>
                    )}
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
