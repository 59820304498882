import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_SALDOESTOQUE } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { dataToStr } from "@/assets/util/datas";
import { MakoInputFornecedor } from "@/components/MakoInputs";

const { ExportCSV } = R.Buttons;

const BASE_URL = "/relatorios/custo-saldo-estoques/";

const FILE_NAME = gerarFileName("Custo Saldos Estoque");

const FILTROS_VIEWSET = {
    estoque: "estoque",
    ordenacao: "ordenacao",
    saldo_consolidado: "saldo_consolidado",
    saldo_normal: "saldo_normal",
    agrupamento: "agrupamento",
    tipo_produto: "tipo_produto",
    sku__categoriasku__categoria: "sku__categoriasku__categoria",
    data_finalizacao: "data_finalizacao",
    fornecedor: "fornecedor",
};

const OPTIONS_ORDENACAO = [
    { label: "Código", value: "codigo" },
    { label: "Descrição", value: "nome_descricao" },
];

const SALDO_CONSOLIDADO = [
    { label: "Qualquer saldo", value: "qualquer_saldo_consolidado" },
    { label: "Saldo positivo", value: "+saldo_consolidado" },
    { label: "Saldo negativo", value: "-saldo_consolidado" },
    { label: "Saldo zerado", value: "saldo_consolidado_zerado" },
];

const SALDO_NORMAL = [
    { label: "Qualquer saldo", value: "qualquer_saldo_normal" },
    { label: "Saldo positivo", value: "+saldo_normal" },
    { label: "Saldo negativo", value: "-saldo_normal" },
    { label: "Saldo zerado", value: "saldo_normal_zerado" },
];

const OPTIONS_AGRUPAMENTO = [
    { label: "Classificação", value: true },
    { label: "Listagem simples", value: false },
];

export const RelatorioSaldoEstoque = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();
    const { empresaSelecionadaId } = useEmpresa();

    const empresaSelecionada = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== undefined && dados[key] !== null) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "estoque",
            label: "Estoque",
            path: "nome",
        },
        {
            key: "sku__categoriasku__categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "data_finalizacao",
            label: "Data do custo",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "tipo_produto",
            label: "Tipo de produto",
            optionKey: "value",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            options: TIPO_SKU_ESTOCADO,
        },
        {
            key: "saldo_consolidado",
            label: "Saldo Consolidado",
            optionKey: "value",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            options: SALDO_CONSOLIDADO,
        },
        {
            key: "saldo_normal",
            label: "Saldo Normal",
            optionKey: "value",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            options: SALDO_NORMAL,
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                estoque: Yup.object().required("Informe um 'estoque' válido.").typeError("Informe um 'estoque' válido"),
                data_finalizacao: Yup.date()
                    .required("Informe uma 'data de finalização' válida")
                    .typeError("Informe uma 'data de finalização' válida"),
                sku__categoriasku__categoria: Yup.array().nullable().default([]),
                fornecedor: Yup.object().nullable().default(null).typeError("Informe um 'fornecedor' válida."),
                tipo_produto: Yup.string()
                    .required("Selecione um 'tipo de produto' válido")
                    .typeError("Selecione um 'tipo de produto' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                sku__categoriasku__categoria: selecionados,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);
            if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.data_finalizacao instanceof Date) {
                dadosValidados.data_finalizacao = dataToStr(dadosValidados.data_finalizacao, "yyyy-MM-dd");
            }
            if (selecionados) {
                dadosValidados.sku__categoriasku__categoria = selecionados.map((c) => c.id);
            }
            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_ESTOQUE_SALDOESTOQUE,
                    emails,
                    filtros,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "codigo", header: "Código", style: { width: "8%" } },
        { field: "descricao", header: "Descrição", style: { width: "8%" } },
        { field: "saldo_consolidado", header: "Quant. Consolidado", style: { width: "8%" }, align: "center" },
        { field: "saldo_normal", header: "Quant. Normal", style: { width: "8%" }, align: "center" },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_ESTOQUE_SALDOESTOQUE}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    return (
        <R.Wrapper titulo={"de saldos estoque"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        categoria: null,
                        empresa: null,
                        data: null,
                        descricao: "",
                        operacao_fiscal: null,
                        chave: null,
                        saldo_consolidado: "qualquer_saldo_consolidado",
                        saldo_normal: "qualquer_saldo_normal",
                        agrupamento: true,
                        sku__categoriasku__categoria: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Estoque",
                        inputId: "estoque",
                        inputName: "estoque",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione um estoque",
                            filter: true,
                            filterBy: "nome",
                            url: `/produtos/estoques-empresas/?empresa=${empresaSelecionadaId}`,
                            optionLabel: "nome",
                            buscar: !!empresaSelecionadaId,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Data do saldo",
                        inputId: "data_finalizacao",
                        inputName: "data_finalizacao",
                        component: MakoCalendar,
                        required: true,

                        fieldSize: 2,
                    },
                    {
                        label: "Tipo de produto",
                        inputId: "tipo_produto",
                        inputName: "tipo_produto",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            options: TIPO_SKU_ESTOCADO,
                            optionLabel: "label",
                            optionValue: "value",
                        },
                    },
                    {
                        label: "Saldo Consolidado",
                        inputId: "saldo_consolidado",
                        inputName: "saldo_consolidado",
                        component: Dropdown,
                        componentProps: {
                            options: SALDO_CONSOLIDADO,
                            placeholder: "Selecione o saldo consolidado",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Saldo Normal",
                        inputId: "saldo_normal",
                        inputName: "saldo_normal",
                        component: Dropdown,
                        componentProps: {
                            options: SALDO_NORMAL,
                            placeholder: "Selecione o saldo normal",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Categoria",
                        inputId: "sku__categoriasku__categoria",
                        inputName: "sku__categoriasku__categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: getCategoriaCompleta,
                            showClear: true,
                            selectionMode: "checkbox",
                            metaKeySelection: false,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 3,
                    },
                    {
                        label: "Agrupamento",
                        inputId: "agrupamento",
                        inputName: "agrupamento",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_AGRUPAMENTO,
                        },
                    },
                    {
                        label: "Ordenação",
                        inputId: "ordenacao",
                        inputName: "ordenacao",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_ORDENACAO,
                            placeholder: "Selecione uma opção de ordenação",
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_ESTOQUE_SALDOESTOQUE}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
