import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { MakoInputPercent } from "@/components/MakoInputs";

const url = "/produtos/formacoes-precos/";
const urlvoltar = "/vendas/cadastros/formacao-precos";

export const FormacaoPrecoForm = (props) => {
    const { formacao } = props.location?.state || {};
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPatch, httpPost } = useHttp();
    const paramVazio = {
        descricao: "",
        margem_lucro: 0,
        encargo_alvo: 0,
        indice_markup: 0,
        usar_markup: false,
    };

    const formik = useFormik({
        initialValues: formacao
            ? {
                  ...paramVazio,
                  ...formacao,
              }
            : paramVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                margem_lucro: Yup.number().required("O campo 'margem de lucro' é obrigatório."),
                encargo_alvo: Yup.number().required("O campo 'encargo alvo' é obrigatório."),
                indice_markup: Yup.number().when("usar_markup", {
                    is: (val) => val === true,
                    then: Yup.number()
                        .min(0.01, "O campo 'índice de markup' não pode ser ZERO.")
                        .required("O campo 'índice de markup' é obrigatório."),
                }),
            });
            const dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Formação de preços cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url, body: dadosValidados }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Formação de preços alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: dadosValidados }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova formação de preços" : "Manutenção de formação de preços"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoFocus
                            tooltip="Descrição que será dada a formação de preços."
                            tooltipOptions={{ position: "top" }}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="margem_lucro" label="% Margem Lucro" obrigatorio />
                        <MakoInputPercent
                            id="margem_lucro"
                            name="margem_lucro"
                            min={0}
                            casasDecimais={2}
                            value={formik.values.margem_lucro}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.margem_lucro })}
                        />
                        {formik.errors.margem_lucro && <small className="p-error">{formik.errors.margem_lucro}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="encargo_alvo" label="% Encargo mensal" obrigatorio />
                        <MakoInputPercent
                            id="encargo_alvo"
                            name="encargo_alvo"
                            casasDecimais={3}
                            value={formik.values.encargo_alvo}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.encargo_alvo })}
                        />
                        {formik.errors.encargo_alvo && <small className="p-error">{formik.errors.encargo_alvo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="usar_markup" label="Usar markup?" />
                        <SelectButton
                            id="usar_markup"
                            name="usar_markup"
                            value={formik.values.usar_markup}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="indice_markup" label="Indice Markup" obrigatorio />
                        <MakoInputPercent
                            id="indice_markup"
                            name="indice_markup"
                            suffix={null}
                            maxFractionDigits={6}
                            disabled={!formik.values.usar_markup}
                            value={formik.values.indice_markup}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indice_markup })}
                        />
                        {formik.errors.indice_markup && (
                            <small className="p-error">{formik.errors.indice_markup}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
