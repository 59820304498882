import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DataScroller } from "primereact/datascroller";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import { Calendar as MakoCalendar } from "@/components/Calendar";

import { SIM_NAO_BOOLEAN, TIPO_OPERADORA_TELEFONICA_CHOICE } from "@/assets/constants/constants";
import { PESSOAS_TELEFONES_TIPOS_IDENTIFICACAO } from "@/assets/constants/parametros";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { Menu } from "primereact/menu";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { SelectButton } from "primereact/selectbutton";

const _URL = "/pessoas/telefones-perfis/";

const TelefoneForm = () => {
    const [tiposTelefone, setTiposTelefone] = useState([]);
    const [telefone, setTelefone] = useState(null);
    const [visible, setVisible] = useState(false);

    const { handleDados, telefones, checarInformacao } = usePessoa();
    const { showError, showSuccess } = useToast();
    const { getParam } = useParam();

    const menuAcoesRef = useRef();

    const TIPOS_TELEFONE = [
        { id: "Nacional", label: "Nacional" },
        { id: "Internacional", label: "Internacional" },
    ];

    const { setValues, ...formik } = useFormik({
        initialValues: {
            identificacao: "",
            tipo_telefone: "Nacional",
            telefone: "",
            principal: false,
            e_whatsapp: false,
            ativo: true,
            operadora: "",
            contato: "",
            data_alteracao: new Date(),
            _status: OP_CRUD_DJANGO.novo,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        const tiposTelefoneParam = getParam(PESSOAS_TELEFONES_TIPOS_IDENTIFICACAO);

        if (tiposTelefoneParam) {
            const tiposTel = tiposTelefoneParam.valor.split("|");

            setTiposTelefone(tiposTel.map((tipo) => ({ id: tipo, label: tipo })));
        }
    }, [getParam]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'identificação do telefone' é obrigatório."),
                telefone: Yup.string().required("O campo 'telefone' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            await handleDados(values, _URL, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const formatarTelefone = (tel) => {
        if (tel) {
            tel = tel.replace(/([^\d])+/gim, "");
            if (formik.values.tipo_telefone === "Nacional") {
                if (tel.startsWith("0800")) {
                    formik.setFieldValue("telefone", tel.replace(/^(\d{4})(\d{3})(\d{4})/, "$1 $2 $3"));
                } else if (tel.length === 11) {
                    formik.setFieldValue("telefone", tel.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"));
                } else if (tel.length === 10) {
                    formik.setFieldValue("telefone", tel.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3"));
                } else {
                    formik.setFieldError("telefone", "A quantidade de dígitos não é válida para um telefone nacional.");
                }
            } else {
                formik.setFieldValue("telefone", `+${tel}`);
            }
        }
    };

    const alterarDataAtualizacaoTelefone = useCallback(
        async (id) => {
            const resposta = await checarInformacao("telefones", id);

            if (resposta === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Telefone verificado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A sua requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        },
        [checarInformacao, showSuccess, showError]
    );

    const excluirTelefone = async () => {
        await handleDados(telefone, _URL, OP_CRUD_DJANGO.deletar);
        setVisible(false);
    };

    const itensMenuAcoes = useMemo(() => {
        return [
            {
                label: "Checar telefone",
                icon: "pi pi-check",
                disabled: !!!telefone?.id,
                command: () => alterarDataAtualizacaoTelefone(telefone?.id),
            },
            {
                label: telefone?.principal ? "Definir como alternativo" : "Definir como prinicipal",
                icon: "pi pi-star-fill",
                command: async () =>
                    await handleDados({ id: telefone.id, principal: !telefone.principal }, _URL, OP_CRUD_DJANGO.editar),
            },
            {
                label: telefone?.ativo ? "Desativar telefone" : "Ativar telefone",
                icon: "pi pi-ban",
                command: async () =>
                    await handleDados({ id: telefone.id, ativo: !telefone.ativo }, _URL, OP_CRUD_DJANGO.editar),
            },
            {
                label: "Excluir telefone",
                icon: "pi pi-trash",
                command: () => setVisible(true),
            },
        ];
    }, [telefone, handleDados, alterarDataAtualizacaoTelefone]);

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.principal ? "instock" : "lowstock"}`}>
                            {data.principal ? "Principal" : "Alternativo"}
                        </span>
                        <span className={`p-ml-2 product-badge status-${data.ativo ? "instock" : "lowstock"}`}>
                            {data.ativo ? "Ativo" : "Inativo"}
                        </span>
                        {data?._status === OP_CRUD_DJANGO.deletar && (
                            <span className={"p-ml-2 product-badge status-outofstock"}>A SER EXCLUIDO</span>
                        )}
                        <div className="p-mt-2 product-name">{data.telefone}</div>
                        {data.e_whatsapp && (
                            <div className="p-mb-2">
                                <i
                                    className="pi pi-whatsapp product-category-icon"
                                    style={{ fontSize: "1.5em", color: "#25d366" }}
                                />
                                <span className="product-category">Contato de WhatsApp</span>
                            </div>
                        )}
                        <div>
                            <i className="pi pi-flag product-category-icon" />
                            <span className="product-category">{`Operadora: ${
                                TIPO_OPERADORA_TELEFONICA_CHOICE.find((el) => el.value === data.operadora)?.label ||
                                "Outra"
                            }`}</span>
                        </div>
                        <i className="pi pi-tag product-category-icon" />
                        <span className="product-category">Identificação: {data.identificacao}</span>
                        {data.contato && (
                            <div>
                                <i className="pi pi-comment product-category-icon" />
                                <span className="product-category">Contato: {data.contato}</span>
                            </div>
                        )}
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        _status: OP_CRUD_DJANGO.editar,
                                    });
                                }}
                            />
                            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu" />
                            <Button
                                icon="pi pi-cog"
                                aria-haspopup
                                aria-controls="popup_menu_acoes"
                                className="p-button-rounded p-button-info"
                                onClick={(e) => {
                                    setTelefone(data);
                                    menuAcoesRef.current?.toggle(e);
                                }}
                            />
                        </div>
                        {(data._id || (data._status && data._status !== OP_CRUD_DJANGO.novo)) && (
                            <div className="product-description p-mb-0" style={{ color: "#f00" }}>
                                **Clique em <b>Finalizar</b> para confirmar**
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [setValues, itensMenuAcoes]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="identificacao" label="Identificação do telefone" obrigatorio />
                        {tiposTelefone.length > 0 ? (
                            <Dropdown
                                id="identificacao"
                                name="identificacao"
                                options={tiposTelefone}
                                optionValue="id"
                                optionLabel="label"
                                autoFocus
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Selecione..."
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        ) : (
                            <InputText
                                id="identificacao"
                                name="identificacao"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                autoFocus
                                placeholder="Sugestão: Pessoal | Profissional"
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        )}
                        {formik.errors.identificacao && (
                            <small className="p-error">{formik.errors.identificacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="tipo-telefone" label="Tipo do telefone" obrigatorio />
                        <Dropdown
                            id="tipo-telefone"
                            name="tipo_telefone"
                            options={TIPOS_TELEFONE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_telefone}
                            onChange={formik.handleChange}
                            onBlur={() => formatarTelefone(formik.values.telefone)}
                            placeholder="Selecione..."
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="telefone" label="Telefone" obrigatorio />
                        <InputText
                            id="telefone"
                            name="telefone"
                            value={formik.values.telefone}
                            onChange={formik.handleChange}
                            onBlur={(e) => formatarTelefone(e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.telefone })}
                        />
                        {formik.errors.telefone && <small className="p-error">{formik.errors.telefone}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="operadora" label="Operadora" />
                        <Dropdown
                            id="operadora"
                            name="operadora"
                            options={TIPO_OPERADORA_TELEFONICA_CHOICE}
                            placeholder="Selecione"
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.operadora}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="e-whatsapp" label="Esse telefone é WhatsApp?" />
                        <Dropdown
                            id="e-whatsapp"
                            name="e_whatsapp"
                            options={SIM_NAO_BOOLEAN}
                            placeholder="Selecione"
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.e_whatsapp}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="contato" label="Contato" />
                        <InputText
                            id="contato"
                            name="contato"
                            value={formik.values.contato}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.contato })}
                        />
                        {formik.errors.contato && <small className="p-error">{formik.errors.contato}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="ult-atualizacao" label="Última atualização" />
                        <MakoCalendar
                            id="ult-atualizacao"
                            name="data_alteracao"
                            disabled
                            showIcon={false}
                            value={formik.values.data_alteracao}
                        />
                    </div>
                </div>
                {telefones.length === 0 && (
                    <div className="p-fluid p-formgrid p-grid p-mb-2">
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="principal" label="Telefone principal?" />
                            <SelectButton
                                id="principal"
                                name="principal"
                                value={formik.values.principal}
                                onChange={formik.handleChange}
                                options={SIM_NAO_BOOLEAN}
                                optionLabel="label"
                                optionValue="id"
                            />
                        </div>
                    </div>
                )}
                <CamposObrigatorios />
                <MakoActionsButtons className="p-mb-4">
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        className="p-button-warning"
                        onClick={() => formik.resetForm()}
                    />
                </MakoActionsButtons>
            </form>
            <div className="list-demo">
                <DataScroller
                    value={telefones}
                    itemTemplate={itemTemplate}
                    rows={3}
                    inline
                    scrollHeight="300px"
                    header="Telefones cadastrados"
                    emptyMessage="Nenhum telefone encontrado"
                />
            </div>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message={
                    <span>
                        Deseja realmente excluir o telefone? <br />
                        <b>{telefone?.identificacao}</b>
                    </span>
                }
                header="Confirmação de exclusão"
                icon="pi pi-info-circle"
                accept={excluirTelefone}
                acceptLabel="Deletar"
                acceptClassName="p-button-danger"
                rejectLabel="Cancelar"
            />
        </>
    );
};

export default memo(TelefoneForm);
