import React, { forwardRef, memo, useEffect, useState } from "react";
import { Dropdown } from "components/Dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import { FINANCEIRO_OBRIGAORCAMENTO, FINANCEIRO_PAGAMENTO_EXIGEPREVISAO } from "@/assets/constants/parametros";
import usePagamento from "@/hooks/usePagamento";
import useParam from "@/hooks/useParam";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoDropdownHistoricoPadrao } from "@/components/MakoInputs";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useToast from "@/hooks/useToast";

export const FinanceiroPagamentosForm = () => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const [exigePrevisao, setExigePrevisao] = useState(0);
    const [obrigaOrcamento, setObrigaOrcamento] = useState(0);
    const { empresaSelecionadaId } = useEmpresa();
    const { handleSubmitPagamento, handleEditarPagamento, pagamento, pagamentos, submit } = usePagamento();
    const { getParam } = useParam();
    const { showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            entrada: null,
            empresa: null,
            credor: null,
            competencia: null,
            historico_padrao: null,
            origem_fracionamento: "",
            data_emissao: new Date(),
            data_lancamento: new Date(),
            documento: "",
            forma_pagamento: null,
            plano_orcamentario: null,
            valor_total: null,
            conta_financeira: null,
            template_rateio: null,
            hasParcelas: true,
            isPrevisao: false,
            valor_parcela: 0,
            caixa: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                credor: Yup.object()
                    .required("O campo 'credor' é obrigatório.")
                    .typeError("O campo 'credor' é obrigatório."),
                valor_total: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .typeError("O campo 'valor' é obrigatório."),
                documento: Yup.string().required("O campo 'documento' é obrigatório."),
                data_lancamento: Yup.date()
                    .required("O campo 'data de lançamento' é obrigatório.")
                    .min(Yup.ref("data_emissao"), "A data de lançamento não pode ser anterior à data de emissão")
                    .max(new Date(), "A data de lançamento não pode ser após a data atual."),
                data_emissao: Yup.date().required("O campo 'data da emissão' é obrigatório."),
                plano_orcamentario:
                    obrigaOrcamento && obrigaOrcamento.valor === "1" && exigePrevisao && exigePrevisao.valor === "0"
                        ? Yup.date().required("O campo 'plano orçamentário' é obrigatório.")
                        : null,
                conta_financeira: Yup.number().nullable().notRequired().default(null),
                hasParcelas: Yup.bool().default(false),
                vencimento: Yup.date().when("hasParcelas", {
                    is: true,
                    then: Yup.date(),
                    otherwise: Yup.date()
                        .required("O campo 'vencimento' é obrigatório.")
                        .typeError("O campo 'vencimento' é obrigatório")
                        .min(Yup.ref("data_emissao"), "A data de vencimento não pode ser anterior a data de emissão"),
                }),
                valor_parcela: pagamento?.id
                    ? null
                    : Yup.number().when("hasParcelas", {
                          is: true,
                          then: Yup.number().nullable(),
                          otherwise: Yup.number()
                              .required("O campo 'valor da parcela' é obrigatório.")
                              .typeError("O campo 'valor da parcela' é obrigatório."),
                      }),
                isPrevisao: Yup.boolean().default(false),
                caixa: Yup.number().nullable().notRequired().default(null),
                template_rateio: Yup.number()
                    .required("O campo 'template de rateio' é obrigatório.")
                    .typeError("Informe uma template válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!pagamento?.id) {
                await handleSubmitPagamento({
                    ...values,
                    empresa: empresaSelecionadaId,
                    data_emissao: dataToStr(values.data_emissao, "yyyy-MM-dd"),
                    data_lancamento: dataToStr(values.data_lancamento, "yyyy-MM-dd"),
                    competencia: values.competencia?.id,
                });
            } else {
                const index = pagamentos?.findIndex((e) => e.id === pagamento.id);
                await handleEditarPagamento(values, index);
            }
        } catch (error) {
            console.log(error);

            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError();
            }
        }
    }

    const autoCompleteCredorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    const handleCompetencia = (e) => {
        setFieldValue("competencia", e?.value);
    };

    const handleDataEmissao = (e) => {
        setFieldValue("data_emissao", e.value);
    };

    useEffect(() => {
        if (exigePrevisao?.valor === "1") setFieldValue("previsao", true);
        else setFieldValue("previsao", false);
    }, [exigePrevisao, setFieldValue]);

    useEffect(() => {
        setExigePrevisao(getParam(FINANCEIRO_PAGAMENTO_EXIGEPREVISAO));
        setObrigaOrcamento(getParam(FINANCEIRO_OBRIGAORCAMENTO));
    }, [getParam]);

    useEffect(() => {
        if (pagamento) setValues(pagamento);
    }, [pagamento, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="credor" label="Credor" obrigatorio />
                    <MakoAutoComplete
                        id="credor"
                        name="credor"
                        value={formik.values.credor}
                        onChange={formik.handleChange}
                        minCaracteresBusca={4}
                        itemTemplate={autoCompleteCredorTemplate}
                        field="nome"
                        autoFocus
                        disabled={formik.values.id}
                        urlSearch={`/pessoas/perfis?query={id,nome,identificacao}&nome__unaccent__icontains=`}
                        placeholder="Comece a digitar o nome do credor para buscar... (min 4 caracteres)"
                        className={classNames({ "p-invalid": formik.errors.credor })}
                    />
                    {formik.errors.credor && <small className="p-error">{formik.errors.credor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="entrada" label="N.º Entrada" />
                    <InputText
                        id="entrada"
                        name="entrada"
                        value={formik.values.entrada}
                        onChange={formik.handleChange}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="conta_financeira" label="Conta financeira" />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url={`/financeiro/contas-financeiras/?query={id,descricao}&perfil=${empresaSelecionadaId}`}
                        placeholder="Selecione uma conta financeira"
                        emptyMessage="Não existem contas cadastradas."
                        showClear
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="forma_pagamento" label="Forma de pagamento" />
                    <Dropdown
                        id="forma_pagamento"
                        name="forma_pagamento"
                        url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_pagamento}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="documento" label="Documento" obrigatorio />
                    <InputText
                        id="documento"
                        name="documento"
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                        disabled={formik.values.id}
                        className={classNames({ "p-invalid": formik.errors.documento })}
                        autoComplete="off"
                    />
                    {formik.errors.documento && <small className="p-error">{formik.errors.documento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="historico" label="Histórico" obrigatorio />
                    <MakoDropdownHistoricoPadrao
                        filtros={{ tipo: "P" }}
                        id="historico"
                        name="historico_padrao"
                        disabled={!!formik.values.id}
                        value={formik.values.historico_padrao}
                        handleChangeForm={formik.handleChange}
                        setFieldValueForm={setFieldValue}
                        inputTextProps={{
                            value: formik.values.historico_original,
                        }}
                        className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                    />
                    {formik.errors.historico_padrao && (
                        <small className="p-error">{formik.errors.historico_padrao}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-6 p-md-3">
                    <Label htmlFor="valor_total" label="Valor bruto" obrigatorio />
                    <InputNumber
                        id="valor_total"
                        name="valor_total"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled={formik.values.id}
                        min={0}
                        value={formik.values.valor_total}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_total })}
                    />
                    {formik.errors.valor_total && <small className="p-error">{formik.errors.valor_total}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="competencia" label="Competência" />
                    <Dropdown
                        id="competencia"
                        name="competencia"
                        placeholder="Selecione uma competencia"
                        url={"/financeiro/competencias?limit=1000"}
                        filter
                        filterBy="grupo_competencia"
                        showClear
                        optionLabel="grupo_competencia"
                        emptyMessage="Não existem registros cadastrados"
                        value={formik.values.competencia}
                        onChange={handleCompetencia}
                        className={classNames({ "p-invalid": formik.errors.competencia })}
                    />
                    {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                </div>
                <div className="p-field p-col-6 p-md-3">
                    <Label htmlFor="data_lancamento" label="Data de lançamento" obrigatorio />
                    <MakoCalendar
                        id="data_lancamento"
                        name="data_lancamento"
                        disabled={formik.values.id}
                        value={formik.values.data_lancamento}
                        onChange={formik.handleChange}
                        className={classNames({
                            "p-invalid": formik.errors.data_lancamento,
                        })}
                    />
                    {formik.errors.data_lancamento && (
                        <small className="p-error">{formik.errors.data_lancamento}</small>
                    )}
                </div>
                <div className="p-field p-col-6 p-md-3">
                    <Label htmlFor="data_emissao" label="Data de emissão" obrigatorio />
                    <MakoCalendar
                        id="data_emissao"
                        name="data_emissao"
                        disabled={formik.values.id}
                        minDate={formik.values.competencia?.data_inicio}
                        maxDate={formik.values.competencia?.data_fim}
                        value={formik.values.data_emissao}
                        onChange={handleDataEmissao}
                        className={classNames({
                            "p-invalid": formik.errors.data_emissao,
                        })}
                    />
                    {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="template_rateio" label="Template de rateio" obrigatorio />
                    <Dropdown
                        id="template_rateio"
                        name="template_rateio"
                        url={`/financeiro/templates-rateios/?query={id,descricao}`}
                        disabled={formik.values.id}
                        optionValue="id"
                        optionLabel="descricao"
                        emptyMessage="Não existem registros cadastrados"
                        value={formik.values.template_rateio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.template_rateio })}
                    />
                    {formik.errors.template_rateio && (
                        <small className="p-error">{formik.errors.template_rateio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label
                        htmlFor="plano_orcamentario"
                        label="Plano Orçamentário"
                        obrigatorio={obrigaOrcamento?.valor === "1"}
                    />
                    <Dropdown
                        id="plano_orcamentario"
                        name="plano_orcamentario"
                        placeholder="Selecione um plano"
                        url={"/financeiro/planos-orcamentarios?limit=1000"}
                        filter
                        filterBy="descricao"
                        optionValue="id"
                        optionLabel="descricao"
                        emptyMessage="Não existem registros cadastrados"
                        value={formik.values.plano_orcamentario}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.plano_orcamentario })}
                    />
                    {formik.errors.plano_orcamentario && (
                        <small className="p-error">{formik.errors.plano_orcamentario}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="origem_fracionamento" label="Origem fracionamento" />
                    <InputText
                        id="origem_fracionamento"
                        name="origem_fracionamento"
                        value={formik.values.origem_fracionamento}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="caixa" label="Caixa" />
                    <Dropdown
                        id="caixa"
                        name="caixa"
                        disabled={!!formik.values.id}
                        url={`/financeiro/caixas/`}
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.caixa}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.caixa })}
                    />
                    {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                </div>
            </div>
            <CamposObrigatorios />
            {!submit ? (
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Incluir dados básicos"
                        type="submit"
                        className="p-button-info"
                    />
                </MakoActionsButtons>
            ) : (
                <FormikAutoSave intervalo={500} autosave={pagamento?.id} formik={formik} />
            )}
        </form>
    );
};

export default memo(forwardRef(FinanceiroPagamentosForm));
