import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import useToast from "@/hooks/useToast";

const url = "/fiscal/cst-icms/";

export const FiscalIcmsPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showSuccess } = useToast();

    useClearRefs(listagemRef);

    const STATUS_ICMS = [
        { value: true, label: "Ativo" },
        { value: false, label: "Inativo" },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_ICMS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de ICMS"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/outros/icms/form",
                            state: { icms: rowData },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/fiscal/cst-icms"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="CST de ICMS"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        onDelete: () => {
                            listagemRef.current?.buscarDados();
                            showSuccess({
                                summary: "Sucesso",
                                detail: "ICMS deletado com sucesso!",
                                life: 3000,
                            });
                        },
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o ICMS <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O CST de ICMS não pode ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_ICMS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/outros/icms/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_ICMS}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="INATIVO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
        },
        {
            header: "Situação",
            field: "ativo",
            style: { width: "10%" },
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Icms"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                naoBuscarSemEmpresa
                filtarPorEmpresa
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    ativo: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
        </PageBase>
    );
};
