import React, { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Ripple } from "primereact/ripple";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { TIPO_STATUS_TRANSFERENCIA_CHOICE } from "@/assets/constants/constants";
import { Panel } from "primereact/panel";
import useHttp from "@/hooks/useHttp";

export const FiltroAvancadoModalForm = ({ setUrl, listagemRef, setFiltroAvancado }) => {
    const [crescente, setCrescente] = useState(null);
    const [decrescente, setDecrescente] = useState(null);
    const [origem, setOrigem] = useState(null);
    const [destino, setDestino] = useState(null);
    const [data, setData] = useState(null);
    const [listaCentros, setListaCentros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("true");
    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();
    const { httpGet } = useHttp();

    async function handleSearch() {
        try {
            showLoading();
            let filtro = "&";
            let multiFiltro = false;

            if (status) {
                filtro = `${filtro}${multiFiltro ? "&" : ""}ativo=${status}`;
                multiFiltro = true;
            }

            setUrl(
                `/produtos/itens?query={id,nome, ncm, marca}${filtro}${
                    crescente ? `&ordering=${crescente}` : decrescente ? `&ordering=-${decrescente}` : ""
                }`
            );
            hideLoading();

            listagemRef.current?.buscarDados();
            setFiltroAvancado(false);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
    }

    const limparFiltro = () => {
        setOrigem(null);
        setDestino(null);
        setStatus("true");
        setCrescente(null);
        setDecrescente(null);
    };

    const template = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>Ordem</span>
            </div>
        );
    };

    const selecionaOrdem = (op, field) => {
        if (op === "desc") {
            setDecrescente(field);
            setCrescente(null);
        } else {
            setCrescente(field);
            setDecrescente(null);
        }
    };

    const listarCentrosEstocagem = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setListaCentros(data.results);
            },
        };

        setLoading(true);
        await httpGet({ url: "/produtos/centros-estocagem?limit=1000" }, handlers);
        setLoading(false);
    }, [httpGet]);

    useEffect(() => {
        listarCentrosEstocagem();
    }, [listarCentrosEstocagem]);

    return (
        <>
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_origem">Centro de Estocagem Origem</label>
                        <Dropdown
                            id="ce_origem"
                            name="ce_origem"
                            placeholder={!loading ? "Selecione" : "Carregando centros de estocagem..."}
                            disabled={loading}
                            options={listaCentros}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={origem}
                            onChange={(e) => setOrigem(e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_destino">Centro de Estocagem Destino</label>
                        <Dropdown
                            id="ce_destino"
                            name="ce_destino"
                            placeholder={!loading ? "Selecione" : "Carregando centros de estocagem..."}
                            disabled={loading}
                            options={listaCentros}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={destino}
                            onChange={(e) => setDestino(e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            placeholder="Todos"
                            options={TIPO_STATUS_TRANSFERENCIA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data">Data</label>
                        <MakoCalendar id="data" name="data" value={data} onChange={(e) => setData(e.target.value)} />
                    </div>
                </div>
                <Panel headerTemplate={template} toggleable collapsed={true}>
                    <b>Ordenar</b>
                    <br />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="crescente">Crescente</label>
                            <Dropdown
                                id="crescente"
                                name="crescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "Transferência" },
                                    { field: "carga", header: "Carga" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={crescente}
                                onChange={(e) => selecionaOrdem("asc", e.value)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="decrescente">Decrescente</label>
                            <Dropdown
                                id="decrescente"
                                name="decrescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "Transferência" },
                                    { field: "carga", header: "Carga" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={decrescente}
                                onChange={(e) => selecionaOrdem("desc", e.value)}
                            />
                        </div>
                    </div>
                </Panel>
                <br />
                <div className="p-grid p-justify-end">
                    <Button
                        type="button"
                        onClick={() => handleSearch()}
                        icon="pi pi-filter"
                        label="Filtrar"
                        className="p-mr-2 p-mb-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => limparFiltro()}
                        className="p-button-warning p-mr-2 p-mb-2"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setFiltroAvancado(false)}
                        className="p-button-danger p-mr-2 p-mb-3"
                    />
                </div>
            </form>
        </>
    );
};
