import React, { useRef, useState, useCallback } from "react";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { MakoTime } from "@/components/MakoTime";
import { dataToStr } from "@/assets/util/datas";
import MakoListagem from "@/components/MakoListagem";
import useCrediario from "@/hooks/useCrediario";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { InputText } from "primereact/inputtext";
import useAuth from "@/hooks/useAuth";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

export const DocumentosRendaForm = () => {
    const [arquivo, setArquivo] = useState(null);
    const [carregaDescricao, setCarregaDescricao] = useState(false);
    const [listaDescricoes, setListaDescricoes] = useState([]);
    const { crediario } = useCrediario();
    const { empresaSelecionadaId } = useEmpresa();
    const { user } = useAuth();
    const { showSuccess, showError } = useToast();
    const { httpGet, httpPost } = useHttp();
    const listagemRef = useRef(null);

    const { resetForm, setFieldValue, ...formik } = useFormik({
        initialValues: {
            tipo_renda: null,
            tipo_documento: null,
            data_doc: null,
            datahora_upload: new Date(),
            descricao: "",
            valor: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_documento: Yup.object().required("O campo 'tipo de documento' é obrigatório."),
                data_doc: Yup.date().required("O campo 'data do documento' é obrigatório."),
                descricao: Yup.string().max(40).required("O campo 'descrição' é obrigatório."),
                valor: Yup.string().required("O campo 'valor' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            delete values.datahora_upload;

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Documento gravado com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                    setArquivo(null);
                    resetForm();
                },
            };

            await httpPost(
                {
                    url: "/crediario/enviar-arquivo-crediario/",
                    body: {
                        ...values,
                        uri: arquivo || null,
                        arquivo: arquivo || null,
                        usuario_registrou: user?.id,
                        protocolo: crediario?.id,
                        tipo_documento: values.tipo_documento?.id,
                        data_doc: dataToStr(values.data_doc, "yyyy-MM-dd"),
                        empresa: empresaSelecionadaId,
                        nome_arquivo: `${values.descricao}-documento_crediario$-${crediario.nome}-Orcamento${crediario.orcamento.id}`,
                    },
                },
                handlers
            );
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const carregaCriterio = useCallback(
        async (chave) => {
            const handlers = {
                200: ({ data }) => {
                    setFieldValue("descricao", "");
                    if (data.results?.length > 0) {
                        const lista = data.results[0].valores_possiveis?.split("|");
                        if (lista?.length > 0)
                            setListaDescricoes(
                                lista?.map((valor) => {
                                    return { value: valor };
                                })
                            );
                    } else setListaDescricoes([]);
                },
            };

            setCarregaDescricao(true);
            await httpGet({ url: `/crediario/criterio-padrao?chave=${chave}` }, handlers);
            setCarregaDescricao(false);
        },
        [setFieldValue, httpGet]
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                tooltip="Baixar"
                icon="pi pi-download"
                className="p-button-help p-button-rounded p-mr-2"
                onClick={() => window.open(rowData.uri)}
                disabled={!rowData.uri}
            />
        );
    };

    const colunas = [
        { field: "id", header: "N.° Documento" },
        { field: "descricao", header: "Descrição" },
        { field: "valor", header: "Valor" },
        { field: "datahora_upload", header: "Data/hora upload", dateFormat: "dd/MM/yyyy HH:mm:SS" },
        { field: "data_doc", header: "Data documento", dateFormat: "dd/MM/yyyy" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setArquivo(imageDataUrl);
        }
    };

    const selecionaTipoDoc = (tipo) => {
        if (tipo) {
            setFieldValue("tipo_documento", tipo);
            carregaCriterio(tipo.chave);
        } else setFieldValue("tipo_documento", null);
    };

    const limparFomulario = () => {
        resetForm();
        setArquivo(null);
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo_documento">Tipo documento *</label>
                        <Dropdown
                            id="tipo_documento"
                            name="tipo_documento"
                            placeholder="Selecione um tipo de documento"
                            url="/crediario/tipo-documento-renda?limit=100"
                            filter
                            filterBy="descricao"
                            optionLabel="descricao"
                            value={formik.values.tipo_documento}
                            onChange={(e) => selecionaTipoDoc(e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.tipo_documento })}
                        />
                        {formik.errors.tipo_documento && (
                            <small className="p-error">{formik.errors.tipo_documento}</small>
                        )}
                        {formik.values.tipo_documento?.orientacoes ? (
                            <div>
                                <br />
                                <div>
                                    <b>OBS:</b> {`${formik.values.tipo_documento.orientacoes}`}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="descricao">Descrição *</label>
                        {listaDescricoes?.length === 0 ? (
                            <InputText
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                            />
                        ) : (
                            <Dropdown
                                id="descricao"
                                name="descricao"
                                placeholder="Selecione uma descrição"
                                options={listaDescricoes}
                                filter
                                filterBy="value"
                                editable
                                disabled={carregaDescricao}
                                optionValue="value"
                                optionLabel="value"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                            />
                        )}
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo_renda">Tipo renda</label>
                        <Dropdown
                            id="tipo_renda"
                            name="tipo_renda"
                            placeholder="Selecione um tipo de renda"
                            url="/crediario/tipo-renda?limit=100"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.tipo_renda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_renda })}
                        />
                        {formik.errors.tipo_renda && <small className="p-error">{formik.errors.tipo_renda}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="datahora_upload">Data *</label>
                        <MakoCalendar
                            id="datahora_upload"
                            name="datahora_upload"
                            disabled
                            value={formik.values.datahora_upload}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="datahora_upload">Hora</label>
                        <MakoTime
                            id="datahora_upload"
                            name="datahora_upload"
                            disabled
                            valueTime={formik.values.datahora_upload}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="data_doc">Data do documento *</label>
                        <MakoCalendar
                            id="data_doc"
                            name="data_doc"
                            value={formik.values.data_doc}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_doc,
                            })}
                        />
                        {formik.errors.data_doc && <small className="p-error">{formik.errors.data_doc}</small>}
                    </div>
                    <div className="p-field p-col-3">
                        <label htmlFor="valor">Conteúdo *</label>
                        <InputText
                            id="valor"
                            name="valor"
                            value={formik.values.valor}
                            autoComplete="off"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label>Selecione um arquivo:</label>
                        <input type="file" onChange={onFileChange} accept="all/*" />
                    </div>
                </div>
                <div className="p-grid p-mt-2">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-upload"
                            label="Salvar documento"
                            className="p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="button"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => limparFomulario()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                titulo="Documentos salvos"
                colunas={colunas}
                urlPesquisa={`/crediario/documentos-renda?protocolo=${crediario.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
