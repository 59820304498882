import React from "react";

import { InputText } from "primereact/inputtext";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import useLoading from "@/hooks/useLoading";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/marcas/";

export const ProdutoMarcasForm = ({ marca, dialog, onSubmit }) => {
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();
    const history = useHistory();

    const { resetForm, ...formik } = useFormik({
        initialValues: state?.marcas
            ? state.marcas
            : {
                  id: "",
                  nome: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório.").typeError("Informe um nome válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Marca cadastrada com sucesso!",
                            life: 3000,
                        });
                        resetForm();
                        if (!marca) history.push("/produto/cadastros/marcas");
                        else {
                            dialog(false);
                            if (typeof onSubmit === "function") onSubmit(data);
                        }
                    },
                    400: ({ data }) => {
                        if (data?.nome)
                            showError({
                                summary: "Erro",
                                detail: `Falha ao realizar cadastro (${data?.nome}).`,
                                life: 3000,
                            });
                        else showError();
                    },
                };

                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Marca alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/marcas");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/marcas");
    };

    return (
        <PageBase>
            {marca ? null : <h4>{!formik.values.id ? "Nova Marca" : "Manutenção de Marca"}</h4>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className={marca ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-6"}>
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-12">
                    <Button label="Gravar" type="submit" className={`p-button-info p-mr-2 ${marca ? "" : "p-mb-6"}`} />
                    {marca ? (
                        <Button
                            label="Fechar"
                            type="reset"
                            className="p-button-danger p-mr-2"
                            onClick={() => dialog(false)}
                        />
                    ) : (
                        <Button
                            label="Cancelar"
                            type="reset"
                            className="p-button-danger p-mr-2 p-mb-6"
                            onClick={cancelar}
                        />
                    )}
                </div>
            </form>
        </PageBase>
    );
};
