import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { Dropdown } from "@/components/Dropdown";
import {
    MakoInputFornecedor,
    MakoInputTransportador,
    MakoInputMotorista,
    MakoDropdownCompetenciaEstoque,
} from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { TIPO_PAGANTE_FRETE } from "@/assets/constants/compras";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useToast from "@/hooks/useToast";
import { dataToStr } from "@/assets/util/datas";
import { TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR } from "@/assets/constants/constants";
import useEmpresa from "@/hooks/useEmpresa";

const estoqueTemplate = (option) => {
    const { nome, endereco } = option;
    const { cidade } = endereco;
    return <span>{`${nome} (${endereco.logradouro}, ${endereco.numero} - ${cidade.nome}/${cidade.estado.uf})`}</span>;
};

export const DevolucaoFornecedorFormDadosBasicos = () => {
    const [motivosDevolucao, setMotivosDevolucao] = useState([]);
    const { handleDevolucaoFornecedor, dadosBasicos } = useDevolucaoFornecedor();
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            entrada: null,
            fornecedor: null,
            tipo: null,
            data_devolucao: new Date(),
            nf_entrada: null,
            nf_devolucao: null,
            info_complementares_nota_fiscal: "",
            status: "P",
            valor_total_itens: 0,
            valor_frete: 0,
            outros_valores: 0,
            valor_total: 0,
            pagante_frete: 1,
            transportador: null,
            placa_veiculo: "",
            motorista: null,
            sigla_uf_veiculo: null,
            empresa: empresaSelecionadaId,
            competencia_estoque: null,
            estoque: null,
            rntrc: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const { status, data } = await handleDevolucaoFornecedor({
                ...values,
                transportador: values.transportador?.id,
            });
            if (status === 200 || status === 201 || status === 204) {
                formikHelpers.resetForm({ values: data });
            } else if (status === 400) {
                const errorMessages = {};
                Object.entries(data).forEach(([k, v]) => {
                    errorMessages[k] = v.toString();
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            formikHelpers.setSubmitting(false);
        }
    }

    useEffect(() => {
        if (dadosBasicos?.id) resetForm({ values: dadosBasicos });
    }, [dadosBasicos, resetForm]);

    const entradaOpcaoTemplate = (option) => {
        if (option) return <span>{`${option?.id} - ${dataToStr(option?.datahora_entrada, "dd/MM/yyyy")}`}</span>;
    };

    const entradaSelecionadaTemplate = (option, props) => {
        if (option) return <span>{`${option?.id} - ${dataToStr(option?.datahora_entrada, "dd/MM/yyyy")}`}</span>;
        return <span>{props?.placeholder}</span>;
    };

    const aposBuscarMotivoDevolucao = useCallback((data) => {
        setMotivosDevolucao(data);
        return data;
    }, []);

    const motivoDevolucao = useMemo(() => {
        let value = motivosDevolucao?.find(({ id }) => id === formik.values.tipo);
        value = TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR.find(({ value: v }) => v === value?.motivo)?.label;
        return value || "";
    }, [formik.values.tipo, motivosDevolucao]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="fornecedor">Fornecedor *</label>
                    <MakoInputFornecedor
                        id="fornecedor"
                        name="fornecedor"
                        autoFocus={!!!formik.values.id}
                        value={formik.values.fornecedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.fornecedor })}
                    />
                    {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="estoque">Estoque *</label>
                    <Dropdown
                        id="estoque"
                        name="estoque"
                        url={`/produtos/estoques-empresas/?empresa=${empresaSelecionadaId}`}
                        optionValue="id"
                        optionLabel="nome"
                        buscar={!!empresaSelecionadaId}
                        value={formik.values.estoque}
                        showClear={false}
                        itemTemplate={estoqueTemplate}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.estoque })}
                    />
                    {formik.errors.estoque && <small className="p-error">{formik.errors.estoque}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo-devolucao">Tipo de devolução *</label>
                    <Dropdown
                        id="tipo-devolucao"
                        name="tipo"
                        url="/compras/tipos-devolucoes-fornecedor?limit=1000"
                        aposBuscar={aposBuscarMotivoDevolucao}
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        value={formik.values.tipo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo })}
                    />
                    {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="competencia-estoque">Competência de estoque *</label>
                    <MakoDropdownCompetenciaEstoque
                        id="competencia-estoque"
                        name="competencia_estoque"
                        value={formik.values.competencia_estoque}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                    />
                    {formik.errors.competencia_estoque && (
                        <small className="p-error">{formik.errors.competencia_estoque}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="motivo-devolucao">Motivo da devolução</label>
                    <InputText id="motivo-devolucao" name="motivo_devolucao" value={motivoDevolucao} disabled />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="entrada">Entrada</label>
                    <Dropdown
                        id="entrada"
                        name="entrada"
                        url={`/compras/entradas?fornecedor=${formik.values.fornecedor?.id}&status=F`}
                        optionValue="id"
                        optionLabel="id"
                        buscar={!!formik.values.fornecedor?.id}
                        valueTemplate={entradaSelecionadaTemplate}
                        itemTemplate={entradaOpcaoTemplate}
                        value={formik.values.entrada}
                        onChange={formik.handleChange}
                        disabled={formik.values.valor_total > 0}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="nf-entrada">NF de entrada</label>
                    <Dropdown
                        id="nf-entrada"
                        name="nf_entrada"
                        url={`/fiscal/notas-fiscais?evento_id=${formik.values.entrada}&evento_content_type__model=entrada`}
                        optionValue="id"
                        optionLabel="numero"
                        buscar={formik.values.entrada}
                        value={formik.values.nf_entrada}
                        onChange={formik.handleChange}
                        disabled={formik.values.valor_total > 0}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-devolucao">Data devolução *</label>
                    <MakoCalendar
                        id="data_devolucao"
                        name="data_devolucao"
                        value={formik.values.data_devolucao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_devolucao })}
                    />
                    {formik.errors.data_devolucao && <small className="p-error">{formik.errors.data_devolucao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="responsavel-frete">Responsável pelo frete *</label>
                    <Dropdown
                        id="responsavel-frete"
                        name="pagante_frete"
                        options={TIPO_PAGANTE_FRETE}
                        showClear={false}
                        value={formik.values.pagante_frete}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.pagante_frete })}
                    />
                    {formik.errors.pagante_frete && <small className="p-error">{formik.errors.pagante_frete}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-frete">Valor do frete</label>
                    <MakoInputMoeda
                        id="valor_frete"
                        name="valor_frete"
                        valueMoeda={formik.values.valor_frete}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="transportador">Transportador</label>
                    <MakoInputTransportador
                        id="transportador"
                        name="transportador"
                        value={formik.values.transportador}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.transportador })}
                    />
                    {formik.errors.transportador && <small className="p-error">{formik.errors.transportador}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="placa-veiculo">Placa do veículo</label>
                    <InputText
                        id="placa-veiculo"
                        name="placa_veiculo"
                        value={formik.values.placa_veiculo}
                        onChange={formik.handleChange}
                        maxLength={7}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="sigla_uf_veiculo">UF</label>
                    <Dropdown
                        id="sigla_uf_veiculo"
                        name="sigla_uf_veiculo"
                        url="/pessoas/estados/?pais=1&limit=30"
                        optionValue="uf"
                        optionLabel="nome"
                        filter
                        filterBy="uf,nome"
                        value={formik.values.sigla_uf_veiculo}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="nome-motorista">Motorista</label>
                    <MakoInputMotorista
                        id="nome-motorista"
                        name="motorista"
                        value={formik.values.motorista}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="rntrc">RNTRC</label>
                    <InputText
                        id="rntrc"
                        name="rntrc"
                        value={formik.values.rntrc}
                        onChange={formik.handleChange}
                        maxLength={10}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-itens">Total dos itens</label>
                    <MakoInputMoeda
                        id="total-itens"
                        name="valor_total_itens"
                        disabled
                        valueMoeda={formik.values.valor_total_itens}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="outros-valores">Outros valores</label>
                    <MakoInputMoeda
                        id="outros-valores"
                        name="outros_valores"
                        disabled
                        valueMoeda={formik.values.outros_valores}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-seguro">Valor seguro</label>
                    <MakoInputMoeda
                        id="valor-seguro"
                        name="valor_seguro"
                        disabled
                        valueMoeda={formik.values.valor_seguro}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-devolucao">Total da devolução</label>
                    <MakoInputMoeda
                        id="total-devolucao"
                        name="valor_total"
                        disabled
                        valueMoeda={formik.values.valor_total}
                    />
                </div>
            </div>
            <CamposObrigatorios />
            {!formik.values.id && (
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Incluir dados básicos"
                            className="p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            )}
            <FormikAutoSave intervalo={1000} autosave={formik.values.id > 0} formik={formik} />
        </form>
    );
};
