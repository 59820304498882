import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useHttp from "@/hooks/useHttp";

export const MakoItemNcm = ({ ncmItem, ...props }) => {
    const [ncm, setNcm] = useState(null);
    const [loading, setLoading] = useState(false);
    const { httpGet } = useHttp();

    const carregaNcm = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                setNcm(data.ncm_descricao);
            },
        };
        setLoading(true);
        await httpGet({ url: `/fiscal/ncm-descricao-cumulativa/${ncmItem}/` }, handlers);
        setLoading(false);
    }, [ncmItem, httpGet]);

    useEffect(() => {
        carregaNcm();
    }, [carregaNcm, ncmItem]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label>NCM</label>
                    <div className="p-inputgroup">
                        <InputText id="ncm" name="ncm" value={ncm} onChange={(e) => setNcm(e.target.value)} disabled />
                        <Button
                            type="button"
                            icon="pi pi-search"
                            loading={loading}
                            className="p-button-help"
                            tooltip={ncm}
                            tooltipOptions={{ position: "bottom" }}
                            disabled={!ncm}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
