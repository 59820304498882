import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const url = "/financeiro/versoes-planos-contas-contabeis/";
const urlvoltar = "/contabil/versao-plano-contas";

export const ContabilVersaoPlanoContasForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: props.location.state?.versaoContas || {
            descricao: "",
            inicio_vigencia: new Date(),
            final_vigencia: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                inicio_vigencia: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
                final_vigencia: Yup.date().when("inicio_vigencia", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("inicio_vigencia") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { descricao, inicio_vigencia, final_vigencia, ativo } = values;
            const versaoPlanoContas = {
                descricao,
                ativo,
                inicio_vigencia: dataToStr(inicio_vigencia, "yyyy-MM-dd"),
                final_vigencia: dataToStr(final_vigencia, "yyyy-MM-dd"),
            };
            if (!values.id) {
                const handlers = {
                    201: () => voltarParaListagem(),
                };
                showLoading();
                await httpPost({ url, body: versaoPlanoContas }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => voltarParaListagem(),
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: versaoPlanoContas }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova versão do plano de contas" : "Manutenção de versão do plano de contas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="inicio_vigencia" label="Vigência inicial" obrigatorio />
                        <MakoCalendar
                            id="inicio_vigencia"
                            name="inicio_vigencia"
                            value={formik.values.inicio_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.inicio_vigencia,
                            })}
                        />
                        {formik.errors.inicio_vigencia && (
                            <small className="p-error">{formik.errors.inicio_vigencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="final_vigencia" label="Vigência final" />
                        <MakoCalendar
                            id="final_vigencia"
                            name="final_vigencia"
                            value={formik.values.final_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.final_vigencia,
                            })}
                        />
                        {formik.errors.final_vigencia && (
                            <small className="p-error">{formik.errors.final_vigencia}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        className="p-button-danger"
                        to={urlvoltar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
