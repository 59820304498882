import React, { useRef } from "react";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { InputNumber } from "primereact/inputnumber";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

export const FinanceiroHistoricoPadraoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de histórico padrão"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/cadastros/historico-padrao/form",
                        state: { historico: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/historico-padrao"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="histórico padrão"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o histórico <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O histórico padrão não pode ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/historico-padrao/form"}
            />
        </>
    );

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
            filter: true,
            filterElement: codigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", filter: true },
        { field: "tipo.label", header: "Tipo", style: { width: "10%" }, align: "center" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Históricos Padrões"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/financeiro/historico-padrao/"}
                responsiva
                configTabela={{
                    filterDisplay: "menu",
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
