import React, { useCallback, useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { PageBase } from "@/components/PageBase";

import { ListagemContaBancaria as Listagem } from "./features";

import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";

import permissoes from "@/assets/constants/permissoes";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/contas-bancarias/";

export const FinanceiroContaBancariaPage = () => {
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actions = useCallback(
        (rowData) => {
            return (
                <MakoActionsButtonsColumn>
                    <MakoControleAcesso
                        permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_EDITAR]}
                        componente={Button}
                        icon={MAKO_ICONS.EDITAR}
                        className="p-button-rounded p-button-warning"
                        tooltip="Alterar cadastro da conta bancária"
                        tooltipOptions={{ position: "left" }}
                        to={{
                            pathname: "/financeiro/cadastros/contas-bancarias/form",
                            state: { contaBancaria: rowData },
                        }}
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_EXCLUIR]}
                        componente={BotaoDelete}
                        url={url}
                        objetoId={rowData.id}
                        msgToastErroExclusao="A conta bancária não pode ser excluída."
                        tooltip="Deletar conta bancária"
                        exigeConfirmacao
                        msgConfirmacao={`Deseja realmente excluir a conta bancária ${rowData.numero_conta}?`}
                        tooltipOptions={{ position: "left" }}
                        onDelete={() => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Conta bancária deletada com sucesso!",
                                life: 3000,
                            });
                            listagemRef.current?.buscarDados();
                        }}
                    />
                    <ButtonListagemOpcoes
                        url={"/financeiro/contas-bancarias"}
                        objetoId={rowData.id}
                        objetoLabel={rowData.numero_conta}
                        prefixo="a"
                        entidade="conta bancária"
                        onAction={() => listagemRef.current?.buscarDados()}
                        deleteOptions={{
                            exigeConfirmacao: true,
                            msgConfirmacao: (
                                <span>Deseja realmente excluir a conta bancária ${rowData.numero_conta}?</span>
                            ),
                            msgToastErroExclusao: "A conta bancária não pode ser excluída.",
                        }}
                        visualizarOptions={false}
                    />
                </MakoActionsButtonsColumn>
            );
        },
        [showSuccess]
    );

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/contas-bancarias/form"}
            />
        </>
    );

    return (
        <PageBase>
            <Listagem
                ref={listagemRef}
                actions={actions}
                listagemProps={{
                    painelEsquerdo,
                }}
            />
        </PageBase>
    );
};
