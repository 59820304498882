import React, { useState, useRef } from "react";

import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Dropdown } from "@/components/Dropdown";
import { Tag } from "primereact/tag";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

export const FinanceiroTemplateRateioPage = () => {
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/financeiro/templates-rateios/form",
                        state: rowData,
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/templates-rateios"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="regra de rateio"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a regra de rateio <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A regra de rateio não pode ser excluída.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const situacaoBodyTemplate = (rowData) => {
        if (rowData.valida) return <Tag value="VÁLIDA" />;
        return <Tag severity="danger" value="INVÁLIDA" />;
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Ativo" },
                    { value: false, label: "Desativado" },
                ]}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_INCLUIR]}
                componente={Button}
                label="Nova"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/financeiro/templates-rateios/form"}
            />
        </>
    );

    const colunas = [
        { expander: true, style: { width: "5rem" } },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "ativo",
            header: "Situação",
            filter: true,
            filterElement: statusFiltroTemplate,
            align: "center",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVA", "DESATIVADA"),
            style: { width: "10%" },
        },
        {
            field: "valida",
            header: "Percentual",
            action: (e) => situacaoBodyTemplate(e),
            style: { width: "10%" },
            align: "center",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
            align: "center",
        },
    ];

    const rowExpansionTemplate = (data) => {
        const detalheBodyTemplate = (rowData) => {
            if (rowData.plano_conta_contabil) return <span>{rowData.plano_conta_contabil.descricao}</span>;
            if (rowData.plano_conta_financeira) return <span>{rowData.plano_conta_financeira.descricao}</span>;
            if (rowData.centro_resultado) return <span>{rowData.centro_resultado.descricao}</span>;
            if (rowData.fase_projeto) return <span>{rowData.fase_projeto.descricao}</span>;
            return <span>N/A</span>;
        };

        const colunas = [
            { field: "tipo_detalhe.descricao", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe", action: (e) => detalheBodyTemplate(e) },
            {
                field: "percentual",
                header: "Percentual (%)",
                decimal: true,
            },
        ];

        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.templatedetalherateio_set} />
            </div>
        );
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Regras de Rateios"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/financeiro/templates-rateios/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    ativo: { value: true, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    expandedRows: linhasExpandidas,
                    onRowToggle: (e) => setLinhasExpandidas(e.data),
                    rowExpansionTemplate,
                }}
            />
        </PageBase>
    );
};
