import React, { useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Label } from "@/components/Label";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { BotaoDelete } from "@/components/BotaoDelete";

import { ModalUploadCertificado } from "./modal-upload";

export const FormCertificado = ({ perfilId, infoEmpresa, apenasLeitura, onUpload, onDelete }) => {
    const modalUploadRef = useRef(null);

    return (
        <>
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="serial" label="Serial" />
                        <InputText id="serial" name="serial" disabled value={infoEmpresa?.serial || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="emitente" label="Emitente" />
                        <InputText id="emitente" name="emitente" disabled value={infoEmpresa?.emitente || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="invalido-antes" label="Inválido antes de" />
                        <MakoCalendar
                            id="invalido-antes"
                            name="invalido_antes"
                            disabled
                            value={infoEmpresa?.invalido_antes}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="invalido-apos" label="Inválido após" />
                        <MakoCalendar
                            id="invalido-apos"
                            name="invalido_apos"
                            disabled
                            value={infoEmpresa?.invalido_apos}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="requerente" label="Nome do requerente" />
                        <InputText
                            id="requerente"
                            name="nome_requerente"
                            disabled
                            value={infoEmpresa?.nome_requerente || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="identificacao-requerente" label="CPF / CNPJ" />
                        <InputText
                            id="identificacao-requerente"
                            name="identificacao_requerente"
                            disabled
                            value={infoEmpresa?.identificacao_requerente || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="razao-social-requerente" label="Razão social" />
                        <InputText
                            id="razao-social-requerente"
                            name="razao_social_requerente"
                            disabled
                            value={infoEmpresa?.razao_social_requerente || ""}
                        />
                    </div>
                </div>
                {!apenasLeitura && (
                    <MakoActionsButtons className="p-jc-end">
                        <BotaoDelete
                            label="Excluir"
                            url="/pessoas/:id:/certificado-digital/upload/"
                            objetoId={perfilId}
                            disabled={!infoEmpresa || !infoEmpresa.certificado_enviado}
                            exigeConfirmacao
                            rounded={false}
                            msgConfirmacao={<span>Deseja realmente excluir o certificado digital?</span>}
                            msgToastErroExclusao="O certificado digital não pôde ser excluído."
                            onDelete={onDelete}
                        />
                        <Button
                            type="button"
                            icon="pi pi-upload"
                            label="Upload"
                            disabled={!infoEmpresa || infoEmpresa.certificado_enviado}
                            onClick={() => modalUploadRef.current?.abrirModal()}
                        />
                    </MakoActionsButtons>
                )}
            </form>
            <ModalUploadCertificado ref={modalUploadRef} perfilId={perfilId} onUpload={onUpload} />
        </>
    );
};
