import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ModalFotosSku } from "./modals/modalFotos";
import useLoading from "@/hooks/useLoading";
import useProduto from "@/hooks/useProduto";
import { Image } from "primereact/image";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const FotosForm = () => {
    const [produto, setProduto] = useState(null);
    const [exibirImagem, setExibirImagem] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [imagem, setImagem] = useState(null);
    const { dadosBasicos } = useProduto();
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deletarImagem = async () => {
        showLoading();
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Imagem deletada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
            400: () => showError(),
        };

        await httpPost(
            {
                url: `/produtos/produto-delete-foto/${imagem.id}/`,
                body: {
                    imagem_cloud: imagem.imagem.replace("image/upload/", ""),
                },
            },
            handlers
        );
        hideLoading();
        setDeleteDialog(false);
    };

    const verificaItens = (results) => {
        if (results.length === 1) setProduto(results[0].id);
        return results;
    };

    const itemTemplate = (sku) => {
        return `${sku.codigo} - ${sku.descricao_complementar}`;
    };

    const valueTemplate = (sku) => {
        if (sku) return `${sku.codigo} - ${sku.descricao_complementar}`;
        return "Selecione...";
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}&limit=1000`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                filter
                filterBy="id,codigo,descricao_complementar"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                optionValue="id"
                optionLabel="descricao_complementar"
                aposBuscar={verificaItens}
                showClear={false}
                className="p-mr-2"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_IMAGENSSKU_INCLUIR]}
                componente={Button}
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setExibirImagem(true)}
                disabled={!produto}
            />
        </>
    );

    const confirmarDelete = (imagem) => {
        setImagem(imagem);
        setDeleteDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_IMAGENSSKU_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Excluir imagem"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <Image
                imageClassName="product-image"
                src={rowData.imagem}
                alt={rowData.descricao}
                onError={(e) =>
                    (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                width="80"
                height="80"
                preview
                downloadable
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "descricao", header: "Descrição", style: { width: "20%" } },
        { field: "imagem", header: "Imagem", style: { width: "25%" }, action: (e) => imageBodyTemplate(e) },
        { field: "galeria.nome", header: "Galeria", style: { width: "15%" } },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "8%" } },
    ];

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/produtos/imagens-sku?sku__id=${produto}`}
                fazerBusca={!!produto}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                responsiveLayout="scroll"
            />
            <Dialog
                header="Adicionar Imagem"
                visible={exibirImagem}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "55vw" }}
                onHide={() => setExibirImagem(false)}
            >
                <ModalFotosSku
                    sku={produto}
                    esconderDialog={() => {
                        setExibirImagem(false);
                        listagemRef.current?.buscarDados();
                    }}
                />
            </Dialog>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    imagem && (
                        <span>
                            {"Deseja realmente excluir a imagem "}
                            <b>{imagem.descricao}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deletarImagem}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </>
    );
};

export default FotosForm;
