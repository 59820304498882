import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { SEXO, ESTADO_CIVIL, TIPO_PESSOA_PESQUISA } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { MakoMultiSelect } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";

const INITIAL_VALUES = {
    perfil_pf__data_nascimento__gte: null,
    perfil_pf__data_nascimento__lte: null,
    mes_aniversario: null,
    perfil_pe__data_nascimento_inicio__gte: null,
    perfil_pe__data_nascimento_fim__lte: null,
    mes_aniversario_pe: null,
    tags: [],
    nome_curto__icontains: "",
    perfil_pf__nome_social__unaccent__icontains: "",
    cidade_endereco_principal: null,
    telefone: "",
    email: "",
    perfil_pj__inscricao_estadual__icontains: "",
    perfil_pf__rg__icontains: "",
    perfil_pf__estado_civil: "",
    perfil_pf__sexo: "",
    perfil_pf__nome_pai__unaccent__icontains: "",
    perfil_pf__nome_mae__unaccent__icontains: "",
    observacao__unaccent__icontains: "",
    perfil_pj__nome_fantasia__icontains: "",
    perfil_pe__passaporte__icontains: "",
    tipo_pessoa: null,
};

const Modal = ({ onFilter, baseUrl, filtros, setFiltro, removerFiltro }, ref) => {
    const [visible, setVisible] = useState(false);

    const MES_ANIVERSARIO = [
        { value: 1, label: "Janeiro" },
        { value: 2, label: "Fevereiro" },
        { value: 3, label: "Março" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Maio" },
        { value: 6, label: "Junho" },
        { value: 7, label: "Julho" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Setembro" },
        { value: 10, label: "Outubro" },
        { value: 11, label: "Novembro" },
        { value: 12, label: "Dezembro" },
    ];

    const formik = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_pf__data_nascimento__gte: Yup.date().nullable().typeError("Informe uma data válida."),
                perfil_pf__data_nascimento__lte: Yup.date().when("data_nascimento_inicio", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.perfil_pf__data_nascimento__gte || new Date(),
                            "A data de nascimento final não pode ser inferior a data de nascimento inicial."
                        )
                        .typeError("Informe uma data válida."),
                    otherwise: Yup.date().nullable(),
                }),
                perfil_pe__data_nascimento_inicio__gte: Yup.date().nullable().typeError("Informe uma data válida."),
                perfil_pe__data_nascimento_fim__lte: Yup.date().when("data_nascimento_inicio", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.perfil_pe__data_nascimento_inicio__gte || new Date(),
                            "A data de nascimento final não pode ser inferior a data de nascimento inicial."
                        )
                        .typeError("Informe uma data válida."),
                    otherwise: Yup.date().nullable(),
                }),
                mes_aniversario: Yup.number().nullable().default(null),
                nome_curto__icontains: Yup.string().default(""),
                perfil_pf__nome_social__unaccent__icontains: Yup.string().default(""),
                cidade_endereco_principal: Yup.object().nullable().default(null),
                telefone: Yup.string().default(""),
                email: Yup.string().email("Informe um email válido."),
                perfil_pj__inscricao_estadual__icontains: Yup.string().default(""),
                perfil_pf__rg__icontains: Yup.string().default(""),
                perfil_pf__estado_civil: Yup.string().default(""),
                perfil_pf__sexo: Yup.string().default(""),
                perfil_pf__nome_pai__unaccent__icontains: Yup.string().default(""),
                perfil_pf__nome_mae__unaccent__icontains: Yup.string().default(""),
                observacao__unaccent__icontains: Yup.string().default(""),
                perfil_pj__nome_fantasia__icontains: Yup.string().default(""),
            });

            const dadosValidados = await formSchema.validate(values, { abortEarly: false });

            if (typeof onFilter === "function") {
                let filtros = {};
                let params = [];
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key] && dadosValidados[key] !== "") {
                        if (key === "cliente") {
                            filtros[key] = dadosValidados[key].id;
                            return params.push(`${key}=${dadosValidados[key].id}`);
                        }
                        if (key === "cidade_endereco_principal") {
                            filtros[key] = dadosValidados[key].id;
                            return params.push(`${key}=${dadosValidados[key].id}`);
                        }
                        if (key === "perfil_pf__data_nascimento__gte" || key === "perfil_pf__data_nascimento__lte") {
                            filtros[key] = dataToStr(dadosValidados[key], "yyyy-MM-dd");
                            params.push(`tipo_pessoa=PF`);
                            return params.push(`${key}=${dataToStr(dadosValidados[key], "yyyy-MM-dd")}`);
                        }
                        if (
                            key === "perfil_pe__data_nascimento_inicio__gte" ||
                            key === "perfil_pe__data_nascimento_fim__lte"
                        ) {
                            filtros[key] = dataToStr(dadosValidados[key], "yyyy-MM-dd");
                            params.push(`tipo_pessoa=PE`);
                            return params.push(`${key}=${dataToStr(dadosValidados[key], "yyyy-MM-dd")}`);
                        }
                        filtros[key] = dadosValidados[key];
                        params.push(`${key}=${dadosValidados[key]}`);
                    }
                });
                if (params.length > 0) {
                    onFilter(baseUrl + `&${params.join("&")}`);
                    setFiltro(filtros);
                } else onFilter(baseUrl);
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const autoCompleteCidadeTemplate = (item) => {
        return <div>{`${item.nome} - ${item.estado.uf}`}</div>;
    };

    const onCancel = () => {
        setVisible(false);
    };

    const limparFiltros = () => {
        formik.resetForm();
    };

    const template = (options, title) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>{title}</span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const limparForm = () => {
        removerFiltro();
        limparFiltros();
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltros }));

    return (
        <Dialog
            header="Filtro avançado de pessoas"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
            breakpoints={{ "960px": "80vw" }}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo-pessoa" label="Tipo pessoa" />
                        <Dropdown
                            id="tipo-pessoa"
                            name="tipo_pessoa"
                            options={TIPO_PESSOA_PESQUISA}
                            placeholder="Selecione"
                            optionValue="id"
                            value={formik.values.tipo_pessoa}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="nome-curto" label="Nome curto" />
                        <InputText
                            id="nome-curto"
                            name="nome_curto__icontains"
                            value={formik.values.nome_curto__icontains}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="telefone" label="Telefone" />
                        <InputText
                            id="telefone"
                            name="telefone"
                            value={formik.values.telefone}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tags" label="Tags de perfil" />
                        <MakoMultiSelect
                            id="tags"
                            name="tags"
                            url="/pessoas/tags-perfis/?limit=100"
                            optionValue="id"
                            optionLabel="descricao"
                            maxSelectedLabels={2}
                            value={formik.values.tags}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="email" label="Email" />
                        <InputText
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.email,
                            })}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cidade-endereco-principal" label="Cidade do end. principal" />
                        <MakoAutoComplete
                            id="cidade-endereco-principal"
                            name="cidade_endereco_principal"
                            urlSearch="/pessoas/cidades?limit=20&nome__unaccent__icontains="
                            field="nome"
                            itemTemplate={autoCompleteCidadeTemplate}
                            minCaracteresBusca={3}
                            placeholder="Digite o nome da cidade para buscar..."
                            value={formik.values.cidade_endereco_principal}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="obs" label="Observação" />
                        <InputText
                            id="obs"
                            name="observacao__unaccent__icontains"
                            value={formik.values.observacao__unaccent__icontains}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <Panel headerTemplate={(e) => template(e, "Opções Pessoa Física:")} toggleable collapsed={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="perfil_pf__rg" label="RG" />
                            <InputText
                                id="perfil_pf__rg"
                                name="perfil_pf__rg__icontains"
                                value={formik.values.perfil_pf__rg__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="estado-civil" label="Estado civil" />
                            <Dropdown
                                id="estado-civil"
                                name="perfil_pf__estado_civil"
                                options={ESTADO_CIVIL}
                                placeholder="Selecione"
                                optionValue="id"
                                value={formik.values.perfil_pf__estado_civil}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="perfil_pf__sexo" label="Sexo" />
                            <Dropdown
                                id="perfil_pf__sexo"
                                name="perfil_pf__sexo"
                                options={SEXO}
                                placeholder="Selecione"
                                optionValue="id"
                                value={formik.values.perfil_pf__sexo}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="mes-aniversario" label="Mês aniversário" />
                            <Dropdown
                                id="mes-aniversario"
                                name="mes_aniversario"
                                placeholder="Selecione"
                                options={MES_ANIVERSARIO}
                                value={formik.values.mes_aniversario}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="data-nascimento-inicio" label="Data de nascimento (início" />
                            <MakoCalendar
                                id="data-nascimento-inicio"
                                name="perfil_pf__data_nascimento__gte"
                                maxDate={new Date()}
                                value={formik.values.perfil_pf__data_nascimento__gte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pf__data_nascimento__gte,
                                })}
                            />
                            {formik.errors.perfil_pf__data_nascimento__gte && (
                                <small className="p-error">{formik.errors.perfil_pf__data_nascimento__gte}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="data-nascimento-fim" label="Data de nascimento (fim)" />
                            <MakoCalendar
                                id="data-nascimento-fim"
                                name="perfil_pf__data_nascimento__lte"
                                maxDate={new Date()}
                                value={formik.values.perfil_pf__data_nascimento__lte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pf__data_nascimento__lte,
                                })}
                            />
                            {formik.errors.perfil_pf__data_nascimento__lte && (
                                <small className="p-error">{formik.errors.perfil_pf__data_nascimento__lte}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="nome-social" label="Nome social" />
                            <InputText
                                id="nome-social"
                                name="perfil_pf__nome_social__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_social__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="nome-pai" label="Nome do pai" />
                            <InputText
                                id="nome-pai"
                                name="perfil_pf__nome_pai__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_pai__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="nome-mae" label="Nome da mãe" />
                            <InputText
                                id="nome-mae"
                                name="perfil_pf__nome_mae__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_mae__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Panel>
                <Panel headerTemplate={(e) => template(e, "Opções Pessoa Juridica:")} toggleable collapsed={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="perfil_pj__nome_fantasia" label="Nome fantasia" />
                            <InputText
                                id="perfil_pj__nome_fantasia"
                                name="perfil_pj__nome_fantasia__icontains"
                                value={formik.values.perfil_pj__nome_fantasia__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="perfil_pj__inscricao_estadual" label="Inscrição estadual" />
                            <InputText
                                id="perfil_pj__inscricao_estadual"
                                name="perfil_pj__inscricao_estadual__icontains"
                                value={formik.values.perfil_pj__inscricao_estadual__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Panel>
                <Panel headerTemplate={(e) => template(e, "Opções Pessoa Estrangeira:")} toggleable collapsed={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="perfil_pe__passaporte" label="Passaporte" />
                            <InputText
                                id="perfil_pe__passaporte"
                                name="perfil_pe__passaporte__icontains"
                                value={formik.values.perfil_pe__passaporte__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="mes-aniversario-pe" label="Mês aniversário" />
                            <Dropdown
                                id="mes-aniversario-pe"
                                name="mes_aniversario_pe"
                                placeholder="Selecione"
                                options={MES_ANIVERSARIO}
                                value={formik.values.mes_aniversario_pe}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="data-nascimento-inicio-pe" label="Data de nascimento (início)" />
                            <MakoCalendar
                                id="data-nascimento-inicio-pe"
                                name="perfil_pe__data_nascimento_inicio__gte"
                                maxDate={new Date()}
                                value={formik.values.perfil_pe__data_nascimento_inicio__gte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pe__data_nascimento_inicio__gte,
                                })}
                            />
                            {formik.errors.perfil_pe__data_nascimento_inicio__gte && (
                                <small className="p-error">
                                    {formik.errors.perfil_pe__data_nascimento_inicio__gte}
                                </small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="data-nascimento-fim-pe" label="Data de nascimento (fim)" />
                            <MakoCalendar
                                id="data-nascimento-fim-pe"
                                name="perfil_pe__data_nascimento_fim__lte"
                                maxDate={new Date()}
                                value={formik.values.perfil_pe__data_nascimento_fim__lte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pe__data_nascimento_fim__lte,
                                })}
                            />
                            {formik.errors.perfil_pe__data_nascimento_fim__lte && (
                                <small className="p-error">{formik.errors.perfil_pe__data_nascimento_fim__lte}</small>
                            )}
                        </div>
                    </div>
                </Panel>
                <MakoActionsButtons className="p-jc-end p-mt-3">
                    <Button type="submit" label="Filtrar" icon={MAKO_ICONS.FILTRAR} onClick={formik.handleSubmit} />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FILTROS}
                        label="Limpar"
                        onClick={limparForm}
                        className="p-button-warning"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        onClick={() => onCancel()}
                        className="p-button-danger"
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalFiltroAvancadoPessoas = forwardRef(Modal);
