import React, { forwardRef, memo, useCallback, useImperativeHandle } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";

import { MakoDropdownVeiculo, MakoInputCodigoAleatorio, MakoInputMotorista } from "@/components/MakoInputs";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { InputCidadesRota } from "./inputs";
import { Buttons } from "./buttons";

import { TIPO_CHOICE_SITUACAO_CARGA } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/vendas/cargas/";

const INITIAL_VALUES = {
    id: null,
    codigo: "",
    empresa: null,
    nome: "",
    rota_entrega: null,
    motorista: null,
    veiculo: null,
    api_integracao: null,
    chave_integracao: "",
    situacao: "A",
};

const MemoButtonFechar = memo(Buttons.Fechar);

const Component = ({ successCallback = () => {}, errorCallback = () => {}, showActions = true, children }, ref) => {
    const { showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const { isSubmitting, ...formik } = useFormik({
        initialValues: { ...INITIAL_VALUES, empresa: empresaSelecionadaId },
        onSubmit: handleSubmit,
    });

    const verificarCodigoInexistente = async (codigo) => {
        const params = { codigo, limit: 1, query: "{codigo}" };
        let _data = [];
        const handlers = {
            200: ({ data }) => {
                _data = data;
            },
        };

        await httpGet({ url: BASE_URL, params }, handlers);
        return _data?.count === 0;
    };

    async function handleSubmit(values, formik) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .test({
                        test: (val) => verificarCodigoInexistente(val),
                        message: "Este código ja existe.",
                    })
                    .required("O campo 'código' é obrigatório")
                    .typeError("Informe um 'código' válido"),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                nome: Yup.string()
                    .required("O campo 'nome da carga' é obrigatório")
                    .typeError("Informe um 'nome' válido"),
                rota_entrega: Yup.number().nullable().default(null).typeError("Informe uma 'rota' válida"),
                motorista: Yup.object().nullable().default(null).typeError("Informe um 'motorista' válido"),
                veiculo: Yup.number().nullable().default(null).typeError("Informe um 'veículo' válido"),
                api_integracao: Yup.number().nullable().default(null).typeError("Informe uma 'api' válida"),
            });

            let dados = await formSchema.validate(values, {
                abortEarly: false,
            });

            dados.motorista = dados.motorista?.id;

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carga cadastrada com sucesso!",
                            life: 1500,
                        });
                        successCallback(data);
                    },
                };

                await httpPost({ url: BASE_URL, body: dados }, handlers);
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carga alterada com sucesso!",
                            life: 1500,
                        });
                        successCallback(data);
                    },
                };

                await httpPatch({ url: `${BASE_URL}${values.id}/`, body: dados }, handlers);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
            errorCallback(error);
        }
    }

    const handleEdit = useCallback(
        (values) => {
            formik.setValues({ ...INITIAL_VALUES, ...values });
        },
        [formik]
    );

    const onGerarCodigo = useCallback((codigo) => formik.setFieldValue("codigo", codigo), [formik]);

    useImperativeHandle(ref, () => ({ ...formik, editar: handleEdit }), [formik, handleEdit]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="codigo" label="Código" obrigatorio />
                    <MakoInputCodigoAleatorio
                        id="codigo"
                        name="codigo"
                        value={formik.values.codigo}
                        onInput={formik.handleChange}
                        onGerarCodigo={onGerarCodigo}
                        length={15}
                        autoFocus
                        className={classNames({ "p-invalid": formik.errors.codigo })}
                    />
                    {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="empresa" label="Empresa / Filial" obrigatorio />
                    <MakoDropdownEmpresas
                        id="empresa"
                        name="empresa"
                        value={formik.values.empresa}
                        onChange={(e) => formik.setFieldValue("empresa", e.id)}
                        className={classNames({ "p-invalid": formik.errors.empresa })}
                    />
                    {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="nome" label="Nome da carga" obrigatorio />
                    <InputText
                        id="nome"
                        name="nome"
                        value={formik.values.nome}
                        onInput={formik.handleChange}
                        maxLength={30}
                        className={classNames({ "p-invalid": formik.errors.nome })}
                    />
                    {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="rota_entrega" label="Rota" />
                    <Dropdown
                        id="rota_entrega"
                        name="rota_entrega"
                        url="/vendas/rotas-entrega/?limit=200"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.rota_entrega}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.rota_entrega && <small className="p-error">{formik.errors.rota_entrega}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="municipios" label="Lista de cidades da rota" />
                    <InputCidadesRota id="municipios" name="municipios" rotaId={formik.values.rota_entrega} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="motorista" label="Motorista" />
                    <MakoInputMotorista
                        id="motorista"
                        name="motorista"
                        value={formik.values.motorista}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.motorista })}
                    />
                    {formik.errors.motorista && <small className="p-error">{formik.errors.motorista}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="veiculo" label="Veículo" />
                    <MakoDropdownVeiculo
                        id="veiculo"
                        name="veiculo"
                        value={formik.values.veiculo}
                        motorista={formik.values.motorista?.id}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.veiculo })}
                    />
                    {formik.errors.veiculo && <small className="p-error">{formik.errors.veiculo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="api_integracao" label="Api de integração" />
                    <Dropdown
                        id="api_integracao"
                        name="api_integracao"
                        url="/configuracoes/api-servicos/?limit=300"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.api_integracao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.api_integracao })}
                    />
                    {formik.errors.api_integracao && <small className="p-error">{formik.errors.api_integracao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="situacao" label="Situação" />
                    <Dropdown
                        id="situacao"
                        name="situacao"
                        options={TIPO_CHOICE_SITUACAO_CARGA}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.situacao}
                        dropdownIcon=" "
                        disabled
                    />
                </div>
            </div>
            <CamposObrigatorios />
            {showActions && (
                <MakoActionsButtons className="p-mt-2">
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar carga"
                        type="submit"
                        className="p-button-info"
                        loading={isSubmitting}
                    />
                    <MemoButtonFechar carga={formik.values} successCallback={successCallback} loading={isSubmitting} />
                </MakoActionsButtons>
            )}
            {children}
        </form>
    );
};

export const Form = forwardRef(Component);
