import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { MakoInputMotivoBloqueio } from "@/components/MakoInputs";
import { Calendar } from "primereact/calendar";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { dataToStr, parseData } from "@/assets/util/datas";
import { isAfter } from "date-fns";

export const FormBloquear = ({ caixaMov, successCalback = () => {}, cancelCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            motivo: "",
            data_bloqueio: new Date(),
            hora_bloqueio: new Date(),
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        values.motivo = values.motivo.id || values.motivo;
        try {
            const formSchema = Yup.object().shape({
                motivo: Yup.string().required("O campo 'motivo' é obrigatório.").typeError("Informe um motivo"),
                data_bloqueio: Yup.date()
                    .required("O campo 'data bloqueio' é obrigatório")
                    .typeError("Seleciona um data de bloqueio."),
                hora_bloqueio: Yup.date()
                    .required("O campo 'hora bloqueio' é obrigatório")
                    .typeError("Seleciona uma hora de bloqueio."),
            });

            await formSchema.validate(values, { abortEarly: true });

            const _dateBloqueio = parseData(
                `${dataToStr(values.data_bloqueio, "yyyy-MM-dd")} ${dataToStr(values.hora_bloqueio, "HH:mm:ss")}`
            );
            const _dateAbertura = parseData(`${caixaMov.data_abertura} ${caixaMov.hora_abertura}`);

            const handlers = {
                200: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Caixa bloqueado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    successCalback(data.caixa);
                },
            };

            if (isAfter(_dateBloqueio, _dateAbertura)) {
                showLoading();
                await httpPost(
                    {
                        url: `/financeiro/caixas/${caixaMov.id}/operar/bloquear`,
                        body: {
                            datahora_bloqueio: dataToStr(_dateBloqueio, "yyyy-MM-dd HH:mm:ss"),
                            motivo: values.motivo,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A data de fechamento deve ser posterior a abertura.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="motivo" label="Motivo" obrigatorio />
                    <MakoInputMotivoBloqueio
                        id="motivo"
                        name="motivo"
                        value={formik.values.motivo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.motivo })}
                    />
                    {formik.errors.motivo && <small className="p-error">{formik.errors.motivo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_bloqueio" label="Data Bloqueio" obrigatorio />
                    <MakoCalendar
                        id="data_bloqueio"
                        name="data_bloqueio"
                        value={formik.values.data_bloqueio}
                        onChange={formik.handleChange}
                        disabled
                        minDate={caixaMov.data_abertura}
                        className={classNames({ "p-invalid": formik.errors.data_bloqueio })}
                    />
                    {formik.errors.data_bloqueio && <small className="p-error">{formik.errors.data_bloqueio}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="hora_bloqueio" label="Hora bloqueio" obrigatorio />
                    <Calendar
                        id=""
                        name=""
                        timeOnly
                        showTime
                        hourFormat="24"
                        disabled
                        value={formik.values.hora_bloqueio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora_bloqueio })}
                    />
                    {formik.errors.hora_bloqueio && <small className="p-error">{formik.errors.hora_bloqueio}</small>}
                </div>
            </div>
            <MakoActionsButtons>
                <Button label="Bloquear" className="p-button-success" type="submit" loading={loading} />
                <Button label="Cancelar" className="p-button-danger" onClick={cancelCallback} loading={loading} />
            </MakoActionsButtons>
        </form>
    );
};
