import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { formatarPercentual, parseNumberToMoneyHTML } from "@/assets/util/util";
import { CodigoFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { PorcentagemFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const RegraCrediarioPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro do parâmetro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/regra-crediario/form",
                            regra: rowData,
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/crediario/regra-crediario"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="regra de crediário"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a regra de crediário <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A regra de crediário não pode ser excluída.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/venda/crediario/regra-crediario/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { with: "25%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "percentual_limite",
            header: "Percentual Limite",
            action: (e) => formatarPercentual(e.percentual_limite),
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "valor_minimo",
            header: "Valor Mínimo",
            action: (e) => parseNumberToMoneyHTML(e.valor_minimo),
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "valor_maximo",
            header: "Valor Máximo",
            action: (e) => parseNumberToMoneyHTML(e.valor_maximo),
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "natureza_ocupacao.descricao",
            header: "Natureza Ocupação",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "natureza_ocupacao__descricao",
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Regras de Crediário"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/crediario/regra-crediario/"
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: { value: null, matchMode: "icontains" },
                    percentual_limite: { value: null, matchMode: "equals" },
                    valor_minimo: { value: null, matchMode: "equals" },
                    valor_maximo: { value: null, matchMode: "equals" },
                    natureza_ocupacao__descricao: { value: null, matchMode: "icontains" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
