import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { EMPRESA_NFE_SERIE } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_MODELO_NOTA_FISCAL } from "@/assets/constants/constants";
import { Dialog } from "primereact/dialog";
import useHttp from "@/hooks/useHttp";
import useEmpresa from "@/hooks/useEmpresa";

const Modal = ({ showLoading, hideLoading, loading, aposVerificar }, ref) => {
    const [inicio, setInicio] = useState(0);
    const [fim, setFim] = useState(0);
    const [modelo, setModelo] = useState("55");
    const { getParam } = useParam();
    const { httpPut } = useHttp();
    const { empresaSelecionadaId } = useEmpresa();
    const { showError, showSuccess } = useToast();
    const serie_nf = getParam(EMPRESA_NFE_SERIE);
    const [serie, setSerie] = useState(serie_nf?.valor ? serie_nf.valor : 1);
    const [visible, setVisible] = useState(false);

    async function consultarNotas() {
        try {
            const handlers = {
                200: ({ data }) => {
                    const { msg } = data;
                    showSuccess({
                        summary: "Sucesso",
                        detail: msg,
                        life: 3000,
                    });
                    if (typeof aposVerificar === "function") aposVerificar();
                },
            };

            showLoading();
            await httpPut(
                {
                    url: `/fiscal/sincronizar-documentos/${empresaSelecionadaId}/`,
                    body: {
                        primeiro_numero: inicio,
                        ultimo_numero: fim,
                        modelo,
                        serie,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    useImperativeHandle(ref, () => ({ setVisible }));

    const fecharModal = () => {
        setVisible(false);
    };

    return (
        <>
            <Dialog
                header="Consultar Contingências"
                visible={visible}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "45vw" }}
                onHide={() => fecharModal()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="modelo">Modelo *</label>
                            <Dropdown
                                id="modelo"
                                name="modelo"
                                options={TIPO_MODELO_NOTA_FISCAL}
                                optionValue="value"
                                optionLabel="label"
                                placeholder="Selecione um modelo"
                                value={modelo}
                                showClear={false}
                                onChange={(e) => setModelo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="inicio">Início *</label>
                            <InputNumber
                                id="inicio"
                                value={inicio}
                                min={0}
                                onValueChange={(e) => setInicio(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="fim">Fim *</label>
                            <InputNumber
                                id="fim"
                                value={fim}
                                min={0}
                                onValueChange={(e) => setFim(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="serie">Série *</label>
                            <InputNumber
                                id="serie"
                                value={serie}
                                min={1}
                                onValueChange={(e) => setSerie(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-grid p-justify-center p-mt-2">
                        <Button
                            label="Consultar"
                            type="button"
                            loading={loading}
                            disabled={loading}
                            className="p-button-info"
                            style={{ width: "200px" }}
                            onClick={() => consultarNotas()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const ContingenciaFormModal = forwardRef(Modal);
