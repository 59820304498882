import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Menu } from "primereact/menu";
import { ModalEntradaXML } from "./ModalEntradaXML";
import { ModalProcessarXML } from "./ModalProcessarXML";
import { ModalVerXML } from "./ModalVerXML";
import MakoListagem from "@/components/MakoListagem";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import { MakoFileUpload } from "@/components/MakoFileUpload";
import { url } from "@/services/axios";
import useEntrada from "@/hooks/useEntrada";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import useHttp from "@/hooks/useHttp";

export const XMLForm = () => {
    const [xmlsSelecionados, setXmlsSelecionados] = useState([]);
    const [xmlSelecionado, setXmlSelecionado] = useState(null);
    const listagemRef = useRef(null);
    const modalXmlRef = useRef(null);
    const modalProcessarXMLRef = useRef(null);
    const modalVerXMLRef = useRef(null);
    const modalErrosRef = useRef(null);
    const modalLancCteRef = useRef(null);
    const menuRef = useRef(null);
    const { dadosBasicos, permiteAlterar, atualizarValoresEntrada } = useEntrada();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpDelete } = useHttp();

    useClearRefs(
        listagemRef,
        modalXmlRef,
        modalProcessarXMLRef,
        modalVerXMLRef,
        modalErrosRef,
        modalLancCteRef,
        menuRef
    );

    const onUploadXml = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "XML enviado com sucesso!",
            life: 3000,
        });

        listagemRef.current?.buscarDados();
    };

    const onErrorXml = (e) => {
        const { xhr } = e;

        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);

            showWarning({
                summary: "XML não importado",
                detail: msg,
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível importar o XML.",
                life: 3000,
            });
        }
    };

    const edicaoBloqueada = !permiteAlterar;

    const itensMenu = [
        {
            label: "Conferir itens",
            icon: "pi pi-check",
            command: () => modalXmlRef.current?.abrirModal(xmlSelecionado),
        },
        {
            label: "Processar XML",
            icon: "pi fas fa-cogs",
            disabled: edicaoBloqueada,
            command: () => modalProcessarXMLRef.current?.abrirModal(xmlSelecionado),
        },
        {
            label: "Deletar XML",
            icon: "pi pi-trash",
            disabled: edicaoBloqueada,
            command: () => confirmarExclusaoXML(xmlSelecionado),
        },
    ];

    const painelEsquerdoTabela = (
        <>
            <Tooltip target=".upload-xml-button" position="top" />
            <MakoFileUpload
                mode="basic"
                name="file"
                url={`${url()}/compras/upload-xml-entrada/${dadosBasicos.id}/`}
                disabled={edicaoBloqueada}
                accept=".xml"
                chooseLabel="Carregar XML"
                data-pr-tooltip="Clique aqui para carregar o XML da nota fiscal."
                className="p-mr-2 p-d-inline-block upload-xml-button"
                chooseOptions={{ className: "p-button-success" }}
                invalidFileSizeMessageSummary="{0}: Tamanho do arquivo inválido, "
                invalidFileSizeMessageDetail="tamanho máximo permitido: {0}."
                maxFileSize={100000}
                onUpload={onUploadXml}
                onError={onErrorXml}
            />
            <Button
                label="Processar XML's"
                icon="fas fa-cogs"
                className="p-button-help p-mr-2"
                disabled={xmlsSelecionados.length === 0 || edicaoBloqueada}
                onClick={() => modalProcessarXMLRef.current?.abrirModal(xmlsSelecionados)}
            />
        </>
    );

    const confirmarExclusaoXML = (xml) => {
        confirmDialog({
            message: `Confirma a exclusão do XML ${xml.chave}?`,
            header: "Confirmação",
            icon: "pi pi-info-circle",
            accept: () => excluirXML(xml),
            acceptLabel: "Sim",
            rejectLabel: "Não",
        });
    };

    const excluirXML = async (xml) => {
        const handlers = {
            204: ({ data }) => {
                setXmlSelecionado(null);
                listagemRef.current?.buscarDados();
            },
        };

        showLoading();
        await httpDelete({ url: `/compras/entradas-xml/${xml.id}/` }, handlers);
        hideLoading();
    };

    const situacaoBodyTemplate = (rowData) => {
        if (rowData.itens_validados && !rowData.usuario_processamento) {
            return <Tag value="VALIDADO" severity="info" />;
        }

        if (rowData.usuario_processamento) {
            return <Tag value="PROCESSADO" severity="success" />;
        }

        return <Tag value="IMPORTADO" severity="warning" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} id="popup_menu" />
                <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-mr-2"
                    disabled={edicaoBloqueada}
                    onClick={(e) => {
                        menuRef.current?.toggle({ ...e, rowData });
                        setXmlSelecionado(rowData);
                    }}
                    aria-controls="popup_menu"
                    aria-haspopup
                />
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-help p-mr-2"
                    tooltip="Ver XML"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => modalVerXMLRef.current?.abrirModal(rowData.id)}
                />
            </div>
        );
    };

    const colunas = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "numero_nf", header: "Documento", style: { width: "8%" } },
        { field: "modelo", header: "Modelo", style: { width: "5%" } },
        {
            field: "cpf_cnpj_emitente",
            header: "Emitente",
            style: { width: "15%" },
            action: (e) => formatarDocumento(e.cpf_cnpj_emitente),
        },
        {
            field: "datahora_emissao",
            header: "Emissão",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
        },
        { field: "qtd_itens", header: "Itens", style: { width: "5%" } },
        // { field: "qtd_servicos", header: "Serviços", style: { width: "8%" } },
        {
            field: "valor_total_nf",
            header: "Valor Total",
            money: true,
            style: { width: "12%" },
        },
        { field: "chave", header: "Chave" },
        { field: "situacao", header: "Situação", action: (e) => situacaoBodyTemplate(e) },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const tipoErroBodyTemplate = (rowData) => {
        if (rowData.tipo === "error") return <Tag severity="danger" value="ERRO" />;
        return <Tag severity="warning" value="AVISO" />;
    };

    const colunasErros = [
        { field: "nf", header: "Documento", style: { width: "15%" } },
        { field: "item", header: "Cód. item", style: { width: "15%" } },
        { field: "erro", header: "Mensagem" },
        { field: "tipo", header: "Erro / Aviso", style: { width: "12%" }, action: (e) => tipoErroBodyTemplate(e) },
    ];

    return (
        <>
            <ConfirmDialog />
            <ModalEntradaXML
                ref={modalXmlRef}
                aposFechar={() => {
                    setXmlSelecionado(null);
                    listagemRef.current?.buscarDados();
                }}
            />
            <ModalProcessarXML
                ref={modalProcessarXMLRef}
                onSuccess={async () => {
                    setXmlSelecionado(null);
                    await atualizarValoresEntrada();
                    listagemRef.current?.buscarDados();
                }}
                onError={(e) => modalErrosRef.current?.abrirModal(e)}
            />
            <ModalVerXML ref={modalVerXMLRef} />
            <MakoErrosPersonalizados
                ref={modalErrosRef}
                colunas={colunasErros}
                titulo="Erros no processamento dos XML's"
            />
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={`/compras/entradas-xml/?entrada=${dadosBasicos.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    responsiveLayout: "scroll",
                    selection: xmlsSelecionados,
                    onSelectionChange: (e) => setXmlsSelecionados(e.value),
                }}
            />
        </>
    );
};
