import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoDropdownClausulaContrato } from "@/components/MakoInputs";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { gerarClausulaTemplate, gerarFiltrosUrl } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useModeloContrato from "@/hooks/useModeloContrato";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast, { DEFAULT_ERROR_TOAST } from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton } from "@/components/MakoButton";

const TIPOS_POSICAO = {
    ACIMA: "AC",
    ABAIXO: "AB",
};

const BASE_URL = "/servicos/clausulas-contrato";
const BASE_URL_NEW = "/servicos/clausulas-modelo-contrato/{id}/clausulas";

export const Componente = ({ onSuccessCallback = () => {} }, ref) => {
    const [selecionados, setSelecionados] = useState([]);
    const [referencia, setReferencia] = useState({ posicao: null, referencia: null, clausula: null });
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState("");

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { dadosBasicos } = useModeloContrato();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const inputRef = useRef(null);

    const show = useCallback((referencia = null, clausula = null, posicao = null) => {
        setReferencia({ posicao, referencia, clausula });
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const colunas = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        {
            field: "parte.identificador",
            header: "Secção",
        },
        {
            field: "nome",
            header: "Nome",
        },
        {
            field: "clausula.texto_clausula",
            header: "Início do texto...",
            action: (e) => gerarClausulaTemplate({ ...e, showHeader: false }),
        },
    ];

    const adicionar = useCallback(async () => {
        const handlers = {
            201: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Cláusulas inseridas com sucesso!",
                    life: 1500,
                });
                onSuccessCallback();
                hide();
            },
            400: ({ err }) => {
                showError({
                    summary: DEFAULT_ERROR_TOAST.summary,
                    detail: err?.msg || DEFAULT_ERROR_TOAST.detail,
                    life: DEFAULT_ERROR_TOAST.life,
                });
            },
        };
        showLoading();
        const { posicao, referencia: ref } = referencia;
        await httpPost(
            {
                url: BASE_URL_NEW.replace("{id}", dadosBasicos?.id),
                body: {
                    posicao,
                    referencia: ref,
                    clausulas: selecionados.map(({ id }) => id),
                },
            },
            handlers
        );
        hideLoading();
    }, [
        dadosBasicos?.id,
        hide,
        hideLoading,
        httpPost,
        onSuccessCallback,
        referencia,
        selecionados,
        showError,
        showLoading,
        showSuccess,
    ]);

    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl({ situacao: "V", search })}`;
    }, [search]);

    useImperativeHandle(ref, () => ({ show, hide, TIPOS_POSICAO }), [show, hide]);

    return (
        <Dialog
            visible={visible}
            header="Adicionando cláusula ao contrato"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "90vw" }}
            onHide={hide}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="secao" label="Secção" />
                    <MakoDropdownClausulaContrato
                        id="secao"
                        name="secao"
                        value={referencia.clausula}
                        disabled
                        placeholder=""
                    />
                </div>
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="clausula" label="Cláusula" />
                    <div className="p-inputgroup">
                        <InputText id="clausula" name="clausula" ref={inputRef} />
                        <Button
                            icon={MAKO_ICONS.FILTRAR}
                            label="Filtrar"
                            type="button"
                            className="p-button p-button-info"
                            onClick={() => setSearch(() => inputRef.current?.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="p-mt-2">
                <MakoListagem
                    colunas={colunas}
                    urlPesquisa={url}
                    fazerBusca={!!search}
                    configTabela={{
                        lazy: true,
                        scrollable: true,
                        paginator: true,
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: selecionados,
                        onSelectionChange: (e) => setSelecionados(e.value),
                    }}
                />
            </div>
            <MakoActionsButtons className="p-jc-end p-mt-4">
                <MakoButton
                    icon={MAKO_ICONS.CONFIRMAR}
                    label="Confirmar"
                    className="p-button p-button-success"
                    type="button"
                    onClick={adicionar}
                    loading={loading}
                    disabled={!selecionados?.length}
                />
            </MakoActionsButtons>
        </Dialog>
    );
};

export const ModalAdicionarClausula = forwardRef(Componente);
