import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PageBase } from "@/components/PageBase";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const URL_API = "/vendas/estagios-vendas/";

export const EstagioVendaForm = (props) => {
    const history = useHistory();
    const { httpPost, httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            ocultar_finalizado: false,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        delete values.sequenciaestagiovenda_set;
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Fluxo de venda alterado!",
                    life: 1500,
                });
                setTimeout(() => voltarParaListagem(), 2000);
            },
            201: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Novo fluxo de venda cadastrado!",
                    life: 1500,
                });
                setTimeout(() => voltarParaListagem(), 2000);
            },
            400: ({ err }) => handleErrors(err),
        };
        if (!values.id) {
            showLoading();
            await httpPost({ url: URL_API, body: values }, handlers);
            hideLoading();
        } else {
            showLoading();
            await httpPut({ url: `${URL_API}${values.id}/`, body: values }, handlers);
            hideLoading();
        }
    }

    const voltarParaListagem = () => {
        history.push("/vendas/cadastros/estagios-vendas");
    };

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [props, setValues]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo fluxo de venda" : "Manutenção de fluxo de venda"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="ocultar_finalizado">Ocultar venda finalizada?</label>
                        <SelectButton
                            id="ocultar-finalizado"
                            name="ocultar_finalizado"
                            value={formik.values.ocultar_finalizado}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <CamposObrigatorios singular />
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        icon="pi pi-save"
                        label="Gravar"
                        loading={loading}
                        className="p-button-info"
                    />
                    <Button
                        type="button"
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        loading={loading}
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
