import React, { useEffect } from "react";
import { useFormik } from "formik";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";

import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { parseData } from "@/assets/util/datas";

const INITIAL_VALUES = {
    tipo_movimento: "C",
    data_movimentacao: null,
    valor: null,
    plano_contas: null,
    historico: "",
};

export const VisualizarLancamento = ({ lancamento }) => {
    const { setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
    });

    useEffect(() => {
        if (lancamento) {
            const { origem } = lancamento;
            const { conta_financeira, tipo_movimento, valor } = origem || {};
            setValues({
                ...INITIAL_VALUES,
                conta_financeira,
                tipo_movimento,
                valor,
                historico: lancamento.descricao,
                data_movimentacao: parseData(lancamento?.datahora_geracao),
            });
        }
    }, [setValues, lancamento]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="tipo_movimento">Tipo</label>
                            <Dropdown
                                id="tipo_movimento"
                                name="tipo_movimento"
                                placeholder="Selecione uma conta financeira"
                                options={NATUREZA_PLANO_CONTAS}
                                filter
                                filterBy="label"
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.tipo_movimento}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-7">
                            <label htmlFor="conta_financeira">Conta Financeira</label>
                            <Dropdown
                                id="conta_financeira"
                                name="conta_financeira"
                                placeholder="Selecione uma conta financeira"
                                url={"/financeiro/contas-financeiras?limit=1000"}
                                filter
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.conta_financeira}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data_movimentacao">Data movimentação</label>
                            <MakoCalendar
                                id="data_movimentacao"
                                name="data_movimentacao"
                                value={formik.values.data_movimentacao}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor">Valor</label>
                            <InputNumber
                                id="valor"
                                name="valor"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                value={formik.values.valor}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-7">
                            <label htmlFor="plano_contas">Plano de contas</label>
                            <Dropdown
                                id="plano_contas"
                                name="plano_contas"
                                placeholder="Selecione uma conta financeira"
                                url={"/financeiro/planos-contas-financeiras?limit=10000"}
                                filter
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.plano_contas}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="caixa_movimento">Histórico</label>
                            <InputTextarea
                                id="historico"
                                name="historico"
                                rows={8}
                                value={formik.values.historico}
                                disabled
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
