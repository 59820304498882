import React, { useImperativeHandle, useState, useCallback, useEffect, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputTransportador } from "@/components/MakoInputs/MakoInputTransportador";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_FRETE, CST_ICMS_FRETE } from "@/assets/constants/compras";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const Modal = ({ onSave }, ref) => {
    const [visible, setVisible] = useState(false);
    const { dadosBasicos } = useEntrada();
    const { showWarning, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            chave: "",
            chave_nf: null,
            tipo_frete: null,
            transportador: null,
            cst_icms_frete: null,
            red_bc_icms_frete: 0,
            bc_icms_frete: 0,
            aliquota_icms_frete: 0,
            valor_icms_frete: 0,
            valor_frete: 0,
            cadastrado: true,
            nota_cte: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().when("chave_nf", {
                    is: (val) => !!val && !values.cadastrado,
                    then: Yup.string()
                        .required("O campo é obrigatório.")
                        // eslint-disable-next-line no-template-curly-in-string
                        .length(44, "Esse campo requer ${length} caracteres."),
                    otherwise: Yup.string().nullable(),
                }),
                nota_cte: Yup.object().when("chave_nf", {
                    is: (val) => !!val && values.cadastrado,
                    then: Yup.object().required("O campo é obrigatório.").typeError("Informe uma CTe válida."),
                    otherwise: Yup.object().nullable(),
                }),
                transportador: Yup.object()
                    .nullable()
                    .test({
                        message: "Para frete avulso é preciso informar um transportador.",
                        test: (value) => {
                            if (values.chave && values.chave_nf) return true;
                            return value instanceof Object;
                        },
                    })
                    .typeError("Selecione um transportador válido."),
                tipo_frete: Yup.number()
                    .required("O campo 'tipo de frete' é obrigatório.")
                    .typeError("Selecione um tipo de frete."),
                cst_icms_frete: Yup.string()
                    .required("O campo 'CST/ICMS do frete' é obrigatório.")
                    .typeError("Selecione um CST/ICMS de frete."),
                valor_frete: Yup.number().min(0.01, "O valor do frete não pode ser 0 (ZERO)."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const cte = {
                ...values,
                chave: values.chave || null,
                chave_nf: values.chave_nf || null,
                transportador: values.transportador?.id,
                valor_icms_frete: formatarCasasDecimais(values.valor_icms_frete),
                entrada: dadosBasicos?.id,
                nota_cte: values.nota_cte?.id || null,
            };
            const handlers = {
                success: () => fecharModal(),
                error: ({ status, err }) => {
                    if (status !== 500) {
                        showWarning({
                            summary: "Aviso!",
                            detail: err.message,
                            life: 3000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não foi possível processar sua requisição",
                            life: 3000,
                        });
                    }
                },
            };
            if (!cte.id) {
                showLoading();
                await httpPost({ url: "/compras/entrada-fretes/", body: cte }, handlers);
                hideLoading();
            } else {
                showLoading();
                await httpPut({ url: `/compras/entrada-fretes/${cte.id}/`, body: cte }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const calcularValorIcmsFrete = useCallback(() => {
        const bcIcmsFrete = parseNumber(formik.values.bc_icms_frete);
        const aliquotaIcmsFrete = parseNumber(formik.values.aliquota_icms_frete);
        if (bcIcmsFrete && aliquotaIcmsFrete) {
            const valorIcmsFrete = (bcIcmsFrete * aliquotaIcmsFrete) / 100;
            setFieldValue("valor_icms_frete", valorIcmsFrete);
        }
    }, [formik.values.bc_icms_frete, formik.values.aliquota_icms_frete, setFieldValue]);

    useEffect(() => {
        calcularValorIcmsFrete();
    }, [calcularValorIcmsFrete]);

    const abrirModal = (frete) => {
        if (frete) setValues({ ...frete, cadastrado: frete.nota_cte?.id ? true : false });
        setVisible(true);
    };

    const fecharModal = () => {
        if (typeof onSave === "function") onSave();
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return (
            <div>
                <Button
                    type="submit"
                    label="Lançar"
                    icon="pi pi-check"
                    loading={loading}
                    onClick={formik.handleSubmit}
                />
            </div>
        );
    };

    const itemTipoFreteTemplate = (option) => {
        return <span>{`${option.value} - ${option.label}`}</span>;
    };

    const valueTipoFreteTemplate = (option, props) => {
        if (option) return itemTipoFreteTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dialog
            header="Lançamento de frete"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
            footer={renderFooter}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="nota-cte">{formik.values.cadastrado ? "CTe" : "Chave CTe"}</label>
                        <div className="p-inputgroup">
                            {formik.values.cadastrado ? (
                                <MakoAutoComplete
                                    id="nota-cte"
                                    name="nota_cte"
                                    placeholder="Busque pela chave... (min 4 caracteres)"
                                    minCaracteresBusca={4}
                                    value={formik.values.nota_cte}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    field="chave_cte"
                                    urlSearch={
                                        "/fiscal/notas-conhecimento-transporte?limit=30&query={id,chave_cte,numero,serie}&chave_cte__icontains="
                                    }
                                    className={classNames({ "p-invalid": formik.errors.nota_cte })}
                                    tooltip="Busque por uma chave CTe cadastrada no sistema"
                                />
                            ) : (
                                <InputText
                                    id="chave"
                                    name="chave"
                                    value={formik.values.chave}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.chave })}
                                    tooltip="Informe uma chave de CTe válida (não cadastrada no sistema)"
                                />
                            )}
                            <Button
                                severity="secondary"
                                icon="pi pi-paperclip"
                                type="button"
                                outlined={!formik.values.cadastrado}
                                tooltip={
                                    formik.values.cadastrado
                                        ? "Trocar para chave não cadastrada"
                                        : "Trocar para busca de CTe cadastrada"
                                }
                                onClick={() => setFieldValue("cadastrado", !formik.values.cadastrado)}
                            />
                        </div>
                        {formik.errors.nota_cte && <small className="p-error">{formik.errors.nota_cte}</small>}
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="chave-nf">Chave NFe</label>
                        <Dropdown
                            id="chave-nf"
                            name="chave_nf"
                            url={`/compras/notafiscal-entradas/?entrada=${dadosBasicos.id}`}
                            optionLabel="nota_fiscal.chave_nf"
                            optionValue="nota_fiscal.chave_nf"
                            value={formik.values.chave_nf}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave_nf })}
                        />
                    </div>
                </div>
                <Divider />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="transportador">Transportador</label>
                        <MakoInputTransportador
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.transportador })}
                        />
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipo-frete">Tipo de frete *</label>
                        <Dropdown
                            id="tipo-frete"
                            name="tipo_frete"
                            options={TIPO_FRETE}
                            value={formik.values.tipo_frete}
                            itemTemplate={itemTipoFreteTemplate}
                            valueTemplate={valueTipoFreteTemplate}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_frete })}
                        />
                        {formik.errors.tipo_frete && <small className="p-error">{formik.errors.tipo_frete}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cst-icms-frete">CST/ICMS do frete *</label>
                        <Dropdown
                            id="cst-icms-frete"
                            name="cst_icms_frete"
                            options={CST_ICMS_FRETE}
                            optionLabel="value"
                            value={formik.values.cst_icms_frete}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cst_icms_frete })}
                        />
                        {formik.errors.cst_icms_frete && (
                            <small className="p-error">{formik.errors.cst_icms_frete}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="red-bc-icms-frete">Red. BC ICMS do frete *</label>
                        <MakoInputPercent
                            id="red-bc-icms-frete"
                            name="red_bc_icms_frete"
                            value={formik.values.red_bc_icms_frete}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-frete">Valor do frete *</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_frete })}
                        />
                        {formik.errors.valor_frete && <small className="p-error">{formik.errors.valor_frete}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="bc-icms-frete">BC ICMS do frete *</label>
                        <MakoInputMoeda
                            id="bc-icms-frete"
                            name="bc_icms_frete"
                            valueMoeda={formik.values.bc_icms_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="aliquota-icms-frete">Alíquota ICMS de frete *</label>
                        <MakoInputPercent
                            id="aliquota-icms-frete"
                            name="aliquota_icms_frete"
                            value={formik.values.aliquota_icms_frete}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-icms-frete">Valor ICMS frete *</label>
                        <MakoInputMoeda
                            id="valor-icms-frete"
                            name="valor_icms_frete"
                            disabled
                            valueMoeda={formik.values.valor_icms_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
            </form>
        </Dialog>
    );
};

export const ModalLancamentoFrete = forwardRef(Modal);
