import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { CancelaNFS } from "./modals/cancela";

export const ListaNotaServicoPage = () => {
    const [notaServico, setNotaServico] = useState(null);
    const [confirmaCancelar, setConfirmaCancelar] = useState(false);
    const history = useHistory();
    const { httpPost, httpGet } = useHttp();
    const { showSuccess, showError, showInfo } = useToast();
    const menu = useRef(null);
    const listagemRef = useRef(null);
    const cancelaRef = useRef(null);

    async function handleTransmitir() {
        try {
            const handlers = {
                200: ({ data }) => {
                    showInfo({
                        summary: "Envio realizado",
                        detail: data.msg,
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: ({ err }) => {
                    const { msg, motivo } = err;
                    showError({
                        summary: "Erro",
                        detail: `${msg} Motivo(s): ${motivo.map(({ descricao }) => descricao).join(", ")}`,
                        life: 9000,
                    });
                },
            };

            await httpPost({ url: `/servicos/transmitir-nota-servico/${notaServico?.id}/` }, handlers);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    /*async function handleConsultar() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Documento transmitido com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                202: ({ data }) => {
                    showInfo({
                        summary: "Aguarde...",
                        detail: data.msg,
                        life: 3000,
                    });
                },
                400: ({ err }) => {
                    const { retorno } = err;
                    showError({
                        summary: "Falha de transmissão",
                        detail: `Erro no processamento do documento. Motivo(s): ${retorno
                            .map(({ descricao }) => descricao)
                            .join(", ")}`,
                        life: 9000,
                    });
                    listagemRef.current?.buscarDados();
                },
            };

            await httpGet({ url: `/servicos/consultar-documento/${notaServico?.id}` }, handlers);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }*/

    async function handleBaixar(formato) {
        try {
            const handlers = {
                200: ({ data }) => {
                    const { documento } = data;
                    let file = new Blob([documento], {
                        type: `application/${formato}`,
                    });
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                },
                400: ({ err }) => {
                    const { retorno = null, msg } = err;
                    showError({
                        summary: "Falha",
                        detail: `${msg} ${retorno ? `Motivo: ${retorno.error.message}` : ""}`,
                        life: 6000,
                    });
                },
            };

            await httpGet({ url: `/servicos/baixar-documento/${notaServico?.id}/${formato}/` }, handlers);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const botoesEmissao = [
        {
            label: "Operações NF",
            items: [
                /*
                {

                    label: "Consultar",
                    icon: "pi pi-search",
                    disabled: notaServico?.status === "C",
                    command: () => handleConsultar(),
                },
                */
                {
                    label: notaServico?.status === "F" ? "Enviar novamente" : "Transmitir",
                    icon: "pi pi-arrow-up",
                    disabled: notaServico?.status === "C" || notaServico?.status === "T",
                    command: () => handleTransmitir(),
                },
                {
                    label: "Baixar PDF",
                    icon: "pi pi-file-pdf",
                    disabled: notaServico?.status !== "T",
                    command: () => handleBaixar("pdf"),
                },
                {
                    label: "Baixar XML",
                    icon: "pi pi-file-word",
                    disabled: notaServico?.status !== "T",
                    command: () => handleBaixar("xml"),
                },

                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: notaServico?.status === "F" || notaServico?.status === "C",
                    command: () =>
                        notaServico?.protocolo ? setConfirmaCancelar(true) : cancelaRef.current?.cancelar(notaServico),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota de serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-servico/incluir-nota",
                            notaServico: rowData.id,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EMITIR]}
                    model={botoesEmissao}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações NFS"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-book"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setNotaServico(rowData);
                    }}
                />
            </div>
        );
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "Não transmitido";
    };

    const colunas = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { width: "80px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { width: "50px" },
        },
        {
            field: "tomador.nome",
            header: "Tomador",
            style: { minWidth: "250px" },
            filter: true,
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "180px" },
        },
        {
            field: "datahora_emissao",
            header: "Data emissão",
            dateFormat: "dd/MM/yyyy HH:mm",
            style: { minWidth: "120px" },
        },
        {
            field: "valor_total",
            header: "Valor total",
            action: (e) => e.servico?.valor_total || 0,
            money: true,
            filter: true,
            align: "right",
            style: { width: "130px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
            style: { minWidth: "200px" },
        },
    ];

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_NOTASERVICO_INCLUIR]}
                componente={Button}
                label="Incluir Nota"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/nota-servico/incluir-nota")}
            />
        </>
    );

    const rowClass = (rowData) => {
        return {
            "mako-table-status-falhou": rowData.status === "C",
            "mako-table-status-inativo": rowData.status === "F",
            "mako-table-status-pendente": rowData.status === "P",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const esconderCancelDialog = () => {
        setConfirmaCancelar(false);
    };

    const aposCancelar = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <PageBase>
            <Menu model={botoesEmissao} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo="Notas Fiscais de Serviços"
                painelEsquerdo={painelEsquerdo}
                colunas={colunas}
                urlPesquisa={`/servicos/notas-fiscais-servicos/`}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    rowClassName: rowClass,
                }}
            />
            <CancelaNFS ref={cancelaRef} aposCancelar={aposCancelar} />
            <ConfirmDialog
                visible={confirmaCancelar}
                onHide={esconderCancelDialog}
                header="Confirmação de Cancelamento"
                message={
                    notaServico && (
                        <span>
                            {"Deseja realmente cancelar a nota de serviço número "}
                            <b>{notaServico.numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => cancelaRef.current?.abrir()}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderCancelDialog}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
