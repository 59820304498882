import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

import * as pd from "@/assets/util/persistenciaDjango";
import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";
import {
    PESSOAS_PERFIL_CADASTRO_TELEFONE_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_EMAIL_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_REDESOCIAL_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_ENDERECO_OBRIGATORIEDADE,
} from "@/assets/constants/parametros";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const PessoaContext = createContext({});

export const PessoaProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [mostrarConfirm, setMostrarConfirm] = useState(false);
    const [loadingPessoa, setLoadingPessoa] = useState(false);
    const [pessoa, setPessoa] = useState({});
    const [enderecos, setEnderecos] = useState([]);
    const [telefones, setTelefones] = useState([]);
    const [emails, setEmails] = useState([]);
    const [redesSociais, setRedesSociais] = useState([]);
    const [tags, setTags] = useState([]);
    const [customFields, setCustomFields] = useState(null);
    const { user } = useAuth();
    const { showSuccess, showError } = useToast();
    const { httpGet, httpPost, httpPatch, httpDelete } = useHttp();

    const _LISTAGENS = useMemo(() => {
        return [
            { url: "/pessoas/enderecos-perfis/", set: setEnderecos },
            { url: "/pessoas/emails-perfis/", set: setEmails },
            { url: "/pessoas/vinculo-tags-perfis/", set: setTags },
            { url: "/pessoas/telefones-perfis/", set: setTelefones },
            { url: "/pessoas/redes-sociais-perfis/", set: setRedesSociais },
        ];
    }, []);

    const listaDados = useCallback(
        async (url, setDados) => {
            if (pessoa?.id) {
                const handlers = {
                    200: ({ data }) => {
                        const { results } = data;
                        setDados(results);
                    },
                };

                await httpGet(
                    {
                        url: `${url}?perfil__id=${pessoa?.id}`,
                    },
                    handlers
                );
            }
        },
        [pessoa?.id, httpGet]
    );

    /*const emailObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_EMAIL_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const telefoneObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_TELEFONE_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const redesocialObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_REDESOCIAL_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const enderecoObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_ENDERECO_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);*/

    const handlePessoaFisica = useCallback(
        async (pessoaFisica) => {
            let status = 200,
                json = {};

            if (!pessoaFisica.id) {
                const handlers = {
                    201: ({ data }) => {
                        json = data;
                        status = 201;
                        setPessoa(data);
                        setMostrarConfirm(true);
                    },
                    400: () => {
                        status = 400;
                        showError();
                    },
                };

                await httpPost(
                    {
                        url: "/pessoas/perfis/",
                        body: {
                            ...pessoaFisica,
                            perfil_pf: pessoaFisica,
                            descricao: pessoaFisica.obs,
                            tipo_pessoa: "PF",
                            nome: `${pessoaFisica.nome} ${pessoaFisica.sobrenome}`,
                            identificacao: pessoaFisica.cpf,
                            nome_curto: pessoaFisica.nome_curto,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            } else {
                let diffPF = {};
                Object.entries(pessoa.perfil_pf).forEach(([k, v]) => {
                    if (v !== pessoaFisica[k]) {
                        diffPF[k] = v;
                    }
                });

                if (Object.keys(diffPF).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            json = data;
                            status = 200;
                            setPessoa(data);
                        },
                        400: () => {
                            status = 400;
                            showError();
                        },
                    };

                    await httpPatch(
                        {
                            url: `/pessoas/perfis/${pessoaFisica._id}/`,
                            body: {
                                perfil_pf: pessoaFisica,
                                descricao: pessoaFisica.obs,
                                tipo_pessoa: "PF",
                                nome: `${pessoaFisica.nome} ${pessoaFisica.sobrenome}`,
                                identificacao: pessoaFisica.cpf,
                                nome_curto: pessoaFisica.nome_curto,
                            },
                        },
                        handlers
                    );
                }
                return { status, data: json };
            }
        },
        [pessoa, httpPost, httpPatch, showError]
    );

    const handlePessoaJuridica = useCallback(
        async (pessoaJuridica) => {
            let status = 200,
                json = {};

            if (!pessoaJuridica.id) {
                const handlers = {
                    201: ({ data }) => {
                        json = data;
                        status = 201;
                        setPessoa(data);
                        setMostrarConfirm(true);
                    },
                    400: () => {
                        status = 400;
                        showError();
                    },
                };

                await httpPost(
                    {
                        url: "/pessoas/perfis/",
                        body: {
                            ...pessoaJuridica,
                            perfil_pj: pessoaJuridica,
                            descricao: pessoaJuridica.obs,
                            tipo_pessoa: "PJ",
                            nome: pessoaJuridica.razao_social,
                            identificacao: pessoaJuridica.cnpj,
                            nome_curto: pessoaJuridica.nome_curto,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            } else {
                let diffPF = {};
                Object.entries(pessoa.perfil_pj).forEach(([k, v]) => {
                    if (v !== pessoaJuridica[k]) {
                        diffPF[k] = v;
                    }
                });

                if (Object.keys(diffPF).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            json = data;
                            status = 200;
                            setPessoa(data);
                        },
                        400: () => {
                            status = 400;
                            showError();
                        },
                    };

                    await httpPatch(
                        {
                            url: `/pessoas/perfis/${pessoaJuridica._id}/`,
                            body: {
                                ...pessoaJuridica,
                                perfil_pj: pessoaJuridica,
                                descricao: pessoaJuridica.obs,
                                tipo_pessoa: "PJ",
                                nome: pessoaJuridica.razao_social,
                                identificacao: pessoaJuridica.cnpj,
                                nome_curto: pessoaJuridica.nome_curto,
                            },
                        },
                        handlers
                    );
                }
                return { status, data: json };
            }
        },
        [pessoa, httpPatch, httpPost, showError]
    );

    const handlePessoaEstrangeira = useCallback(
        async (pessoaEstrangeira) => {
            let status = 200,
                json = {};

            if (!pessoaEstrangeira.id) {
                const handlers = {
                    201: ({ data }) => {
                        json = data;
                        status = 201;
                        setPessoa(data);
                        setMostrarConfirm(true);
                    },
                    400: () => {
                        status = 400;
                        showError();
                    },
                };

                await httpPost(
                    {
                        url: "/pessoas/perfis/",
                        body: {
                            ...pessoaEstrangeira,
                            perfil_pe: pessoaEstrangeira,
                            descricao: pessoaEstrangeira.obs,
                            tipo_pessoa: "PE",
                            nome: `${pessoaEstrangeira.nome} ${pessoaEstrangeira.sobrenome}`,
                            identificacao: pessoaEstrangeira.passaporte,
                            nome_curto: pessoaEstrangeira.nome_curto,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            } else {
                let diffPF = {};
                Object.entries(pessoa.perfil_pe).forEach(([k, v]) => {
                    if (v !== pessoaEstrangeira[k]) {
                        diffPF[k] = v;
                    }
                });

                if (Object.keys(diffPF).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            json = data;
                            status = 200;
                            setPessoa(data);
                        },
                        400: () => {
                            status = 400;
                            showError();
                        },
                    };

                    await httpPatch(
                        {
                            url: `/pessoas/perfis/${pessoaEstrangeira._id}/`,
                            body: {
                                ...pessoaEstrangeira,
                                perfil_pe: pessoaEstrangeira,
                                descricao: pessoaEstrangeira.obs,
                                tipo_pessoa: "PE",
                                nome: `${pessoaEstrangeira.nome} ${pessoaEstrangeira.sobrenome}`,
                                identificacao: pessoaEstrangeira.passaporte,
                                nome_curto: pessoaEstrangeira.nome_curto,
                            },
                        },
                        handlers
                    );
                }
                return { status, data: json };
            }
        },
        [pessoa, httpPost, httpPatch, showError]
    );

    const handleFotoPerfil = useCallback(
        (foto) => {
            setPessoa({
                ...pessoa,
                foto,
            });
            setMostrarConfirm(true);
        },
        [pessoa]
    );

    const aposSalvar = useCallback(
        (_URL) => {
            const { url, set } = _LISTAGENS.find((e) => e.url === _URL);
            listaDados(url, set);
        },
        [listaDados, _LISTAGENS]
    );

    const handleDados = useCallback(
        async (dados, url, op) => {
            let status = 400,
                json = {};

            if (op === pd.OP_CRUD_DJANGO.novo) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Informação inserida com sucesso!",
                            life: 3000,
                        });
                        aposSalvar(url);
                    },
                    400: (err) => {
                        json = err;
                        showError();
                    },
                };

                await httpPost(
                    {
                        url: url,
                        body: {
                            ...dados,
                            perfil: pessoa?.id,
                            usuario_alteracao: user.id,
                            data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Informação editada com sucesso!",
                            life: 3000,
                        });
                        aposSalvar(url);
                    },
                    400: (err) => {
                        json = err;
                        showError();
                    },
                };

                await httpPatch(
                    {
                        url: `${url}${dados.id}/`,
                        body: {
                            ...dados,
                            perfil: pessoa?.id,
                            usuario_alteracao: user.id,
                            data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.deletar) {
                const handlers = {
                    204: () => {
                        status = 204;
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Informação removida com sucesso!",
                            life: 3000,
                        });
                        aposSalvar(url);
                    },
                    400: (err) => {
                        json = err;
                        showError();
                    },
                };

                await httpDelete(
                    {
                        url: `${url}${dados.id}/`,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [httpPatch, httpPost, httpDelete, showError, showSuccess, aposSalvar, user, pessoa?.id]
    );

    const handleDeletaImagem = useCallback(async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Foto de perfil removida com sucesso!",
                    life: 1500,
                });
                setPessoa({
                    ...pessoa,
                    foto: null,
                });
            },
        };
        await httpPost(
            {
                url: `/pessoas/deletar-imagem-pessoa/${pessoa.id}/`,
                body: {
                    imagem_cloud: pessoa.foto?.replace("image/upload/", ""),
                },
            },
            handlers
        );
    }, [showSuccess, pessoa, httpPost]);

    const handleCustomFields = useCallback((values) => {
        setCustomFields(values);
        setMostrarConfirm(true);
    }, []);

    const checarInformacao = useCallback(
        async (tipo, id) => {
            const body = {
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                usuario_alteracao: user.id,
                ativo: true,
            };
            let status = 500;
            const handlers = {
                200: () => {
                    status = 200;
                    if (tipo === "enderecos") {
                        const _enderecos = [...enderecos];
                        const index = _enderecos?.findIndex((el) => el.id === id);
                        _enderecos[index] = {
                            ..._enderecos[index],
                            ...body,
                        };
                        setEnderecos(_enderecos);
                    } else if (tipo === "telefones") {
                        const _telefones = [...telefones];
                        const index = _telefones?.findIndex((el) => el.id === id);
                        _telefones[index] = {
                            ..._telefones[index],
                            ...body,
                        };
                        setTelefones(_telefones);
                    } else if (tipo === "emails") {
                        const _emails = [...emails];
                        const index = _emails?.findIndex((el) => el.id === id);
                        _emails[index] = {
                            ..._emails[index],
                            ...body,
                        };
                        setEmails(_emails);
                    } else if (tipo === "redes-sociais") {
                        const _redesSociais = [...redesSociais];
                        const index = _redesSociais?.findIndex((el) => el.id === id);
                        _redesSociais[index] = {
                            ..._redesSociais[index],
                            ...body,
                        };
                        setRedesSociais(_redesSociais);
                    }
                },
            };
            await httpPatch({ url: `/pessoas/${tipo}-perfis/${id}/`, body }, handlers);
            return status;
        },
        [enderecos, telefones, emails, redesSociais, user, httpPatch]
    );

    useEffect(() => {
        _LISTAGENS.forEach(({ url, set }) => {
            listaDados(url, set);
        });
    }, [listaDados, _LISTAGENS]);

    const handlePreencherPessoa = useCallback(
        async (idPessoa) => {
            const handlers = {
                200: ({ data }) => {
                    const [pessoa] = data.results;

                    const {
                        id,
                        tipo_pessoa,
                        nome_curto,
                        foto,
                        perfil_pf,
                        perfil_pj,
                        perfil_pe,
                        descricao: obs,
                    } = pessoa;

                    let perfil = {};
                    if (perfil_pf) {
                        perfil = {
                            perfil_pf: { ...perfil_pf, nome_curto, obs },
                            nome: `${perfil_pf.nome} ${perfil_pf.sobrenome}`,
                            identificacao: perfil_pf.cpf,
                        };
                    }
                    if (perfil_pj) {
                        perfil = {
                            perfil_pj: {
                                ...perfil_pj,
                                nome_curto,
                                obs,
                            },
                            nome: perfil_pj.razao_social,
                            identificacao: perfil_pj.cnpj,
                        };
                    }
                    if (perfil_pe) {
                        perfil = {
                            perfil_pe: { ...perfil_pe, nome_curto, obs },
                            nome: `${perfil_pe.nome} ${perfil_pe.sobrenome}`,
                            identificacao: perfil_pe.passaporte,
                        };
                    }

                    setPessoa({
                        ...perfil,
                        id,
                        tipo_pessoa,
                        foto,
                    });
                    setMostrarConfirm(false);
                },
            };

            setLoadingPessoa(true);
            await httpGet(
                {
                    url: `/pessoas/perfis?id=${idPessoa}&query={id,perfil_pf,perfil_pj,perfil_pe,nome_curto,foto,tipo_pessoa,descricao,enderecoperfil_set,telefoneperfil_set,emailperfil_set,redesocialperfil_set,vinculoperfiltag_set}`,
                },
                handlers
            );
            setLoadingPessoa(false);
        },
        [httpGet]
    );

    return (
        <PessoaContext.Provider
            value={{
                submit,
                loadingPessoa,
                pessoa,
                enderecos,
                redesSociais,
                tags,
                emails,
                telefones,
                customFields,
                mostrarConfirm,
                handlePessoaFisica,
                handlePessoaJuridica,
                handlePessoaEstrangeira,
                handleFotoPerfil,
                handleDados,
                handleCustomFields,
                handlePreencherPessoa,
                handleDeletaImagem,
                checarInformacao,
                setSubmit,
            }}
        >
            {children}
        </PessoaContext.Provider>
    );
};

export default PessoaContext;
