import React, { useMemo } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import classNames from "classnames";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const url = "/financeiro/carteiras-contas-financeiras/";
const urlvoltar = "/financeiro/cadastros/carteira-conta-financeira";

export const FinanceiroCarteiraFinanceiraForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const { state } = useLocation();
    const history = useHistory();
    const carteiraVazia = {
        codigo: "",
        descricao: "",
        ativo: true,
    };

    const formik = useFormik({
        initialValues: state ? state.carteiras : carteiraVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carteira de contas financeiras cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url: `${url}`, body: values }, handlers);
                hideLoading();
            } else {
                const body = {
                    codigo: values.codigo,
                    descricao: values.descricao,
                    ativo: values.ativo,
                };

                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carteira de contas financeiras alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const valido = useMemo(() => {
        return formik.values.descricao !== "" && formik.values.codigo !== "";
    }, [formik.values.descricao, formik.values.codigo]);

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Nova carteira de contas financeiras"
                    : "Manutenção de carteira de contas financeiras"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <InputText
                            id="codigo"
                            name="codigo"
                            keyfilter={/^[0-9]/}
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            autoComplete="off"
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        disabled={!valido}
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
