import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { dataToStr } from "@/assets/util/datas";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { gerarId } from "@/assets/util/util";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const url = "/financeiro/planos-recebimentos/";
const urlvoltar = "/financeiro/plano-recebimentos";

export const FinanceiroPlanoRecebimentoForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [novasFormas, setNovasFormas] = useState([]);
    const [removerFormas, setRemoverFormas] = useState([]);
    const [formaRecebimento, setFormaRecebimento] = useState(null);
    const [listaFormas, setListaFormas] = useState([]);
    const [opcoesFormas, setOpcoesFormas] = useState([]);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { state } = useLocation();
    const { httpPost, httpPut, httpGet } = useHttp();
    const { showSuccess } = useToast();
    const { planRecebimento } = state || {};

    const planoVazio = {
        descricao: "",
        taxa_minima: 0,
        taxa_alvo: 0,
        vigencia_inicial: null,
        vigencia_final: null,
        percentual_minimo_entrada: 0,
        exige_crediario: false,
        minimo_parcelas: 1,
        maximo_parcelas: 1,
        dia_vencimento: 0,
        intervalo_parcelas: "",
        formas_recebimento: [],
        ativo: true,
        exige_identificacao: true,
        juros_compostos: false,
        altera_forma_parcela: false,
    };

    const { setValues, ...formik } = useFormik({
        initialValues: planoVazio,
        onSubmit: handleSubmit,
    });

    const validarIntervaloParcelas = (intervaloParcelas, minParcelas, maxParcelas) => {
        const intervalo = intervaloParcelas.split(",");

        if (intervalo.length >= minParcelas && intervalo.length <= maxParcelas) {
            return true;
        } else {
            return false;
        }
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                minimo_parcelas: Yup.number().required("O campo 'mínimo de parcelas' é obrigatório."),
                maximo_parcelas: Yup.number()
                    .min(values.minimo_parcelas, "O número máximo de parcelas é inferior ao mínimo.")
                    .required("O campo 'máximo de parcelas' é obrigatório."),
                vigencia_inicial: Yup.date()
                    .required("O campo 'vigência inicial' é obrigatório.")
                    .typeError("Informe uma 'vigencia inicial' válida."),
                vigencia_final: Yup.date().nullable(),
                dia_vencimento: Yup.number().required("O campo 'dia de vencimento' é obrigatório."),
                intervalo_parcelas: Yup.string()
                    .required("O campo 'intervalo de parcelas' é obrigatório.")
                    .test("Validation", "O intervalo diverge da quantidade máxima de parcelas.", (value) =>
                        validarIntervaloParcelas(value, values.minimo_parcelas, values.maximo_parcelas)
                    ),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");
            values.vigencia_inicial = dataToStr(values.vigencia_inicial, "yyyy-MM-dd");
            if (!values.id) {
                delete values.formas_recebimento;
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de recebimento cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push({
                            path: "/financeiro/plano-recebimentos/form",
                            state: { planRecebimento: data },
                        });
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de recebimento alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            formas_recebimento:
                                novasFormas || removerFormas ? { add: novasFormas, remove: removerFormas } : [],
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const deletaFormaPagamento = (forma) => {
        let removeLista = [];
        if (typeof forma?.id === "number") setRemoverFormas([...removerFormas, forma.id]);
        else {
            let novaLista = [];
            listaFormas.forEach((aux) => {
                if (forma.id !== aux.id) novaLista.push(aux);
            });
            setNovasFormas(novaLista);
        }
        listaFormas.forEach((aux) => {
            if (forma.id !== aux.id) removeLista.push(aux);
        });
        setListaFormas(removeLista);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    type="button"
                    className="p-button-rounded p-button-danger"
                    onClick={() => deletaFormaPagamento(rowData)}
                    tooltip="Deletar forma de pagamento"
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const inserirFormaRecebimento = () => {
        setListaFormas([
            ...listaFormas,
            { id: gerarId(), descricao: formaRecebimento.descricao, tipo: formaRecebimento.tipo },
        ]);
        setNovasFormas([...novasFormas, formaRecebimento.id]);
        setFormaRecebimento(null);
    };

    const colunas = [
        { field: "descricao", header: "Descrição", style: { width: "60%" } },
        { field: "tipo.descricao", header: "Tipo", style: { width: "30%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const opcoesFormasValid = useMemo(() => {
        let lista = [];
        opcoesFormas.forEach((forma) => {
            let existe = false;
            listaFormas.forEach((aux) => {
                if (forma.descricao === aux.descricao) existe = true;
            });
            if (!existe) lista.push(forma);
        });
        return lista;
    }, [opcoesFormas, listaFormas]);

    const listarFormasRecebimento = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setOpcoesFormas(data.results),
        };
        showLoading();
        await httpGet({ url: "/financeiro/formas-recebimentos/" }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, httpGet]);

    useEffect(() => {
        listarFormasRecebimento();
        if (planRecebimento) setListaFormas(planRecebimento.formas_recebimento);
    }, [planRecebimento, listarFormasRecebimento]);

    useEffect(() => {
        if (planRecebimento) setValues(planRecebimento);
    }, [planRecebimento, setValues]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo plano de recebimento" : "Manutenção de plano de recebimento"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <TabView>
                    <TabPanel header="Cadastro" leftIcon="pi pi-save">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-9">
                                <Label htmlFor="descricao" label="Descrição" obrigatorio />
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoFocus
                                    tooltip="Descricao que será dado ao plano de recebimento."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="taxa_minima" label="% Taxa mínima" />
                                <InputNumber
                                    id="taxa_minima"
                                    name="taxa_minima"
                                    suffix=" %"
                                    min={0}
                                    mode="decimal"
                                    minFractionDigits={3}
                                    maxFractionDigits={3}
                                    value={formik.values.taxa_minima}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.taxa_minima })}
                                    tooltip="Taxa mínima que será dada ao plano de recebimento."
                                    autoComplete="off"
                                    locale="pt-BR"
                                />
                                {formik.errors.taxa_minima && (
                                    <small className="p-error">{formik.errors.taxa_minima}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="taxa_alvo" label="% Taxa alvo" />
                                <InputNumber
                                    id="taxa_alvo"
                                    name="taxa_alvo"
                                    suffix=" %"
                                    min={0}
                                    mode="decimal"
                                    minFractionDigits={3}
                                    maxFractionDigits={3}
                                    value={formik.values.taxa_alvo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.taxa_alvo })}
                                    tooltip="Taxa alvo que será dada ao plano de recebimento."
                                    autoComplete="off"
                                    locale="pt-BR"
                                />
                                {formik.errors.taxa_alvo && (
                                    <small className="p-error">{formik.errors.taxa_alvo}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="vigencia_inicial" label="Vigência inicial" obrigatorio />
                                <MakoCalendar
                                    id="vigencia_inicial"
                                    name="vigencia_inicial"
                                    value={formik.values.vigencia_inicial}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_inicial,
                                    })}
                                />
                                {formik.errors.vigencia_inicial && (
                                    <small className="p-error">{formik.errors.vigencia_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="vigencia_final" label="Vigência final" />
                                <MakoCalendar
                                    id="vigencia_final"
                                    name="vigencia_final"
                                    value={formik.values.vigencia_final}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_final,
                                    })}
                                />
                                {formik.errors.vigencia_final && (
                                    <small className="p-error">{formik.errors.vigencia_final}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="percentual_minimo_entrada" label="% Mínimo de entrada" />
                                <InputNumber
                                    id="percentual_minimo_entrada"
                                    name="percentual_minimo_entrada"
                                    suffix=" %"
                                    min={0}
                                    mode="decimal"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    value={formik.values.percentual_minimo_entrada}
                                    onValueChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.percentual_minimo_entrada,
                                    })}
                                    tooltip="Máximo de parcelas primitido ao plano de recebimento."
                                    autoComplete="off"
                                    locale="pt-BR"
                                />
                                {formik.errors.percentual_minimo_entrada && (
                                    <small className="p-error">{formik.errors.percentual_minimo_entrada}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-2">
                                <Label htmlFor="minimo_parcelas" label="Mín. parcelas" obrigatorio />
                                <InputNumber
                                    id="minimo_parcelas"
                                    name="minimo_parcelas"
                                    showButtons
                                    value={formik.values.minimo_parcelas}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.minimo_parcelas })}
                                    tooltip="Minimo de parcelas permitido ao plano de recebimento."
                                    autoComplete="off"
                                />
                                {formik.errors.minimo_parcelas && (
                                    <small className="p-error">{formik.errors.minimo_parcelas}</small>
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-2">
                                <Label htmlFor="maximo_parcelas" label="Máx. parcelas" obrigatorio />
                                <InputNumber
                                    id="maximo_parcelas"
                                    name="maximo_parcelas"
                                    showButtons
                                    max={120}
                                    value={formik.values.maximo_parcelas}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.maximo_parcelas })}
                                    tooltip="Máximo de parcelas primitido ao plano de recebimento."
                                    autoComplete="off"
                                />
                                {formik.errors.maximo_parcelas && (
                                    <small className="p-error">{formik.errors.maximo_parcelas}</small>
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-2">
                                <Label htmlFor="dia_vencimento" label="Dia do vencimento" obrigatorio />
                                <InputNumber
                                    id="dia_vencimento"
                                    name="dia_vencimento"
                                    showButtons
                                    value={formik.values.dia_vencimento}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.dia_vencimento })}
                                    tooltip="Dia do vencimento padrão."
                                    autoComplete="off"
                                />
                                {formik.errors.dia_vencimento && (
                                    <small className="p-error">{formik.errors.dia_vencimento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="intervalo_parcelas" label="Intervalo entre parcelas" obrigatorio />
                                <InputText
                                    id="intervalo_parcelas"
                                    name="intervalo_parcelas"
                                    placeholder="Ex: 10,20,30"
                                    value={formik.values.intervalo_parcelas}
                                    onChange={formik.handleChange}
                                    keyfilter={/[0-9,]/}
                                    className={classNames({ "p-invalid": formik.errors.intervalo_parcelas })}
                                    autoComplete="off"
                                />
                                {formik.errors.intervalo_parcelas && (
                                    <small className="p-error">{formik.errors.intervalo_parcelas}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="exige_identificacao" label="Exige identificação" />
                                <SelectButton
                                    id="exige_identificacao"
                                    name="exige_identificacao"
                                    value={formik.values.exige_identificacao}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="exige_crediario" label="Exige crediário" />
                                <SelectButton
                                    id="exige_crediario"
                                    name="exige_crediario"
                                    value={formik.values.exige_crediario}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="juros_compostos" label="Juros Compostos?" />
                                <SelectButton
                                    id="juros_compostos"
                                    name="juros_compostos"
                                    value={formik.values.juros_compostos}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="altera_forma_parcela" label="Altera forma de parcela" />
                                <SelectButton
                                    id="altera_forma_parcela"
                                    name="altera_forma_parcela"
                                    value={formik.values.altera_forma_parcela}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel
                        header="Formas de recebimento"
                        leftIcon="pi pi-money-bill"
                        disabled={formik.values.id ? false : true}
                    >
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10 p-md-6">
                                <Label htmlFor="formas_recebimento" label="Forma de recebimento" />
                                <div className="p-inputgroup">
                                    <Dropdown
                                        id="formas_recebimento"
                                        name="formas_recebimento"
                                        placeholder="Selecione uma forma de recebimento"
                                        onChange={(e) => setFormaRecebimento(e.value)}
                                        value={formaRecebimento}
                                        filter
                                        filterBy="descricao"
                                        optionLabel="descricao"
                                        options={opcoesFormasValid}
                                        autoComplete="off"
                                        autoFocus
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        type="button"
                                        className="p-button-success"
                                        onClick={() => inserirFormaRecebimento()}
                                        disabled={!formaRecebimento}
                                    />
                                </div>
                            </div>
                        </div>
                        <MakoListagem
                            ref={listagemRef}
                            colunas={colunas}
                            dadosLocal={listaFormas}
                            configTabela={{
                                scrollable: true,
                                scrollHeight: "260px",
                            }}
                        />
                    </TabPanel>
                </TabView>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
