import { dataToStr } from "@/assets/util/datas";
import { Calendar as MakoCalendar } from "@/components/Calendar";

export const DateFiltroTemplate = (options) => {
    return (
        <MakoCalendar
            value={options.value}
            onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
            className="p-column-filter"
        />
    );
};
