import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { TIPO_TAG_CHOICE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const url = "/crediario/nome-relacao/";

export const NomeRelacaoForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.relacao
            : {
                  id: "",
                  tipo: null,
                  relacao: "",
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                relacao: Yup.string().required("O campo 'descrição' é obrigatório."),
                tipo: Yup.number().required("O campo 'tipo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Relação cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/nomes-relacao");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Relação alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/nomes-relacao");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/vendas/venda/crediario/nomes-relacao");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Novo Nome de Relação" : "Manutenção de Nome de Relação"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="relacao">Nome *</label>
                        <InputText
                            id="relacao"
                            name="relacao"
                            value={formik.values.relacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.relacao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.relacao && <small className="p-error">{formik.errors.relacao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo *</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione um tipo"
                            options={TIPO_TAG_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button label="Gravar" icon={MAKO_ICONS.GRAVAR} type="submit" className="p-button-info" />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
