import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_MOVIMENTACAO_UNIDADE_MEDIDA_CHOICE } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import * as pd from "@/assets/util/persistenciaDjango";
import { Tag } from "primereact/tag";
import useToast from "@/hooks/useToast";
import { Menu } from "primereact/menu";
import { ProdutoUnidadeMedidaFormBase } from "../../Cadastros/UnidadeMedida/formBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Delete } from "@/components/Delete";

const UnidadeMedidaSkuForm = () => {
    const { handleUnidade, dadosBasicos } = useProduto();
    const [inserirUnidade, setInserirUnidade] = useState(false);
    const [produto, setProduto] = useState(null);
    const [unidade, setUnidade] = useState(null);
    const [cadastroUnidade, setCadastroUnidade] = useState(false);
    const menu = useRef(null);
    const unidadeRef = useRef(null);
    const listagemRef = useRef(null);
    const deleteRef = useRef(null);
    const { showSuccess } = useToast();

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            unidade: null,
            tipo_mov_und_medida: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_mov_und_medida: Yup.string().required("O campo 'tipo de movimentação' é obrigatório."),
                unidade: Yup.number().required("O campo 'unidade de medida' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const resposta = await handleUnidade(
                { ...values, sku: values.sku?.id || produto },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );
            if (
                (resposta instanceof Object && (resposta.status === 201 || resposta.status === 200)) ||
                resposta instanceof Array
            ) {
                resetForm();
                esconderInserirUnidade();
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleSituacao = async (situacao) => {
        const { status } = await handleUnidade({ id: unidade.id, ativo: situacao }, pd.OP_CRUD_DJANGO.editar);
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const editarUnidade = (unidade) => {
        setValues({ ...unidade, unidade: unidade.unidade.id, tipo_mov_und_medida: unidade.tipo_mov_und_medida.id });
        setInserirUnidade(true);
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Ativar",
                    icon: "pi pi-chevron-circle-down",
                    disabled: unidade?.ativo,
                    command: () => handleSituacao(true),
                },
                {
                    label: "Desativar",
                    icon: "pi pi-times-circle",
                    disabled: !unidade?.ativo,
                    command: () => handleSituacao(false),
                },
                {
                    label: "Excluir",
                    icon: MAKO_ICONS.DELETE,
                    disabled: !unidade?.ativo,
                    command: () => deleteRef.current?.handleClick(),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    type="button"
                    className="p-button-rounded p-button-warning"
                    icon={MAKO_ICONS.EDITAR}
                    onClick={() => editarUnidade(rowData)}
                />
                <Delete
                    ref={deleteRef}
                    exigeConfirmacao
                    url="/produtos/unidades-medida-sku/"
                    msgConfirmacao={<span>Deseja realmente remover a unidade de medida?</span>}
                    objetoId={unidade?.id}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Unidade de medida removida com sucesso.",
                            life: 3000,
                        });
                    }}
                    msgToastErroExclusao="O unidade de medida não pode ser removida."
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    model={opcoes}
                    componente={Button}
                    className="p-button-rounded p-button-info"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setUnidade(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const verificaItens = (results) => {
        if (results.length === 1) setProduto(results[0].id);
        return results;
    };

    const itemTemplate = (sku) => {
        return `${sku.codigo} - ${sku.descricao_complementar}`;
    };

    const valueTemplate = (sku) => {
        if (sku) return `${sku.codigo} - ${sku.descricao_complementar}`;
        return "Selecione...";
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}&limit=1000`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                filter
                filterBy="id,codigo,descricao_complementar"
                valueTemplate={valueTemplate}
                itemTemplate={itemTemplate}
                optionValue="id"
                optionLabel="descricao_complementar"
                aposBuscar={verificaItens}
                showClear={false}
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
                className="p-mr-2"
            />
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirUnidade(true)}
                disabled={!produto}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        { field: "sku.codigo", header: "Cód. Produto", style: { minWidth: "5%" } },
        { field: "sku.descricao_reduzida", header: "Descrição produto", style: { minWidth: "25%" } },
        { field: "unidade.nome", header: "Unidade de medida" },
        { field: "tipo_mov_und_medida.descricao", header: "Contexto movimentação", style: { minWidth: "20%" } },
        {
            field: "ativo",
            header: "Situação",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const esconderInserirUnidade = () => {
        setInserirUnidade(false);
        resetForm();
    };

    const esconderCadastroUnidade = () => {
        unidadeRef.current?.fetchDados();
        setCadastroUnidade(false);
    };

    const onSubmitUnidade = ({ id }) => {
        setFieldValue("unidade", id);
    };

    return (
        <>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/produtos/unidades-medida-sku?sku__id=${produto}`}
                fazerBusca={!!produto}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    scrollable: true,
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header="Cadastro unidade de medida"
                visible={inserirUnidade}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => esconderInserirUnidade()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="unidade">Unidade de Medida *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    ref={unidadeRef}
                                    id="unidade"
                                    name="unidade"
                                    placeholder="Selecione..."
                                    optionValue="id"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome"
                                    url="/produtos/unidades-medida?limit=300"
                                    value={formik.values.unidade}
                                    onChange={formik.handleChange}
                                    showClear={false}
                                    className={classNames({ "p-invalid": formik.errors.unidade })}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR]}
                                    componente={Button}
                                    type="button"
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    tooltip="Cadastrar unidade de medida"
                                    tooltipOptions={{ position: "left" }}
                                    onClick={() => setCadastroUnidade(true)}
                                />
                            </div>
                            {formik.errors.unidade && <small className="p-error">{formik.errors.unidade}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="tipo_mov_und_medida">Contexto de movimentação *</label>
                            <Dropdown
                                id="tipo_mov_und_medida"
                                placeholder="Selecione..."
                                name="tipo_mov_und_medida"
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                options={TIPO_MOVIMENTACAO_UNIDADE_MEDIDA_CHOICE}
                                value={formik.values.tipo_mov_und_medida}
                                onChange={formik.handleChange}
                                showClear={false}
                                className={classNames({ "p-invalid": formik.errors.tipo_mov_und_medida })}
                            />
                            {formik.errors.tipo_mov_und_medida && (
                                <small className="p-error">{formik.errors.tipo_mov_und_medida}</small>
                            )}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-mr-1">
                        <Button type="submit" icon="pi pi-check-circle" label="Inserir" />
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderInserirUnidade()}
                        />
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Cadastrar unidade de medida"}
                visible={cadastroUnidade}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => esconderCadastroUnidade()}
            >
                <ProdutoUnidadeMedidaFormBase
                    unidade
                    closeDialog={esconderCadastroUnidade}
                    onSubmit={onSubmitUnidade}
                />
            </Dialog>
        </>
    );
};

export default UnidadeMedidaSkuForm;
