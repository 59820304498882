import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { MakoButton as Button } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/versoes-planos-contas-contabeis/";

export const ContabilVersaoPlanoContasPage = () => {
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [versaoContas, setVersaoContas] = useState(null);
    const [listEmpresaVinculada, setListEmpresaVinculada] = useState([]);
    const [empresaVinculada, setEmpresaVinculada] = useState(null);
    const [exibirEmpresasVinc, setExibirEmpresasVinc] = useState(false);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPatch } = useHttp();
    const { showSuccess, showWarning, showError } = useToast();
    const [filtro, setFiltro] = useState("");
    const listagemRef = useRef(null);
    const opcoesPesquisa = [
        { id: "todos", label: "Todos", value: "" },
        { id: "ativos", label: "Ativos", value: "true" },
        { id: "inativos", label: "Inativos", value: "false" },
    ];

    async function handleVincular() {
        try {
            if (empresaVinculada !== null) {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Versão de plano de contas cadastrada com sucesso!",
                            life: 1500,
                        });
                        esconderVincularEmpresa();
                        listagemRef.current?.buscarDados();
                    },
                };
                showLoading();
                await httpPatch(
                    {
                        url: `${url}${versaoContas.id}/`,
                        body: {
                            empresas: { add: [empresaVinculada] },
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                showWarning({
                    summary: "Ops :(",
                    detail: "Selecione uma empresa para realizar o vinculo.",
                    life: 1500,
                });
            }
        } catch (error) {
            showError();
        }
    }

    const esconderVincularEmpresa = () => {
        setVincularEmpresa(false);
        setEmpresaVinculada(null);
    };

    const exibirVincularEmpresa = (rowData) => {
        setVincularEmpresa(true);
        setVersaoContas(rowData);
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    onClick={() => esconderVincularEmpresa()}
                    className="p-button-text"
                    loading={loading}
                />
                <Button
                    label="Confirmar"
                    icon={MAKO_ICONS.CONFIRMAR}
                    className="p-button"
                    onClick={() => handleVincular()}
                    loading={loading}
                />
            </div>
        );
    };

    const opcoesVersaoPlano = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de versão do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/contabil/versao-plano-contas/form",
                        state: { versaoContas: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-sitemap"
                    className="p-button-rounded p-button-info"
                    onClick={() => exibirVincularEmpresa(rowData)}
                    tooltip="Vincular plano de contas a empresas"
                    tooltipOptions={{ position: "left" }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/versoes-planos-contas-contabeis"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="versão do plano de contas"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a versão <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    async function desvincularEmpresa(empresa) {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Empresa desvinculada com sucesso!",
                    life: 3000,
                });
                setExibirEmpresasVinc(false);
                listagemRef.current?.buscarDados();
            },
        };
        showLoading();
        await httpPatch(
            {
                url: `${url}${versaoContas.id}/`,
                body: {
                    empresas: { remove: [empresa.id] },
                },
            },
            handlers
        );
        hideLoading();
    }

    const opcoesEmpresasVinculadas = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => desvincularEmpresa(rowData)}
                    tooltip="Desvincular empresa da versao do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    loading={loading}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/contabil/versao-plano-contas/form"}
            />

            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const exibirListaEmpresasVinc = (row) => {
        setVersaoContas(row);
        setListEmpresaVinculada(row.empresas);
        setExibirEmpresasVinc(true);
    };

    const qtdEmpresasTemplate = (rowData) => {
        return (
            <Button
                label={rowData.empresas.length === 0 ? "0" : rowData.empresas.length}
                onClick={() => exibirListaEmpresasVinc(rowData)}
                className="p-button-outlined p-button-primary"
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "descricao", header: "Descrição" },
        { field: "ativo", header: "Ativo" },
        {
            field: "empresas",
            header: "Empresas",
            action: (e) => qtdEmpresasTemplate(e),
            style: { width: "8%" },
            align: "center",
        },
        { field: "inicio_vigencia", header: "Vigência Inicial", dateFormat: "dd/MM/yyyy", style: { width: "8%" } },
        { field: "final_vigencia", header: "Vigência Final", dateFormat: "dd/MM/yyyy", style: { width: "8%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => opcoesVersaoPlano(e),
            style: { width: "15%" },
        },
    ];

    const colunasEmpresa = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "nome", header: "Empresa", style: { width: "60%" } },
        {
            field: "action",
            header: "Remover",
            action: (e) => opcoesEmpresasVinculadas(e),
            style: { width: "15%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de versões do plano de contas contábeis"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                }}
            />
            <Dialog
                header="Vincular Empresas"
                visible={vincularEmpresa}
                style={{ width: "30vw" }}
                footer={renderFooter()}
                onHide={() => esconderVincularEmpresa()}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12" style={{ width: "100%", paddingLeft: "4%" }}>
                        <Label htmlFor="empresa_vinculada" label="Empresa" obrigatorio />
                        <MakoDropdownEmpresas
                            id="empresa_vinculada"
                            name="empresa_vinculada"
                            value={empresaVinculada}
                            onChange={({ id }) => {
                                setEmpresaVinculada(id);
                            }}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Empresas vinculadas"
                visible={exibirEmpresasVinc}
                style={{ width: "30vw" }}
                onHide={() => setExibirEmpresasVinc(false)}
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunasEmpresa}
                    dadosLocal={listEmpresaVinculada}
                    responsiva
                    configTabela={{ paginator: false }}
                />
            </Dialog>
        </PageBase>
    );
};
