import React, { useMemo } from "react";

import {
    PESSOAS_TAGPERFIL_PADRAO_MONTADOR,
    PESSOAS_TAGPERFIL_PADRAO_MONTADOR_AUXILIAR,
} from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputMontadorEAuxiliar = ({ disabled, somenteAtivo, value, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const formatarMontador = (montador) => {
        return `${montador.nome} - ${formatarDocumento(montador.identificacao)}`;
    };

    const itemMontadorTemplate = (item) => {
        return <div>{formatarMontador(item)}</div>;
    };

    const montadorSelecionadoTemplate = (option) => {
        return formatarMontador(option);
    };

    const tagMontador = useMemo(() => {
        const tagPerfilMontador = getParam(PESSOAS_TAGPERFIL_PADRAO_MONTADOR);
        const tagPerfilMontadorAux = getParam(PESSOAS_TAGPERFIL_PADRAO_MONTADOR_AUXILIAR);
        const tags = [];
        if (tagPerfilMontador) tags.push(tagPerfilMontador.valor);
        if (tagPerfilMontadorAux) tags.push(tagPerfilMontadorAux.valor);
        if (tags.length) return tags.join(",");
        return null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tagMontador}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tagMontador]);

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagMontador || disabled}
                minCaracteresBusca={4}
                itemTemplate={itemMontadorTemplate}
                selectedItemTemplate={montadorSelecionadoTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do montador / auxiliar para buscar... (min 4 caracteres)"
                value={value}
                {...props}
            />
            {!!!tagMontador && (
                <small className="p-error">Falta parametrizar a identificação do montador / auxiliar.</small>
            )}
        </>
    );
};
