import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { SelecionarArquivoModalForm } from "./modals/selecionar";
import { ListaPendencias } from "./listagem";
import permissoes from "@/assets/constants/permissoes";
import { PageBase } from "@/components/PageBase";

export const ImportarProdutosPage = () => {
    const [exibirPendencias, setExibirPendencias] = useState(false);
    const selecaoRef = useRef();

    return (
        <PageBase>
            <h5>Importação de Produtos</h5>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_IMPORTARPRODUTOS_IMPORTAR]}
                componente={Button}
                icon="pi pi-upload"
                className="p-button-info p-mr-2"
                label="Carregar arquivo"
                onClick={() => selecaoRef.current?.abrir()}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_IMPORTARPRODUTOS_CONSULTAR]}
                componente={Button}
                icon="pi pi-info-circle"
                className="p-button-help p-mr-2"
                label="Importações pendentes"
                onClick={() => setExibirPendencias(!exibirPendencias)}
            />
            {exibirPendencias && (
                <div className="card">
                    <ListaPendencias exibir={exibirPendencias} />
                </div>
            )}
            <SelecionarArquivoModalForm ref={selecaoRef} />
        </PageBase>
    );
};
