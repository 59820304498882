import React, { useEffect } from "react";
import classNames from "classnames";
import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const FormDadosBasicos = ({ contaBancaria, aposSalvar = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();

    const { httpPost, httpPut } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            perfil: null,
            conta_financeira: null,
            banco: null,
            agencia: "",
            agencia_dv: "",
            numero_conta: "",
            numero_conta_dv: "",
            variacao: "",
            limite: 0,
            ativo: true,
            recebe_via_cartao: false,
            valor_taxa_pix: 0,
            api_cartao_id: null,
            api_pix_id: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.number()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("O campo 'perfil' é obrigatório."),
                conta_financeira: Yup.number().nullable(),
                banco: Yup.number().required("O campo 'banco' é obrigatório.").typeError("Selecione um banco."),
                agencia: Yup.string().required("O campo 'agência' é obrigatório."),
                agencia_dv: Yup.string().required("O campo 'agência (dígito verificador)' é obrigatório."),
                numero_conta: Yup.string().required("O campo 'conta' é obrigatório."),
                numero_conta_dv: Yup.string().required("O campo 'conta (dígito verificador)' é obrigatório."),
                variacao: Yup.string().required("O campo 'variação' é obrigatório."),
                valor_taxa_pix: Yup.number().when("recebe_via_cartao", {
                    is: (val) => !!val,
                    then: Yup.number()
                        .required("O campo 'taxa de pix' é obrigatório.")
                        .typeError("O campo 'taxa de pix' é obrigatório."),
                    otherwise: Yup.number().nullable().notRequired("O campo 'taxa de pix' é obrigatório."),
                }),
                // api_cartao_id: Yup.string().when("recebe_via_cartao", {
                //     is: (val) => !!val,
                //     then: Yup.string()
                //         .required("O campo 'Identificador de API de cartão' é obrigatório.")
                //         .typeError("O campo 'Identificador de API de cartão' é obrigatório."),
                //     otherwise: Yup.string()
                //         .nullable()
                //         .notRequired("O campo 'Identificador de API de cartão' é obrigatório."),
                // }),
                // api_pix_id: Yup.string().when("recebe_via_cartao", {
                //     is: (val) => !!val,
                //     then: Yup.string()
                //         .required("O campo 'Identificador de API de PIX' é obrigatório.")
                //         .typeError("O campo 'Identificador de API de PIX' é obrigatório."),
                //     otherwise: Yup.string()
                //         .nullable()
                //         .notRequired("O campo 'Identificador de API de PIX' é obrigatório."),
                // }),
            });

            await formSchema.validate(values, { abortEarly: false });
            const contaBancaria = {
                ...values,
                perfil: values.perfil,
            };
            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta bancária cadastrada com sucesso!",
                            life: 1500,
                        });
                        if (aposSalvar) aposSalvar(data);
                    },
                };
                showLoading();
                await httpPost({ url: "/financeiro/contas-bancarias/", body: contaBancaria }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta bancária alterada com sucesso!",
                            life: 1500,
                        });
                        if (aposSalvar) aposSalvar(data);
                    },
                };
                showLoading();
                await httpPut({ url: `/financeiro/contas-bancarias/${values.id}/`, body: contaBancaria }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeRecebeViaCartao = (e) => {
        if (!e.value) {
            setFieldValue("valor_taxa_pix", null);
            setFieldValue("api_cartao_id", null);
            setFieldValue("api_pix_id", null);
        }
        formik.handleChange(e);
    };

    useEffect(() => {
        if (contaBancaria) {
            const { banco, conta_financeira, perfil, ...rest } = contaBancaria;
            setValues({
                ...rest,
                banco: banco.id,
                conta_financeira: conta_financeira?.id,
                perfil: perfil.id,
            });
        }
    }, [setValues, contaBancaria]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="perfil" label="Loja / Unidade" obrigatorio />
                    <MakoDropdownEmpresas
                        id="perfil"
                        name="perfil"
                        value={formik.values.perfil}
                        onChange={(e) => setFieldValue("perfil", e.id)}
                        showClear={false}
                        minCaracteresBusca={4}
                        autoFocus
                        urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa}&ativo=true&nome__unaccent__icontains="
                        className={classNames({ "p-invalid": formik.errors.perfil })}
                    />
                    {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="conta-financeira" label="Conta financeira" obrigatorio />
                    <Dropdown
                        id="conta-financeira"
                        name="conta_financeira"
                        placeholder="Selecione uma conta financeira"
                        url="/financeiro/contas-financeiras?limit=300"
                        showClear={false}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="banco" label="Banco" obrigatorio />
                    <Dropdown
                        id="banco"
                        name="banco"
                        placeholder="Selecione um banco"
                        url="/financeiro/bancos?limit=300"
                        showClear={false}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.banco}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.banco })}
                    />
                    {formik.errors.banco && <small className="p-error">{formik.errors.banco}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="agencia" label="Agência" obrigatorio />
                    <InputText
                        id="agencia"
                        name="agencia"
                        keyfilter={/^[0-9]/}
                        maxLength={8}
                        value={formik.values.agencia}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.agencia })}
                    />
                    {formik.errors.agencia && <small className="p-error">{formik.errors.agencia}</small>}
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="agencia-dv" label="DV" obrigatorio />
                    <InputText
                        id="agencia-dv"
                        name="agencia_dv"
                        keyfilter={/^[0-9]/}
                        maxLength={1}
                        tooltip="Dígito verificador da agência"
                        value={formik.values.agencia_dv}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.agencia_dv })}
                    />
                    {formik.errors.agencia_dv && <small className="p-error">{formik.errors.agencia_dv}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="numero-conta" label="Conta" obrigatorio />
                    <InputText
                        id="numero-conta"
                        name="numero_conta"
                        keyfilter={/^[0-9]/}
                        maxLength={32}
                        value={formik.values.numero_conta}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.numero_conta })}
                    />
                    {formik.errors.numero_conta && <small className="p-error">{formik.errors.numero_conta}</small>}
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="numero-conta-dv" label="DV" obrigatorio />
                    <InputText
                        id="numero-conta-dv"
                        name="numero_conta_dv"
                        keyfilter={/^[0-9]/}
                        maxLength={1}
                        tooltip="Dígito verificador da conta"
                        value={formik.values.numero_conta_dv}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.numero_conta_dv })}
                    />
                    {formik.errors.numero_conta_dv && (
                        <small className="p-error">{formik.errors.numero_conta_dv}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="variacao" label="Variação" obrigatorio />
                    <InputText
                        id="variacao"
                        name="variacao"
                        keyfilter={/^[0-9]/}
                        maxLength={3}
                        value={formik.values.variacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.variacao })}
                    />
                    {formik.errors.variacao && <small className="p-error">{formik.errors.variacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="limite" label="Limite" />
                    <MakoInputMoeda
                        id="limite"
                        name="limite"
                        valueMoeda={formik.values.limite}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.limite })}
                    />
                    {formik.errors.limite && <small className="p-error">{formik.errors.limite}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="recebe_via_cartao" label="Recebe via cartão" />
                    <SelectButton
                        id="recebe_via_cartao"
                        name="recebe_via_cartao"
                        value={formik.values.recebe_via_cartao}
                        onChange={onChangeRecebeViaCartao}
                        options={SIM_NAO_BOOLEAN}
                        optionLabel="label"
                        optionValue="id"
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="api_cartao_id" label="Identificador de API de cartão" obrigatorio={false} />
                    <Dropdown
                        id="api_cartao_id"
                        name="api_cartao_id"
                        url="/configuracoes/api-servicos/?limit=300&tipo_servico=PIX"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.api_cartao_id}
                        onChange={formik.handleChange}
                        disabled={!formik.values.recebe_via_cartao}
                        className={classNames({ "p-invalid": formik.errors.api_cartao_id })}
                    />
                    {formik.errors.api_cartao_id && <small className="p-error">{formik.errors.api_cartao_id}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="valor_taxa_pix" label="Taxa de PIX" obrigatorio={false} />
                    <MakoInputMoeda
                        id="valor_taxa_pix"
                        name="valor_taxa_pix"
                        valueMoeda={formik.values.valor_taxa_pix}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_taxa_pix })}
                    />
                    {formik.errors.valor_taxa_pix && <small className="p-error">{formik.errors.valor_taxa_pix}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="api_pix_id" label="Identificador de API de PIX" obrigatorio={false} />
                    <Dropdown
                        id="api_pix_id"
                        name="api_pix_id"
                        url="/configuracoes/api-servicos/?limit=300&tipo_servico=PIX"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.api_pix_id}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.api_pix_id })}
                    />
                    {formik.errors.api_pix_id && <small className="p-error">{formik.errors.api_pix_id}</small>}
                </div>
            </div>
            <CamposObrigatorios />
            <MakoActionsButtons>
                <Button
                    label={!!!formik.values.id ? "Incluir dados básicos" : "Gravar"}
                    icon={MAKO_ICONS.GRAVAR}
                    type="submit"
                    loading={loading}
                    className="p-button-info"
                />
            </MakoActionsButtons>
        </form>
    );
};
