import { dataToStr } from "@/assets/util/datas";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { Dropdown } from "@/components/Dropdown";
import { RELATORIO_ESTOQUE_SALDOGERAL } from "@/assets/constants/relatorios";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import MakoListagem from "@/components/MakoListagem";
import { ExportCSV } from "@/components/MakoBaseRelatorio/Buttons/exportCSV";
import { formatDocumento, gerarFileName } from "@/assets/util/util";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoInputFornecedor } from "@/components/MakoInputs";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const BASE_URL = "/relatorios/saldo-geral/";

const FILE_NAME = gerarFileName("Saldo Geral");

const FILTROS_VIEWSET = {
    sku: "sku",
    data_entrega_start: "data_hora__gte",
    data_entrega_end: "data_hora__lte",
    categoria: "categorias",
    ordenacao: "ordenacao",
    fornecedor: "fornecedor",
    apenas_movimentados: "apenas_movimentados",
};

const OPTIONS_ORDENACAO = [
    { label: "Código", value: "codigo" },
    { label: "Descrição", value: "descricao" },
];

export const RelatorioSaldoGeral = () => {
    const formRef = useRef();
    const categoriaRef = useRef();
    const { solicitarRelatorio } = useRelatorio();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const listagemRef = useRef();
    const botaoCsv = useRef();

    useClearRefs(formRef);

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (key === "apenas_movimentados") return (filtros[key] = dados[key]);
                if (dados[key]) return (filtros[key] = dados[key]);
            });
        }
        return filtros;
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                data_entrega_start: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Selecione uma 'data de início' válida."),
                data_entrega_end: Yup.date()
                    .when("data_entrega_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .nullable()
                            .required("Selecione uma 'data de final' válida.")
                            .min(
                                values.data_entrega_start || new Date(),
                                "A 'data final de entrega' não pode ser anterior a data inicial de entrega"
                            ),
                        otherwise: Yup.date().nullable(),
                    })
                    .default(null)
                    .typeError("Seleciona um 'final de data' válido."),
                sku: Yup.object().nullable().typeError("Você precisa buscar e selecionar um produto."),
                categoria: Yup.array().nullable().typeError("Informe 'categorias' válidas"),
                fornecedor: Yup.object().nullable().default(null).typeError("Informe um 'fornecedor' válida."),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data_entrega_start)
                dadosValidados.data_entrega_start = dataToStr(dadosValidados.data_entrega_start, "yyyy-MM-dd");
            if (dadosValidados.data_entrega_end)
                dadosValidados.data_entrega_end = dataToStr(dadosValidados.data_entrega_end, "yyyy-MM-dd");
            if (dadosValidados.sku) dadosValidados.sku = dadosValidados.sku.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_ESTOQUE_SALDOGERAL,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "" && v !== undefined) {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                    if (k === "apenas_movimentados") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        const selecionados = categoriaRef.current;
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.data_entrega_start)
            dadosValidados.data_entrega_start = dataToStr(dadosValidados.data_entrega_start, "yyyy-MM-dd");
        if (dadosValidados.data_entrega_end)
            dadosValidados.data_entrega_end = dataToStr(dadosValidados.data_entrega_end, "yyyy-MM-dd");
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_ESTOQUE_SALDOGERAL}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const filtrosTemplate = [
        {
            key: ["data_entrega_start", "data_entrega_end"],
            label: "Intervalo de data de entrega",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "sku",
            label: "Produto",
            type: TIPOS_FILTROS_TEMPLATE.SKU,
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "ordenacao",
            label: "Ordenação",
            options: OPTIONS_ORDENACAO,
            optionKey: "value",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "apenas_movimentados",
            label: "Apenas itens movimentados",
            options: SIM_NAO_BOOLEAN,
            optionKey: "id",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const colunas = [
        { field: "codigo", header: "Código" },
        { field: "descricao", header: "Descrição" },
        {
            field: "fornecedor_nome",
            header: "Fornecedor",
            action: ({ fornecedor_nome, fornecedor_identificacao }) => {
                if (!fornecedor_nome) return "";
                return `${fornecedor_nome} - ${formatDocumento(fornecedor_identificacao)}`;
            },
        },
        { field: "entrada_consolidada", header: "Entradas(C)", align: "right" },
        { field: "entrada_normal", header: "Entrada(N)", align: "right" },
        { field: "vendas", header: "Vendas", align: "right" },
        { field: "faturado", header: "Faturado", align: "right" },
        { field: "saldo_geral", header: "Saldo Geral", align: "right" },
        { field: "saldo_fiscal", header: "Saldo a faturar", align: "right" },
        { field: "custo_base", header: "Preço de custo", money: true },
        { field: "preco_base", header: "Preço de venda", money: true },
    ];

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    return (
        <>
            <R.Wrapper titulo={"de saldo geral"}>
                <MakoFormGerador
                    ref={formRef}
                    formikProps={{
                        initialValues: {
                            data_entrega_start: null,
                            data_entrega_end: null,
                            sku: null,
                            categorias: null,
                            fornecedor: null,
                            apenas_movimentados: false,
                        },
                        onSubmit: handleSubmit,
                    }}
                    camposFormularios={[
                        {
                            label: "Data Inicial",
                            inputId: "data_entrega_start",
                            inputName: "data_entrega_start",
                            component: MakoCalendar,
                            componentProps: {},
                            fieldSize: 2,
                        },
                        {
                            label: "Data Final",
                            inputId: "data_entrega_end",
                            inputName: "data_entrega_end",
                            component: MakoCalendar,
                            componentProps: {},
                            fieldSize: 2,
                        },
                        {
                            label: "Categoria",
                            inputId: "categoria",
                            inputName: "categoria",
                            component: MakoDropdownCategoriasHierarquicas,
                            componentProps: {
                                getCategoriaCompleta: getCategoriaCompleta,
                                showClear: true,
                                selectionMode: "checkbox",
                                metaKeySelection: false,
                            },
                            fieldSize: 4,
                        },
                        {
                            label: "Fornecedor",
                            inputId: "fornecedor",
                            inputName: "fornecedor",
                            component: MakoInputFornecedor,
                            fieldSize: 4,
                        },
                        {
                            label: "Produto",
                            inputId: "sku",
                            inputName: "sku",
                            required: false,
                            component: MakoBuscaSkuPersonalizada,
                            componentEspecial: COMPONENTES_ESPECIAIS.SKU,
                            fieldSize: 12,
                        },
                        {
                            label: "Ordenação",
                            inputId: "ordenacao",
                            inputName: "ordenacao",
                            component: Dropdown,
                            componentProps: {
                                placeholder: "Selecione",
                                options: OPTIONS_ORDENACAO,
                                optionLabel: "label",
                                optionValue: "value",
                            },
                            fieldSize: 2,
                        },
                        {
                            label: "Apenas itens movimentados",
                            inputId: "apenas_movimentados",
                            inputName: "apenas_movimentados",
                            component: Dropdown,
                            componentProps: {
                                placeholder: "Selecione",
                                options: SIM_NAO_BOOLEAN,
                                optionLabel: "label",
                                optionValue: "id",
                                showClear: false,
                            },
                            fieldSize: 2,
                        },
                    ]}
                >
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_ESTOQUE_SALDOGERAL}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </MakoFormGerador>
                <div className="p-mt-2">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={url}
                        msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                        botaoExportar={BotaoExportar}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            exportFilename: FILE_NAME,
                        }}
                    />
                </div>
            </R.Wrapper>
        </>
    );
};
