import React, { useRef } from "react";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import { TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/carteiras-contas-financeiras/";

export const FinanceiroCarteiraFinanceiraPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de carteira de contas financeiras"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/cadastros/carteira-conta-financeira/form",
                        state: { carteiras: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/carteiras-contas-financeiras"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="carteira de contas financeiras"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                {"Deseja realmente excluir a carteira de contas financeiras "}
                                <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/carteira-conta-financeira/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo),
            align: "center",
            style: { width: "4%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Carteiras de Contas Financeiras"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
