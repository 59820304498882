import React, { useState, useRef } from "react";

import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import useClearRefs from "@/hooks/useClearRefs";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { dataToStr } from "@/assets/util/datas";

const dateTemplate = (value) => {
    return dataToStr(value, "dd/MM/yyyy") || "";
};

export const CategoriasVinculadasPage = () => {
    const [grupo, setGrupo] = useState(null);
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "10%" }, expander: true },
        { field: "nome", header: "Nome", style: { minWidth: "30%" } },
        { field: "grupo.descricao", header: "Grupo", style: { minWidth: "30%" } },
        {
            field: "vigencia_inicial",
            header: "Vigência Inicial",
            action: ({ data }) => dateTemplate(data?.vigencia_inicial),
        },
        {
            field: "vigencia_final",
            header: "Vigência Final",
            action: ({ data }) => dateTemplate(data?.vigencia_final),
        },
    ];

    const selecionaGrupo = (grupo) => {
        setGrupo(grupo);
    };

    const cabecalhoTabela = (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="grupo">Grupo *</label>
                    <Dropdown
                        id="grupo"
                        name="grupo"
                        placeholder="Selecione um grupo"
                        url="/produtos/categorias-grupos/"
                        optionValue="id"
                        optionLabel="descricao"
                        value={grupo}
                        onChange={(e) => selecionaGrupo(e.value)}
                    />
                </div>
            </div>
        </>
    );

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                urlPesquisa={grupo ? `/produtos/categorias-menu?grupo_id=${grupo}` : null}
                colunas={colunas}
                cabecalho={cabecalhoTabela}
            />
        </PageBase>
    );
};
