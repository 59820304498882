import React, { useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { FiltroAvancadoServicos } from "./filtroAvancado";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/servicos/servicos/?template=true";

export const ServicosPage = () => {
    const [desativarDialog, setDesativarDialog] = useState(false);
    const [url, setUrl] = useState(BASE_URL);
    const [servico, setServico] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const menuAcoesRef = useRef(null);
    const listagemRef = useRef();
    const history = useHistory();
    const { httpPatch } = useHttp();
    const filtroRef = useRef();

    useClearRefs(menuAcoesRef, listagemRef);

    const editarStatus = async (situacao) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Status alterado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        showLoading();
        await httpPatch(
            {
                url: `/servicos/servicos/${servico.id}/`,
                body: {
                    ativo: situacao,
                },
            },
            handlers
        );
        hideLoading();
    };

    const botoesMenu = [
        {
            label: "Ações",
            items: [
                {
                    label: "Editar",
                    icon: "pi pi-pencil",
                    command: () =>
                        history.push({
                            pathname: "/servicos/servicos/servico/form",
                            state: { servico: servico },
                        }),
                },
                {
                    label: "Ativar",
                    icon: "pi pi-check",
                    disabled: servico?.ativo,
                    command: () => editarStatus(true),
                },
                {
                    label: "Desativar",
                    icon: "pi pi-times",
                    disabled: !servico?.ativo,
                    command: () => setDesativarDialog(true),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_SERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2"
                    model={botoesMenu}
                    tooltipOptions={{ position: "left" }}
                    onClick={(event) => {
                        menuAcoesRef?.current.toggle(event);
                        setServico(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_SERVICO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/servicos/servicos/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o serviço <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O serviço não pode ser excluído."
                    tooltip="Deletar serviço"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.SERVICOS_SERVICOS_SERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                disabled={!empresaSelecionadaId}
                className="p-button-success p-mr-2"
                onClick={() => history.push("/servicos/servicos/servico/form")}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => filtroRef.current?.setVisivel(true)}
            />
        </>
    );

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Ativo" },
                        { value: false, label: "Desativado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            style: { minWidth: "250px" },
        },
        {
            field: "tipo",
            header: "Tipo",
            action: ({ tipo }) => {
                return TIPOS_CHOICE_SERVICOS_SERVICO.find(({ value }) => value === tipo).label;
            },
            style: { minWidth: "60px" },
        },
        {
            field: "ativo",
            header: "Situação",
            style: { minWidth: "50px" },
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            filter: true,
            filterElement: StatusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "100px" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const onConfirmFiltro = (newUrl) => {
        setUrl(newUrl);
        listagemRef.current?.buscarDados();
    };

    const esconderDesativarDialog = () => {
        setDesativarDialog(false);
        setServico(null);
    };

    return (
        <PageBase>
            <Menu model={botoesMenu} popup ref={menuAcoesRef} id="popup_menu_acoes" />
            <MakoListagem
                ref={listagemRef}
                titulo="Catálogo de Serviços"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
            <FiltroAvancadoServicos onConfirm={onConfirmFiltro} url={BASE_URL} ref={filtroRef} />
            <ConfirmDialog
                visible={desativarDialog}
                onHide={esconderDesativarDialog}
                header="Confirmação"
                message={
                    servico && (
                        <span>
                            {"Deseja realmente desativar o serviço de código "}
                            <b>{servico.codigo}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => editarStatus(false)}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDesativarDialog}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
