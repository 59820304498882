import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { ModalCentroEstocagem } from "./modalCentroEstocagem";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";

const url = "/produtos/estoques-empresas/";
const urlvoltar = "/estoque/cadastros/estoques-empresas";

export const EstoquesForm = () => {
    const [estoqueId, setEstoqueId] = useState(null);
    const listagemRef = useRef(null);
    const modalCentroEstocagemRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionada } = useEmpresa();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionada.id,
            nome: "",
            descricao: "",
            endereco: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                endereco: Yup.string()
                    .required("O campo 'endereço' é obrigatório.")
                    .typeError("Informe um 'endereço' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            delete values.centroestocagem_set;
            if (!values.id) {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Estoque cadastrado com sucesso!",
                            life: 1500,
                        });
                        setEstoqueId(data.id);
                    },
                };

                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Estoque alterado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };

                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const enderecoTemplate = (option) => {
        return <span>{`${option.logradouro} - ${option.numero} - ${option.bairro} - ${option.cep}`}</span>;
    };

    const enderecoSelecionadoTemplate = (option, props) => {
        if (option) return enderecoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    useEffect(() => {
        if (state?.estoque) {
            const { estoque } = state;
            setEstoqueId(estoque.id);
            setValues({
                ...estoque,
                endereco: estoque.endereco.id,
            });
        }
    }, [state, setValues]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/centros-estocagem/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o centro de estocagem <b>{rowData.nome}</b>?
                        </span>
                    }
                    classNames="p-mr-2"
                    tooltip="Deletar centro de estocagem"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O centro de estocagem não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    type="button"
                    className="p-button-rounded p-button-warning"
                    onClick={() => modalCentroEstocagemRef.current?.abrirModal(estoqueId, rowData)}
                />
            </div>
        );
    };

    const reservaBodyTemplate = (rowData) => {
        if (rowData.reserva) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_INCLUIR]}
                componente={Button}
                disabled={!!!estoqueId}
                label="Adicionar centro estocagem"
                icon="pi pi-plus"
                type="button"
                className="p-button-success"
                onClick={() => modalCentroEstocagemRef.current?.abrirModal(estoqueId)}
            />
        </>
    );

    const colunas = [
        { field: "nome", header: "Nome" },
        { field: "descricao", header: "Descrição" },
        { field: "data_saldo_inicial", header: "Data do saldo inicial", dateFormat: "dd/MM/yyyy" },
        { field: "reserva", header: "É reserva", action: (e) => reservaBodyTemplate(e) },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo estoque" : "Manutenção de estoque"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            autoComplete="off"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="endereco">Endereço *</label>
                        <Dropdown
                            id="endereco"
                            name="endereco"
                            placeholder="Selecione"
                            options={empresaSelecionada.enderecoperfil_set}
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="logradouro"
                            itemTemplate={enderecoTemplate}
                            valueTemplate={enderecoSelecionadoTemplate}
                            value={formik.values.endereco}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.endereco })}
                        />
                        {formik.errors.endereco && <small className="p-error">{formik.errors.endereco}</small>}
                    </div>
                </div>
                <MakoListagem
                    ref={listagemRef}
                    urlPesquisa={estoqueId && `/produtos/centros-estocagem/?estoque_empresa__id=${estoqueId}`}
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    configTabela={{
                        paginator: true,
                    }}
                />
                <br />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={cancelar}
                    />
                </div>
            </form>
            <ModalCentroEstocagem ref={modalCentroEstocagemRef} onClose={() => listagemRef.current?.buscarDados()} />
        </PageBase>
    );
};
