import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import { dataToStr } from "@/assets/util/datas";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

export const SaldoInicialSKUForm = () => {
    const [centrosEstocagem, setCentrosEstocagem] = useState([]);
    const [quantidadeMinima, setQuantidadeMinima] = useState(0.001);
    const [tipoMovEstoqueSaldoInicial, setTipoMovEstoqueSaldoInicial] = useState(null);
    const [loading, showLoadingLocal, hideLoadingLocal] = useLoadingLocal();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpGet, httpPost, httpPut } = useHttp();
    const { state } = useLocation();
    const history = useHistory();

    const initialSaldo = {
        sku: null,
        codigo: "",
        descricao: "",
        unidade_medida: "",
        centro_estocagem: null,
        data_hora: null,
        cubagem: 1,
        quantidade: 0,
        valor: 0,
        custo_medio: 0,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: initialSaldo,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .test(
                        "saldo",
                        "Este 'produto' ja possui saldo informado para este 'centro de estocagem'!",
                        async (v) => {
                            if (values.id) return true;
                            return await verificarExistenciadeSaldo({
                                centro_estocagemId: values.centro_estocagem,
                                sku: v,
                            });
                        }
                    )
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                centro_estocagem: Yup.number()
                    .typeError("Selecione um centro de estocagem.")
                    .required("Selecione um centro de estocagem."),
                cubagem: Yup.number().min(0.00001, "A dimensão não pode ser ZERO."),
                quantidade: Yup.number()
                    .min(quantidadeMinima, `O campo 'quantidade' não pode ser menor que: ${quantidadeMinima}.`)
                    .required("O campo 'quantidade' não pode ser ZERO."),
                valor: Yup.number().min(0.01, "O valor não pode ser ZERO.").required("O campo 'valor' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { estoque_empresa } = centrosEstocagem.find((ce) => ce.id === values.centro_estocagem);
            const saldoInicial = {
                ...values,
                sku: values.sku.id,
                unidade_medida: values.sku.unidade_padrao.id,
                tipo_movimentacao: tipoMovEstoqueSaldoInicial.id,
                data_hora: dataToStr(values.data_hora, "yyyy-MM-dd 00:00:00"),
                custo_medio: formatarCasasDecimais(values.custo_medio),
                evento_id: 0,
                estoque_empresa: estoque_empresa.id,
                processada: false,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Saldo inicial lançado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                    },
                    400: ({ err }) => {
                        showWarning({
                            summary: "Aviso",
                            detail: err.message || "Não foi possível finalizar a requisição.",
                            life: 3000,
                        });
                    },
                };

                showLoading();
                await httpPost({ url: "/produtos/movimentacoes-estoques-sku/", body: saldoInicial }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Saldo inicial alterado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPut(
                    { url: `/produtos/movimentacoes-estoques-sku/${values.id}/`, body: saldoInicial },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const { unidade_padrao } = formik.values.sku;
            setFieldValue("unidade_medida", unidade_padrao.nome);
        }
    }, [formik.values.sku, setFieldValue]);

    const definirDataSaldoInicial = useCallback(() => {
        if (formik.values.centro_estocagem) {
            const centro = centrosEstocagem.find((ce) => ce.id === formik.values.centro_estocagem);
            if (centro) {
                setFieldValue("data_hora", centro.data_saldo_inicial);
            }
        }
    }, [formik.values.centro_estocagem, setFieldValue, centrosEstocagem]);

    const getTipoMovEstoqueSaldoInicial = useCallback(
        async (signal) => {
            const handlers = {
                200: ({ data }) => {
                    const { results } = data;
                    if (results.length > 0) {
                        setTipoMovEstoqueSaldoInicial(results[0]);
                    } else {
                        showWarning({
                            summary: "Aviso!",
                            detail: "Você não possui um tipo de movimentação de estoque para SALDO INICIAL.",
                            life: 5000,
                        });
                    }
                },
            };

            await httpGet(
                { url: "/produtos/tipos-movimentacoes-estoques?classe_movimentacao=10&limit=1", signal },
                handlers
            );
        },
        [showWarning, httpGet]
    );

    const calcularCustoMedio = useCallback(() => {
        if (formik.values.quantidade > 0 && formik.values.valor > 0) {
            const custoMedio = (formik.values.valor / formik.values.quantidade) * formik.values.cubagem;
            setFieldValue("custo_medio", custoMedio);
        }
    }, [formik.values.quantidade, formik.values.valor, formik.values.cubagem, setFieldValue]);

    const voltarParaListagem = () => {
        history.push("/estoque/movimentacoes/saldo-inicial-sku");
    };

    const centroEstocagemTemplate = (option) => {
        const tipoCE = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === option.tipo);
        return <span>{`${option.nome} [${tipoCE ? tipoCE.label : "Desconhecido"}]`}</span>;
    };

    const buscarSaldoNormal = useCallback(
        async (signal) => {
            const centro_estocagem = centrosEstocagem.find(({ id }) => id === formik.values.centro_estocagem);
            const sku = formik.values.sku;
            let saldo_minimo = !sku?.permite_fracionamento ? 1 : 0.001;
            if (centro_estocagem?.tipo === "C" && typeof sku === "object") {
                showLoadingLocal();

                const handlers = {
                    200: async ({ data }) => {
                        if (data?.results?.length > 0) {
                            try {
                                const centro_normal = data.results[0];
                                const params = {
                                    estoque_empresa: centro_normal.estoque_empresa.id,
                                    centro_estocagem: centro_normal.id,
                                    sku: sku.id,
                                };

                                const saldoHandlers = {
                                    200: ({ data }) => {
                                        saldo_minimo = data.results.length
                                            ? parseNumber(data.results[0].quantidade)
                                            : 0;
                                    },
                                };

                                await httpGet({ url: `/produtos/saldos-sku/`, params }, saldoHandlers);
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    },
                };

                await httpGet(
                    { url: `/produtos/centros-estocagem/?estoque_empresa__id=${centro_estocagem.id}&tipo=N`, signal },
                    handlers
                );
                hideLoadingLocal();
            }
            setQuantidadeMinima(saldo_minimo);
        },
        [
            formik.values.centro_estocagem,
            formik.values.sku,
            showLoadingLocal,
            hideLoadingLocal,
            centrosEstocagem,
            httpGet,
        ]
    );

    const verificarExistenciadeSaldo = useCallback(
        async ({ centro_estocagemId, sku }) => {
            let existe = true;
            const { id } = centrosEstocagem.find(({ id }) => id === centro_estocagemId);
            if (!tipoMovEstoqueSaldoInicial) return existe;
            const params = {
                tipo_movimentacao: tipoMovEstoqueSaldoInicial.id,
                centro_estocagem: id,
                sku__id: sku.id,
            };

            const handlers = {
                200: ({ data }) => {
                    existe = !data.results.length;
                },
            };

            showLoadingLocal();
            await httpGet({ url: `/produtos/movimentacoes-estoques-sku/`, params }, handlers);
            hideLoadingLocal();

            return existe;
        },
        [showLoadingLocal, hideLoadingLocal, centrosEstocagem, tipoMovEstoqueSaldoInicial, httpGet]
    );

    useEffect(() => {
        const controller = new AbortController();
        buscarSaldoNormal(controller.signal);
        return function clear() {
            controller.abort();
        };
    }, [buscarSaldoNormal]);

    useEffect(() => {
        const controller = new AbortController();
        getTipoMovEstoqueSaldoInicial(controller.signal);
        return function clear() {
            controller.abort();
        };
    }, [getTipoMovEstoqueSaldoInicial]);

    useEffect(() => calcularCustoMedio(), [calcularCustoMedio]);

    useEffect(() => definirDataSaldoInicial(), [definirDataSaldoInicial]);

    useEffect(() => setInformacoesItem(), [setInformacoesItem]);

    useEffect(() => {
        if (state?.saldo) {
            setValues({
                id: state.saldo.id,
                sku: state.saldo.sku,
                codigo: "",
                descricao: "",
                unidade_medida: state.saldo.unidade_medida,
                centro_estocagem: state.saldo.centro_estocagem.id,
                data_hora: state.saldo.data_hora,
                cubagem: state.saldo.cubagem,
                quantidade: state.saldo.quantidade,
                valor: state.saldo.valor,
                custo_medio: state.saldo.custo_medio,
            });
        }
    }, [state, setValues]);

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Novo lançamento de saldo inicial de produto"
                    : "Manutenção de saldo inicial de produto"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="unidade-medida">Unidade de medida</label>
                        <InputText
                            id="unidade-medida"
                            name="unidade_medida"
                            disabled
                            value={formik.values.unidade_medida}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="centro-estocagem">Centro de estocagem *</label>
                        <Dropdown
                            id="centro-estocagem"
                            name="centro_estocagem"
                            url="/produtos/centros-estocagem?query={id,estoque_empresa,nome,data_saldo_inicial,tipo}&limit=100&tipo__in=C,N"
                            disabled={!!formik.values.id}
                            setObjects={setCentrosEstocagem}
                            itemTemplate={centroEstocagemTemplate}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            autoFocus
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                        />
                        {formik.errors.unidade_medida && (
                            <small className="p-error">{formik.errors.unidade_medida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="data-hora">Data de lançamento</label>
                        <MakoCalendar id="data-hora" name="data_hora" value={formik.values.data_hora} disabled />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cubagem">Cubagem *</label>
                        <InputNumber
                            inputId="cubagem"
                            name="cubagem"
                            mode="decimal"
                            disabled={!formik.values.sku?.movimenta_cubagem}
                            minFractionDigits={6}
                            maxFractionDigits={6}
                            value={formik.values.cubagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cubagem })}
                        />
                        {formik.errors.cubagem && <small className="p-error">{formik.errors.cubagem}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            disabled={loading}
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor">Valor *</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="custo-medio">Custo unitário</label>
                        <MakoInputMoeda
                            id="custo-medio"
                            name="custo_medio"
                            disabled
                            valueMoeda={formik.values.custo_medio}
                            className={classNames({ "p-invalid": formik.errors.custo_medio })}
                        />
                        {formik.errors.custo_medio && <small className="p-error">{formik.errors.custo_medio}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        label="Gravar"
                        icon="pi pi-save"
                        type="submit"
                        disabled={!!!tipoMovEstoqueSaldoInicial}
                        loading={loading}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
