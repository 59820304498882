import React, { useCallback, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCrediario from "@/hooks/useCrediario";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { DataScroller } from "primereact/datascroller";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ValidarDadosModalForm } from "./modal/validarDadosModalForm";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Divider } from "primereact/divider";
import useToast from "@/hooks/useToast";

export const DadosComplementaresForm = () => {
    const [dadosComplementares, setDadosComplementares] = useState(null);
    const [exibirObservacoes, setExibirObservacoes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exibirDesativarDados, setExibirDesativarDados] = useState(false);
    const { crediario, handleDadosComplementares } = useCrediario();
    const { showSuccess, showError } = useToast();
    const validaRef = useRef(null);

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            nome: crediario?.nome,
            endereco: null,
            telefone: null,
            email: null,
            ativo: true,
            observacoes: "",
            op: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                endereco: Yup.string().required("O campo 'endereço' é obrigatório."),
                telefone: Yup.string().required("O campo 'telefone' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            setLoading(true);
            handleDadosComplementares(values.op, values);
            resetForm();
            showSuccess({
                summary: "Sucesso!",
                detail: "Dados complementares adicionados com sucesso.",
                life: 3000,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    function handleDesativar() {
        try {
            setLoading(true);
            handleDadosComplementares("editar", { ...dadosComplementares, op: "editar", ativo: false });
            esconderDialogDesativar();
            showSuccess({
                summary: "Sucesso!",
                detail: "Dado complementar desativado com sucesso.",
                life: 3000,
            });
            setTimeout(() => {
                setLoading(false);
            }, [200]);
        } catch (error) {
            setLoading(false);
            showError();
        }
    }

    const esconderDialogDesativar = () => {
        setDadosComplementares(null);
        setExibirDesativarDados(false);
    };

    const referenciaTemplate = useCallback((data) => {
        return (
            <div className="product-list-item">
                <div className="product-list-detail">
                    <span className={`product-badge status-${data.ativo ? "instock" : "outofstock"}`}>
                        <i className={`pi pi-home product-category-icon p-mt-0`} />
                        {data.nome}
                    </span>
                    <div className="product-name">{`${data.endereco}`}</div>
                    <div className="product-description">{`${data?.email || "-"}`}</div>
                    <i className="pi pi-phone product-category-icon" />
                    <span className="product-category">{data.telefone}</span>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-check"
                                className="p-button-rounded p-button-info p-mr-2"
                                tooltip="Validar"
                                tooltipOptions={{ position: "bottom" }}
                                disabled={data.validado || !data.ativo}
                                onClick={() => validaRef.current?.abrir(data)}
                            />
                            <Button
                                icon="pi pi-book"
                                className="p-button-rounded p-button-help p-mr-2"
                                tooltip="Exibir observações"
                                tooltipOptions={{ position: "bottom" }}
                                disabled={!data.ativo}
                                onClick={() => {
                                    setDadosComplementares(data);
                                    setExibirObservacoes(true);
                                }}
                            />
                            <Button
                                icon="pi pi-times"
                                className="p-button-rounded p-button-danger"
                                tooltip="Desativar"
                                tooltipOptions={{ position: "bottom" }}
                                disabled={!data.ativo}
                                onClick={() => {
                                    setDadosComplementares(data);
                                    setExibirDesativarDados(true);
                                }}
                            />
                        </div>
                        {data.validado ? (
                            <div className="p-text-right">
                                <span className="product-badge">
                                    <b>{`Validado pelo usuário: ${data.usuario_verificacao?.nome}`}</b>
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }, []);

    const formataEndereco = (dados) => {
        return dados.map((endereco) => {
            return {
                ...endereco,
                label: `${endereco.cidade.nome} - ${endereco.cidade.estado.uf}: ${endereco.logradouro} - ${endereco.numero} - ${endereco.bairro} (${endereco.cep})`,
            };
        });
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome">Nome</label>
                        <InputText id="nome" name="nome" value={formik.values.nome} readOnly disabled />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="endereco">Endereço *</label>
                        <Dropdown
                            id="endereco"
                            name="endereco"
                            placeholder="Selecione um endereço"
                            url={`/pessoas/enderecos-perfis?perfil__id=${crediario?.perfil_titular?.id}`}
                            filter
                            filterBy="label"
                            optionValue="label"
                            optionLabel="label"
                            aposBuscar={formataEndereco}
                            value={formik.values.endereco}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.endereco })}
                        />
                        {formik.errors.endereco && <small className="p-error">{formik.errors.endereco}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="email">Email</label>
                        <Dropdown
                            id="email"
                            name="email"
                            placeholder="Selecione um email"
                            url={`/pessoas/emails-perfis?perfil__id=${crediario?.perfil_titular?.id}`}
                            filter
                            filterBy="email"
                            optionValue="email"
                            optionLabel="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.email })}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="telefone">Telefone *</label>
                        <Dropdown
                            id="telefone"
                            name="telefone"
                            placeholder="Selecione um telefone"
                            url={`/pessoas/telefones-perfis?perfil__id=${crediario?.perfil_titular?.id}`}
                            filter
                            filterBy="telefone"
                            optionValue="telefone"
                            optionLabel="telefone"
                            value={formik.values.telefone}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.telefone })}
                        />
                        {formik.errors.telefone && <small className="p-error">{formik.errors.telefone}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacoes">Observações</label>
                        <InputTextarea
                            id="observacoes"
                            name="observacoes"
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                            rows={5}
                            autoComplete="off"
                            autoFocus
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Adicionar"
                            className="p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                    </div>
                </div>
            </form>
            <div className="list-demo">
                {!loading ? (
                    <DataScroller
                        value={
                            crediario?.referenciacrediarioperfil_set
                                ? [...crediario.referenciacrediarioperfil_set].reverse()
                                : []
                        }
                        itemTemplate={referenciaTemplate}
                        rows={3}
                        inline
                        scrollHeight="300px"
                        header="Dados Informados"
                        emptyMessage="Nenhum dado complementar registrado anteriormente."
                    />
                ) : (
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "8rem" }}></i>
                )}
            </div>
            <ConfirmDialog
                visible={exibirDesativarDados}
                onHide={esconderDialogDesativar}
                header="Confirmação"
                message={<span>{"Deseja realmente desativar essa informação?"}</span>}
                icon="pi pi-info-circle p-mr-3"
                accept={handleDesativar}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDialogDesativar}
                rejectLabel="Não"
            />
            <Dialog
                header="Observações"
                breakpoints={{ "1080px": "90vw" }}
                style={{ width: "50vw", display: "block" }}
                visible={exibirObservacoes}
                onHide={() => {
                    setDadosComplementares(null);
                    setExibirObservacoes(false);
                }}
            >
                <label>{dadosComplementares?.observacoes || "Sem observações"}</label>
                {dadosComplementares?.validado && (
                    <>
                        <Divider>Validado</Divider>
                        <b>{dadosComplementares.nota_verificacao}</b>
                    </>
                )}
            </Dialog>
            <ValidarDadosModalForm ref={validaRef} />
        </PageBase>
    );
};
