import React, { useMemo } from "react";

import classNames from "classnames";

import { InputText } from "primereact/inputtext";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Label } from "@/components/Label";

import { InputDesconto, InputJuros, InputMulta } from "../../inputs";

import { FINANCEIRO_RECEBIMENTO_RECEBER_JUROSMULTAS } from "@/assets/constants/parametros";
import permissoes from "@/assets/constants/permissoes";

import useParam from "@/hooks/useParam";
import useAuth from "@/hooks/useAuth";

const BASE_VALUES = {
    documento: "",
    parcela: "",
    data_emissao: null,
    vencimento: null,
    dias: null,
    valor: null,
    multa: null,
    recebido: null,
};

const BASE_ERROS = {
    descontos_percent: null,
    multa: null,
    juros: null,
};

export const Parcela = ({
    values = BASE_VALUES,
    errors = BASE_ERROS,
    handleSubmit = () => {},
    setFieldValue = () => {},
    setFieldError = () => {},
}) => {
    const { verifyPermission } = useAuth();
    const { getParam } = useParam();

    const alterarJuros = useMemo(() => {
        const parametro = getParam(FINANCEIRO_RECEBIMENTO_RECEBER_JUROSMULTAS);
        const valorParametro = parametro ? parametro.valor === "1" : null;
        const permissaoEditar = verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR_ACRESCIMOS]);
        return valorParametro || permissaoEditar;
    }, [getParam, verifyPermission]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="documento" label="Documento" />
                    <InputText id="documento" name="documento" disabled value={values.documento} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText id="parcela" name="parcela" disabled value={values.parcela} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-emissao" label="Emissão" />
                    <MakoCalendar id="data-emissao" name="data_emissao" disabled value={values.data_emissao} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-vencimento" label="Vencimento" />
                    <MakoCalendar id="data-vencimento" name="vencimento" disabled value={values.vencimento} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="dias" label="Dias de atraso" />
                    <InputText id="dias" name="dias" disabled value={values.dias} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="original" label="Valor original" />
                    <MakoInputMoeda id="original" disabled valueMoeda={values.valor} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total-descontos" label="Descontos" />
                    <InputDesconto values={values} errors={errors} setFieldValue={setFieldValue} />
                    {errors.descontos_percent && <small className="p-error">{errors.descontos_percent}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total-multa" label="Valor da multa" />
                    <InputMulta
                        id="total-multa"
                        valueMoeda={values.multa}
                        valuesCalculo={values}
                        setFieldError={setFieldError}
                        disabled={!alterarJuros}
                        onChange={(e) => setFieldValue("multa", e.value)}
                        className={classNames({ "p-invalid": errors.multa })}
                    />
                    {errors.multa && <small className="p-error">{errors.multa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total-juros" label="Valor dos juros" />
                    <InputJuros
                        id="total-juros"
                        valueMoeda={values.juros}
                        valuesCalculo={values}
                        setFieldError={setFieldError}
                        onChange={(e) => setFieldValue("juros", e.value)}
                        disabled={!alterarJuros}
                        className={classNames({ "p-invalid": errors.juros })}
                    />
                    {errors.juros && <small className="p-error">{errors.juros}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="total-recebimento" label="Total a receber" />
                    <MakoInputMoeda id="total-recebimento" disabled valueMoeda={values.recebido} />
                </div>
            </div>
        </form>
    );
};
