import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { EnvioEmailFormModal } from "./modal/formEmailModal";
import { CancelaNotaFormModal } from "./modal/formCancelaModal";
import { CartaCorrecaoFormModal } from "./modal/formCartaCorrecao";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalFiltroAvancadoNf } from "./modal/formFiltroAvancado";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { key_filtros } from "@/assets/constants/filtros";
import useToast from "@/hooks/useToast";
import { ContingenciaFormModal } from "./modal/formContingenciaModal";
import { parseData } from "@/assets/util/datas";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import { ModalImpressoras } from "@/components/ModalImpressoras";
import { PageBase } from "@/components/PageBase";
import { MakoSituacaoSefaz } from "@/components/MakoSituacaoSefaz";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const ListaNotaFiscalPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.FISCAL_NOTA_FISCAL);
    const [notaFiscal, setNotaFiscal] = useState(null);
    const [cancelDialog, setCancelDialog] = useState(false);
    const [emissaoDialog, setEmissaoDialog] = useState(false);
    const [impressaoDialog, setImpressaoDialog] = useState(false);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { verifyPermission } = useAuth();
    const { httpPatch, httpPut, httpGet } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const modalImpressorasRef = useRef(null);
    const BASE_URL = `/fiscal/notas-fiscais?query={id, destinatario, numero, serie, modelo, status, valor_total_nf, chave_nf, protocolo, datahora_emissao}`;

    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}&${filtroString}`;
        return BASE_URL;
    });

    const { showWarning, showError, showSuccess } = useToast();

    const menu = useRef(null);
    const listagemRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const history = useHistory();
    const emailRef = useRef(null);
    const cancelaRef = useRef(null);
    const cartaRef = useRef(null);
    const contingenciaRef = useRef(null);

    useClearRefs(listagemRef, modalFiltroAvancadoRef, menu);

    async function transmitirDocumento() {
        try {
            const handlers = {
                200: () => {
                    setImpressaoDialog(true);
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nota fiscal transmitida com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: ({ err }) => {
                    const { msg, motivo } = err;
                    showError({
                        summary: "Erro",
                        detail: motivo
                            ? `${msg} Motivo(s): ${
                                  typeof motivo === "object"
                                      ? motivo.error?.errors
                                          ? motivo.error.errors.map(({ message }) => message).join("; ")
                                          : motivo.error.message
                                      : motivo
                              }`
                            : "Falha ao transmitir documento fiscal.",
                        life: 9000,
                    });
                    listagemRef.current?.buscarDados();
                },
            };

            showLoading();
            await httpPut({ url: `/fiscal/transmitir-nota-fiscal/${notaFiscal?.id}/`, body: {} }, handlers);
            hideLoading();
        } catch (error) {
            hideLoading();
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const downloadNota = useCallback(
        async (nota, formato) => {
            try {
                const handlers = {
                    200: ({ data }) => {
                        const { documento } = data;
                        if (formato === "pdf") {
                            let objbuilder = "";
                            objbuilder += '<object width="100%" height="100%" data="data:application/pdf;base64,';
                            objbuilder += documento;
                            objbuilder += '" type="application/pdf" class="internal">';
                            objbuilder += '<embed src="data:application/pdf;base64,';
                            objbuilder += documento;
                            objbuilder += '" type="application/pdf"  />';
                            objbuilder += "</object>";
                            let win = window.open("#", "_blank");
                            let title = "SysMako - ERP";
                            win.document.write(
                                "<html><title>" +
                                    title +
                                    '</title><body style="margin-top:0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">'
                            );
                            win.document.write(objbuilder);
                            win.document.write("</body></html>");
                        } else {
                            let file = new Blob([documento], {
                                type: `application/xml`,
                            });
                            let fileURL = URL.createObjectURL(file);
                            window.open(fileURL);
                        }
                    },
                    400: ({ err }) => {
                        const { msg, motivo } = err;
                        showError({
                            summary: "Erro",
                            detail: motivo
                                ? `${msg} Motivo(s): ${
                                      typeof motivo === "object"
                                          ? motivo.error?.errors
                                              ? motivo.error.errors.map(({ message }) => message).join("; ")
                                              : motivo.error.message
                                          : motivo
                                  }`
                                : "Falha ao imprimir documento fiscal.",
                            life: 9000,
                        });
                        listagemRef.current?.buscarDados();
                    },
                };

                showLoading();
                await httpGet({ url: `/fiscal/imprimir-documento/${nota?.id}/${formato}` }, handlers);
                hideLoading();
            } catch (error) {
                hideLoading();
                showError({
                    summary: "Erro",
                    detail: `Falha ao realizar o download do ${formato.toUpperCase()}, por favor tente novamente mais tarde.`,
                    life: 3000,
                });
            }
        },
        [httpGet, showError, showLoading, hideLoading]
    );

    const verificaEmissao = (chave) => {
        if (chave) return `${chave.slice(0, 4)}...${chave.slice(40, 44)}`;
        else return "Não processado";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "Não transmitido";
    };

    const botoesEmissao = [
        {
            label: "Operações NF",
            items: [
                {
                    label: "Copiar chave de acesso",
                    icon: "pi pi-copy",
                    disabled: !notaFiscal?.chave_nf,
                    command: () =>
                        window.prompt("Copie para área de transferência: Ctrl+C e tecle Enter", notaFiscal.chave_nf),
                },
                {
                    label: "Transmitir",
                    icon: "pi pi-arrow-up",
                    disabled: notaFiscal?.protocolo || notaFiscal?.status === "C" || notaFiscal?.status === "T",
                    command: () => setEmissaoDialog(true),
                },
                {
                    label: "Carta de correção",
                    icon: "pi pi-book",
                    disabled: !notaFiscal?.protocolo || notaFiscal?.status === "C" || notaFiscal?.modelo !== "55",
                    command: () => cartaRef.current?.setVisible(true),
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: notaFiscal?.status === "C",
                    command: () => setCancelDialog(true),
                },
                {
                    label: "Baixar XML",
                    icon: "pi pi-download",
                    disabled: !verifyPermission([permissoes.FISCAL_NOTASAIDA_EMITIR]) || !notaFiscal?.protocolo,
                    command: () => downloadNota(notaFiscal, "xml"),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota fiscal"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.protocolo || rowData.status === "C" || loading}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EMITIR]}
                    model={botoesEmissao}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações NF"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-book"
                    disabled={loading}
                    onClick={(event) => {
                        setNotaFiscal(rowData);
                        menu.current.toggle(event);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-print"
                    className="p-button-rounded p-mr-2 p-mb-1"
                    onClick={() =>
                        modalImpressorasRef.current?.abrirModal({
                            chave: "fiscal.notafiscal.nfce",
                            filtros: {
                                id_nfce: rowData.id,
                            },
                        })
                    }
                    tooltip="Imprimir cupom"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.modelo !== "65" || rowData.status !== "T" || loading}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-file-pdf"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => downloadNota(rowData, "pdf")}
                    tooltip={`Imprimir ${rowData.modelo === "55" ? "NFe" : "NFCe"}`}
                    tooltipOptions={{ position: "left" }}
                    disabled={!!!rowData.protocolo || loading}
                />
            </div>
        );
    };

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_NOTASAIDA_INCLUIR]}
                componente={Button}
                label="Incluir Nota"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                disabled={loading}
                onClick={() => history.push("/fiscal/nota-saida/emissao-nota")}
            />
            <Button
                label="Verificar Contingências"
                icon="pi pi-search"
                className="p-button-secondary p-mr-2"
                onClick={() => contingenciaRef.current?.setVisible(true)}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
                disabled={loading}
            />
            <Button
                label="Limpar filtro"
                icon="pi pi-filter-slash"
                className="p-button-warning p-mr-2"
                disabled={loading}
                onClick={() => {
                    removerFiltro();
                    setTotalizadorFiltros(0);
                    setUrl(BASE_URL);
                }}
            />
        </>
    );

    const colunas = [
        {
            field: "numero",
            header: "Número",
            style: { width: "80px" },
        },
        {
            field: "serie",
            header: "Série",
            style: { width: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            style: { width: "50px" },
        },
        {
            field: "destinatario.nome",
            header: "Destinatário",
            style: { minWidth: "200px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            align: "left",
            style: { minWidth: "150px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            style: { minWidth: "140px" },
        },
        {
            field: "datahora_emissao",
            header: "Data emissão",
            dateFormat: "dd/MM/yyyy HH:mm",
            style: { minWidth: "120px" },
        },
        {
            field: "valor_total_nf",
            header: "Total",
            style: { minWidth: "80px" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            alignFrozen: "right",
            style: { minWidth: "180px" },
        },
    ];

    const cancelNf = async () => {
        if (notaFiscal.protocolo && notaFiscal.status === "T") {
            setCancelDialog(false);
            cancelaRef.current?.setVisible(true);
        } else {
            const handlers = {
                200: () => {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Nota cancelada com sucesso.",
                        life: 3000,
                    });
                },
                400: () => {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível cancelar a nota fiscal.",
                        life: 3000,
                    });
                },
            };

            await httpPatch({ url: `/fiscal/notas-fiscais/${notaFiscal.id}/`, body: { status: "C" } }, handlers);
        }
    };

    const esconderCancelDialog = () => {
        setCancelDialog(false);
    };

    const onFilter = (e, contador) => {
        setUrl(e);
        setTotalizadorFiltros(contador);
    };

    const rowClass = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const verificaTabelaIBPT = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                const { results } = data;
                if (results?.length === 0) {
                    showWarning({
                        summary: "Tabela IBPT não informada",
                        detail: "Não existe tabela IBPT informada. Por favor, importe a tabela mais recente ou entre em contato com o suporte para mais informações.",
                        sticky: true,
                    });
                } else {
                    const [dia, mes, ano] = results[0].vigenciafim.split("/");
                    const vigencia = parseData(`${ano}-${mes}-${dia}`);
                    if (vigencia < new Date())
                        showWarning({
                            summary: "Tabela IBPT desatualizada",
                            detail: "A tabela IBPT está desatualizada! Por favor, importe a versão mais recente.",
                            sticky: true,
                        });
                }
            },
        };

        await httpGet({ url: "/fiscal/tabela-ibpt?limit=1&order_by=-id" }, handlers);
    }, [httpGet, showWarning]);

    useEffect(() => {
        verificaTabelaIBPT();
    }, [verificaTabelaIBPT]);

    return (
        <PageBase>
            <Menu model={botoesEmissao} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo={
                    <div className="p-d-flex p-justify-between">
                        <span>Notas Fiscais</span>
                        {<MakoSituacaoSefaz tipoInicial="nfe" />}
                    </div>
                }
                colunas={colunas}
                painelEsquerdo={painelEsquerdo}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                fieldFiltroEmpresa="emitente__id"
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                    rowClassName: rowClass,
                }}
            />
            <EnvioEmailFormModal nota={notaFiscal} ref={emailRef} />
            <CancelaNotaFormModal
                nota={notaFiscal}
                onCancel={() => {
                    listagemRef.current?.buscarDados();
                }}
                ref={cancelaRef}
            />
            <CartaCorrecaoFormModal nota={notaFiscal} ref={cartaRef} />
            <ContingenciaFormModal
                ref={contingenciaRef}
                showLoading={showLoading}
                hideLoading={hideLoading}
                loading={loading}
                aposVerificar={() => listagemRef.current?.buscarDados()}
            />
            <ModalFiltroAvancadoNf
                ref={modalFiltroAvancadoRef}
                onFilter={onFilter}
                baseUrl={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ConfirmDialog
                visible={cancelDialog}
                onHide={esconderCancelDialog}
                header="Confirmação de Cancelamento"
                message={
                    notaFiscal && (
                        <span>
                            {"Deseja realmente cancelar a nota número "}
                            <b>{notaFiscal.numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={cancelNf}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderCancelDialog}
                rejectLabel="Não"
            />
            <ConfirmDialog
                visible={impressaoDialog}
                onHide={() => {
                    setImpressaoDialog(false);
                    setNotaFiscal(null);
                }}
                header="Confirmação de impressão"
                message={
                    notaFiscal && (
                        <span>
                            {"Deseja imprimir a DANFE do documento número "}
                            <b>{notaFiscal.numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-print p-mr-3"
                accept={() => downloadNota(notaFiscal, "pdf")}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => {
                    setImpressaoDialog(false);
                    setNotaFiscal(null);
                }}
                rejectLabel="Não"
            />
            <ConfirmDialog
                visible={emissaoDialog}
                onHide={() => setEmissaoDialog(false)}
                header="Confirmação de Emissão de Documento Fiscal"
                message={
                    notaFiscal && (
                        <span>
                            {"Atenção! Deseja realmente transmitir a nota fiscal número "}
                            <b>{notaFiscal.numero}</b> {" de modelo "}
                            <b>{notaFiscal.modelo}</b>
                            {"\n para o destinatário "} <b>{notaFiscal.destinatario?.nome}</b>
                            {" de documento número "}
                            <b>{formatarDocumento(notaFiscal.destinatario?.identificacao)}</b> {" no valor de "}
                            <b>{parseNumberToMoneyHTML(notaFiscal.valor_total_nf)}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => transmitirDocumento()}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => setEmissaoDialog(false)}
                rejectLabel="Não"
            />
            <ModalImpressoras ref={modalImpressorasRef} apenasTermicas />
        </PageBase>
    );
};
