import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "@/components/Dropdown";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { TIPO_REQUISICAO_TRANSF } from "@/assets/constants/estoque";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";

const INITIAL_VALUES = {
    id: null,
    ce_destino: null,
    ce_origem: null,
    venda: null,
    lote: "",
    data_hora__gte: null,
    data_hora__lte: null,
    data_limite__gte: null,
    data_limite__lte: null,
};

const Modal = ({ onSuccess, url, filtros, setTotalizadorFiltros, removerFiltro }, ref) => {
    const [dialog, setDialog] = useState(false);

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            let params = [];
            let _filtros = {};
            Object.keys(values).map((key) => {
                if (values[key] !== null && values[key] !== "") {
                    _filtros[key] = values[key];
                    return params.push(`${key}=${values[key]}`);
                }
            });
            if (params.length > 0) {
                const _url = url + "?" + params.join("&");
                if (typeof onSuccess === "function") {
                    onSuccess(_url, totalizadorFiltrosAplicados(INITIAL_VALUES, values), _filtros);
                }
            } else {
                if (typeof onSuccess === "function")
                    onSuccess(url, totalizadorFiltrosAplicados(INITIAL_VALUES, values), _filtros);
            }
            setDialog(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const exibirDialog = () => {
        setDialog(true);
    };

    useImperativeHandle(ref, () => ({ exibirDialog }));

    useEffect(() => {
        if (filtros) setTotalizadorFiltros(totalizadorFiltrosAplicados(INITIAL_VALUES, filtros));
    }, [filtros, setTotalizadorFiltros]);

    return (
        <Dialog
            header="Filtro Avançado"
            visible={dialog}
            style={{ width: "65vw", display: "block" }}
            onHide={() => setDialog(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="venda">Nº da venda</label>
                        <InputText
                            id="venda"
                            name="venda"
                            placeholder="Digite uma venda"
                            value={formik.values.venda}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="lote">Nº do lote</label>
                        <InputText
                            id="lote"
                            name="lote"
                            placeholder="Digite um lote"
                            value={formik.values.venda}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_requisicao">Tipo de requisição</label>
                        <Dropdown
                            id="tipo_requisicao"
                            name="tipo_requisicao"
                            placeholder="Selecione..."
                            options={TIPO_REQUISICAO_TRANSF}
                            value={formik.values.tipo_requisicao}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="status">Situação</label>
                        <Dropdown
                            id="status"
                            name="status"
                            placeholder="Selecione..."
                            //options={TIPO_REQUISICAO_TRANSF}
                            value={formik.values.status}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ce_origem">Centro de Estocagem Origem</label>
                        <Dropdown
                            id="ce_origem"
                            name="ce_origem"
                            placeholder="Selecione um centro de estocagem"
                            url="/produtos/centros-estocagem?limit=1000"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="id,nome,descricao"
                            value={formik.values.ce_origem}
                            onChange={(e) => setFieldValue("ce_origem", e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ce_destino">Centro de Estocagem Destino</label>
                        <Dropdown
                            id="ce_destino"
                            name="ce_destino"
                            placeholder="Selecione um centro de estocagem"
                            url="/produtos/centros-estocagem?limit=1000"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="id,nome,descricao"
                            value={formik.values.ce_destino}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-6">
                        <label htmlFor="data_hora__gte">Período da requisição</label>
                        <div className="p-inputgroup">
                            <MakoCalendarTime
                                id="data_hora__gte"
                                name="data_hora__gte"
                                valueCalendar={formik.values.data_hora__gte}
                                onChange={formik.handleChange}
                            />
                            <MakoCalendarTime
                                id="data_hora__lte"
                                name="data_hora__lte"
                                valueCalendar={formik.values.data_hora__lte}
                                onChange={formik.handleChange}
                                className="p-ml-2"
                            />
                        </div>
                    </div>
                    <div className="p-field p-col-6 p-md-6">
                        <label htmlFor="data_limite__gte">Período limite para transferir</label>
                        <div className="p-inputgroup">
                            <MakoCalendar
                                id="data_limite__gte"
                                name="data_limite__gte"
                                value={formik.values.data_limite__gte}
                                onChange={formik.handleChange}
                            />
                            <MakoCalendar
                                id="data_limite__lte"
                                name="data_limite__lte"
                                value={formik.values.data_limite__lte}
                                onChange={formik.handleChange}
                                className="p-ml-2"
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="p-grid p-justify-end">
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2"
                        onClick={() => {
                            resetForm();
                            removerFiltro();
                        }}
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setDialog(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroRequisicoesModalForm = forwardRef(Modal);
