import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

import MakoListagem from "@/components/MakoListagem";
import { ButtonMenuPopup } from "@/components/ButtonMenuPopup";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { BUTTON_LISTAGEM, BUTTON_LISTAGEM_OPCOES } from "@/assets/constants/presets";
import { dataToStr } from "@/assets/util/datas";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

import { DialogRejeicao } from "./modal-desconhecimento";

const AMBIENTES_NFE = [
    { label: "Homologação", value: "homolog" },
    { label: "Produção", value: "prod" },
];

const ConsultarNotasEmitidasContraCnpj = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [arquivos, setArquivos] = useState([]);
    const [ambiente, setAmbiente] = useState(null);
    const [eventoSelecionado, setEventoSelecionado] = useState(null);
    const [dialogConhecimento, setDialogConhecimento] = useState("");
    const dialogRef = useRef(null);
    const { empresaSelecionadaId, empresaSelecionada } = useEmpresa();
    const [nsu, setNSU] = useState(`${empresaSelecionada?.ultimo_nsu_nfe || ""}`);
    const { showSuccess, showError, showWarning } = useToast();
    const { httpGet, httpPost } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const manifestar = async (evento, justificativa = "") => {
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Manifestação realizada com sucesso!",
                    life: 1500,
                });
            },
            400: (err) => {
                const { msg } = err;
                if (msg?.startsWith("O parâmetro")) {
                    showWarning({
                        summary: "Aviso",
                        detail: msg,
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: msg || "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            },
            500: (err) => {
                showError({
                    summary: "Erro :(",
                    detail: err?.msg || "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            },
        };
        const body = {
            perfil: empresaSelecionadaId,
            ambiente,
            chave_acesso: eventoSelecionado?.chave,
            evento,
            justificativa,
        };
        await httpPost({ url: "/compras/manifestar-nf/", body }, handlers);
    };

    const baixarDocumento = async (eventoId, formato, chave) => {
        const handlers = {
            200: ({ data }) => {
                const { documento } = data;
                const byteCharacters = atob(documento.split(",")[1]);
                const byteNumbers = new Array(byteCharacters.length)
                    .fill(0)
                    .map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray]);
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `${chave}.${formato}`;
                link.click();
                URL.revokeObjectURL(blobUrl);
                link.remove();
            },
            400: (err) => {
                const { msg } = err;
                if (msg?.startsWith("O parâmetro")) {
                    showWarning({
                        summary: "Aviso",
                        detail: msg,
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: msg || "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            },
            500: (err) => {
                showError({
                    summary: "Erro :(",
                    detail: err?.msg || "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            },
        };
        const url = `/compras/download-doc-nfe/?evento=${eventoId}&formato=${formato}`;
        await httpGet({ url }, handlers);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const itens = [
        {
            label: "Confirmar (210200)",
            disabled: !eventoSelecionado || !ambiente,
            command: () => setDialogConhecimento("210200"),
        },
        {
            label: "Ciente (210210)",
            disabled: !eventoSelecionado || !ambiente,
            command: () => setDialogConhecimento("210210"),
        },
        {
            label: "Desconheço (210220)",
            disabled: !eventoSelecionado || !ambiente,
            command: () => dialogRef.current?.abrirModal("210220"),
        },
        {
            label: "Não Realizada (210240)",
            disabled: !eventoSelecionado || !ambiente,
            command: () => dialogRef.current?.abrirModal("210240"),
        },
        {
            label: "Baixar PDF",
            disabled: !eventoSelecionado || !ambiente,
            command: () => baixarDocumento(eventoSelecionado?.evento_id, "pdf", eventoSelecionado?.chave),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Tooltip target=".tooltip-info-nfe">
                    <>
                        <p className="p-m-0">NSU: {rowData.nsu}</p>
                        <p className="p-m-0">Chave de acesso: {rowData.chave}</p>
                        <p className="p-m-0">Data Recebimento: {dataToStr(rowData.data_recebimento, "dd/MM/yyyy")}</p>
                    </>
                </Tooltip>
                <Button
                    {...BUTTON_LISTAGEM}
                    icon="pi pi-info-circle"
                    severity="secondary"
                    className="tooltip-info-nfe"
                />
                <Button
                    {...BUTTON_LISTAGEM}
                    icon="pi pi-check-circle"
                    severity="success"
                    tooltip="Selecionar a nota e baixar o XML"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => baixarDocumento(rowData.evento_id, "xml", rowData.chave)}
                />
                <ButtonMenuPopup
                    {...BUTTON_LISTAGEM_OPCOES}
                    menu={{ model: itens }}
                    aria-controls="menu-popup-nfe"
                    onClick={() => setEventoSelecionado(rowData)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "tipo_documento", header: "Tipo Doc.", style: { width: "10%" } },
        { field: "numero", header: "Número", style: { width: "10%" } },
        { field: "emitente", header: "Emitente" },
        { field: "data_evento", header: "Data emissão", dateFormat: "dd/MM/yyyy", style: { width: "10%" } },
        { field: "valor", header: "Valor", money: true, style: { width: "10%" } },
        { field: "action", header: "Ações", style: { width: "15%" }, action: actionBodyTemplate },
    ];

    const listarArquivos = useCallback(
        async (cancelToken) => {
            let url = `/compras/consultar-notas-cnpj/${empresaSelecionadaId}/`;
            const handlers = {
                200: ({ data }) => {
                    if (!Object.keys(data).length) return;
                    const { ultimo_nsu, documentos } = data;
                    setNSU(ultimo_nsu);
                    setArquivos(documentos);
                },
                400: (err) => {
                    const { msg } = err;
                    if (msg?.startsWith("O parâmetro")) {
                        showWarning({
                            summary: "Aviso",
                            detail: msg,
                            life: 3000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: msg || "Desculpe, não foi possível processar a requisição.",
                            life: 3000,
                        });
                    }
                },
                500: (err) => {
                    showError({
                        summary: "Erro :(",
                        detail: err?.msg || "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                },
            };
            showLoading();
            await httpGet({ url, signal: cancelToken }, handlers);
            hideLoading();
        },
        [empresaSelecionadaId, showLoading, hideLoading, httpGet, showError, showWarning]
    );

    return (
        <>
            <Dialog
                header="Consulta NFe online"
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "50vw" }}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="ultimo-nsu" label="Último NSU" />
                        <InputText
                            id="ultimo-nsu"
                            placeholder="000000"
                            value={nsu}
                            onChange={(e) => setNSU(e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="ambiente" label="Ambiente" />
                        <Dropdown
                            id="ambiente"
                            options={AMBIENTES_NFE}
                            value={ambiente}
                            onChange={(e) => setAmbiente(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-col-align-end">
                        <Button
                            type="button"
                            icon="pi pi-globe"
                            label="Pesquisar"
                            disabled={!ambiente}
                            onClick={listarArquivos}
                        />
                    </div>
                </div>
                <MakoListagem
                    colunas={colunas}
                    dadosLocal={arquivos}
                    configTabela={{
                        paginator: true,
                        loading,
                    }}
                />
            </Dialog>
            <DialogRejeicao ref={dialogRef} confirm={manifestar} />
            <ConfirmDialog
                visible={!!dialogConhecimento}
                onHide={() => setDialogConhecimento("")}
                message="Confirma o reconhecimento da NFe?"
                header="Confirmação / Ciência"
                icon="pi pi-exclamation-triangle"
                accept={async () => {
                    await manifestar(dialogConhecimento);
                }}
                acceptLabel="Sim"
                rejectLabel="Não"
            />
        </>
    );
};

export const ModalConsultaNotasCnpj = forwardRef(ConsultarNotasEmitidasContraCnpj);
