import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";

const url = "/plano-operacional/fases-projetos/";

export const OperacionalFasesProjetosPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de fases de projetos"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/operacional/plano-operacional/fases-projetos/form",
                        state: { fasesProjetos: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a fase de projetos "}
                            <b>{rowData.nome}</b>?
                        </span>
                    }
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/operacional/plano-operacional/fases-projetos/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "100px" } },
        { field: "projeto.nome", header: "Projeto", style: { minWidth: "300px" } },
        { field: "fase", header: "Fase", style: { minWidth: "100px" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "400px" } },
        { field: "data_inicio", header: "Data inicio", style: { minWidth: "130px" }, dateFormat: "dd/MM/yyyy" },
        { field: "data_fim", header: "Data fim", style: { minWidth: "130px" }, dateFormat: "dd/MM/yyyy" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "130px" },
            align: "center",
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Fases de Projetos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                    frozenWidth: "0.5vw",
                    scrollable: true,
                }}
            />
        </PageBase>
    );
};
