import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import useBoleto from "@/hooks/useBoleto";

export const BoletoAntecipacaoForm = (props) => {
    const { handleAntecipacao } = useBoleto();

    const formik = useFormik({
        initialValues: {
            valor_antecipado: 0,
            custo_antecipacao: 0,
            data_antecipacao: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor_antecipado: Yup.number().notRequired().default(0),
                custo_antecipacao: Yup.number().notRequired().default(0),
                data_antecipacao: Yup.date().nullable().default(null),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            handleAntecipacao(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="valor-antecipado">Valor antecipado</label>
                    <MakoInputMoeda
                        id="valor-antecipado"
                        name="valor_antecipado"
                        valueMoeda={formik.values.valor_antecipado}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="custo-antecipacao">Custo da antecipação</label>
                    <MakoInputMoeda
                        id="custo-antecipacao"
                        name="custo_antecipacao"
                        valueMoeda={formik.values.custo_antecipacao}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="data-antecipacao">Data da antecipação</label>
                    <MakoCalendar
                        id="data-antecipacao"
                        name="data_antecipacao"
                        value={formik.values.data_antecipacao}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.data_antecipacao && (
                        <small className="p-error">{formik.errors.data_antecipacao}</small>
                    )}
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2 p-mb-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};
