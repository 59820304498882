import React, { useRef, useState } from "react";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { Tooltip } from "primereact/tooltip";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { Menu } from "primereact/menu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { Tag } from "primereact/tag";
import { Editor } from "primereact/editor";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { TIPO_SITUACAO_CLAUSULA_CHOICE } from "@/assets/constants/constants";
import { key_filtros } from "@/assets/constants/filtros";

export const ClausulasContratoPage = () => {
    const [clausula, setClausula] = useState(null);
    const listagemRef = useRef();
    const menuAcoesRef = useRef();
    const { verifyPermission, user } = useAuth();
    const { httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();

    async function handleSituacao(values) {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `A cláusula de contrato foi ${
                        values.situacao === "B" ? "desativada" : "validada"
                    } com sucesso.`,
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        await httpPatch({ url: `/servicos/clausulas-contrato/${values.id}/`, body: values }, handlers);
    }

    const botoesMenu = [
        {
            label: "Ações",
            items: [
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled: !verifyPermission([permissoes.SERVICOS_CADASTROS_CLAUSULAS_VALIDAR]),
                    command: () =>
                        handleSituacao(
                            {
                                ...clausula,
                                situacao: "V",
                                usuario_validou: user.id,
                                data_validacao: new Date(),
                            },
                            "B"
                        ),
                },
                {
                    label: "Baixar",
                    icon: "pi pi-times",
                    disabled: !verifyPermission([permissoes.SERVICOS_CADASTROS_CLAUSULAS_BAIXAR]),
                    command: () =>
                        handleSituacao({
                            ...clausula,
                            situacao: "B",
                            usuario_alterou: user.id,
                            data_alteracao: new Date(),
                        }),
                },
                {
                    label: "Contratos",
                    icon: "pi pi-book",
                    //command: () => setDesativarDialog(true),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_CADASTROS_CLAUSULAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    onClick={() =>
                        history.push({ pathname: "/servicos/cadastros/clausulas-contrato/form", state: rowData })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_CADASTROS_CLAUSULAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info"
                    model={botoesMenu}
                    tooltipOptions={{ position: "left" }}
                    onClick={(event) => {
                        menuAcoesRef?.current.toggle(event);
                        setClausula(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const secaoClausulaTemplate = ({ id, parte, nome }) => {
        if (!parte) return nome;
        const { descricao_visivel, identificador } = parte;
        if (descricao_visivel) return descricao_visivel;
        return identificador;
    };

    const situacaoTemplate = ({ situacao }) => {
        const { id, descricao } = situacao;
        const severity = { E: "warning", V: "info", B: "danger" };
        return <Tag value={descricao.toUpperCase()} severity={severity[id]} />;
    };

    const textoTemplate = ({ id, nome, texto_clausula, paragrafo }) => {
        let texto = texto_clausula;
        if (paragrafo) texto = texto.replace(/\);">(?=)/, `$&§ `);
        return (
            <>
                <Tooltip target={`.custom-target-secaoclausula${id}`} content={nome} position="top" />
                <Editor
                    value={texto}
                    style={{ height: "50px" }}
                    showHeader={false}
                    readOnly
                    className={`custom-target-secaoclausula${id}`}
                />
            </>
        );
    };

    const SituacaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_SITUACAO_CLAUSULA_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "parte.identificador",
            header: "Secção",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "parte__identificador",
            action: secaoClausulaTemplate,
        },
        {
            field: "texto_clausula",
            header: "Início do texto...",
            style: { minWidth: "250px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "texto_clausula",
            action: textoTemplate,
        },
        {
            field: "situacao.descricao",
            header: "Situação",
            style: { minWidth: "90px" },
            align: "center",
            filter: true,
            filterElement: SituacaoFiltroTemplate,
            filterField: "situacao",
            action: situacaoTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { minWidth: "50px" },
        },
    ];

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.SERVICOS_CADASTROS_CLAUSULAS_INCLUIR]}
            componente={Button}
            label="Novo"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/servicos/cadastros/clausulas-contrato/form")}
        />
    );

    return (
        <PageBase>
            <Menu model={botoesMenu} popup ref={menuAcoesRef} id="popup_menu_acoes" />
            <MakoListagem
                ref={listagemRef}
                titulo="Cláusulas de Contrato Jurídico"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/servicos/clausulas-contrato/"
                keyFiltrosStorage={key_filtros.SERVICOS_CLAUSULAS_CONTRATO}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    parte__identificador: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    texto_clausula: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    situacao: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
