import React, { forwardRef, useImperativeHandle, useState } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Label } from "@/components/Label";

const Modal = ({ confirm }, ref) => {
    const [visible, setVisible] = useState(null);
    const [codigo, setCodigo] = useState("");
    const [justificativa, setJustificativa] = useState("");
    const [erroJustificativa, setErroJustificativa] = useState(null);

    const abrirModal = (codigo) => {
        setCodigo(codigo);
        setVisible(true);
    };

    const fecharModal = () => {
        setCodigo("");
        setJustificativa("");
        setErroJustificativa(null);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const naoRealizada = codigo === "210240";

    const accept = async () => {
        if (naoRealizada && !justificativa) {
            setErroJustificativa("Campo obrigatório para o evento '210240'");
            return;
        }
        if (typeof confirm === "function") await confirm(codigo, justificativa);
        fecharModal();
    };

    const footerContent = (
        <div>
            <Button label="Não" onClick={fecharModal} className="p-button-text" />
            <Button label="Sim" severity="danger" onClick={accept} autoFocus />
        </div>
    );

    return (
        <Dialog header="Desconhecimento / Não realização" visible={visible} onHide={fecharModal} footer={footerContent}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <Label htmlFor="justificativa" label="Justificativa" obrigatorio={naoRealizada} />
                    <InputText
                        id="justificativa"
                        value={justificativa}
                        onFocus={() => setErroJustificativa(null)}
                        onChange={(e) => setJustificativa(e.target.value)}
                        className={classNames({ "p-invalid": !!erroJustificativa })}
                    />
                    {erroJustificativa && <small className="p-error">{erroJustificativa}</small>}
                </div>
            </div>
        </Dialog>
    );
};

export const DialogRejeicao = forwardRef(Modal);
