import React, { forwardRef, useImperativeHandle, useState } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import { dataToStr } from "@/assets/util/datas";
import { Calendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputMontadorEAuxiliar } from "@/components/MakoInputs/MakoInputMontadorEAuxiliar";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const Modal = ({ onGravar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [addMode, setAddMode] = useState(true);
    const [pesquisaDtInicio, setPesquisaDtInicio] = useState(null);
    const [pesquisaDtFim, setPesquisaDtFim] = useState(null);
    const [pesquisaVenda, setPesquisaVenda] = useState("");
    const [pesquisaSKU, setPesquisaSKU] = useState("");
    const [venda, setVenda] = useState(null);
    const [itensEntregues, setItensEntregues] = useState([]);
    const { showWarning, showSuccess } = useToast();
    const { httpGet, httpPost, httpPatch } = useHttp();
    const [loadingPesquisa, showLoadingPesquisa, hideLoadingPesquisa] = useLoadingLocal();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [formatarDocumento] = useFormatCNPJCPF();

    const formik = useFormik({
        initialValues: {
            id: 0,
            item_entregue: null,
            executor: null,
            teve_auxiliar: false,
            datahora_montagem: null,
            valor_comissao: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                item_entregue: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Selecione um item para ser comissionado."),
                executor: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Selecione um montador / auxiliar."),
                datahora_montagem: Yup.date()
                    .required("O campo é obrigatório.")
                    .min(
                        values.item_entregue.datahora_finalizacao,
                        "A data/hora de montagem não pode ser inferior a data da entrega / retirada."
                    ),
                valor_comissao: Yup.number()
                    .required("O campo é obrigatório.")
                    .min(0.01, "O valor da comissão não pode ser 0 (ZERO)."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const body = {
                ...values,
                item_entregue: values.item_entregue.id,
                executor: values.executor.id,
            };
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Comissão de montagem alterada com sucesso!",
                        life: 1500,
                    });
                    if (typeof onGravar === "function") onGravar();
                    fecharModal();
                },
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Comissão de montagem lançada com sucesso!",
                        life: 1500,
                    });
                    if (typeof onGravar === "function") onGravar();
                    fecharModal();
                },
            };
            if (addMode) {
                showLoading();
                await httpPost({ url: "/vendas/montagens/", body }, handlers);
                hideLoading();
            } else {
                showLoading();
                await httpPatch({ url: `/vendas/montagens/${values.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = async (montagem) => {
        if (montagem) {
            setAddMode(false);
            await buscarVenda(montagem.item_entregue.venda_id);
            formik.setValues({
                id: montagem.id,
                item_entregue: montagem.item_entregue,
                executor: montagem.executor,
                teve_auxiliar: montagem.teve_auxiliar,
                datahora_montagem: new Date(montagem.datahora_montagem),
                valor_comissao: parseNumber(montagem.valor_comissao),
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setAddMode(true);
        setPesquisaDtInicio(null);
        setPesquisaDtFim(null);
        setPesquisaVenda("");
        setPesquisaSKU("");
        setVenda(null);
        setItensEntregues([]);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const buscarVenda = async (vendaId) => {
        const url = `/vendas/vendas/${vendaId}/?query={id,cliente,data}`;
        const handlers = {
            200: ({ data }) => setVenda(data),
        };
        await httpGet({ url }, handlers);
    };

    const pesquisarMontagensRealizadas = async () => {
        if (!pesquisaDtInicio || !pesquisaDtFim) {
            showWarning({
                summary: "Aviso",
                detail: "Você precisa selecionar o período para buscar as montagens realizadas.",
                life: 3000,
            });
            return;
        }
        const filtros = ["entregue=true"];
        filtros.push(`data_finalizacao__gte=${dataToStr(pesquisaDtInicio, "yyyy-MM-dd")}`);
        filtros.push(`data_finalizacao__lte=${dataToStr(pesquisaDtFim, "yyyy-MM-dd")}`);
        if (pesquisaVenda) filtros.push(`item_venda__venda=${pesquisaVenda}`);
        if (pesquisaSKU && /^\d+$/.test(pesquisaSKU)) filtros.push(`item_venda__sku__codigo=${pesquisaSKU}`);
        if (pesquisaSKU && !/^\d+$/.test(pesquisaSKU))
            filtros.push(`item_venda__sku__descricao_reduzida=${pesquisaSKU}`);
        const query = filtros.join("&");
        const url = `/vendas/previsoes-entregas-itens-vendas/?${query}`;
        const handlers = {
            200: ({ data }) => {
                const montagensAgrupadasPorVenda = [];
                data.results.forEach((result) => {
                    const { id, sku, quantidade, venda_id, datahora_finalizacao, cliente } = result;
                    const index = montagensAgrupadasPorVenda.findIndex((ma) => ma.venda === venda_id);
                    const entrega = { id, venda: venda_id, sku, quantidade, datahora_finalizacao, cliente };
                    if (index === -1) montagensAgrupadasPorVenda.push({ venda: venda_id, entregas: [entrega] });
                    else montagensAgrupadasPorVenda[index].entregas.push(entrega);
                });
                setItensEntregues(montagensAgrupadasPorVenda);
            },
        };
        showLoadingPesquisa();
        await httpGet({ url }, handlers);
        hideLoadingPesquisa();
    };

    const agrupamentoVendaTemplate = (option) => {
        return <span>Venda: {option.venda}</span>;
    };

    const entregasAgrupadasTemplate = (option) => {
        return (
            <span>
                {option.sku.codigo} - {option.sku.descricao_reduzida} / Qtd.: {parseNumber(option.quantidade)}
            </span>
        );
    };

    return (
        <Dialog header="Incluindo comissão montagem" visible={visible} onHide={fecharModal} style={{ width: "60vw" }}>
            <form onSubmit={formik.handleSubmit}>
                <Divider align="left">
                    <b>Localizando item de montagem</b>
                </Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="inicio-montagem" obrigatorio label="Período de montagem" />
                        <Calendar
                            id="inicio-montagem"
                            autoFocus
                            disabled={!addMode}
                            value={pesquisaDtInicio}
                            onChange={(e) => setPesquisaDtInicio(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="fim-montagem" obrigatorio label="Período de montagem" />
                        <Calendar
                            id="fim-montagem"
                            disabled={!addMode || !pesquisaDtInicio}
                            minDate={pesquisaDtInicio}
                            value={pesquisaDtFim}
                            onChange={(e) => setPesquisaDtFim(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="num-venda" label="Nº da venda" />
                        <InputText
                            id="num-venda"
                            keyfilter="int"
                            disabled={!addMode}
                            value={pesquisaVenda}
                            onChange={(e) => setPesquisaVenda(e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="sku" label="Código ou descrição do produto / mercadoria" />
                        <InputText
                            id="sku"
                            disabled={!addMode}
                            value={pesquisaSKU}
                            onChange={(e) => setPesquisaSKU(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="item-montagem" obrigatorio label="Selecione o item de montagem" />
                        <div className="p-inputgroup">
                            <Dropdown
                                id="item-montagem"
                                name="item_entregue"
                                options={itensEntregues}
                                disabled={!addMode || loadingPesquisa}
                                optionLabel="venda"
                                optionGroupLabel="venda"
                                optionGroupChildren="entregas"
                                optionGroupTemplate={agrupamentoVendaTemplate}
                                itemTemplate={entregasAgrupadasTemplate}
                                value={formik.values.item_entregue}
                                onChange={async (e) => {
                                    formik.handleChange(e);
                                    if (e.value) {
                                        formik.setFieldValue("valor_comissao", parseNumber(e.value.sku.custo_montagem));
                                        await buscarVenda(e.value.venda);
                                    }
                                }}
                                className={classNames({ "p-invalid": formik.errors.item_entregue })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-search"
                                loading={!addMode || loadingPesquisa}
                                onClick={pesquisarMontagensRealizadas}
                            />
                        </div>
                        {formik.errors.item_entregue && (
                            <small className="p-error">{formik.errors.item_entregue}</small>
                        )}
                    </div>
                </div>
                <Divider align="left">
                    <b>Registro da montagem</b>
                </Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="num-venda-montagem" label="Nº da venda" />
                        <InputText id="num-venda-montagem" disabled value={venda?.id || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-venda" label="Data da venda" />
                        <InputText
                            id="data-venda"
                            name="data_venda"
                            disabled
                            value={venda ? dataToStr(venda.data, "dd/MM/yyyy") : ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="cliente" label="Cliente" />
                        <InputText
                            id="cliente"
                            name="cliente"
                            disabled
                            value={
                                venda ? `${venda.cliente.nome} - ${formatarDocumento(venda.cliente.identificacao)}` : ""
                            }
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-entrega" label="Data da entrega / retirada" />
                        <InputText
                            id="data-entrega"
                            name="data_entrega"
                            disabled
                            value={
                                formik.values.item_entregue
                                    ? dataToStr(formik.values.item_entregue.datahora_finalizacao, "dd/MM/yyyy")
                                    : ""
                            }
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="produto-mercadoria" label="Produto / mercadoria" />
                        <InputText
                            id="produto-mercadoria"
                            disabled
                            value={
                                formik.values.item_entregue
                                    ? `${formik.values.item_entregue.sku.codigo} - ${formik.values.item_entregue.sku.descricao_reduzida}`
                                    : ""
                            }
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="quantidade" label="Quantidade" />
                        <InputText
                            id="quantidade"
                            disabled
                            value={parseNumber(formik.values.item_entregue?.quantidade)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="houve-auxiliar" label="Registro de auxiliar?" />
                        <SelectButton
                            id="houve-auxiliar"
                            name="teve_auxiliar"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            value={formik.values.teve_auxiliar}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="montador-auxiliar" label="Montador / auxiliar" obrigatorio />
                        <MakoInputMontadorEAuxiliar
                            id="montador-auxiliar"
                            name="executor"
                            value={formik.values.executor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.executor })}
                        />
                        {formik.errors.executor && <small className="p-error">{formik.errors.executor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-hora-montagem" label="Data / hora da montagem" obrigatorio />
                        <Calendar
                            id="data-hora-montagem"
                            name="datahora_montagem"
                            value={formik.values.datahora_montagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.datahora_montagem })}
                        />
                        {formik.errors.datahora_montagem && (
                            <small className="p-error">{formik.errors.datahora_montagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor-comissao" label="Valor da comissão" obrigatorio />
                        <MakoInputMoeda
                            id="valor-comissao"
                            name="valor_comissao"
                            valueMoeda={formik.values.valor_comissao}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_comissao })}
                        />
                        {formik.errors.valor_comissao && (
                            <small className="p-error">{formik.errors.valor_comissao}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons className="p-jc-end">
                    <Button
                        type="button"
                        icon={MAKO_ICONS.CANCEL}
                        label="Cancelar"
                        loading={loading}
                        severity="danger"
                        onClick={fecharModal}
                    />
                    <Button
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        loading={loading}
                        severity="success"
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalComissaoMontagem = forwardRef(Modal);
