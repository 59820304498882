import React, { useCallback, useEffect, useRef, useState } from "react";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { useFormik } from "formik";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoTime } from "@/components/MakoTime";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "@/components/Dropdown";
import useLoading from "@/hooks/useLoading";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ItensOrcamentoRapidoForm } from "./list";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { dataToStr, horaToStr } from "@/assets/util/datas";
import { InputText } from "primereact/inputtext";
import useHttp from "@/hooks/useHttp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const OrcamentoRapidoForm = () => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [itensOrcamento, setItensOrcamento] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showError, showInfo } = useToast();
    const { state } = useLocation();
    const history = useHistory();

    const toastRef = useRef();
    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: state
            ? { ...state.orcamento, valor_total: parseFloat(state.orcamento.valor_total) }
            : {
                  cliente: null,
                  data: new Date(),
                  hora: null,
                  plano_recebimento: null,
                  valor_total: 0,
                  observacoes: "",
                  identificacao: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object().required("O campo é obrigatório.").typeError("Informe um 'cliente' válido."),
                data: Yup.date().required("O campo é obrigatório."),
                plano_recebimento: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um 'plano de recebimento' válido."),
                identificacao: Yup.string().max(40).required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const __STATUS = values.id || values.localId ? "editar" : "novo";

            setDadosBasicos(values);
            showSuccess({
                summary: "Sucesso",
                detail: "Dados básicos do orçamento rápido gravados com sucesso!",
                life: 3000,
            });
            if (__STATUS === "novo")
                showInfo({
                    summary: "Atenção",
                    detail: "Agora você já pode vincular os itens ao orçamento.",
                    life: 6000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const gerenciaCrud = () => {
        let objCrud = {};
        objCrud["create"] = [];
        if (!dadosBasicos.id) {
            itensOrcamento.forEach((item) => {
                if (item.status && item.status === "novo")
                    objCrud.create.push({
                        ...item,
                        tabela_preco: item.tabela_preco?.id,
                        sku: item.sku.id,
                        sku_movimenta_estoque: item.sku_movimenta_estoque.id,
                        unidade_venda: item.unidade_venda.id,
                    });
            });
            return objCrud;
        } else {
            objCrud["remove"] = [];

            itensOrcamento.forEach((item) => {
                if (item.status && item.status === "novo") {
                    objCrud.create.push({
                        ...item,
                        sku: item.sku.id,
                        sku_movimenta_estoque: item.sku_movimenta_estoque.id,
                        unidade_venda: item.unidade_venda.id,
                        tabela_preco: item.tabela_preco?.id,
                    });
                } else if (item.status && item.status === "deletar") objCrud.remove.push(item.id);
            });
            return objCrud;
        }
    };

    async function handlePersistir() {
        try {
            let valorTotal = 0;
            itensOrcamento.forEach((item) => {
                if (!item.status) valorTotal += parseFloat(item.valor_total_ap);
                else if (item.status !== "deletar") valorTotal += parseFloat(item.valor_total);
            });

            if (!dadosBasicos.id) {
                const crud = gerenciaCrud();

                const handlers = {
                    201: () => {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Orçamento rápido cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/orcamento-rapido");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: `/vendas/orcamento-rapido/`,
                        body: {
                            ...dadosBasicos,
                            itemorcamentorapido_set: crud,
                            cliente: dadosBasicos.cliente?.id,
                            data: dataToStr(dadosBasicos.data, "yyyy-MM-dd"),
                            hora: horaToStr(dadosBasicos.hora, "HH:mm:ss"),
                            valor_total: valorTotal.toFixed(4),
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const crud = gerenciaCrud();

                const handlers = {
                    200: () => {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Orçamento rápido alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/orcamento-rapido");
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `/vendas/orcamento-rapido/${dadosBasicos.id}/`,
                        body: {
                            ...dadosBasicos,
                            itemorcamentorapido_set: crud,
                            cliente: dadosBasicos.cliente?.id,
                            data: dataToStr(dadosBasicos.data, "yyyy-MM-dd"),
                            hora: horaToStr(dadosBasicos.hora, "HH:mm:ss"),
                            valor_total: valorTotal.toFixed(4),
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    useEffect(() => {
        let total = 0;
        itensOrcamento.forEach((item) => {
            if (item.status && item.status !== "deletar") total += parseFloat(item.valor_total);
            else if (!item.status) total += parseFloat(item.valor_total_av);
        });
        setFieldValue("valor_total", total);
    }, [setItensOrcamento, itensOrcamento, setFieldValue]);

    const carregaInformacoes = useCallback(async () => {
        const { itemorcamentorapido_set, ...orcamento } = state?.orcamento;
        setDadosBasicos(orcamento);
        setItensOrcamento(itemorcamentorapido_set);
    }, [state?.orcamento]);

    const onChangeCliente = (e) => {
        setFieldValue("cliente", e);
        setFieldValue("identificacao", e.nome);
    };

    useEffect(() => {
        if (state?.orcamento?.id) carregaInformacoes();
    }, [state?.orcamento, carregaInformacoes]);

    return (
        <PageBase>
            <h4>{!formik.values.id ? "Novo Orçamento Rápido" : "Manutenção de Orçamento Rápido"}</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="id">Código</label>
                        <InputText id="id" name="id" value={formik.values.id} onChange={formik.handleChange} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="cliente">Cliente *</label>
                        <MakoInputCliente
                            id="cliente"
                            name="cliente"
                            disabled={!!formik.values.id}
                            somenteAtivo
                            value={formik.values.cliente}
                            onChange={(e) => onChangeCliente(e.value)}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-venda">Data *</label>
                        <MakoCalendar
                            id="data-venda"
                            name="data"
                            value={formik.values.data}
                            minDate={new Date()}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data })}
                        />
                        {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="hora-venda">Hora</label>
                        <MakoTime
                            id="hora-venda"
                            name="hora"
                            valueTime={formik.values.hora}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.hora })}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="identificacao">Identificação *</label>
                        <InputText
                            id="identificacao"
                            name="identificacao"
                            value={formik.values.identificacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.identificacao })}
                        />
                        {formik.errors.identificacao && (
                            <small className="p-error">{formik.errors.identificacao}</small>
                        )}
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="plano_recebimento">Plano de recebimentos *</label>
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            url="/financeiro/planos-recebimentos?query={id,descricao}&limit=300"
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            placeholder="Selecione um plano..."
                            value={formik.values.plano_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_total">Valor total</label>
                        <MakoInputMoeda
                            id="valor_total"
                            name="valor_total"
                            disabled
                            valueMoeda={formik.values.valor_total}
                            className={classNames({ "p-invalid": formik.errors.valor_total })}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacoes">Observações</label>
                        <InputTextarea
                            id="observacoes"
                            name="observacoes"
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.observacoes })}
                            rows={4}
                            autoComplete="off"
                            autoResize
                        />
                        {formik.errors.observacoes && <small className="p-error">{formik.errors.observacoes}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR]}
                        componente={Button}
                        label="Gravar"
                        icon="pi pi-save"
                        type="submit"
                        className="p-button-info p-mr-2"
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR]}
                        componente={Button}
                        label="Finalizar"
                        icon="pi pi-check"
                        type="button"
                        disabled={!dadosBasicos}
                        onClick={() => handlePersistir()}
                        className="p-button-success p-mr-2"
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon="pi pi-angle-double-left"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/vendas/venda/orcamento-rapido")}
                    />
                </div>
            </form>
            {dadosBasicos ? (
                <ItensOrcamentoRapidoForm
                    setItensOrcamento={setItensOrcamento}
                    itensOrcamento={itensOrcamento}
                    toastRef={toastRef}
                />
            ) : null}
        </PageBase>
    );
};
