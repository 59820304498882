import React, { forwardRef, memo, useEffect, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { dataToStr } from "@/assets/util/datas";
import { InputTextarea } from "primereact/inputtextarea";
import useTroca from "@/hooks/useTroca";
import classNames from "classnames";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useToast from "@/hooks/useToast";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputNumVenda } from "@/components/MakoInputs/MakoInputNumVenda";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

export const TrocaDadosBasicosForm = () => {
    const [loadingVendSup, setLoadingVendSup] = useState(false);
    const [supervisores, setSupervisores] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const { handleDadosTroca, dadosBasicos } = useTroca();
    const { user } = useAuth();
    const { showError } = useToast();
    const { httpGet } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            venda: null,
            cliente: null,
            status: "P",
            empresa: empresaSelecionadaId,
            incluido_por: user?.id,
            vendedor: null,
            supervisor: null,
            competencia: null,
            data_venda: null,
            plano_recebimento: null,
            observacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_venda: Yup.date()
                    .required("O campo 'data da venda' é obrigatório.")
                    .typeError("Informe uma 'data da venda' válida."),
                venda: Yup.object().required("O campo 'venda' é obrigatório.").typeError("Informe uma 'venda' válida."),
                cliente: Yup.object()
                    .required("O campo 'cliente' é obrigatório.")
                    .typeError("Informe um 'cliente' válido."),
                competencia: Yup.number()
                    .required("O campo 'competência' é obrigatório.")
                    .typeError("Informe uma 'competência' válida."),
                vendedor: Yup.number()
                    .required("O campo 'vendedor' é obrigatório.")
                    .typeError("Informe um 'vendedor' válido."),
                plano_recebimento: Yup.number()
                    .required("O campo 'plano de recebimento' é obrigatório.")
                    .typeError("Informe um 'plano de recebimento' válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            await handleDadosTroca({
                ...values,
                data_venda: dataToStr(values.data_venda, "yyyy-MM-dd"),
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da troca.",
                    life: 3000,
                });
            }
        }
    }

    const buscarVendedoresSupervisores = useCallback(async () => {
        const params = {
            usuario__isnull: false,
            query: "{id,perfil_pf,papeis_vigentes}",
            limit: 200,
        };
        let _supervisores = [];
        let _vendedores = [];

        const handlers = {
            200: ({ data }) => {
                data.results.forEach((perfil) => {
                    perfil.papeis_vigentes.forEach((papel) => {
                        if (papel.chave?.id === "SUP") {
                            _supervisores.push({
                                id: perfil.id,
                                nome: perfil.perfil_pf.nome_completo,
                            });
                        }
                        if (papel.chave?.id === "VND") {
                            _vendedores.push({
                                id: perfil.id,
                                nome: perfil.perfil_pf.nome_completo,
                            });
                        }
                    });
                });
                setSupervisores(_supervisores);
                setVendedores(_vendedores);
            },
            400: () =>
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos listar os supervisores e vendedores.",
                    life: 3000,
                }),
        };

        setLoadingVendSup(true);
        await httpGet({ url: "/pessoas/perfis/", params }, handlers);
        setLoadingVendSup(false);
    }, [showError, httpGet]);

    useEffect(() => {
        buscarVendedoresSupervisores();
    }, [buscarVendedoresSupervisores]);

    function onChangeNumVenda(event) {
        const { value } = event;
        console.log(value);
        setFieldValue("venda", value);
        if (value instanceof Object) {
            setFieldValue("data_venda", value.data);
            setFieldValue("cliente", value.cliente);
            setFieldValue("plano_recebimento", value.plano_recebimento?.id);
            setFieldValue("vendedor", value.vendedor?.id);
            setFieldValue("supervisor", value.supervisor?.id);
            setFieldValue("competencia", value.competencia);
        }
    }

    useEffect(() => {
        if (dadosBasicos) setValues(dadosBasicos);
    }, [dadosBasicos, setValues]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="venda">Nº da venda *</label>
                        <MakoInputNumVenda
                            id="venda"
                            name="venda"
                            vendaCompleta
                            disabled={!!formik.values.id}
                            value={formik.values.venda}
                            onChange={onChangeNumVenda}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.venda })}
                        />
                        {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="cliente">Cliente *</label>
                        <MakoInputCliente
                            id="cliente"
                            name="cliente"
                            disabled={!!formik.values.id}
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_venda">Data da venda *</label>
                        <MakoCalendar
                            id="data_venda"
                            name="data_venda"
                            value={formik.values.data_venda}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_venda,
                            })}
                        />
                        {formik.errors.data_venda && <small className="p-error">{formik.errors.data_venda}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="supervisor">Supervisor</label>
                        <MakoControleAcesso
                            permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARSUPERVISOR]}
                            componente={Dropdown}
                            exibirDesabilitadoSemPermissao
                            id="supervisor"
                            name="supervisor"
                            placeholder={loadingVendSup ? "Carregando supervisores..." : "Selecione..."}
                            options={supervisores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={formik.values.supervisor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.supervisor })}
                        />
                        {formik.errors.supervisor && <small className="p-error">{formik.errors.supervisor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="vendedor">Vendedor *</label>
                        <MakoControleAcesso
                            permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARVENDEDOR]}
                            componente={Dropdown}
                            exibirDesabilitadoSemPermissao
                            id="vendedor"
                            name="vendedor"
                            placeholder={loadingVendSup ? "Carregando vendedores..." : "Selecione..."}
                            options={vendedores}
                            showClear={false}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={formik.values.vendedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vendedor })}
                        />
                        {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Competência de estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia"
                            name="competencia"
                            value={formik.values.competencia}
                            showClear={false}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="plano-pagamento">Plano de recebimento *</label>
                        <Dropdown
                            id="plano-pagamento"
                            name="plano_recebimento"
                            url="/financeiro/planos-recebimentos?limit=1000"
                            optionLabel="descricao"
                            optionValue="id"
                            showClear={false}
                            value={formik.values.plano_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="obs">Observações</label>
                        <InputTextarea
                            id="obs"
                            name="observacoes"
                            rows={3}
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                {!dadosBasicos?.id ? (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button
                                type="submit"
                                icon="pi pi-save"
                                label="Incluir dados básicos"
                                className="p-button-info"
                            />
                        </div>
                    </div>
                ) : null}
            </form>
            <FormikAutoSave intervalo={500} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
        </>
    );
};

export default memo(forwardRef(TrocaDadosBasicosForm));
