import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import useCrediario from "@/hooks/useCrediario";
import * as Yup from "yup";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";

const Modal = ({ relacao, aposInserir }, ref) => {
    const { handleRelacao } = useCrediario();
    const { showSuccess, showError } = useToast();
    const [visivel, setVisivel] = useState(false);

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            renda: null,
            data_inicio: null,
            data_fim: null,
            ativo: true,
            ocupacao: null,
            op: "editar",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                ocupacao: Yup.number().required('O campo "ocupação" é obrigatório.'),
                renda: Yup.number().required('O campo "dias de validade" é obrigatório.'),
                data_inicio: Yup.string().required('O campo "data de início" é obrigatório.'),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleRelacao("editar", {
                ...relacao,
                op: "editar",
                renda_perfil: {
                    ...values,
                    perfil_grupo: relacao.perfil_titular?.id,
                    perfil_fonte_renda: relacao.perfil_vinculado?.id,
                    data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
                    data_fim: dataToStr(values.data_fim, "yyyy-MM-dd"),
                },
            });

            resetForm();
            setVisivel(false);
            if (typeof aposInserir === "function") aposInserir();
            showSuccess({
                summary: "Sucesso",
                detail: "Renda da Relação registrada com sucesso!",
                life: 3000,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const esconderRenda = () => {
        setVisivel(false);
    };

    const exibirDialog = useCallback(
        (dados) => {
            if (dados?.renda_perfil) setValues(dados.renda_perfil);
            setVisivel(true);
        },
        [setValues]
    );

    useImperativeHandle(ref, () => ({ abrir: exibirDialog }));

    return (
        <Dialog
            header="Renda da Relação de Perfil"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw", display: "block" }}
            visible={visivel}
            onHide={() => esconderRenda()}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ocupacao">Ocupação *</label>
                        <Dropdown
                            id="ocupacao"
                            name="ocupacao"
                            placeholder="Selecione uma ocupação"
                            url="/crediario/ocupacao?limit=1000"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.ocupacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ocupacao })}
                        />
                        {formik.errors.ocupacao && <small className="p-error">{formik.errors.ocupacao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="renda">Renda *</label>
                        <InputNumber
                            id="renda"
                            name="renda"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            min={0}
                            value={formik.values.renda}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.renda })}
                        />
                        {formik.errors.renda && <small className="p-error">{formik.errors.renda}</small>}
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="data_inicio">Data de início *</label>
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            value={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="data_fim">Data final</label>
                        <MakoCalendar
                            id="data_fim"
                            name="data_fim"
                            value={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_fim,
                            })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>

                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Salvar"
                            className="p-button-primary p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="button"
                            icon="pi pi-times"
                            label="Voltar"
                            className="p-button-danger p-mr-2 p-mb-2"
                            onClick={() => esconderRenda()}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const RendaRelacaoModalForm = forwardRef(Modal);
