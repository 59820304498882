import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoInputRepresentante } from "@/components/MakoInputs/MakoInputRepresentante";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const VinculoRepresentateFornecedorForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();
    const history = useHistory();
    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            representante: null,
            fornecedor: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                representante: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'representante' é obrigatório."),
                    })
                    .typeError("Informe um 'representante' válido."),
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'fornecedor' é obrigatório."),
                    })
                    .typeError("Informe um 'fornecedor' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const vinculoRepresentanteFornecedor = {
                fornecedor: values.fornecedor.id,
                representante: values.representante.id,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo cadastrado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: "/produtos/vinculo-fornecedor-representante/",
                        body: vinculoRepresentanteFornecedor,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo alterado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };
                showLoading();
                await httpPut(
                    {
                        url: `/produtos/vinculo-fornecedor-representante/${values.id}/`,
                        body: vinculoRepresentanteFornecedor,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/gestao/cadastros/vinculo-representante");
    };

    useState(() => {
        if (state && state.vinculo !== null) {
            setValues({ ...state.vinculo });
        }
    }, [state]);

    return (
        <PageBase>
            <h3>Formulário de vínculo representante e fornecedor</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6">
                        <Label htmlFor="representante" label="Representante" obrigatorio />
                        <MakoInputRepresentante
                            id="representante"
                            name="representante"
                            value={formik.values.representante}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.representante })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.representante && (
                            <small className="p-error">{formik.errors.representante}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6">
                        <Label htmlFor="fornecedor" label="Fornecedor" obrigatorio />
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            autoComplete="off"
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        loading={loading}
                        type="submit"
                        className="p-button-info"
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
