import React, { useRef } from "react";

import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const OperacoesFiscaisServicoPage = () => {
    const history = useHistory();
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais-servicos/form",
                            opFiscal: rowData,
                        })
                    }
                />
                {/*<MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_CLONAR]}
                    componente={Button}
                    icon="pi pi-clone"
                    className="p-button-rounded p-button-help"
                    tooltip="Clonar operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    disabled
                    // onClick={() => mostrarClonarDialog(rowData)}
                />*/}
                <ButtonListagemOpcoes
                    url={"/servicos/operacoes-fiscais-servicos"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="opração fiscal"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a operação de serviço <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A operação fiscal de serviço não pode ser excluída.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                onClick={() => history.push("/fiscal/cadastros/operacoes-fiscais-servicos/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "350px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "vigencia_inicial",
            header: "Início",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "vigencia_final",
            header: "Fim",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "150px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Operações Fiscais de Serviços"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/servicos/operacoes-fiscais-servicos/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    vigencia_inicial: { value: "", matchMode: "equals" },
                    vigencia_final: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
            />
        </PageBase>
    );
};
