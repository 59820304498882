import React, { useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import IdentificacaoPFForm from "./formIdentificacaoPF";
import IdentificacaoPJForm from "./formIdentificacaoPJ";
import IdentificacaoPEForm from "./formIdentificacaoPE";
import EnderecoForm from "./formEndereco";
import TelefoneForm from "./formTelefone";
import EmailForm from "./formEmail";
import RedeSocialForm from "./formRedeSocial";
import TagForm from "./formTag";
//import CustomFieldsForm from "./formCustomField";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { ModalErrosValidacao } from "@/components/ModalErrosValidacao";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import usePessoa from "@/hooks/usePessoa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const TabPessoas = ({ tipoForm, perfilId, aposSalvar }) => {
    const modalErrosValidacaoRef = useRef(null);
    const history = useHistory();
    const {
        loadingPessoa,
        handlePessoa,
        handlePreencherPessoa,
        pessoa,
        enderecos,
        telefones,
        emails,
        redesSociais,
        tags,
    } = usePessoa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();

    const TABS = [
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Física",
            component: <IdentificacaoPFForm />,
        },
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Jurídica",
            component: <IdentificacaoPJForm />,
        },
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Estrangeira",
            component: <IdentificacaoPEForm />,
        },
        {
            header: enderecos.length > 0 ? `Endereços (${enderecos.length})` : "Endereços",
            icon: "pi pi-fw pi-map-marker",
            disabled: !!!pessoa?.id,
            component: <EnderecoForm />,
        },
        {
            header: telefones.length > 0 ? `Telefones (${telefones.length})` : "Telefones",
            icon: "pi pi-fw pi-phone",
            disabled: !!!pessoa?.id,
            component: <TelefoneForm />,
        },
        {
            header: emails.length > 0 ? `E-mails (${emails.length})` : "E-mails",
            icon: "pi pi-fw pi-envelope",
            disabled: !!!pessoa?.id,
            component: <EmailForm />,
        },
        {
            header: redesSociais.length > 0 ? `Redes Sociais (${redesSociais.length})` : "Redes Sociais",
            icon: "pi pi-fw pi-at",
            disabled: !!!pessoa?.id,
            component: <RedeSocialForm />,
        },
        {
            header: tags.length ? `Tags (${tags.length})` : "Tags",
            icon: "pi pi-fw pi-tags",
            disabled: !!!pessoa?.id,
            component: <TagForm />,
        },
        /*{
            header: "Campos personalizados",
            icon: "pi pi-fw pi-align-center",
            disabled: !!!pessoa?.id,
            visible: tipoForm === "Pessoa Física" || tipoForm === "Pessoa Jurídica",
            component: <CustomFieldsForm />,
        },*/
    ];

    const voltarParaListagem = useCallback(() => {
        history.push("/gestao/pessoas/pessoas");
    }, [history]);

    const carregarPerfil = useCallback(async () => {
        if (perfilId) {
            await handlePreencherPessoa(perfilId);
        }
    }, [perfilId, handlePreencherPessoa]);

    useEffect(() => {
        carregarPerfil();
    }, [carregarPerfil]);

    return (
        <PageBase blocked={loadingPessoa}>
            <MakoMultiTabs
                tabs={TABS}
                title={`Cadastro de ${tipoForm}: ${pessoa?.nome || ""} ${
                    pessoa?.identificacao ? `(${formatarDocumento(pessoa?.identificacao)})` : ""
                }`}
                propsButtonProx={{ disabled: !!!pessoa?.id }}
                propsButtonCancelar={{ onClick: voltarParaListagem }}
                ocultarButtonFinalizar
            />
            <ModalErrosValidacao ref={modalErrosValidacaoRef} />
        </PageBase>
    );
};
