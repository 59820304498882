import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { dataToStr } from "@/assets/util/datas";
import { InputText } from "primereact/inputtext";
import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";
import { Calendar as MakoCalendar } from "@/components/Calendar";

const Modal = ({ onConfirm, url }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showError } = useToast();
    const { showWarning } = useToast();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            ip: "",
            detalhes__icontains: "",
            usuario__username: "",
            data_hora__lte: null,
            data_hora__gte: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            let filtrar = true;
            if (values.ip)
                if (!/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(values.ip)) {
                    showWarning({
                        summary: "IP Inválido!",
                        detail: "Por favor, informe um IP válido.",
                        life: 3000,
                    });
                    filtrar = false;
                }

            if (filtrar) {
                const params = Object.keys(values).map((key) => {
                    if (values[key] !== null && values[key] !== "") {
                        if (key === "data_hora__lte") return `${key}=${dataToStr(values[key], "yyyy-MM-dd")} 23:59:59`;
                        if (key === "data_hora__gte") return `${key}=${dataToStr(values[key], "yyyy-MM-dd")} 00:00:00`;
                        return `${key}=${values[key]}`;
                    }
                    return null;
                });
                if (params.length > 0) {
                    if (typeof onConfirm === "function")
                        onConfirm(`${url}?${params.filter(Boolean).join("&")}`, params.filter(Boolean).length);
                } else {
                    if (typeof onConfirm === "function") onConfirm(url);
                }
                setVisible(false);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            visible={visible}
            header="Filtro Avançado"
            style={{ width: "70vw" }}
            breakpoints={{ "1080px": "90vw" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="usuario__username">Usuário</label>
                        <InputText
                            id="usuario__username"
                            name="usuario__username"
                            value={formik.values.usuario__username}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.usuario__username })}
                        />
                        {formik.errors.usuario__username && (
                            <small className="p-error">{formik.errors.usuario__username}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="detalhes__icontains">Detalhes</label>
                        <InputText
                            id="detalhes__icontains"
                            name="detalhes__icontains"
                            value={formik.values.detalhes__icontains}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.detalhes__icontains })}
                        />
                        {formik.errors.detalhes__icontains && (
                            <small className="p-error">{formik.errors.detalhes__icontains}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="ip">IP</label>
                        <InputText
                            id="ip"
                            name="ip"
                            value={formik.values.ip}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ip })}
                        />
                        {formik.errors.ip && <small className="p-error">{formik.errors.ip}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_hora__gte">Período inicial</label>
                        <MakoCalendar
                            id="data_hora__gte"
                            name="data_hora__gte"
                            value={formik.values.data_hora__gte}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_hora__lte">Período final</label>
                        <MakoCalendar
                            id="data_hora__lte"
                            name="data_hora__lte"
                            value={formik.values.data_hora__lte}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => fecharModal()}
                        className="p-button-danger p-mr-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={resetForm}
                        className="p-button-warning p-mr-2"
                    />
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoLogs = forwardRef(Modal);
