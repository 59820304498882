import React, { memo, useCallback, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DataScroller } from "primereact/datascroller";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";

import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import useToast from "@/hooks/useToast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const _URL = "/pessoas/redes-sociais-perfis/";

const RedeSocialForm = () => {
    const [redesSociais, setRedesSociais] = useState([]);
    const [redeSocial, setRedeSocial] = useState(null);
    const [visible, setVisible] = useState(false);

    const { handleDados, redesSociais: redesSociaisPerfil, checarInformacao } = usePessoa();
    const { showSuccess, showError } = useToast();

    const menuAcoesRef = useRef();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            rede_social: null,
            chave: "",
            ativo: true,
            data_alteracao: new Date(),
            _status: OP_CRUD_DJANGO.novo,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                rede_social: Yup.number()
                    .required("O campo 'rede social' é obrigatório")
                    .typeError("Selecione uma rede social"),
                chave: Yup.string().required("O campo 'chave na rede social' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const redeSocial = redesSociais.find((el) => el.id === values.rede_social);
            const novaRedeSocial = {
                ...values,
                rede_social: redeSocial,
            };
            await handleDados(novaRedeSocial, _URL, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const alterarDataAtualizacaoRedeSocial = useCallback(
        async (id) => {
            const resposta = await checarInformacao("redes-sociais", id);
            if (resposta === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Rede social verificada com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A sua requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        },
        [checarInformacao, showError, showSuccess]
    );

    const excluirRedeSocial = async () => {
        await handleDados(redeSocial, _URL, OP_CRUD_DJANGO.deletar);
        setVisible(false);
    };

    const itensMenuAcoes = useMemo(() => {
        return [
            {
                label: "Checar rede social",
                icon: "pi pi-check",
                disabled: !!!redeSocial?.id,
                command: () => alterarDataAtualizacaoRedeSocial(redeSocial?.id),
            },
            {
                label: redeSocial?.ativo ? "Desativar rede social" : "Ativar rede social",
                icon: "pi pi-ban",
                command: async () =>
                    await handleDados({ id: redeSocial?.id, ativo: !redeSocial.ativo }, _URL, OP_CRUD_DJANGO.editar),
            },
            {
                label: "Excluir rede social",
                icon: "pi pi-trash",
                command: () => setVisible(true),
            },
        ];
    }, [redeSocial, alterarDataAtualizacaoRedeSocial, handleDados]);

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.ativo ? "instock" : "lowstock"}`}>
                            {data.ativo ? "Ativo" : "Inativo"}
                        </span>
                        {data?._status === OP_CRUD_DJANGO.deletar && (
                            <span className={"p-ml-2 product-badge status-outofstock"}>A SER EXCLUIDO</span>
                        )}
                        <div className="p-mt-2 product-description">{data.rede_social.nome}</div>
                        <div className="product-name">{`${data.rede_social.url_base}${data.chave}`}</div>
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        rede_social: data.rede_social.id,
                                        _status: OP_CRUD_DJANGO.editar,
                                    });
                                }}
                            />
                            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu" />
                            <Button
                                icon="pi pi-cog"
                                aria-haspopup
                                aria-controls="popup_menu_acoes"
                                className="p-button-rounded p-button-info"
                                onClick={(e) => {
                                    setRedeSocial(data);
                                    menuAcoesRef.current?.toggle(e);
                                }}
                            />
                        </div>
                        {(data._id || (data._status && data._status !== OP_CRUD_DJANGO.novo)) && (
                            <div className="product-description p-mb-0" style={{ color: "#f00" }}>
                                **Clique em <b>Finalizar</b> para confirmar**
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [setValues, itensMenuAcoes]
    );

    const aposBuscar = useMemo(() => {
        return (data) => {
            setRedesSociais(data);
            return data;
        };
    }, [setRedesSociais]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="rede-social" label="Rede social" obrigatorio />
                        <Dropdown
                            id="rede-social"
                            name="rede_social"
                            url={"/pessoas/redes-sociais/?ativo=True"}
                            placeholder="Selecione"
                            optionValue="id"
                            optionLabel="nome"
                            emptyMessage="Nenhum registro disponível"
                            aposBuscar={aposBuscar}
                            autoFocus
                            value={formik.values.rede_social}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.rede_social })}
                        />
                        {formik.errors.rede_social && <small className="p-error">{formik.errors.rede_social}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="chave" label="Chave na rede social" obrigatorio />
                        <InputText
                            id="chave"
                            name="chave"
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="ult-atualizacao" label="Última atualização" />
                        <MakoCalendar
                            id="ult-atualizacao"
                            name="data_alteracao"
                            disabled
                            showIcon={false}
                            value={formik.values.data_alteracao}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons className="p-mb-4">
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        className="p-button-warning"
                        onClick={() => formik.resetForm()}
                    />
                </MakoActionsButtons>
            </form>
            <div className="list-demo">
                <DataScroller
                    value={redesSociaisPerfil}
                    itemTemplate={itemTemplate}
                    rows={3}
                    inline
                    scrollHeight="300px"
                    header="Redes sociais cadastradas"
                    emptyMessage="Nenhuma rede social encontrada"
                />
            </div>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message={
                    <span>
                        Deseja realmente excluir a rede social? <br />
                        <b>{redeSocial?.rede_social.url_base}</b>
                    </span>
                }
                header="Confirmação de exclusão"
                icon="pi pi-info-circle"
                accept={excluirRedeSocial}
                acceptLabel="Deletar"
                acceptClassName="p-button-danger"
                rejectLabel="Cancelar"
            />
        </>
    );
};

export default memo(RedeSocialForm);
