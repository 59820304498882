import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_CONFIGURACOES_PERMISSOESUSUARIOS } from "@/assets/constants/relatorios";
import { OPCOES_PESQUISA_BOOLEAN } from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

const { ExportCSV } = R.Buttons;

const OPTIONS_ATIVO = OPCOES_PESQUISA_BOOLEAN.filter(({ value }) => value !== 0);

const FILTROS_TEMPLATE = [
    {
        key: "empresa",
        label: "Empresa",
        type: TIPOS_FILTROS_TEMPLATE.PESSOA,
    },
    {
        key: "permissao",
        label: "Permissao",
        path: "descricao",
    },
    {
        key: "perfil",
        label: "Perfil",
        type: TIPOS_FILTROS_TEMPLATE.PESSOA,
    },
    {
        key: "papel",
        label: "Papel",
        path: "nome",
    },
    {
        key: "chave",
        label: "Chave contém",
    },
    {
        key: "descricao",
        label: "Descrição contém",
    },
    {
        key: ["vigencia_inicial", "vigencia_final"],
        label: "Vigência",
        type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
    },
    {
        key: "status",
        label: "Status",
        optionKey: "value",
        optionLabel: "label",
        options: OPTIONS_ATIVO,
        type: TIPOS_FILTROS_TEMPLATE.CHOICE,
    },
];

const FILTROS_VIEWSET = {
    empresa: "empresa",
    permissao: "permissao",
    perfil: "perfil",
    papel: "papel",
    chave: "chave",
    descricao: "descricao",
    status: "status",
    vigencia_inicial: "vigencia_inicial",
    vigencia_final: "vigencia_final",
};

const COLUNAS = [
    { field: "papel_id", header: "Pap. Cód.", style: { width: "6%" }, align: "center" },
    { field: "papel", header: "Papel" },
    { field: "user_id", header: "Usu. Cód.", style: { width: "6%" }, align: "center" },
    { field: "user", header: "Usuário" },
    { field: "user_id", header: "Emp. Cód.", style: { width: "6%" }, align: "center" },
    { field: "empresa", header: "Empresa" },
    { field: "permissao_id", header: "Op. Cód.", style: { width: "6%" }, align: "center" },
    { field: "permissao_chave", header: "Chave" },
    { field: "permissao_descricao", header: "Descrição Operação" },
];

const BASE_URL = "/relatorios/permissoes-usuarios/";

const FILE_NAME = gerarFileName("Permissões usuários");

export const RelatorioPermissoesUsuarios = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, listagemRef, formRef, botaoCsv);

    const { solicitarRelatorio } = useRelatorio();

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                vigencia_inicial: Yup.date().nullable().typeError("Informe uma 'vigência inicial' válido"),
                vigencia_final: Yup.date()
                    .when("vigencia_inicial", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.vigencia_inicial || new Date(),
                                "A 'vigência final' não pode ser anterior a inicial"
                            )

                            .typeError("Informe uma 'vigência final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
                    .nullable(),
                empresa: Yup.number().nullable().default(null).typeError("Informe uma 'empresa' válida"),
                perfil: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'perfil' válido."),
                permissao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'permissão' válida."),
                papel: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'papel' válido."),
                descricao: Yup.string().nullable().typeError("Informe uma 'descrição' válida"),
                chave: Yup.string().nullable().default("").typeError("Informe uma 'chave' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(FILTROS_TEMPLATE, {
                ...dadosValidados,
                empresa: empresaSelecionada.current,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.papel) dadosValidados.papel = dadosValidados.papel.id;
            if (dadosValidados.perfil) dadosValidados.perfil = dadosValidados.perfil.id;
            if (dadosValidados.permissao) dadosValidados.permissao = dadosValidados.permissao.id;
            if (dadosValidados.vigencia_inicial)
                dadosValidados.vigencia_inicial = dataToStr(dadosValidados.vigencia_inicial, "yyyy-MM-dd");
            if (dadosValidados.vigencia_final)
                dadosValidados.vigencia_final = dataToStr(dadosValidados.vigencia_final, "yyyy-MM-dd");
            if (typeof dadosValidados.status === "number") dadosValidados.status = dadosValidados.status === 1;
            if (buscarListagem === false) {
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                    });
                    solicitarRelatorio({
                        chave: RELATORIO_CONFIGURACOES_PERMISSOESUSUARIOS,
                        emails,
                        filtros,
                        versao,
                        filtros_template: filtrosTemplateAplicados,
                        corpo_email,
                        enviar_fila: background,
                    });
                }
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false);
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            for (const [k, v] of Object.entries(dados)) {
                if (v !== null && v !== "") {
                    filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
            }
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.papel) dadosValidados.papel = dadosValidados.papel.id;
        if (dadosValidados.perfil) dadosValidados.perfil = dadosValidados.perfil.id;
        if (dadosValidados.permissao) dadosValidados.permissao = dadosValidados.permissao.id;
        if (dadosValidados.vigencia_inicial)
            dadosValidados.vigencia_inicial = dataToStr(dadosValidados.vigencia_inicial, "yyyy-MM-dd");
        if (dadosValidados.vigencia_final)
            dadosValidados.vigencia_final = dataToStr(dadosValidados.vigencia_final, "yyyy-MM-dd");
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_CONFIGURACOES_PERMISSOESUSUARIOS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={"permissões usuários"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        empresa: null,
                        vigencia_inicial: null,
                        vigencia_final: null,
                        permissao: null,
                        chave: "",
                        perfil: null,
                        papel: null,
                        descricao: "",
                        status: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Período vigência",
                        inputId: "vigencia_inicial",
                        inputName: "vigencia_inicial",
                        component: MakoCalendar,
                        fieldSize: 2,
                    },
                    {
                        inputId: "vigencia_final",
                        inputName: "vigencia_final",
                        component: MakoCalendar,
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Papel",
                        inputId: "papel",
                        inputName: "papel",
                        component: Dropdown,
                        componentProps: {
                            filter: true,
                            filterBy: "nome",
                            placeholder: "Selecione um papel perfil",
                            optionLabel: "nome",
                            url: "/pessoas/papeis-perfis/?limit=10000&query={id,nome}",
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Perfil",
                        inputId: "perfil",
                        inputName: "perfil",
                        component: MakoInputPerfil,
                        fieldSize: 6,
                    },
                    {
                        label: "Permissão",
                        inputId: "permissao",
                        inputName: "permissao",
                        component: Dropdown,
                        componentProps: {
                            filter: true,
                            filterBy: "descricao",
                            placeholder: "Selecione uma permissao",
                            optionLabel: "descricao",
                            url: "/pessoas/permissoes-perfis/?limit=10000&query={id,descricao,chave}",
                            showClear: true,
                        },
                        fieldSize: 6,
                    },
                    {
                        label: "Descrição contém",
                        inputId: "descricao",
                        inputName: "descricao",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            autoComplete: "off",
                        },
                        fieldSize: 6,
                    },
                    {
                        label: "Chave contém",
                        inputId: "chave",
                        inputName: "chave",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            autoComplete: "off",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Status",
                        inputId: "status",
                        inputName: "status",
                        component: Dropdown,
                        componentProps: {
                            filter: true,
                            filterBy: "label",
                            optionLabel: "label",
                            optionValue: "value",
                            options: OPTIONS_ATIVO,
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_CONFIGURACOES_PERMISSOESUSUARIOS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={COLUNAS}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                botaoExportar={BotaoExportar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    exportFilename: FILE_NAME,
                }}
            />
        </R.Wrapper>
    );
};
