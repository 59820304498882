import React, { useRef } from "react";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

export const ServicosMunicipioPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_SERVICOMUNICIPIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro da conta bancária"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/servicos/servicos/servico-municipio/form",
                            state: { servico: rowData },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/servicos/servicos-municipio"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="serviço"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o serviço <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O serviço não pôde ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.SERVICOS_SERVICOS_SERVICOMUNICIPIO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/servicos/servicos/servico-municipio/form")}
            />
        </>
    );

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Ativo" },
                        { value: false, label: "Desativado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "servico.codigo",
            header: "Código",
            filter: true,
            style: { minWidth: "50px" },
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "municipio.nome",
            header: "Município",
            style: { minWidth: "180px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "municipio__nome",
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            style: { minWidth: "200px" },
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "cnae",
            header: "CNAE",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "aliquota", header: "Alíquota (%)" },
        {
            field: "ativo",
            header: "Situação",
            style: { minWidth: "50px" },
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            filter: true,
            filterElement: StatusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "100px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Serviços por Municípios"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/servicos/servicos-municipio/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: { value: null, matchMode: "equals" },
                    cnae: { value: null, matchMode: "equals" },
                    municipio__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
