import React from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { dataToStr } from "@/assets/util/datas";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoButton as Button } from "@/components/MakoButton";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import * as Yup from "yup";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";

const url = "/plano-operacional/fases-projetos/";
const urlvoltar = "/operacional/plano-operacional/fases-projetos";

export const OperacionalFasesProjetosForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();

    const faseProjetoVazio = {
        fase: null,
        descricao: "",
        projeto: "",
        data_inicio: "",
        data_fim: "",
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location?.state?.fasesProjetos
            ? { ...props.location.state.fasesProjetos, projeto: props.location.state.fasesProjetos.projeto?.id }
            : faseProjetoVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                fase: Yup.number().required("O campo é obrigatório.").typeError("Informe uma 'fase' válida"),
                projeto: Yup.number().required("O campo é obrigatório.").typeError("Informe uma 'projeto' válida"),
                descricao: Yup.string()
                    .required("O campo é obrigatório.")
                    .max(255)
                    .typeError("Informe uma 'descrição' válida"),
                data_inicio: Yup.date()
                    .required("O campo é obrigatório.")
                    .typeError("Informe uma 'data inicial' válida"),
                data_fim: Yup.date().when("data_inicio", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_inicio || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida")
                        .required("O campo é obrigatório."),
                }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            values.data_fim = dataToStr(values.data_fim, "yyyy-MM-dd");
            values.data_inicio = dataToStr(values.data_inicio, "yyyy-MM-dd");
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Fases de projetos cadastrado com sucesso!",
                            life: 1500,
                        });

                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url, body: { ...values, responsavel: values.responsavel?.id } }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Fases de projetos alterado com sucesso!",
                            life: 1500,
                        });

                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            responsavel: values.responsavel?.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova fase do projeto" : "Manutenção da fase de projeto"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="projeto" label="Projeto" obrigatorio />
                        <Dropdown
                            id="projeto"
                            name="projeto"
                            placeholder="Selecione um projeto"
                            url="/plano-operacional/projetos/"
                            optionValue="id"
                            optionLabel="nome"
                            autoFocus
                            value={formik.values.projeto}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.projeto,
                            })}
                        />
                        {formik.errors.projeto && <small className="p-error">{formik.errors.projeto}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="fase" label="Fase" obrigatorio />
                        <InputNumber
                            id="fase"
                            name="fase"
                            showButtons
                            min={0}
                            value={formik.values.fase}
                            onValueChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.fase,
                            })}
                            autoComplete="off"
                        />
                        {formik.errors.fase && <small className="p-error">{formik.errors.fase}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data_inicio" label="Data inicio" obrigatorio />
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            value={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data_fim" label="Data final" obrigatorio />
                        <MakoCalendar
                            id="data_fim"
                            name="data_fim"
                            value={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_fim,
                            })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.descricao,
                            })}
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
