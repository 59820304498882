import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";

import { InputText } from "primereact/inputtext";

import { MakoInputCliente, MakoInputPercentMoeda } from "@/components/MakoInputs";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useTaxasRecebimentos from "@/pages/Financeiro/Financeiro/Recebimentos/hooks/useTaxasRecebimentos";
import useReceberCrediario from "../../../../../hooks/useReceberCrediario";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/processar";

const INITIAL_VALUES = {
    id: null,
    venda: null,
    cliente: null,
    vencimento: null,
    parcela: null,
    atraso: null,
    descontos: 0,
    descontos_percent: 0,
    multa: null,
    juros: null,
    valor: 0,
    valor_pagar: 0,
    template_rateio: null,
};

const Component = ({ successCallback = () => {} }, ref) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov } = useCaixaMovimento();
    const { httpPost } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const { pendencia } = useReceberCrediario();
    const { taxas } = useTaxasRecebimentos({ recebimentoId: formik.values.id });

    const { descontos, descontos_percent, juros, multa, recebido } = taxas;
    const { tipo_chave, grupo } = pendencia || {};

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                template_rateio: Yup.number()
                    .required("O campo 'regra rateio' é obrigatório")
                    .typeError("Informe uma 'regra rateio' válida"),
                valor_pagar: Yup.number()
                    .min(0.01, "Informe um 'valor' maior que 0")
                    .required("O campo 'valor' é obrigatório")
                    .typeError("Informe um 'valor' válido"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { id, multa, juros, descontos, valor_pagar, template_rateio } = values;

            let success = false;
            let dataCallback = null;

            const handlers = {
                200: ({ data }) => {
                    success = true;
                    dataCallback = data;
                    formikHelpers.resetForm({ values: INITIAL_VALUES });
                },
            };

            const url = BASE_URL.replace("{caixa}", caixaMov?.id).replace("{pendencia}", id);
            showLoading();
            await httpPost(
                {
                    url,
                    body: {
                        juros,
                        multa,
                        descontos,
                        tipo_chave,
                        template_rateio,
                        grupo: grupo?.id,
                        valor_calculado: valor_pagar,
                        preprocessar: true,
                    },
                },
                handlers
            );
            hideLoading();
            if (success) successCallback(dataCallback);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    const prepareEdit = useCallback(
        ({ id, venda, cliente, vencimento, parcela, atraso, descontos, multa, juros, valor, template_rateio }) => {
            if (id !== formik.values.id) {
                setValues({
                    ...INITIAL_VALUES,
                    id,
                    venda,
                    cliente,
                    vencimento,
                    parcela,
                    atraso,
                    descontos,
                    multa,
                    juros,
                    valor,
                    template_rateio,
                    valor_pagar: valor,
                });
            }
        },
        [setValues, formik.values.id]
    );

    const onChageMulta = useCallback(
        (e) => {
            const { value } = e;
            formik.handleChange(e);
            setFieldValue("valor_pagar", formik.values.valor + value + formik.values.juros - formik.values.descontos);
        },
        [formik, setFieldValue]
    );

    const onChageJuros = useCallback(
        (e) => {
            const { value } = e;
            formik.handleChange(e);
            setFieldValue("valor_pagar", formik.values.valor + value + formik.values.multa - formik.values.descontos);
        },
        [formik, setFieldValue]
    );

    const onChangeDescontos = useCallback(
        (desconto) => {
            setFieldValue("valor_pagar", formik.values.valor + formik.values.juros + formik.values.multa - desconto);
        },
        [formik.values.juros, formik.values.multa, formik.values.valor, setFieldValue]
    );

    const preencherTaxas = useCallback(() => {
        setFieldValue("descontos", descontos);
        setFieldValue("descontos_percent", descontos_percent || 0);
        setFieldValue("juros", juros);
        setFieldValue("multa", multa);
        setFieldValue("valor_pagar", recebido);
    }, [descontos, descontos_percent, juros, multa, recebido, setFieldValue]);

    useEffect(() => preencherTaxas(), [preencherTaxas]);

    useImperativeHandle(ref, () => ({ prepareEdit }), [prepareEdit]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="venda" label="Venda / Orçamento" />
                    <InputText id="venda" name="venda" value={formik.values.venda} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="cliente" label="Cliente" />
                    <MakoInputCliente id="cliente" name="cliente" value={formik.values.cliente} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="vencimento" label="Vencimento" />
                    <MakoCalendar id="vencimento" name="vencimento" value={formik.values.vencimento} disabled />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText id="parcela" name="parcela" value={formik.values.parcela} disabled />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="atraso" label="Dias atraso" />
                    <InputText id="atraso" name="atraso" value={formik.values.atraso} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="template_rateio" label="Regra de rateio" obrigatorio />
                    <Dropdown
                        id="template_rateio"
                        name="template_rateio"
                        url="/financeiro/templates-rateios/"
                        optionValue="id"
                        optionLabel="descricao"
                        filter
                        filterBy="descricao"
                        showClear={false}
                        value={formik.values.template_rateio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.template_rateio })}
                    />
                    {formik.errors.template_rateio && (
                        <small className="p-error">{formik.errors.template_rateio}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="multa" label="Valor da multa" />
                    <MakoInputMoeda
                        id="multa"
                        name="multa"
                        valueMoeda={formik.values.multa}
                        onChangeMoeda={onChageMulta}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="juros" label="Valor dos juros" />
                    <MakoInputMoeda
                        id="juros"
                        name="juros"
                        valueMoeda={formik.values.juros}
                        onChangeMoeda={onChageJuros}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="descontos" label="Descontos" />
                    <MakoInputPercentMoeda
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={setFieldValue}
                        getDesconto={onChangeDescontos}
                        disabled={!formik.values.venda}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor_pagar" label="Total da parcela" />
                    <MakoInputMoeda
                        id="valor_pagar"
                        name="valor_pagar"
                        valueMoeda={formik.values.valor_pagar}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Button
                        icon={MAKO_ICONS.CONFIRMAR}
                        loading={loading}
                        type="submit"
                        className="p-button-success"
                        style={{ top: "1.9rem", width: "100%" }}
                    />
                </div>
            </div>
        </form>
    );
};

export const Dados = forwardRef(Component);
