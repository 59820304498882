import { VendasServicosPage } from "@/pages/Vendas/Cadastros/Servicos";
import { VendasServicosForm } from "@/pages/Vendas/Cadastros/Servicos/form";
import { TipoEstagioPage } from "@/pages/Vendas/Cadastros/TipoEstagio";
import { TipoEstagioForm } from "@/pages/Vendas/Cadastros/TipoEstagio/form";
import { EstagioVendaPage } from "@/pages/Vendas/Cadastros/EstagioVenda";
import { EstagioVendaForm } from "@/pages/Vendas/Cadastros/EstagioVenda/form";
import { VendasPage } from "@/pages/Vendas/Venda";
import { TabVendasContext } from "@/pages/Vendas/Venda/form";
import { SequenciaEstagioPage } from "@/pages/Vendas/Cadastros/EstagioVenda/SequenciaEstagio";
import { ComissaoVendasPage } from "@/pages/Vendas/Cadastros/ComissaoVenda";
import { ComissaoVendasForm } from "@/pages/Vendas/Cadastros/ComissaoVenda/form";
import { FormacaoPrecoPage } from "@/pages/Vendas/Cadastros/FormacaoPrecos";
import { FormacaoPrecoForm } from "@/pages/Vendas/Cadastros/FormacaoPrecos/form";
import { CrediarioPage } from "@/pages/Vendas/Crediario/Crediario";
import { TabCreadiarioContext } from "@/pages/Vendas/Crediario/Crediario/tabCrediario";
import { VendasConsultaSpcForm } from "@/pages/Vendas/Crediario/ConsultaSpc/form";
import { VendasConsultaSpcPage } from "@/pages/Vendas/Crediario/ConsultaSpc";
import { RegraArredondamentoValorPage } from "@/pages/Vendas/Cadastros/ArredondamentoValor";
import { TabRegraArredondamentoValor } from "@/pages/Vendas/Cadastros/ArredondamentoValor/TabRegraArredondamentoValor";
import { RegraCrediarioPage } from "@/pages/Vendas/Crediario/Cadastros/RegraCrediario";
import { NomeRelacaoForm } from "@/pages/Vendas/Crediario/Cadastros/NomeRelacao/form";
import { NomeRelacaoPage } from "@/pages/Vendas/Crediario/Cadastros/NomeRelacao";
import { CrediarioTipoRendaForm } from "@/pages/Vendas/Crediario/Cadastros/TipoRenda/form";
import { CrediarioTipoRendaPage } from "@/pages/Vendas/Crediario/Cadastros/TipoRenda";
import { CrediarioOcupacaoForm } from "@/pages/Vendas/Crediario/Cadastros/Ocupacao/form";
import { CrediarioOcupacaoPage } from "@/pages/Vendas/Crediario/Cadastros/Ocupacao";
import { TiposDocRendaForm } from "@/pages/Vendas/Crediario/Cadastros/TipoDocRenda/form";
import { TiposDocRendaPage } from "@/pages/Vendas/Crediario/Cadastros/TipoDocRenda";
import { RegraCrediarioForm } from "@/pages/Vendas/Crediario/Cadastros/RegraCrediario/formRegraCrediario";
import { CarteiraClientePage } from "@/pages/Vendas/Cadastros/CarteiraCliente";
import { CarteiraClienteForm } from "@/pages/Vendas/Cadastros/CarteiraCliente/form";
import { RotaEntregaPage } from "@/pages/Vendas/Cadastros/RotaEntrega";
import { RotaEntregaForm } from "@/pages/Vendas/Cadastros/RotaEntrega/form";
import { RegraApuracaoComissaoPage } from "@/pages/Vendas/Cadastros/RegraApuracaoComissao";
import { RegraApuracaoComissaoForm } from "@/pages/Vendas/Cadastros/RegraApuracaoComissao/form";
import { FaixaComissaoForm } from "@/pages/Vendas/Cadastros/FaixaComissao/form";
import { FaixaComissaoPage } from "@/pages/Vendas/Cadastros/FaixaComissao";
import { ApuracaoComissaoPage } from "@/pages/Vendas/Comissao/ApuracaoComissao";
import { ApuracaoComissaoForm } from "@/pages/Vendas/Comissao/ApuracaoComissao/form";
import { EntregasPage } from "@/pages/Vendas/Entregas";
import { VoucherPage } from "@/pages/Vendas/Cadastros/Vouchers";
import { VoucherForm } from "@/pages/Vendas/Cadastros/Vouchers/form";
import { VendaTrocaPage } from "@/pages/Vendas/Trocas";
import { TabTrocaContext } from "@/pages/Vendas/Trocas/tabTrocas";
import { MotivoDevolucaoPage } from "@/pages/Vendas/Cadastros/MotivoDevolucao";
import { MotivoDevolucaoForm } from "@/pages/Vendas/Cadastros/MotivoDevolucao/form";
import { VendaDevolucaoPage } from "@/pages/Vendas/Devolucao";
import { DevolucaoVendaForm } from "@/pages/Vendas/Devolucao/DevolucaoForm";
import { ConsultaPrecosPage } from "@/pages/Vendas/ConsultaPrecos";
import { OrcamentoRapidoForm } from "@/pages/Vendas/OrcamentoRapido/form";
import { OrcamentoRapidoPage } from "@/pages/Vendas/OrcamentoRapido";
import { RelatorioTabelaProdutosVendas } from "@/pages/Relatorios/Vendas/TabelaProdutosVendas";
import { RelatorioCurvaAbcPage } from "@/pages/Relatorios/Vendas/CurvaABC";
import { RelatorioExtratoComissaoSintetico } from "@/pages/Relatorios/Vendas/ExtratoComissaoSintetico";
import { RelatorioVendasListagemSimples } from "@/pages/Relatorios/Vendas/ListagemSimples";
import { RelatorioExtratoComissao } from "@/pages/Relatorios/Vendas/ExtratoComissaoAnalitico";
import { RelatorioHistoricoSimplesCliente } from "@/pages/Relatorios/Vendas/HistoricoSimplesCliente";
import { ProdutoTabelaPrecosPage } from "@/pages/Vendas/Cadastros/TabelaPrecos";
import { ProdutoTabelaPrecosForm } from "@/pages/Vendas/Cadastros/TabelaPrecos/form";
import { VigenciaPrecoPage } from "@/pages/Vendas/Cadastros/VigenciaPreco";
import { VigenciaPrecoForm } from "@/pages/Vendas/Cadastros/VigenciaPreco/form";
import { RelatorioTabelaPrecos } from "@/pages/Relatorios/Vendas/TabelaPrecos";
import { RelatorioEntrega } from "@/pages/Vendas/Entregas/features/RelatorioEntrega";
import { RelatorioEntregasRealizadas } from "@/pages/Vendas/Entregas/features/RelatorioRealizadas";
import { RelatorioTabelaProdutosVendidos } from "@/pages/Relatorios/Vendas/ProdutosVendidos";
import { RelatorioItensFaturadosVenda } from "@/pages/Relatorios/Vendas/ItensFaturadosVenda";
import { EntregasRealizadasPage } from "@/pages/Vendas/EntregasRealizadas";
import { TipoOcorrenciaAssistTecnicaPage } from "@/pages/Vendas/Cadastros/TipoOcorrenciaAssistTecnica";
import { TipoOcorrenciaAssistTecnicaForm } from "@/pages/Vendas/Cadastros/TipoOcorrenciaAssistTecnica/form";
import { AssistenciaTecnicaPage } from "@/pages/Vendas/AssistenciaTecnica";
import { AssistenciaTecnicaForm } from "@/pages/Vendas/AssistenciaTecnica/form";
import { AjusteComissaoPage } from "@/pages/Vendas/Comissao/AjusteComissao";
import { ComissaoMontagemPage } from "@/pages/Vendas/Comissao/ComissaoMontagem";

export const vendasRoutes = [
    { path: "/vendas/venda", component: VendasPage },
    { path: "/vendas/venda/form", component: TabVendasContext },
    { path: "/vendas/venda/trocas", component: VendaTrocaPage },
    { path: "/vendas/venda/trocas/form", component: TabTrocaContext },
    { path: "/vendas/venda/devolucao", component: VendaDevolucaoPage },
    { path: "/vendas/venda/devolucao/form", component: DevolucaoVendaForm },
    { path: "/vendas/venda/crediario", component: CrediarioPage },
    { path: "/vendas/venda/crediario/form", component: TabCreadiarioContext },
    { path: "/vendas/venda/orcamento-rapido", component: OrcamentoRapidoPage },
    { path: "/vendas/venda/orcamento-rapido/form", component: OrcamentoRapidoForm },
    { path: "/vendas/venda/consultas-spc", component: VendasConsultaSpcPage },
    { path: "/vendas/venda/consultas-spc/form", component: VendasConsultaSpcForm },
    { path: "/vendas/venda/crediario/tipos-documento-renda", component: TiposDocRendaPage },
    { path: "/vendas/venda/crediario/tipos-documento-renda/form", component: TiposDocRendaForm },
    { path: "/vendas/venda/crediario/regra-crediario", component: RegraCrediarioPage },
    { path: "/vendas/venda/crediario/regra-crediario/form", component: RegraCrediarioForm },
    { path: "/vendas/venda/crediario/nomes-relacao", component: NomeRelacaoPage },
    { path: "/vendas/venda/crediario/nomes-relacao/form", component: NomeRelacaoForm },
    { path: "/vendas/venda/crediario/tipos-renda", component: CrediarioTipoRendaPage },
    { path: "/vendas/venda/crediario/tipos-renda/form", component: CrediarioTipoRendaForm },
    { path: "/vendas/venda/crediario/ocupacao", component: CrediarioOcupacaoPage },
    { path: "/vendas/venda/crediario/ocupacao/form", component: CrediarioOcupacaoForm },
    { path: "/vendas/venda/crediario/tipo-documento-renda", component: TiposDocRendaPage },
    { path: "/vendas/venda/crediario/tipo-documento-renda/form", component: TiposDocRendaForm },
    { path: "/vendas/venda/entregas", component: EntregasPage },
    { path: "/vendas/venda/entregas-realizadas", component: EntregasRealizadasPage },
    { path: "/vendas/cadastros/consulta-precos", component: ConsultaPrecosPage },
    { path: "/vendas/cadastros/regra-apuracao-comissao", component: RegraApuracaoComissaoPage },
    { path: "/vendas/cadastros/regra-apuracao-comissao/form", component: RegraApuracaoComissaoForm },
    { path: "/vendas/comissao/apuracao", component: ApuracaoComissaoPage },
    { path: "/vendas/comissao/apuracao/form", component: ApuracaoComissaoForm },
    { path: "/vendas/comissao/montagem", component: ComissaoMontagemPage },
    { path: "/vendas/comissao/ajustes", component: AjusteComissaoPage },
    { path: "/vendas/cadastros/faixa-comissao", component: FaixaComissaoPage },
    { path: "/vendas/cadastros/faixa-comissao/form", component: FaixaComissaoForm },
    { path: "/vendas/cadastros/motivo-devolucao", component: MotivoDevolucaoPage },
    { path: "/vendas/cadastros/motivo-devolucao/form", component: MotivoDevolucaoForm },
    { path: "/vendas/cadastros/servicos", component: VendasServicosPage },
    { path: "/vendas/cadastros/servicos/form", component: VendasServicosForm },
    { path: "/vendas/cadastros/tipos-estagios", component: TipoEstagioPage },
    { path: "/vendas/cadastros/tipos-estagios/form", component: TipoEstagioForm },
    { path: "/vendas/cadastros/estagios-vendas", component: EstagioVendaPage },
    { path: "/vendas/cadastros/estagios-vendas/form", component: EstagioVendaForm },
    { path: "/vendas/cadastros/estagios-vendas/sequencia", component: SequenciaEstagioPage },
    { path: "/vendas/cadastros/arredondamento-valor", component: RegraArredondamentoValorPage },
    { path: "/vendas/cadastros/arredondamento-valor/form", component: TabRegraArredondamentoValor },
    { path: "/vendas/cadastros/comissao-vendas", component: ComissaoVendasPage },
    { path: "/vendas/cadastros/comissao-vendas/form", component: ComissaoVendasForm },
    { path: "/vendas/cadastros/formacao-precos", component: FormacaoPrecoPage },
    { path: "/vendas/cadastros/formacao-precos/form", component: FormacaoPrecoForm },
    { path: "/vendas/cadastros/carteira-clientes", component: CarteiraClientePage },
    { path: "/vendas/cadastros/carteira-clientes/form", component: CarteiraClienteForm },
    { path: "/vendas/cadastros/rota-entrega", component: RotaEntregaPage },
    { path: "/vendas/cadastros/rota-entrega/form", component: RotaEntregaForm },
    { path: "/vendas/cadastros/voucher", component: VoucherPage },
    { path: "/vendas/cadastros/voucher/form", component: VoucherForm },
    { path: "/vendas/cadastros/tabela-precos", component: ProdutoTabelaPrecosPage },
    { path: "/vendas/cadastros/tabela-precos/form", component: ProdutoTabelaPrecosForm },
    { path: "/vendas/relatorios/vendas-faturadas", component: RelatorioTabelaProdutosVendas },
    { path: "/vendas/relatorios/curva-abc", component: RelatorioCurvaAbcPage },
    { path: "/vendas/relatorios/extrato-comissao-sintetico", component: RelatorioExtratoComissaoSintetico },
    { path: "/vendas/relatorios/listagem-simples", component: RelatorioVendasListagemSimples },
    { path: "/vendas/relatorios/extrato-comissao-analitco", component: RelatorioExtratoComissao },
    { path: "/vendas/relatorios/historico-simples-cliente", component: RelatorioHistoricoSimplesCliente },
    { path: "/vendas/cadastros/vigencia-preco", component: VigenciaPrecoPage },
    { path: "/vendas/cadastros/vigencia-preco/form", component: VigenciaPrecoForm },
    { path: "/vendas/relatorios/tabela-precos", component: RelatorioTabelaPrecos },
    { path: "/vendas/relatorios/entrega", component: RelatorioEntrega },
    { path: "/vendas/relatorios/entregas-realizadas", component: RelatorioEntregasRealizadas },
    { path: "/vendas/relatorios/tabela-produtos", component: RelatorioTabelaProdutosVendidos },
    { path: "/vendas/relatorios/itens-faturados-vendas", component: RelatorioItensFaturadosVenda },
    { path: "/vendas/cadastros/assistencia-tecnica/tipos-ocorrencia", component: TipoOcorrenciaAssistTecnicaPage },
    { path: "/vendas/cadastros/assistencia-tecnica/tipos-ocorrencia/form", component: TipoOcorrenciaAssistTecnicaForm },
    { path: "/vendas/assistencias-tecnicas", component: AssistenciaTecnicaPage },
    { path: "/vendas/assistencias-tecnicas/form", component: AssistenciaTecnicaForm },
];
