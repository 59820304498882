import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoDropdownOrdenado, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_ESTOQUEREGULADOR } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

const OPTIONS_ORDENACAO = [
    { value: "categoria", label: "Categoria + Descrição" },
    { value: "venda", label: "Categoria + Venda diária" },
];

export const RelatorioEstoqueRegulador = () => {
    const [empresa, setEmpresa] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();
    const formRef = useRef();

    useClearRefs(formRef, categoriaRef);

    const submit = (e) => {
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "estoque",
            label: "Estoque",
            path: "nome",
        },
        {
            key: "categoria",
            label: "Grupo",
            path: "nome",
        },
        {
            key: ["periodo_gte", "periodo_lte"],
            label: "Período de venda",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionLabel: "label",
            optionKey: "value",
            options: OPTIONS_ORDENACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório!")
                    .typeError("Informe uma 'empresa' válida"),
                estoque: Yup.object()
                    .required("O campo 'estoque' é obrigatório!")
                    .typeError("Informe um 'estoque' válido"),
                categoria: Yup.number().nullable().default(null).typeError("Informe um 'grupo' válido"),
                fornecedor: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida"),
                periodo_gte: Yup.date()
                    .required("O campo 'período inicial' é obrigatório!")
                    .typeError("Informe um 'período inicial' válido"),
                periodo_lte: Yup.date()
                    .required("O campo 'período final' é obrigatório!")
                    .typeError("Informe um 'período final' válida"),
                orderBy: Yup.string().typeError("Seleciona uma 'ordenação' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: categoriaRef.current,
                empresa: dadosValidados?.empresa ? empresa : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.periodo_gte)
                dadosValidados.periodo_gte = dataToStr(dadosValidados.periodo_gte, "yyyy-MM-dd");
            if (dadosValidados.periodo_lte)
                dadosValidados.periodo_lte = dataToStr(dadosValidados.periodo_lte, "yyyy-MM-dd");

            const filtros = gerarFiltrosDadosValidados(dadosValidados);
            solicitarRelatorio({
                chave: RELATORIO_ESTOQUE_ESTOQUEREGULADOR,
                emails,
                filtros,
                versao,
                corpo_email,
                filtros_template: filtrosTemplateAplicados,
                enviar_fila: background,
            });
            limparFormulario();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const onChangeEmpresa = (e) => {
        setEmpresa(e?.empresa);
    };

    return (
        <R.Wrapper titulo={"de estoque regulador"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        categoria: null,
                        fornecedor: null,
                        empresa: null,
                        estoque: null,
                        orderBy: "",
                        periodo_gte: null,
                        periodo_lte: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        required: true,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Estoque",
                        inputId: "estoque",
                        inputName: "estoque",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione um estoque",
                            filter: true,
                            filterBy: "nome",
                            url: `/produtos/estoques-empresas/?empresa=${empresa?.id}`,
                            optionLabel: "nome",
                            buscar: !!empresa?.id,
                        },
                        fieldSize: 4,
                    },
                    {
                        inputId: "periodo_gte",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        required: true,
                        componentProps: {
                            label: "Período de venda",
                            nameInicio: "periodo_gte",
                            nameFinal: "periodo_lte",
                            valueInicio: "periodo_gte",
                            valueFinal: "periodo_lte",
                            errorInicio: "periodo_gte",
                            errorFinal: "periodo_lte",
                            obrigatorio: true,
                            makoDate: true,
                        },
                    },
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 4,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Ordenado por",
                        inputId: "ordering",
                        inputName: "ordering",
                        component: MakoDropdownOrdenado,
                        componentProps: {
                            campos: OPTIONS_ORDENACAO,
                        },
                        fieldSize: 4,
                        render: false,
                    },
                ]}
            >
                <R.Buttons.GerarPdf
                    chave={RELATORIO_ESTOQUE_ESTOQUEREGULADOR}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                    onlyBackground
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
        </R.Wrapper>
    );
};
