import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const Modal = ({ nota }, ref) => {
    const [correcao, setCorrecao] = useState("");
    const { httpPost } = useHttp();
    const { showSuccess, showError } = useToast();
    const [visible, setVisible] = useState(false);

    async function handleSubmit() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Evento registrado e vinculado a NF-e.",
                        life: 3000,
                    });
                    fecharModal();
                },
                400: ({ err }) => {
                    const { msg } = err;
                    showError({
                        summary: "Erro",
                        detail: msg || "Falha ao enviar carta de correção.",
                        life: 1500,
                    });
                },
            };

            await httpPost(
                {
                    url: `/fiscal/enviar-carta-correcao/${nota.id}/`,
                    body: {
                        correcao,
                    },
                },
                handlers
            );
        } catch (error) {
            showError();
        }
    }

    function validateForm() {
        return correcao.length >= 15;
    }

    const fecharModal = () => {
        setCorrecao("");
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ setVisible }));

    return (
        <Dialog
            header="Carta de Correção (CC-e)"
            visible={visible}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "35vw" }}
            onHide={() => fecharModal()}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <InputTextarea
                            id="correcao"
                            placeholder="Digite a correção (min. 15 caracteres)"
                            value={correcao}
                            rows={6}
                            onChange={(e) => setCorrecao(e.target.value)}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-12">
                    <Button
                        label="Enviar"
                        type="button"
                        className="p-button-info"
                        disabled={!validateForm()}
                        onClick={handleSubmit}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const CartaCorrecaoFormModal = forwardRef(Modal);
