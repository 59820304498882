import React, { useCallback, useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoDropdownVendedores } from "@/components/MakoInputs";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";

import {
    SIM_NAO_BOOLEAN,
    TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC,
    TIPO_STATUS_CONTRATO_CHOICE,
} from "@/assets/constants/constants";
import { gerarStringAleatoria } from "@/assets/util/util";

import useContratoServico from "@/hooks/useContratoServico";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

import { getDate } from "date-fns";
import { parseData } from "@/assets/util/datas";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoCadastroPessoas } from "@/components/MakoCadastroPessoas";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";

const FormDadosBasicos = () => {
    const { contrato, updateContrato } = useContratoServico();
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            numero: "",
            nome_curto: "",
            descricao: "",
            cliente: null,
            data_negociacao: null,
            data_iniciacao: new Date(),
            data_finalizacao: null,
            vendedor: null,
            percentual_comissao: 0,
            primeiro_vencimento: null,
            dia_vencimento: null,
            intervalo_recebimento: null,
            link_documento: "",
            template_rateio: null,
            status: "P",
            aditivo: false,
            contrato_referencia: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                numero: Yup.string()
                    .max(9, "Máximo de caracteres atingido: 9")
                    .required("O campo 'numero' é obrigatório")
                    .typeError("Informe um 'numero' válido"),
                nome_curto: Yup.string()
                    .max(30, "Máximo de caracteres atingido: 30")
                    .required("O campo 'numero' é obrigatório")
                    .typeError("Informe um 'numero' válido"),
                descricao: Yup.string()
                    .required("O campo 'descricao' é obrigatório")
                    .typeError("Informe um 'descricao' válido"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório"),
                    })
                    .typeError("Informe um 'cliente' válida"),
                data_negociacao: Yup.date().nullable().typeError("Informe uma 'negociação' válida"),
                data_iniciacao: Yup.date()
                    .required("O campo 'data inicicao' é obrigatório")
                    .typeError("Informe uma 'data iniciação' válida"),
                data_finalizacao: Yup.date().when("data_iniciacao", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.data_iniciacao || new Date(),
                            "O campo 'data finalização' não pode ser anterior a inicial"
                        )
                        .required("O campo 'data finalizacao' é obrigatório")
                        .typeError("Informe uma 'data finalização' válida"),
                }),
                vendedor: Yup.number()
                    .required("O campo 'vendedor' é obrigatório.")
                    .typeError("Informe um 'vendedor' válido."),
                percentual_comissao: Yup.number()
                    .required("O campo 'percentual comissão' é obrigatório.")
                    .typeError("Informe um 'percentual comissão' válido"),
                primeiro_vencimento: Yup.date()
                    .required("O campo '1° vencimento' é obrigatório")
                    .typeError("Informe uma 'primeiro vencimento' válida"),
                intervalo_recebimento: Yup.number()
                    .required("O campo 'intervalo de recebimento' é obrigatório.")
                    .typeError("Informe um 'intervalo de recebimento' válido"),
                template_rateio: Yup.number()
                    .required("O campo 'template de ratio' é obrigatório.")
                    .typeError("Informe uma 'template de ratio' válida."),
                link_documento: Yup.string().nullable().typeError("Informe um 'descricao' válido"),
                contrato_referencia: values.aditivo
                    ? Yup.object()
                          .required("O campo 'contrato referência' é obrigatório.")
                          .typeError("Informe uma 'contrato referência' válida.")
                    : null,
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateContrato({
                ...values,
                contrato_referencia: values.contrato_referencia?.id || null,
                dia_vencimento: values.primeiro_vencimento
                    ? getDate(values.primeiro_vencimento) || getDate(parseData(values.primeiro_vencimento))
                    : null,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, a requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        }
    }

    function gerarCodigo() {
        setFieldValue("numero", gerarStringAleatoria(9));
    }

    const handleReset = useCallback(() => {
        const contratoId = formik.values?.id;
        formik.resetForm();
        if (contratoId) setFieldValue("id", contratoId);
    }, [formik, setFieldValue]);

    useEffect(() => {
        if (contrato) setValues({ ...contrato });
    }, [contrato, setValues]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="numero" label="N° do contrato" obrigatorio />
                        <div className="p-inputgroup">
                            <InputText
                                id="numero"
                                name="numero"
                                onChange={formik.handleChange}
                                value={formik.values.numero}
                                autoComplete="off"
                                disabled={contrato?.status === "F"}
                                maxLength={8}
                                autoFocus
                                className={classNames({ "p-invalid": formik.errors.numero })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-refresh"
                                className="p-button-info"
                                tooltip="Gerar código aleatório"
                                disabled={contrato?.status === "F"}
                                onClick={gerarCodigo}
                            />
                        </div>
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="nome_curto" label="Nome curto" obrigatorio />
                        <InputText
                            id="nome_curto"
                            name="nome_curto"
                            maxLength={30}
                            value={formik.values.nome_curto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome_curto })}
                        />
                        {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="descricao" label="Descrição do contrato(Titulo)" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="cliente" label="Cliente" obrigatorio />
                        <div className="p-inputgroup">
                            <MakoInputCliente
                                id="cliente"
                                name="cliente"
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                                disabled={contrato?.status === "F"}
                            />
                            <MakoCadastroPessoas />
                        </div>

                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="modelo_contrato" label="Modelo contrato jurídico" />
                        <Dropdown
                            id="modelo_contrato"
                            name="modelo_contrato"
                            url={`/servicos/modelos-contrato?limit=1000`}
                            optionValue="id"
                            optionLabel="titulo"
                            filter
                            filterBy="titulo"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.modelo_contrato}
                            onChange={formik.handleChange}
                            disabled={!!contrato?.situacao_assinatura || contrato?.situacao_assinatura === "N"}
                            className={classNames({ "p-invalid": formik.errors.modelo_contrato })}
                        />
                        {formik.errors.modelo_contrato && (
                            <small className="p-error">{formik.errors.modelo_contrato}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="template_rateio" label="Regra de rateio" obrigatorio />
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            disabled={contrato?.status === "F"}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_negociacao" label="Data negociação" />
                        <MakoCalendar
                            id="data_negociacao"
                            name="data_negociacao"
                            value={formik.values.data_negociacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_negociacao })}
                        />
                        {formik.errors.data_negociacao && (
                            <small className="p-error">{formik.errors.data_negociacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_iniciacao" label="Data Inicio" obrigatorio />
                        <MakoCalendar
                            id="data_iniciacao"
                            name="data_iniciacao"
                            value={formik.values.data_iniciacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_iniciacao })}
                        />
                        {formik.errors.data_iniciacao && (
                            <small className="p-error">{formik.errors.data_iniciacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_finalizacao" label="Previsão encerramento" obrigatorio />
                        <MakoCalendar
                            id="data_finalizacao"
                            name="data_finalizacao"
                            value={formik.values.data_finalizacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_finalizacao })}
                        />
                        {formik.errors.data_finalizacao && (
                            <small className="p-error">{formik.errors.data_finalizacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="dia_vencimento" label="Dia vencimento" />
                        <InputNumber
                            id="dia_vencimento"
                            name="dia_vencimento"
                            value={formik.values.dia_vencimento}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.dia_vencimento })}
                        />
                        {formik.errors.dia_vencimento && (
                            <small className="p-error">{formik.errors.dia_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="dia_util" label="É dia útil?" />
                        <SelectButton
                            id="dia_util"
                            name="dia_util"
                            value={formik.values.dia_util}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                            tooltip="Referente ao dia do vencimento."
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="vendedor" label="Vendedor" obrigatorio />
                        <MakoDropdownVendedores
                            id="vendedor"
                            name="vendedor"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            disabled={contrato?.status === "F"}
                            value={formik.values.vendedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vendedor })}
                        />
                        {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="percentual_comissao" label="% comissão" obrigatorio />
                        <MakoInputPercent
                            id="percentual_comissao"
                            name="percentual_comissao"
                            onValueChange={formik.handleChange}
                            value={formik.values.percentual_comissao}
                            disabled={contrato?.status === "F"}
                            className={classNames({ "p-invalid": formik.errors.percentual_comissao })}
                        />
                        {formik.errors.percentual_comissao && (
                            <small className="p-error">{formik.errors.percentual_comissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label
                            htmlFor="intervalo_recebimento"
                            label="Intervalo de recebimento do contrato"
                            obrigatorio
                        />
                        <Dropdown
                            id="intervalo_recebimento"
                            name="intervalo_recebimento"
                            placeholder="Selecione"
                            options={TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC}
                            optionValue="value"
                            optionLabel="label"
                            disabled={contrato?.status === "F"}
                            value={formik.values.intervalo_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.intervalo_recebimento })}
                        />
                        {formik.errors.intervalo_recebimento && (
                            <small className="p-error">{formik.errors.intervalo_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="primeiro_vencimento" label="1° vencimento" obrigatorio />
                        <MakoCalendar
                            id="primeiro_vencimento"
                            name="primeiro_vencimento"
                            value={formik.values.primeiro_vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.primeiro_vencimento })}
                        />
                        {formik.errors.primeiro_vencimento && (
                            <small className="p-error">{formik.errors.primeiro_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_compromisso" label="Data compromisso jurídico" />
                        <MakoCalendar
                            id="data_compromisso"
                            name="data_compromisso"
                            value={formik.values.data_compromisso}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_compromisso })}
                        />
                        {formik.errors.data_compromisso && (
                            <small className="p-error">{formik.errors.data_compromisso}</small>
                        )}
                    </div>

                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="aditivo" label="Contrato aditivo?" />
                        <SelectButton
                            id="aditivo"
                            name="aditivo"
                            value={formik.values.aditivo}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setFieldValue("contrato_referencia", null);
                            }}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                            tooltip="Referente ao dia do vencimento."
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label
                            htmlFor="contrato_referencia"
                            label="Contrato referência"
                            obrigatorio={formik.values.aditivo}
                        />
                        <MakoAutoComplete
                            id="contrato_referencia"
                            name="contrato_referencia"
                            minCaracteresBusca={3}
                            field="descricao"
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            urlSearch={`/servicos/contrato-servico?search=`}
                            itemTemplate={({ id, descricao }) => `${id} - ${descricao}`}
                            value={formik.values.contrato_referencia}
                            onChange={formik.handleChange}
                            disabled={!!!formik.values.aditivo}
                            className={classNames({ "p-invalid": formik.errors.contrato_referencia })}
                        />
                        {formik.errors.contrato_referencia && (
                            <small className="p-error">{formik.errors.contrato_referencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="status" label="Status" />
                        <Dropdown
                            id="status"
                            disabled
                            name="status"
                            placeholder="Selecione"
                            options={TIPO_STATUS_CONTRATO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                        {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="link_documento" label="Link do documento do contrato" />
                        <InputText
                            id="link_documento"
                            name="link_documento"
                            value={formik.values.link_documento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.link_documento })}
                        />
                        {formik.errors.link_documento && (
                            <small className="p-error">{formik.errors.link_documento}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
                <MakoActionsButtons className="p-mt-2">
                    <Button
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        label={!contrato?.id ? "Incluir dados básicos" : "Grava dados básicos"}
                    />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        className="p-button-warning"
                        onClick={handleReset}
                    />
                </MakoActionsButtons>
            </form>
        </div>
    );
};

export default React.memo(FormDadosBasicos);
