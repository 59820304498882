import React, { useRef } from "react";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TIPO_CONTA_FINANCEIRA_CHOICE } from "@/assets/constants/constants";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/contas-financeiras/";

export const FinanceiroContaFinanceiraPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_CONTA_FINANCEIRA_CHOICE,
                }}
                options={options}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/cadastros/contas-financeiras/form",
                        state: { contaFinanceira: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/contas-financeiras"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="conta financeira"
                    attr="ativa"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                {"Deseja realmente excluir a conta financeira "}
                                <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/contas-financeiras/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "tipo_conta.descricao",
            header: "Tipo conta",
            style: { width: "13%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo_conta",
        },
        {
            field: "ativa",
            header: "Ativa",
            style: { width: "13%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo_conta",
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data_abertura",
            header: "Data abertura",
            style: { width: "12%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "saldo_inicial",
            header: "Saldo inicial",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "saldo_atual",
            header: "Saldo atual",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Contas Financeiras"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    tipo_conta: { value: null, matchMode: "equals" },
                    codigo: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    data_abertura: { value: null, matchMode: "equals" },
                    saldo_inicial: { value: null, matchMode: "equals" },
                    saldo_atual: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
