import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { useFormik } from "formik";
import { PageBase } from "@/components/PageBase";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useToast from "@/hooks/useToast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";

export const CompetenciaMovEstoqueForm = () => {
    const [empresas, setEmpresas] = useState([]);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { httpGet, httpPost, httpPut } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            data_inicio: null,
            data_fim: null,
            ativa: true,
            empresas: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                data_inicio: Yup.date()
                    .required("O campo 'data inicial' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_fim: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .min(values.data_inicio, "A data final não pode ser inferior a data inicial.")
                    .typeError("Informe uma data válida."),
                empresas: Yup.array()
                    .min(1, "Selecione ao menos uma empresa.")
                    .typeError("Selecione uma opção válida."),
            });
            const dadosValidados = await formSchema.validate(values, { abortEarly: false });
            const compMovEstoque = {
                ...dadosValidados,
                data_inicio: dataToStr(dadosValidados.data_inicio, "yyyy-MM-dd"),
                data_fim: dataToStr(dadosValidados.data_fim, "yyyy-MM-dd"),
            };

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Competência de estoque cadastrada com sucesso!",
                            life: 1500,
                        });
                        history.push("/estoque/cadastros/competencias-movimentacoes-estoque");
                    },
                };

                showLoading();
                await httpPost(
                    { url: "/produtos/competencias-movimentacoes-estoques/", body: compMovEstoque },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Competência de estoque alterada com sucesso!",
                            life: 1500,
                        });
                        history.push("/estoque/cadastros/competencias-movimentacoes-estoque");
                    },
                };

                showLoading();
                await httpPut(
                    { url: `/produtos/competencias-movimentacoes-estoques/${values.id}/`, body: compMovEstoque },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const listarEmpresasVinculadas = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                const { results } = data;
                setEmpresas(
                    results.map((vinculo) => {
                        const { perfil } = vinculo;
                        let label = `${perfil.nome} - ${formatarDocumento(perfil.identificacao)}`;
                        if (perfil.nome_curto)
                            label = `${perfil.nome_curto} - ${formatarDocumento(perfil.identificacao)}`;
                        return { ...perfil, label };
                    })
                );
            },
        };

        await httpGet({ url: "/pessoas/grupos-perfis/" }, handlers);
    }, [formatarDocumento, httpGet]);

    useEffect(() => {
        listarEmpresasVinculadas();
    }, [listarEmpresasVinculadas]);

    useEffect(() => {
        if (state) setValues(state);
    }, [state, setValues]);

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Nova competência de movimentação de estoque"
                    : "Manutenção de competência de movimentação de estoque"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-inicio" label="Data inicial" obrigatorio />
                        <MakoCalendar
                            id="data-inicio"
                            name="data_inicio"
                            value={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicio })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>

                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-fim" label="Data final" obrigatorio />
                        <MakoCalendar
                            id="data-fim"
                            name="data_fim"
                            value={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_fim })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="empresas-vinculadas" label="Empresas vinculadas" obrigatorio />
                        <MultiSelect
                            id="empresas-vinculadas"
                            name="empresas"
                            optionValue="id"
                            optionLabel="label"
                            placeholder="Selecione"
                            selectedItemsLabel="{0} empresas selecionadas"
                            maxSelectedLabels={2}
                            filter
                            filterBy="nome,nome_curto,identificacao"
                            value={formik.values.empresas}
                            options={empresas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.empresas })}
                        />
                        {formik.errors.empresas && <small className="p-error">{formik.errors.empresas}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button label="Gravar" icon={MAKO_ICONS.GRAVAR} type="submit" className="p-button-info" />
                    <Button
                        label="Cancelar"
                        type="button"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={() => history.push("/estoque/cadastros/competencias-movimentacoes-estoque")}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
