import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TIPO_TAG_CHOICE } from "@/assets/constants/constants";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

export const NomeRelacaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CREDIARIO_NOMERELACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de nome de relação"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/nomes-relacao/form",
                            state: { relacao: { ...rowData, tipo: rowData.tipo.id } },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/crediario/nome-relacao"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.relacao}
                    prefixo="o"
                    entidade="nome de relação"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o nome de relação <b>{rowData.relacao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O nome de relação não pode ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CREDIARIO_NOMERELACAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/crediario/nomes-relacao/form")}
            />
        </>
    );

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_TAG_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "relacao", header: "Nome relação", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "tipo.descricao",
            header: "Tipo",
            style: { width: "15%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo",
        },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "10%" },
            filter: true,
            filterElement: AtivoFiltroTemplate,
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
            align: "center",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return (
        <>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo="Nome de Relação de Perfil"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa="/crediario/nome-relacao/"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                    filtros={{
                        id: { value: "", matchMode: "equals" },
                        relacao: { value: "", matchMode: "icontains" },
                        tipo: { value: null, matchMode: "equals" },
                        ativo: { value: null, matchMode: "equals" },
                    }}
                />
            </PageBase>
        </>
    );
};
