import React, { useMemo } from "react";

import { Dropdown } from "../../Dropdown";

const OPTIONS_RECENTE = [
    { value: "-id", label: "Mais recente" },
    { value: "id", label: "Menos recente" },
];

export const MakoDropdownOrdenado = ({
    campos = [],
    camposAvulsos = [],
    utilizarPadraoRecente = false,
    campoRecente,
    getOptions,
    ...props
}) => {
    const options = useMemo(() => {
        let _options = [];
        if (utilizarPadraoRecente) {
            if (!campoRecente) return OPTIONS_RECENTE;
            _options = OPTIONS_RECENTE;
            _options[0].value = campoRecente;
            _options[1].value = `-${campoRecente}`;
            return options;
        }
        campos.forEach(({ value, label }) => {
            let asc = { value, label: `${label} (Crescente)` };
            let dsc = { value: `-${value}`, label: `${label} (Descrescente)` };
            _options = [..._options, asc, dsc];
        });
        _options = [..._options, ...camposAvulsos];
        if (typeof getOptions === "function") getOptions(_options);
        return _options;
    }, [campoRecente, campos, camposAvulsos, utilizarPadraoRecente, getOptions]);

    return (
        <Dropdown
            options={options}
            optionValue="value"
            optionLabel="label"
            placeholder="Selecione uma ordenação"
            {...props}
        />
    );
};
