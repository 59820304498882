import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoListagem } from "@/components/MakoListagem";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputPorcentagem } from "@/components/MakoInputs/MakoInputPorcentagem";
import { TIPO_DETALHE_RATEIO } from "@/assets/constants/financeiro";
import { MakoDropdownPlanosContasContabeis } from "@/components/MakoInputs";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const tipoBodyTemplate = (rowData) => {
    if (rowData.id && !rowData._editar && !rowData._deletar) return <span>{rowData.tipo_detalhe.descricao}</span>;
    const tipoDetalhe = TIPO_DETALHE_RATEIO.find((el) => el.value === rowData.tipo_detalhe);
    return <span>{tipoDetalhe?.label || "N/A"}</span>;
};

const vinculoBodyTemplate = (rowData) => {
    if (rowData.id && !rowData._editar && !rowData._deletar) {
        switch (rowData.tipo_detalhe.id) {
            case 1:
                return <span>{rowData.plano_conta_contabil.descricao}</span>;
            case 2:
                return <span>{rowData.plano_conta_financeira.descricao}</span>;
            case 3:
                return <span>{rowData.centro_resultado.descricao}</span>;
            case 4:
                return <span>{rowData.fase_projeto.descricao}</span>;
            default:
                return null;
        }
    } else {
        return <span>{rowData._detalhe.descricao}</span>;
    }
};

const itemTemplate = (item) => (!!item?.conta_titulo ? <b>{item.descricao}</b> : item.descricao);

export const DetalhesTemplateRateioFormBase = ({
    validarPercentual = false,
    natureza,
    detalhesTemplateRateio,
    onSubmit,
}) => {
    const [detalhesRateio, setDetalhesRateio] = useState(detalhesTemplateRateio || []);
    const [detalhes, setDetalhes] = useState([]);

    const formik = useFormik({
        initialValues: {
            tipo_detalhe: null,
            classificador: null,
            fase_projeto: null,
            centro_resultado: null,
            plano_conta_financeira: null,
            plano_conta_contabil: null,
            percentual: 0,
            validarPercentual: validarPercentual,
            _editar: false,
            _deletar: false,
            _index: -1,
        },
        onSubmit: handleSubmit,
    });

    function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_detalhe: Yup.number()
                    .required("O campo 'tipo de detalhe' é obrigatório.")
                    .typeError("Selecione um tipo de detalhe."),
                classificador: Yup.number()
                    .nullable()
                    .when("tipo_detalhe", {
                        is: (value) => [1, 2, 4].includes(value),
                        then: Yup.number()
                            .required("O campo 'classificador' é obrigatório.")
                            .typeError("Selecione um classificador."),
                        otherwise: Yup.number().nullable(),
                    }),

                plano_conta_contabil: Yup.number().when("tipo_detalhe", {
                    is: (val) => val === 1,
                    then: Yup.number()
                        .required("O campo 'conta contábil' é obrigatório.")
                        .typeError("Selecione uma conta contábil."),
                    otherwise: Yup.number().nullable(),
                }),
                plano_conta_financeira: Yup.number().when("tipo_detalhe", {
                    is: (val) => val === 2,
                    then: Yup.number()
                        .required("O campo 'conta financeira' é obrigatório.")
                        .typeError("Selecione uma conta financeira."),
                    otherwise: Yup.number().nullable(),
                }),
                centro_resultado: Yup.number().when("tipo_detalhe", {
                    is: (val) => val === 3,
                    then: Yup.number()
                        .required("O campo 'centro de resultado' é obrigatório.")
                        .typeError("Selecione um centro de resultado."),
                    otherwise: Yup.number().nullable(),
                }),
                fase_projeto: Yup.number().when("tipo_detalhe", {
                    is: (val) => val === 4,
                    then: Yup.number()
                        .required("O campo 'fase de projeto' é obrigatório.")
                        .typeError("Selecione uma fase de projeto."),
                    otherwise: Yup.number().nullable(),
                }),
                percentual:
                    validarPercentual === true
                        ? Yup.number()
                              .required("O percentual é obrigatorio")
                              .min(0.01, "O percentual deve ser maior ou igual a 0.01.")
                              .max(100.0, "O percentual deve ser menor ou igual a 100.")
                        : Yup.number()
                              .min(0, "O percentual deve ser maior ou igual a 0.")
                              .max(100, "O percentual deve ser menor ou igual a 100."),
            });
            formSchema.validateSync(values, { abortEarly: false });
            const vinculo = detalhes.find(
                (d) =>
                    d.id ===
                    (values.plano_conta_contabil ||
                        values.plano_conta_financeira ||
                        values.centro_resultado ||
                        values.fase_projeto)
            );
            const _detalhe = { ...values, _detalhe: vinculo };
            if (detalhesRateio.length > 0 && values._editar) {
                const _detalhes = [...detalhesRateio];
                _detalhes[values._index] = _detalhe;
                if (typeof onSubmit === "function") onSubmit(_detalhes);
                setDetalhesRateio(_detalhes);
            } else {
                const _detalhes = [...detalhesRateio];
                _detalhes.push(_detalhe);
                if (typeof onSubmit === "function") onSubmit(_detalhes);
                setDetalhesRateio(_detalhes);
            }
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const deletarDetalheRateio = (index) => {
        const detalhe = detalhesRateio[index];
        const _detalhes = [...detalhesRateio];
        _detalhes[index] = {
            ...detalhe,
            _deletar: true,
        };
        setDetalhesRateio(_detalhes);
        if (typeof onSubmit === "function") onSubmit(_detalhes);
    };

    const aposBuscar = useCallback((data) => {
        const sortedData = data
            .sort((a, b) => a.estrutura - b.estrutura)
            .map((item) => ({
                ...item,
                disabled: !!item.conta_titulo,
            }));
        return sortedData;
    }, []);

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    onClick={() => {
                        formik.setValues({
                            ...rowData,
                            tipo_detalhe:
                                typeof rowData.tipo_detalhe === "object"
                                    ? rowData.tipo_detalhe.id
                                    : rowData.tipo_detalhe,
                            plano_conta_contabil:
                                typeof rowData.plano_conta_contabil === "object" && rowData.plano_conta_contabil
                                    ? rowData.plano_conta_contabil.id
                                    : rowData.plano_conta_contabil,
                            plano_conta_financeira:
                                typeof rowData.plano_conta_financeira === "object" && rowData.plano_conta_financeira
                                    ? rowData.plano_conta_financeira.id
                                    : rowData.plano_conta_financeira,
                            fase_projeto:
                                typeof rowData.fase_projeto === "object" && rowData.fase_projeto
                                    ? rowData.fase_projeto.id
                                    : rowData.fase_projeto,
                            centro_resultado:
                                typeof rowData.centro_resultado === "object" && rowData.centro_resultado
                                    ? rowData.centro_resultado.id
                                    : rowData.centro_resultado,
                            _editar: true,
                            _index: rowInfo.rowIndex,
                        });
                    }}
                />
                <Button
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => deletarDetalheRateio(rowInfo.rowIndex)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "tipo_detalhe", header: "Tipo", action: (e) => tipoBodyTemplate(e) },
        { field: "vinculo", header: "Vínculo", action: (e) => vinculoBodyTemplate(e) },
        {
            field: "percentual",
            header: "Percentual (%)",
            style: { width: "20%" },
            decimal: true,
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e, i) => actionBodyTemplate(e, i),
        },
    ];

    const filtros = useMemo(() => {
        return natureza ? { natureza } : {};
    }, [natureza]);

    const definirComponenteDestino = (tipoDetalhe) => {
        switch (tipoDetalhe) {
            case 1:
                return (
                    <>
                        <Label htmlFor="conta-contabil" label="Conta contábil" obrigatorio />
                        <MakoDropdownPlanosContasContabeis
                            id="conta-contabil"
                            name="plano_conta_contabil"
                            versaoId={1}
                            setObjects={setDetalhes}
                            filtros={filtros}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.plano_conta_contabil}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_conta_contabil })}
                        />
                        {formik.errors.plano_conta_contabil && (
                            <small className="p-error">{formik.errors.plano_conta_contabil}</small>
                        )}
                    </>
                );
            case 2:
                return (
                    <>
                        <Label htmlFor="conta-financeira" label="Conta financeira" obrigatorio />
                        <Dropdown
                            id="conta-financeira"
                            name="plano_conta_financeira"
                            url={`/financeiro/planos-contas-financeiras/`}
                            setObjects={setDetalhes}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.plano_conta_financeira}
                            onChange={formik.handleChange}
                            itemTemplate={itemTemplate}
                            className={classNames({ "p-invalid": formik.errors.plano_conta_financeira })}
                            aposBuscar={aposBuscar}
                        />
                        {formik.errors.plano_conta_financeira && (
                            <small className="p-error">{formik.errors.plano_conta_financeira}</small>
                        )}
                    </>
                );
            case 3:
                return (
                    <>
                        <Label htmlFor="conta-resultado" label="Conta resultado" obrigatorio />
                        <Dropdown
                            id="centro-resultado"
                            name="centro_resultado"
                            url={`/plano-operacional/centros-resultados/?query={id,descricao}`}
                            setObjects={setDetalhes}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.centro_resultado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultado })}
                        />
                        {formik.errors.centro_resultado && (
                            <small className="p-error">{formik.errors.centro_resultado}</small>
                        )}
                    </>
                );
            case 4:
                return (
                    <>
                        <Label htmlFor="conta-projeto" label="Fase de projeto" obrigatorio />
                        <Dropdown
                            id="fase-projeto"
                            name="fase_projeto"
                            url={`/plano-operacional/fases-projetos/?query={id,descricao}`}
                            setObjects={setDetalhes}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.fase_projeto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fase_projeto })}
                        />
                        {formik.errors.fase_projeto && <small className="p-error">{formik.errors.fase_projeto}</small>}
                    </>
                );
            default:
                return null;
        }
    };

    const exibirDetalhesNaoDeletados = useCallback((detalhes) => {
        return detalhes.filter((d) => !d._deletar);
    }, []);

    return (
        <>
            <h5>Detalhes da regra de rateio</h5>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="tipo-detalhe" label="Tipo de detalhe" obrigatorio />
                        <Dropdown
                            id="tipo-detalhe"
                            name="tipo_detalhe"
                            options={TIPO_DETALHE_RATEIO}
                            value={formik.values.tipo_detalhe}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.tipo_detalhe })}
                        />
                        {formik.errors.tipo_detalhe && <small className="p-error">{formik.errors.tipo_detalhe}</small>}
                    </div>

                    {formik.values.tipo_detalhe !== 3 && (
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="classificador" label="Classificador" obrigatorio />
                            <Dropdown
                                id="classificador"
                                name="classificador"
                                url="/financeiro/classificadores-financeiros/?limit=100"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.classificador}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.classificador })}
                            />
                            {formik.errors.classificador && (
                                <small className="p-error">{formik.errors.classificador}</small>
                            )}
                        </div>
                    )}

                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="percentual" label="Percentual" obrigatorio={validarPercentual} />
                        <MakoInputPorcentagem
                            id="percentual"
                            name="percentual"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.percentual}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.percentual })}
                        />
                        {formik.errors.percentual && <small className="p-error">{formik.errors.percentual}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        {definirComponenteDestino(formik.values.tipo_detalhe)}
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-auto">
                        <Button
                            type="submit"
                            icon={MAKO_ICONS.NOVO}
                            className="p-button-success"
                            label="Adicionar"
                            disabled={
                                !!detalhesRateio.some(
                                    ({ tipo_detalhe }) => tipo_detalhe.id === formik.values.tipo_detalhe
                                )
                            }
                        />
                    </div>
                </div>
                <CamposObrigatorios />
            </form>
            <MakoListagem dadosLocal={detalhesRateio} colunas={colunas} aposPesquisar={exibirDetalhesNaoDeletados} />
        </>
    );
};
