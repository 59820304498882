import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { BaseModalAjusteComissao } from "./BaseModal";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputPercent } from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { STATUS_APURACAO_COMISSAO } from "@/assets/constants/vendas";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [baseComissao, setBaseComissao] = useState(null);

    const abrirModal = (baseComissao) => {
        setVisible(true);
        setBaseComissao(baseComissao);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button type="button" icon="pi pi-times" label="Fechar" severity="danger" onClick={fecharModal} />
        </div>
    );

    const situacaoApuracao = (situacao) => {
        const status = STATUS_APURACAO_COMISSAO.find((el) => el.value === situacao);
        if (!status) return "Não apurada";
        return status.value;
    };

    if (!baseComissao) return <></>;

    return (
        <Dialog header="Detalhes de comissão" visible={visible} onHide={fecharModal} footer={footer}>
            <BaseModalAjusteComissao baseComissao={baseComissao} />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-9">
                    <label htmlFor="comissionado">Comissionado(a)</label>
                    <InputText id="comissionado" disabled value={baseComissao.comissionado?.nome || ""} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="cod-apuracao">Cód. de apuração</label>
                    <InputText id="cod-apuracao" disabled value={baseComissao.comissionado?.nome || ""} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base-encargos">Base de encargos</label>
                    <MakoInputMoeda id="base-encargos" disabled valueMoeda={baseComissao.base_encargo} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percent-encargos">Percentual de encargos</label>
                    <MakoInputPercent id="percent-encargos" disabled value={baseComissao.percent_encargo} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vlr-comissao-encargos">Valor comissão encargos</label>
                    <MakoInputMoeda id="vlr-comissao-encargos" disabled valueMoeda={baseComissao.comissao_encargo} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo-apuracao">Tipo de apuração</label>
                    <InputText id="tipo-apuracao" disabled value="Direta" />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base-mercadorias">Base de mercadorias</label>
                    <MakoInputMoeda id="base-mercadorias" disabled valueMoeda={baseComissao.base_mercadoria} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percent-mercadorias">Percentual de mercadorias</label>
                    <MakoInputPercent id="percent-mercadorias" disabled value={baseComissao.percent_mercadoria} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vlr-comissao-mercadorias">Valor comissão mercadorias</label>
                    <MakoInputMoeda
                        id="vlr-comissao-mercadorias"
                        disabled
                        valueMoeda={baseComissao.comissao_mercadoria}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data-final-apuracao">Data final da apuração</label>
                    <MakoCalendar
                        id="data-final-apuracao"
                        disabled
                        value={baseComissao.apuracao_comissao?.data_final}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base-servicos">Base de serviços</label>
                    <MakoInputMoeda id="base-servicos" disabled valueMoeda={baseComissao.base_servico} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percent-servicos">Percentual de serviços</label>
                    <MakoInputPercent id="percent-servicos" disabled value={baseComissao.percent_servico} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vlr-comissao-servicos">Valor comissão serviços</label>
                    <MakoInputMoeda id="vlr-comissao-servicos" disabled valueMoeda={baseComissao.comissao_servico} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="situacao-apuracao">Situação da apuração</label>
                    <InputText id="situacao-apuracao" disabled value={situacaoApuracao()} />
                </div>
            </div>
        </Dialog>
    );
};

export const ModalDetalhesComissao = forwardRef(Modal);
