import React, { useCallback, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { ProcoloCrediarioForm } from "./formProtocoloCrediario/formProtocoloCrediario";
import { GrupoFamiliarForm } from "./formGrupoFamiliar";
import { DadosProfissionaisForm } from "./formDadosProfissionais/formDadosProfissionais";
import { CrediarioProvider } from "@/contexts/crediarioContext";
import { DocumentosRendaForm } from "./formDocumentosRenda";
import { DadosComplementaresForm } from "./formDadosComplementares/formDadosComplementares";
import { ResumoCrediarioForm } from "./formResumosCrediario";
import useCrediario from "@/hooks/useCrediario";
import useLoading from "@/hooks/useLoading";
import { AnaliseCrediarioForm } from "./formAnaliseCrediario";
import { Dialog } from "primereact/dialog";
import { ImprimirFichaCrediarioForm } from "./modals/imprimirFichaCrediario";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import { MakoAjudaOverlay } from "@/components/MakoAjudaOverlay";

const urlvoltar = "/vendas/venda/crediario";

const TabCrediario = ({ props }) => {
    const { showLoading, hideLoading } = useLoading();
    const { crediario, handleCrediario, handlePreencherCrediario } = useCrediario();
    const [carregaVerificacao, setCarregaVerificacao] = useState(false);
    const [imprimirFicha, setImprimirFicha] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const { showError, showSuccess, showWarning, showInfo } = useToast();
    const { httpPatch } = useHttp();
    const history = useHistory();

    useEffect(() => {
        if (props?.location.crediario?.id) handlePreencherCrediario(props.location.crediario.id);
    }, [handlePreencherCrediario, props?.location?.crediario?.id]);

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 4) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    async function persistirDados(fechar) {
        showLoading();
        const { status, data } = await handleCrediario();
        hideLoading();

        if (status === 201 || status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Crediário salvo com sucesso.",
                life: 3000,
            });
            if (fechar)
                setTimeout(() => {
                    history.push(urlvoltar);
                }, [2000]);
            else await handlePreencherCrediario(data.id);
        } else showError();
    }

    const cancelar = () => {
        history.push(urlvoltar);
    };

    const validarCrediario = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                await handlePreencherCrediario(data.id);
                if (data.status === "P") {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "O crediário foi validado com sucesso! Agora o mesmo está pendente para aprovação.",
                        life: 3000,
                    });
                } else if (data?.status === "I") {
                    showWarning({
                        summary: "Crediário incompleto",
                        detail: `O crediário não atende aos critérios necessários, por favor verifique as informações e tente novamente. ${
                            data?.historico_validacao ? `(${data.historico_validacao})` : ""
                        }`,
                        life: 9000,
                    });
                } else if (data?.status === "R") {
                    showInfo({
                        summary: "Crediário Rejeitado",
                        detail: `O crediário foi validado porém as informaçẽos estão inconsistêntes, uma rejeição foi registrada. ${
                            data?.historico_validacao ? `(${data.historico_validacao})` : ""
                        }`,
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: `Desculpe, não foi possível validar o crediário. ${
                            data?.erros ? `(${data.erros})` : ""
                        }`,
                        life: 3000,
                    });
                }
            },
        };

        setCarregaVerificacao(true);
        await httpPatch({ url: `/crediario/protocolo-crediario/${crediario.id}/`, body: { status: "P" } }, handlers);
        setCarregaVerificacao(false);
    }, [showError, showSuccess, showWarning, showInfo, handlePreencherCrediario, httpPatch, crediario?.id]);

    return (
        <PageBase>
            <div className="p-formgroup-inline">
                <h5 className={!!!crediario?.id ? "" : "p-mt-2"}>
                    {!!!crediario?.id && (
                        <MakoAjudaOverlay
                            buttonProps={{
                                style: {
                                    fontSize: "1.8rem",
                                    display: "inline-flex",
                                    alignItem: "center",
                                    textAlign: "center",
                                },
                                className: "p-link p-px-2 p-mr-2",
                            }}
                            icon="pi pi-question-circle"
                            showCloseIcon={false}
                        >
                            <b>As abas "documentos" e "análises" são liberadas somente ao salvar o crediário.</b>
                        </MakoAjudaOverlay>
                    )}
                    Cadastro de Crediário{" "}
                    {crediario.nome ? <b>{`(${crediario?.nome} - ${crediario?.identificacao})`}</b> : null}
                </h5>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_VALIDARCREDIARIO]}
                    componente={Button}
                    label="Validar cadastro"
                    type="button"
                    icon="pi pi-check-square"
                    className="p-button-primary p-ml-3"
                    loading={carregaVerificacao}
                    disabled={!!!crediario?.id}
                    onClick={() => validarCrediario()}
                />
            </div>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Protocolo" leftIcon="pi pi-id-card">
                    <ProcoloCrediarioForm />
                </TabPanel>
                <TabPanel header="Dados complementares" disabled={!crediario?.regra} leftIcon="pi pi-id-card">
                    <DadosComplementaresForm />
                </TabPanel>
                <TabPanel header="Grupo familiar" disabled={!crediario?.regra} leftIcon="pi pi-users">
                    <GrupoFamiliarForm />
                </TabPanel>
                <TabPanel header="Dados profissionais" disabled={!crediario?.regra} leftIcon="pi pi-shopping-bag">
                    <DadosProfissionaisForm />
                </TabPanel>
                <TabPanel header="Documentos" disabled={!(crediario?.regra && crediario?.id)} leftIcon="pi pi-id-card">
                    <DocumentosRendaForm />
                </TabPanel>
                <TabPanel header="Resumo" disabled={!crediario?.regra} leftIcon="pi pi-id-card">
                    <ResumoCrediarioForm />
                </TabPanel>
                <TabPanel
                    header="Análise"
                    disabled={!(crediario?.regra && crediario?.id)}
                    leftIcon="pi pi-question-circle"
                >
                    <AnaliseCrediarioForm />
                </TabPanel>
            </TabView>

            <div className="p-grid p-fluid p-justify-start">
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-2">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 3 ? (
                    <div className="p-col-6 p-md-2">
                        <Button
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                        />
                    </div>
                ) : null}
                <div className="p-col-6 p-md-2">
                    <Button
                        label="Salvar"
                        className="p-button-warning"
                        type="button"
                        onClick={() => persistirDados(false)}
                    />
                </div>
                <div className="p-col-6 p-md-2">
                    <Button
                        label="Salvar e Sair"
                        className="p-button-success"
                        type="button"
                        onClick={() => persistirDados(true)}
                    />
                </div>
                <div className="p-col-6 p-md-2">
                    <Button label="Voltar" className="p-button-danger" onClick={cancelar} />
                </div>
            </div>
            <Dialog
                header="Imprimir Ficha de Crediário"
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                visible={imprimirFicha}
                onHide={() => setImprimirFicha(false)}
            >
                <ImprimirFichaCrediarioForm esconderDialog={setImprimirFicha} />
            </Dialog>
        </PageBase>
    );
};

export const TabCreadiarioContext = (props) => {
    return (
        <CrediarioProvider>
            <TabCrediario props={props} />
        </CrediarioProvider>
    );
};
