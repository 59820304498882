import React, { useRef } from "react";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import { AtivoFiltroTemplate, CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const CrediarioOcupacaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIOOCUPACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de ocupação"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/ocupacao/form",
                            state: { ocupacao: rowData },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/crediario/ocupacao"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="ocupacao"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a ocupação <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A ocupação não pode ser excluída.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_CREDIARIOOCUPACAO_INCLUIR]}
                componente={Button}
                className="p-button-success p-mr-2"
                icon="pi pi-plus"
                label="Novo"
                onClick={() => history.push("/vendas/venda/crediario/ocupacao/form")}
                tooltipOptions={{ position: "left" }}
            />
        </>
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "especialidade",
            header: "Especialidade",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            header: "Situação",
            field: "ativo",
            style: { width: "12%" },
            filter: true,
            filterElement: AtivoFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Ocupação"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/crediario/ocupacao/"}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    codigo: { value: "", matchMode: "icontains" },
                    descricao: { value: "", matchMode: "icontains" },
                    especialidade: { value: "", matchMode: "icontains" },
                    ativo: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
