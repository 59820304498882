import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { ACEITE_BOLETO_BANCARIO } from "@/assets/constants/financeiro";
import useBoleto from "@/hooks/useBoleto";

export const BoletoDadosBasicosForm = (props) => {
    const { handleDadosBasicos } = useBoleto();

    const formik = useFormik({
        initialValues: {
            beneficiario: null,
            conta_beneficiario: null,
            pagador: null,
            endereco_pagador: null,
            lote: null,
            carteira: null,
            convenio: "",
            referencia: "",
            codigo_barras: "",
            nosso_numero: "",
            numero_documento: "",
            intrucoes: "",
            data_emissao: null,
            data_processamento: null,
            vencimento: null,
            aceite: "N",
            especie_documento: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                beneficiario: Yup.number()
                    .required("O campo 'beneficiário' é obrigatório.")
                    .typeError("Selecione um beneficiário."),
                conta_beneficiario: Yup.number()
                    .required("O campo 'conta financeira do beneficiário' é obrigatório.")
                    .typeError("Selecione uma conta financeira."),
                pagador: Yup.object()
                    .shape({
                        id: Yup.string().required("O campo 'pagador' é obrigatório."),
                    })
                    .typeError("Você precisa pesquisar e escolher um pagador."),
                endereco_pagador: Yup.number()
                    .required("O campo 'endereço do pagador' é obrigatório.")
                    .typeError("Selecione um endereço."),
                lote: Yup.number()
                    .required("O campo 'lote' é obrigatório.")
                    .typeError("Selecione um lote para o boleto ou gere um novo."),
                carteira: Yup.number()
                    .required("O campo 'carteira' é obrigatório.")
                    .typeError("Selecione uma carteira."),
                convenio: Yup.string().notRequired().default(""),
                referencia: Yup.string().notRequired().default(""),
                codigo_barras: Yup.string()
                    .required("O campo 'código de barras' é obrigatório.")
                    .min(47, "Esse campo não pode possui MENOS que 47 caracteres.")
                    .max(47, "Esse campo não pode possui MAIS que 47 caracteres."),
                nosso_numero: Yup.string().required("O campo 'nosso número' é obrigatório."),
                numero_documento: Yup.string().required("O campo 'número do documento' é obrigatório."),
                data_emissao: Yup.date()
                    .required("O campo 'data de emissão' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_processamento: Yup.date()
                    .min(Yup.ref("data_emissao"), "A data de processamento não pode ser menor que a emissão.")
                    .nullable()
                    .optional(),
                vencimento: Yup.date()
                    .required("O campo 'data de vencimento' é obrigatório.")
                    .min(Yup.ref("data_emissao"), "A data de vencimento não pode ser menor que a emissão.")
                    .typeError("Informe uma data válida."),
                especie_documento: Yup.number()
                    .required("O campo 'espécie do documento' é obrigatório.")
                    .typeError("Selecione uma espécie de documento."),
                aceite: Yup.string()
                    .required("O campo 'aceite' é obrigatório.")
                    .typeError("Selecione uma opção de aceite."),
                intrucoes: Yup.string().notRequired().default(""),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            handleDadosBasicos(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="beneficiario">Beneficiário *</label>
                    <MakoDropdownEmpresas
                        id="beneficiario"
                        name="beneficiario"
                        value={formik.values.beneficiario}
                        onChange={(e) => formik.setFieldValue("beneficiario", e.id)}
                        className={classNames({ "p-invalid": formik.errors.beneficiario })}
                    />
                    {formik.errors.beneficiario && <small className="p-error">{formik.errors.beneficiario}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="conta-beneficiario">Conta financeira do beneficiário *</label>
                    <Dropdown
                        id="conta-beneficiario"
                        name="conta_beneficiario"
                        buscar={!!formik.values.beneficiario}
                        url={`/financeiro/contas-financeiras/?perfil=${formik.values.beneficiario}`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.conta_beneficiario}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conta_beneficiario })}
                    />
                    {formik.errors.conta_beneficiario && (
                        <small className="p-error">{formik.errors.conta_beneficiario}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="pagador">Pagador *</label>
                    <MakoInputCliente
                        id="pagador"
                        name="pagador"
                        value={formik.values.pagador}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.pagador })}
                    />
                    {formik.errors.pagador && <small className="p-error">{formik.errors.pagador}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="endereco-pagador">Endereço pagador *</label>
                    <Dropdown
                        id="endereco-pagador"
                        name="endereco_pagador"
                        buscar={formik.values.pagador instanceof Object}
                        url={`/pessoas/enderecos-perfis/?perfil__id=${formik.values.pagador?.id}`}
                        optionValue="id"
                        optionLabel="logradouro"
                        filter
                        filterBy="logradouro"
                        value={formik.values.endereco_pagador}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.endereco_pagador })}
                    />
                    {formik.errors.endereco_pagador && (
                        <small className="p-error">{formik.errors.endereco_pagador}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="lote">Lote *</label>
                    <Dropdown
                        id="lote"
                        name="lote"
                        url="/financeiro/lotes-boletos-bancarios/?status=P"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.lote}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.lote })}
                    />
                    {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="carteira">Carteira *</label>
                    <Dropdown
                        id="carteira"
                        name="carteira"
                        url="/financeiro/carteiras-contas-financeiras/"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.carteira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.carteira })}
                    />
                    {formik.errors.carteira && <small className="p-error">{formik.errors.carteira}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="convenio">Convênio</label>
                    <InputText
                        id="convenio"
                        name="convenio"
                        autoComplete="off"
                        value={formik.values.convenio}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="referencia">Referência</label>
                    <InputText
                        id="referencia"
                        name="referencia"
                        autoComplete="off"
                        value={formik.values.referencia}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="codigo-barras">Código de barras *</label>
                    <InputText
                        id="codigo-barras"
                        name="codigo_barras"
                        autoComplete="off"
                        value={formik.values.codigo_barras}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.codigo_barras })}
                    />
                    {formik.errors.codigo_barras && <small className="p-error">{formik.errors.codigo_barras}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="nosso-numero">Nosso número *</label>
                    <InputText
                        id="nosso-numero"
                        name="nosso_numero"
                        autoComplete="off"
                        value={formik.values.nosso_numero}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.nosso_numero })}
                    />
                    {formik.errors.nosso_numero && <small className="p-error">{formik.errors.nosso_numero}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="numero-documento">Número do documento *</label>
                    <InputText
                        id="numero-documento"
                        name="numero_documento"
                        autoComplete="off"
                        value={formik.values.numero_documento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.numero_documento })}
                    />
                    {formik.errors.numero_documento && (
                        <small className="p-error">{formik.errors.numero_documento}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-emissao">Data de emissão *</label>
                    <MakoCalendar
                        id="data-emissao"
                        name="data_emissao"
                        value={formik.values.data_emissao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_emissao })}
                    />
                    {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-processamento">Data de processamento</label>
                    <MakoCalendar
                        id="data-processamento"
                        name="data_processamento"
                        value={formik.values.data_processamento}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-vencimento">Data de vencimento *</label>
                    <MakoCalendar
                        id="data-vencimento"
                        name="vencimento"
                        value={formik.values.vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento })}
                    />
                    {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="especie-documento">Espécie do documento *</label>
                    <Dropdown
                        id="especie-documento"
                        name="especie_documento"
                        url="/financeiro/especies-documentos-boletos/"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.especie_documento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.especie_documento })}
                    />
                    {formik.errors.especie_documento && (
                        <small className="p-error">{formik.errors.especie_documento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aceite">Aceite *</label>
                    <Dropdown
                        id="aceite"
                        name="aceite"
                        options={ACEITE_BOLETO_BANCARIO}
                        value={formik.values.aceite}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.aceite })}
                    />
                    {formik.errors.aceite && <small className="p-error">{formik.errors.aceite}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="instrucoes">Instruções</label>
                    <InputTextarea
                        id="instrucoes"
                        name="instrucoes"
                        rows={3}
                        autoComplete="off"
                        autoResize
                        value={formik.values.instrucoes}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <CamposObrigatorios />
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2 p-mb-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};
