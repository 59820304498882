import { useCallback, useMemo } from "react";

import { useCookies } from "react-cookie";

import { MAKO_COOKIES } from "@/assets/constants/cookies";

import useToast from "./useToast";
import useAuth from "./useAuth";
import useHttp from "./useHttp";

const BASE_URL_PERFIL = "/pessoas/perfis/{id}/";

const INITIAL_VALUE = [
    {
        label: "Favoritos",
        items: [],
    },
    { separator: true },
];

const useMenuFavoritos = () => {
    const [cookies, setFavoritos] = useCookies([MAKO_COOKIES.MENUFAVORITOS_COOKIES], INITIAL_VALUE);
    const { httpGet, httpPatch } = useHttp();
    const { showInfo } = useToast();
    const { user } = useAuth();

    const favoritos = useMemo(() => {
        return cookies[MAKO_COOKIES.MENUFAVORITOS_COOKIES];
    }, [cookies]);

    const parseToArray = useCallback(() => {
        if (!favoritos) return INITIAL_VALUE;
        return Object.keys(favoritos || {}).map((key) => favoritos[key]);
    }, [favoritos]);

    const salvar = useCallback(
        async (novos) => {
            if (!user) return null;
            const url = BASE_URL_PERFIL.replace("{id}", user.id);
            const body = { favoritos_menu: novos };
            await httpPatch({ url, body }, {});
        },
        [httpPatch, user]
    );

    const reload = useCallback(
        async (userId) => {
            const id = user?.id || userId;
            if (!id) return null;
            const params = {
                query: "{id,favoritos_menu}",
            };
            const url = BASE_URL_PERFIL.replace("{id}", id);
            const handlers = {
                200: ({ data }) => {
                    let [_favoritos, whiteLabel] = parseToArray();
                    _favoritos.items = data.favoritos_menu?.items || [];
                    setFavoritos(MAKO_COOKIES.MENUFAVORITOS_COOKIES, [_favoritos, whiteLabel]);
                },
            };
            await httpGet({ url, params }, handlers);
        },
        [user, httpGet, parseToArray, setFavoritos]
    );

    const handler = useCallback(
        async (item) => {
            let novo = parseToArray();
            let _favoritos = novo?.at(0);
            let favoritado = true;
            const whiteLabel = novo?.at(1);
            if (!_favoritos?.items?.some((i) => i.to === item.to)) {
                _favoritos.items.push({ ...item, favoritos: true });
            } else {
                _favoritos.items = _favoritos.items.filter((i) => i.to !== item.to);
                favoritado = false;
            }
            setFavoritos(MAKO_COOKIES.MENUFAVORITOS_COOKIES, [_favoritos, whiteLabel]);
            showInfo({
                summary: "Aviso!",
                detail: `O menu "${item.label}" foi ${favoritado ? "favoritado" : "desfavoritado"}`,
                life: 1500,
            });
            await salvar({ items: _favoritos?.items });
        },
        [setFavoritos, showInfo, parseToArray, salvar]
    );

    return { favoritos, handler, parseToArray, reload };
};

export default useMenuFavoritos;
