import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import { MakoInputMotorista } from "@/components/MakoInputs/MakoInputMotorista";
import { Dropdown } from "@/components/Dropdown";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const VinculoMotoristaVeiculoForm = (props) => {
    const [url, setUrl] = useState("/pessoas/veiculo-entrega?placa__icontains=");
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state?.vinculo || {
            perfil_motorista: null,
            veiculo_entrega: null,
            busca: 1,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_motorista: Yup.object()
                    .required("O campo 'motorista' é obrigatório.")
                    .typeError("Informe um 'motorista' válido."),
                veiculo_entrega: Yup.object()
                    .required("O campo 'veículo de entrega' é obrigatório.")
                    .typeError("Informe um 'veiculo' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vínculo entre motorista e veículo cadastrada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: `/pessoas/vinculo-motorista-veiculo/`,
                        body: {
                            perfil_motorista: values.perfil_motorista.id,
                            veiculo_entrega: values.veiculo_entrega.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vínculo entre motorista e veículo alterado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPut({ url: `/pessoas/vinculo-motorista-veiculo/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompleteTemplate = (veiculo) => {
        return `${veiculo.placa} - ${veiculo.chassi} - ${veiculo.renavan}`;
    };

    const selecionaTipoBusca = (busca) => {
        switch (busca) {
            case 2:
                setUrl("/pessoas/veiculo-entrega?chassi__icontains=");
                break;
            case 3:
                setUrl("/pessoas/veiculo-entrega?renavan__icontains=");
                break;
            default:
                setUrl("/pessoas/veiculo-entrega?placa__icontains=");
        }
        setFieldValue("busca", busca);
    };

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/vinculo-motorista-veiculo");
    };

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Novo Vínculo de Motorista com Veículo"
                    : "Manutenção de Vínculo de Motorista com Veículo"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="busca" label="Buscar veículo por" obrigatorio />
                        <Dropdown
                            id="busca"
                            name="busca"
                            placeholder="Selecione"
                            options={[
                                { value: 1, label: "Placa" },
                                { value: 2, label: "Chassi" },
                                { value: 3, label: "Renavan" },
                            ]}
                            autoFocus
                            onChange={(e) => selecionaTipoBusca(e.value)}
                            value={formik.values.busca}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="veiculo_entrega" label="Veículo" obrigatorio />
                        <MakoAutoComplete
                            id="veiculo_entrega"
                            name="veiculo_entrega"
                            value={formik.values.veiculo_entrega}
                            onChange={formik.handleChange}
                            minCaracteresBusca={3}
                            itemTemplate={autoCompleteTemplate}
                            field={
                                formik.values.busca === 2 ? "chassi" : formik.values.busca === 3 ? "renavan" : "placa"
                            }
                            urlSearch={url}
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.veiculo_entrega })}
                        />
                        {formik.errors.veiculo_entrega && (
                            <small className="p-error">{formik.errors.veiculo_entrega}</small>
                        )}
                    </div>
                </div>
                {formik.values.veiculo_entrega && typeof formik.values.veiculo_entrega === "object" ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="placa" label="Placa" />
                            <InputText id="placa" name="placa" value={formik.values.veiculo_entrega?.placa} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="chassi" label="Chassi" />
                            <InputText
                                id="chassi"
                                name="chassi"
                                value={formik.values.veiculo_entrega?.chassi}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="renavan" label="Renavan" />
                            <InputText
                                id="renavan"
                                name="renavan"
                                value={formik.values.veiculo_entrega?.renavan}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="tipo" label="Tipo de veículo" />
                            <InputText
                                id="tipo"
                                name="tipo"
                                value={formik.values.veiculo_entrega?.tipo?.descricao}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="categoria_exigida" label="CNH Exigida" />
                            <InputText
                                id="categoria_exigida"
                                name="categoria_exigida"
                                value={formik.values.veiculo_entrega?.categoria_exigida?.descricao}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-5 p-md-3">
                            <Label htmlFor="ano_modelo" label="Ano modelo" />
                            <MakoCalendar
                                id="ano_modelo"
                                name="ano_modelo"
                                value={formik.values.veiculo_entrega?.categoria_exigida?.ano_modelo}
                            />
                        </div>
                    </div>
                ) : null}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="perfil_motorista" label="Motorista" obrigatorio />
                        <MakoInputMotorista
                            id="perfil_motorista"
                            name="perfil_motorista"
                            value={formik.values.perfil_motorista}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil_motorista })}
                        />
                        {formik.errors.perfil_motorista && (
                            <small className="p-error">{formik.errors.perfil_motorista}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        loading={loading}
                        type="submit"
                        className="p-button-info"
                    />
                    <Button
                        icon={MAKO_ICONS.CANCEL}
                        label="Cancelar"
                        loading={loading}
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
