import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputCubagem } from "@/components/MakoInputs/MakoInputCubagem";
import { MakoInputDimensao } from "@/components/MakoInputs/MakoInputDimensao";
import { Dropdown } from "@/components/Dropdown";
import { TIPOS_BUSCA_SKU } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import { VENDA_TIPO_BUSCA_PRODUTO_PADRAO } from "@/assets/constants/parametros";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";
import useClearRefs from "@/hooks/useClearRefs";
import { MakoVisualizarSku } from "../MakoVisualizarSku";
import { Avatar } from "primereact/avatar";
import { Label } from "../Label";
import useHttp from "@/hooks/useHttp";

const BASE_LABEL = "Buscar produto / mercadoria";

const Input = (
    {
        disabledBusca,
        skuValue,
        skuChange,
        skuError,
        exibeDimensoes,
        alturaValue,
        onChangeAltura,
        larguraValue,
        onChangeLargura,
        comprimentoValue,
        onChangeComprimento,
        dimensaoValue,
        onChangeDimensao,
        categoriaId,
        destinacoes,
        apenasComLote = false,
        statusItem = "F",
        exibirVisualizacaoSku = false,
        exibirBotaoSelecionar = false,
        onSelecionar,
        obrigatorio = false,
        label = BASE_LABEL,
        autoFocus = undefined,
    },
    ref
) => {
    const opcoesDimensaoDefault = {
        unidade: null,
        fazCalculo: false,
        desabilitado: true,
        min: null,
        max: null,
    };

    const { getParam } = useParam();
    const tipoBuscaPadrao = useMemo(() => {
        const param = getParam(VENDA_TIPO_BUSCA_PRODUTO_PADRAO);
        return parseNumber(param?.valor, 1);
    }, [getParam]);

    const [tipoBusca, setTipoBusca] = useState(tipoBuscaPadrao);
    const [codigoSKU, setCodigoSKU] = useState(skuValue?.codigo || "");
    const [descricaoSKU, setDescricaoSKU] = useState(skuValue?.descricao_derivada || "");
    const [opcoesAltura, setOpcoesAltura] = useState(opcoesDimensaoDefault);
    const [opcoesLargura, setOpcoesLargura] = useState(opcoesDimensaoDefault);
    const [opcoesComprimento, setOpcoesComprimento] = useState(opcoesDimensaoDefault);
    const [undDimensao, setUndDimensao] = useState(null);
    const { showWarning } = useToast();
    const { httpPost } = useHttp();

    const visualizarSkuRef = useRef(null);

    useClearRefs(visualizarSkuRef);

    const obterInformacoesSKU = (sku) => {
        if (sku instanceof Object) {
            const { codigo, descricao_derivada, gradeatributosku_set } = sku;
            setCodigoSKU(codigo);
            setDescricaoSKU(descricao_derivada);
            if (exibeDimensoes) {
                gradeatributosku_set.forEach((gradeatributo) => {
                    if (gradeatributo.dimensao === "A") {
                        if (typeof onChangeAltura === "function") onChangeAltura(gradeatributo.valor_minimo);
                        setOpcoesAltura({
                            unidade: gradeatributo.unidade_padrao,
                            fazCalculo: gradeatributo.grade.calcula_medida,
                            desabilitado: !gradeatributo.valor_grade_variavel,
                            min: parseNumber(gradeatributo.valor_minimo) || null,
                            max: parseNumber(gradeatributo.valor_maximo) || null,
                        });
                    } else if (gradeatributo.dimensao === "L") {
                        if (typeof onChangeLargura === "function") onChangeLargura(gradeatributo.valor_minimo);
                        setOpcoesLargura({
                            unidade: gradeatributo.unidade_padrao,
                            fazCalculo: gradeatributo.grade.calcula_medida,
                            desabilitado: !gradeatributo.valor_grade_variavel,
                            min: parseNumber(gradeatributo.valor_minimo) || null,
                            max: parseNumber(gradeatributo.valor_maximo) || null,
                        });
                    } else if (gradeatributo.dimensao === "C") {
                        if (typeof onChangeComprimento === "function") onChangeComprimento(gradeatributo.valor_minimo);
                        setOpcoesComprimento({
                            unidade: gradeatributo.unidade_padrao,
                            fazCalculo: gradeatributo.grade.calcula_medida,
                            desabilitado: !gradeatributo.valor_grade_variavel,
                            min: parseNumber(gradeatributo.valor_minimo) || null,
                            max: parseNumber(gradeatributo.valor_maximo) || null,
                        });
                    }
                });
            }
        } else {
            setCodigoSKU("");
            setDescricaoSKU("");
            setOpcoesLargura(opcoesDimensaoDefault);
            setOpcoesAltura(opcoesDimensaoDefault);
            setOpcoesComprimento(opcoesDimensaoDefault);
            setUndDimensao(null);
        }
    };

    const onChangeSKU = (value) => {
        if (typeof skuChange === "function") skuChange(value);
        obterInformacoesSKU(value);
    };

    const urlPesquisa = useMemo(() => {
        let url = `/produtos/sku-busca-personalizada/?tipo_busca=${tipoBusca}`;
        if (statusItem) url = `${url}&status=${statusItem}`;
        if (categoriaId) url = `${url}&categoria=${categoriaId}`;
        if (destinacoes) url = `${url}&destinacoes=${destinacoes}`;
        if (apenasComLote) url = `${url}&apenas_com_lote=true`;
        return `${url}&busca=`;
    }, [categoriaId, destinacoes, statusItem, apenasComLote, tipoBusca]);

    const autoCompleteItemTemplate = (item) => {
        const { codigo, descricao_derivada, unidade_padrao, categoriasku_set, codigosku_set, imagem } = item;
        const categorias = categoriasku_set.filter((cat) => cat.ativo);
        const [categoria] = categorias;
        const codigosEAN = codigosku_set.filter((cod) => cod.tipo.padrao_gtin);
        const [codigoEAN] = codigosEAN;
        return (
            <div className="p-d-flex p-ai-center" style={{ width: "40rem" }}>
                <Avatar
                    icon="pi pi-box"
                    image={imagem?.imagem}
                    imageAlt={imagem?.descricao ? imagem?.descricao : `Icone do produto: ${descricao_derivada}`}
                    className="p-mr-2"
                    shape="circle"
                    size="xlarge"
                />
                <div style={{ width: "inherit" }}>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <span>{`Código: `}</span>
                            <b>{codigo}</b>
                        </div>
                        <div className="p-col-8">
                            <span>{`Descrição: `}</span>
                            <b>{descricao_derivada}</b>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <span>{`Unidade: `}</span>
                            <b>{unidade_padrao?.sigla || "-"}</b>
                        </div>
                        <div className="p-col-4">
                            <span>{`Categoria: `}</span>
                            <b>{categoria?.categoria.nome || "-"}</b>
                        </div>
                        <div className="p-col-4">
                            <span>{`EAN: `}</span>
                            <b>{codigoEAN?.codigo || "-"}</b>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const calcularDimensao = useCallback(async () => {
        const unidadesPreenchidas = !!(opcoesAltura.unidade || opcoesLargura.unidade || opcoesComprimento.unidade);
        if (exibeDimensoes && skuValue instanceof Object && unidadesPreenchidas) {
            let body = {
                und_venda: skuValue?.unidade_padrao?.id,
            };
            if (opcoesAltura?.fazCalculo) {
                body["altura"] = parseNumber(alturaValue, 1);
                body["und_altura"] = opcoesAltura.unidade.id;
            }
            if (opcoesLargura?.fazCalculo) {
                body["largura"] = parseNumber(larguraValue, 1);
                body["und_largura"] = opcoesLargura.unidade.id;
            }
            if (opcoesComprimento?.fazCalculo) {
                body["comprimento"] = parseNumber(comprimentoValue, 1);
                body["und_comprimento"] = opcoesComprimento.unidade.id;
            }

            const handlers = {
                200: ({ data }) => {
                    if (typeof onChangeDimensao === "function") onChangeDimensao(data.dimensao);
                    setUndDimensao(data.unidademedida);
                },
                400: ({ err }) =>
                    showWarning({
                        summary: "Aviso!",
                        detail: err.msg,
                        life: 4000,
                    }),
                409: ({ err }) =>
                    showWarning({
                        summary: "Aviso!",
                        detail: err.msg,
                        life: 4000,
                    }),
            };

            await httpPost({ url: "/produtos/calcular-dimensao/", body }, handlers);
        }
    }, [
        alturaValue,
        comprimentoValue,
        larguraValue,
        exibeDimensoes,
        onChangeDimensao,
        showWarning,
        skuValue,
        httpPost,
        opcoesAltura,
        opcoesLargura,
        opcoesComprimento,
    ]);

    useEffect(() => {
        calcularDimensao();
    }, [calcularDimensao]);

    const limparCampos = () => {
        if (typeof skuChange === "function") {
            skuChange(null);
            setCodigoSKU("");
            setDescricaoSKU("");
        }
        if (typeof onChangeLargura === "function") onChangeLargura(1);
        if (typeof onChangeAltura === "function") onChangeAltura(1);
        if (typeof onChangeComprimento === "function") onChangeComprimento(1);
        if (typeof onChangeDimensao === "function") onChangeDimensao(1);
    };

    useImperativeHandle(ref, () => ({ limparCampos }));

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="tipo-busca">Campo de busca</label>
                    <Dropdown
                        id="tipo-busca"
                        options={TIPOS_BUSCA_SKU}
                        disabled={disabledBusca}
                        showClear={false}
                        value={tipoBusca}
                        onChange={(e) => setTipoBusca(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="sku" label={label} obrigatorio={obrigatorio} />
                    <div className="p-inputgroup">
                        <MakoAutoComplete
                            inputId="sku"
                            name="sku"
                            placeholder="Digite para buscar..."
                            disabled={disabledBusca}
                            value={skuValue}
                            delay={1500}
                            onChange={(e) => onChangeSKU(e.value)}
                            itemTemplate={autoCompleteItemTemplate}
                            field="descricao_derivada"
                            urlSearch={urlPesquisa}
                            showEmptyMessage
                            emptyMessage="Produto não encontrado / Não finalizado."
                            className={classNames({ "p-invalid": skuError })}
                            autoFocus={autoFocus}
                        />
                        {exibirBotaoSelecionar && (
                            <Button
                                type="button"
                                icon="pi pi-check"
                                onClick={onSelecionar}
                                className="p-button-success"
                            />
                        )}
                        <Button
                            type="button"
                            icon="pi pi-trash"
                            onClick={limparCampos}
                            disabled={disabledBusca}
                            className="p-button-warning"
                        />
                        {exibirVisualizacaoSku && (
                            <Button
                                type="button"
                                icon="pi pi-eye"
                                onClick={() =>
                                    visualizarSkuRef.current?.show({
                                        id: skuValue?.id,
                                        buscarPor: "sku",
                                    })
                                }
                                disabled={!skuValue?.id}
                                className="p-button-info"
                            />
                        )}
                    </div>
                    {skuError && <small className="p-error">{skuError}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="codigo">Código do produto</label>
                    <InputText id="codigo" name="codigo" value={codigoSKU} disabled />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="descricao">Descrição no cadastro interno</label>
                    <InputText id="descricao" name="descricao" value={descricaoSKU} disabled />
                </div>
            </div>
            {exibeDimensoes && skuValue?.movimenta_cubagem && (
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="largura">Largura</label>
                        <div className="p-inputgroup">
                            <MakoInputDimensao
                                inputId="largura"
                                name="largura"
                                disabled={opcoesLargura.desabilitado}
                                min={opcoesLargura.min}
                                max={opcoesLargura.max}
                                value={larguraValue}
                                onValueChange={(e) => onChangeLargura(e.target.value)}
                            />
                            <span className="p-inputgroup-addon">{opcoesLargura.unidade?.sigla || "-"}</span>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="altura">Altura</label>
                        <div className="p-inputgroup">
                            <MakoInputDimensao
                                inputId="altura"
                                name="altura"
                                disabled={opcoesAltura.desabilitado}
                                min={opcoesAltura.min}
                                max={opcoesAltura.max}
                                value={alturaValue}
                                onValueChange={(e) => onChangeAltura(e.target.value)}
                            />
                            <span className="p-inputgroup-addon">{opcoesAltura.unidade?.sigla || "-"}</span>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="comprimento">Comprimento</label>
                        <div className="p-inputgroup">
                            <MakoInputDimensao
                                inputId="comprimento"
                                name="comprimento"
                                disabled={opcoesComprimento.desabilitado}
                                min={opcoesComprimento.min}
                                max={opcoesComprimento.max}
                                value={comprimentoValue}
                                onValueChange={(e) => onChangeComprimento(e.target.value)}
                            />
                            <span className="p-inputgroup-addon">{opcoesComprimento.unidade?.sigla || "-"}</span>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cubagem">Dimensão</label>
                        <div className="p-inputgroup">
                            <MakoInputCubagem inputId="cubagem" name="cubagem" disabled value={dimensaoValue} />
                            <span className="p-inputgroup-addon">{undDimensao?.sigla || "-"}</span>
                        </div>
                    </div>
                </div>
            )}
            {exibirVisualizacaoSku && <MakoVisualizarSku ref={visualizarSkuRef} />}
        </>
    );
};

export const MakoBuscaSkuPersonalizada = forwardRef(Input);
