import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import useVenda from "@/hooks/useVenda";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OpcoesFaturamento } from "@/components/MakoOpcoesFaturamento";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";

export const DocFiscalForm = () => {
    const [exibirItens, setExibirItens] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const [opcaoFaturamento, setOpcaoFaturamento] = useState(null);
    const modeloNfRef = useRef(null);
    const opcoesRef = useRef(null);
    const { dadosBasicos, parcelasVenda } = useVenda();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning } = useToast();
    const { httpGet, httpPost } = useHttp();
    const history = useHistory();
    const listagemRef = useRef();

    async function enviarNota() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada?.enderecoperfil_set[0];
                const body = {
                    modelo: modeloNfRef.current,
                    venda: dadosBasicos.id,
                    operacao_fiscal: itensSelecionados[0].operacao_fiscal,
                    natureza_operacao: null,
                    transferencia: null,
                    evento_id: null,
                    tipo_operacao: 1,
                    app_label: "vendas",
                    model: "venda",
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: dadosBasicos.empresa.id,
                        destinatario: dadosBasicos.cliente.id || null,
                        requer_identificacao: dadosBasicos.cliente?.id ? true : false,
                        indicador_pagamento:
                            parcelasVenda?.lenght > 0 ? (parcelasVenda[0].quantidade_parcelas === 1 ? 1 : 2) : 1,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_final: 1,
                        formato_impressao: 0,
                        nota_referencia: [],
                        info_adicionais: "",
                    },
                    itens: itensSelecionados.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_venda.sigla,
                            sku: item.sku.id,
                            valor_total: item.valor_unitario * item.quantidade_nf,
                            valor_desconto: item.valor_desconto_unitario,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade: item.quantidade_nf,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                            app_label: "vendas",
                            model: "itemfiscalitemvenda",
                        };
                    }),
                    parcelas: parcelasVenda,
                };

                const handlers = {
                    200: () => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Nota fiscal incluida com sucesso!",
                            life: 3000,
                        });
                        setExibirItens(false);
                    },
                    400: ({ err }) =>
                        showWarning({
                            summary: "Falha ao inserir",
                            detail: `Não foi possível inserir a nota fiscal, verifique as informações e tente novamente. Motivo(s): ${err.erro}`,
                            life: 5000,
                        }),
                };

                await httpPost({ url: "/fiscal/inserir-nota-fiscal/", body }, handlers);
            }
        } catch (error) {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const sugerirOperacoesNF = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                let lista = listaItens;
                data.operacoes?.forEach((op, index) => {
                    lista[index]["operacao_fiscal"] = op;
                });
                setItensSelecionados(lista.filter(({ operacao_fiscal }) => operacao_fiscal));
                setListaItens(lista);
                const nao_corresponde = lista.filter(({ operacao_fiscal }) => !operacao_fiscal);
                if (nao_corresponde?.length > 0)
                    showWarning({
                        summary: "Alerta!",
                        detail: `Atenção, alguns itens não possuem operação fiscal correspondente (${nao_corresponde
                            .map((e) => e.sku.descricao_reduzida)
                            .join(", ")}).`,
                        life: 5000,
                    });
            },
        };

        await httpPost(
            {
                url: `/fiscal/sugerir-operacoes-fiscais/${empresaSelecionadaId}/`,
                body: {
                    itens: listaItens,
                    devolucao: false,
                },
            },
            handlers
        );
    }, [listaItens, empresaSelecionadaId, httpPost, showWarning]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T"}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunasNota = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        setListaItens(
            listaItens.map((item) => {
                if (item.aux_quantidade === aux) item.quantidade_nf = quantidade;
                return item;
            })
        );
    };

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => editarQuantidade(options, e.target.value, options.rowData.aux_quantidade)}
            />
        );
    };

    const editarOperacao = (options, operacao, aux) => {
        options.editorCallback(operacao);
        setListaItens(
            listaItens.map((item) => {
                if (item.id === aux) item.operacao_fiscal = operacao;
                return item;
            })
        );
    };

    const operacaoEditor = (options) => {
        return (
            <Dropdown
                id="operacao_fiscal"
                name="operacao_fiscal"
                options={listaOperacoes}
                optionValue="id"
                optionLabel="descricao"
                style={{ width: "100%" }}
                value={options.value}
                onChange={(e) => editarOperacao(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const colunasItens = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "sku.codigo", header: "Código", style: { minWidth: "80px" } },
        { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "250px" } },
        { field: "sku.item.ncm.codigo", header: "NCM" },
        {
            field: "quantidade",
            header: "Quant",
            style: { minWidth: "100px" },
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "Qtd. NF",
            decimal: true,
            style: { minWidth: "100px" },
            editor: (options) => textEditor(options),
        },
        {
            field: "operacao_fiscal",
            header: "Operação Fiscal",
            decimal: true,
            style: { minWidth: "300px" },
            editor: (options) => operacaoEditor(options),
            action: ({ operacao_fiscal }) => (
                <Dropdown
                    id="operacao_fiscal"
                    name="operacao_fiscal"
                    options={listaOperacoes}
                    optionValue="id"
                    optionLabel="descricao"
                    style={{ width: "100%" }}
                    value={operacao_fiscal}
                />
            ),
        },
    ];

    const listarItensVenda = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setListaItens(
                    data.results.map((item, index) => ({
                        ...item,
                        aux_quantidade: index + 1,
                        quantidade_nf: item.quantidade,
                    }))
                );
            },
        };

        await httpGet({ url: `/vendas/itens-vendas/?venda=${dadosBasicos?.id}` }, handlers);
    }, [dadosBasicos, httpGet]);

    const carregaOpcaoFaturamento = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data.results?.length > 0) setOpcaoFaturamento(data.results[0]);
            },
        };

        await httpGet({ url: `/fiscal/opcoes-faturamento?limit=1&empresa=${empresaSelecionadaId}` }, handlers);
    }, [empresaSelecionadaId, httpGet]);

    useEffect(() => {
        carregaOpcaoFaturamento();
    }, [carregaOpcaoFaturamento]);

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "S",
            limit: 1000,
            ativo: true,
        };

        const handlers = {
            200: ({ data }) => {
                const { enderecoperfil_set = [] } = empresaSelecionada;
                const enderecoEmpresa = enderecoperfil_set?.find(({ principal }) => !!principal);
                const enderecoCliente = dadosBasicos.cliente?.enderecoperfil_set?.find(({ principal }) => !!principal);

                if (enderecoEmpresa?.lenght > 0 && enderecoCliente?.lenght > 0) {
                    setListaOperacoes(
                        data.results
                            .filter(({ esfera }) =>
                                esfera === "D"
                                    ? enderecoEmpresa[0].cidade.estado.id === enderecoCliente[0].cidade.estado.id
                                    : enderecoEmpresa[0].cidade.estado.id !== enderecoCliente[0].cidade.estado.id
                            )
                            .map(({ operacao }) => operacao)
                    );
                } else {
                    setListaOperacoes(data.results);
                }
            },
        };

        await httpGet({ url: "/fiscal/operacoes-fiscais/", params }, handlers);
    }, [empresaSelecionadaId, empresaSelecionada, dadosBasicos.cliente.enderecoperfil_set, httpGet]);

    useEffect(() => {
        listarOperacoes();
        listarItensVenda();
    }, [listarItensVenda, listarOperacoes]);

    const aposSalvarPadrao = () => {
        carregaOpcaoFaturamento();
        showSuccess({
            summary: "Sucesso!",
            detail: "Padrão de opções de faturamento alterado com sucesso.",
            life: 3000,
        });
    };

    const aposSalvarContexto = useCallback(
        async (opcao) => {
            setOpcaoFaturamento(opcao);
            showSuccess({
                summary: "Sucesso!",
                detail: "Opções de faturamento salvas com sucesso.",
                life: 3000,
            });
        },
        [showSuccess]
    );

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    return (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
                componente={Button}
                type="button"
                label="Gerar Documento Fiscal"
                disabled={!opcaoFaturamento || !dadosBasicos}
                onClick={() => {
                    setExibirItens(true);
                    modeloNfRef.current =
                        opcaoFaturamento?.modelo_documento === "QQ"
                            ? dadosBasicos?.cliente?.tipo_pessoa === "PF"
                                ? "65"
                                : "55"
                            : opcaoFaturamento?.modelo_documento === "NFC"
                            ? "65"
                            : "55";
                }}
                className="p-mr-2"
            />
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_OPCOESFATURAMENTO_EDITAR]}
                componente={Button}
                type="button"
                label="Opções de Faturamento"
                className="p-mr-2 p-button-help"
                onClick={async () => {
                    await opcoesRef.current?.abrirModal(opcaoFaturamento);
                }}
            />
            <MakoListagem
                ref={listagemRef}
                titulo="Documentos fiscais da venda"
                urlPesquisa={`/fiscal/notas-fiscais?venda=${dadosBasicos ? dadosBasicos.id : null}`}
                colunas={colunasNota}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
            <Dialog
                header={"Selecionando itens para emissão do documento fiscal"}
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => setExibirItens(false)}
            >
                <MakoListagem
                    titulo="Itens do Orçamento"
                    colunas={colunasItens}
                    dadosLocal={listaItens}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: itensSelecionados,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!itensSelecionados}
                    onClick={() => enviarNota()}
                />
                <Button
                    type="button"
                    icon="pi pi-question"
                    label="Sugerir operações"
                    className="p-mt-4 p-ml-2 p-button-help"
                    onClick={() => sugerirOperacoesNF()}
                />
            </Dialog>
            <OpcoesFaturamento venda ref={opcoesRef} onSuccess={aposSalvarPadrao} onContext={aposSalvarContexto} />
        </>
    );
};
