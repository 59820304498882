import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import useOrdemCompra from "@/hooks/useOrdemCompra";
import useToast from "@/hooks/useToast";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

export const AnotacoesOrdemCompraForm = () => {
    const { dadosBasicos, handleDadosBasicos } = useOrdemCompra();
    const { showSuccess } = useToast();

    const formik = useFormik({
        initialValues: {
            anotacoes: dadosBasicos?.anotacoes || "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const { anotacoes } = values;
        if (anotacoes) {
            const { status } = await handleDadosBasicos({ ...dadosBasicos, anotacoes });
            if (status === 200 || status === 204) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Anotações adicionadas com sucesso.",
                    life: 2000,
                });
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="anotacoes">Anotações da ordem de compra</label>
                    <InputTextarea
                        id="anotacoes"
                        name="anotacoes"
                        rows={12}
                        autoFocus
                        value={formik.values.anotacoes}
                        onChange={formik.handleChange}
                    />
                </div>
                <MakoActionsButtons className="p-ml-3">
                    <Button icon={MAKO_ICONS.EDITAR} type="submit" label="Adicionar" />
                </MakoActionsButtons>
            </div>
        </form>
    );
};
