import React, { useRef } from "react";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/bancos/";

export const FinanceiroBancoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/cadastros/bancos/form",
                        state: { bancos: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/bancos"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="banco"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o banco <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O banco não pôde ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_INCLUIR]}
            componente={Button}
            label="Novo"
            icon={MAKO_ICONS.NOVO}
            className="p-button-success "
            to={"/financeiro/cadastros/bancos/form"}
        />
    );

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Ativo" },
                        { value: false, label: "Desativado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { width: "12%" },

            filter: true,
        },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "ativo",
            header: "Situação",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            style: { width: "10%" },
            align: "center",
            filter: true,
            filterElement: StatusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Bancos"
                urlPesquisa={url}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    ativo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
