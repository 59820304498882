import React, { useState } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Password } from "primereact/password";
import { LOGO_SHARKDEV } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const ConfirmarSenhaPage = (props) => {
    const [novaSenha, setNovaSenha] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();
    const history = useHistory();

    async function confirmarSenha() {
        const handlers = {
            201: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `Nova senha registrada com sucesso.`,
                    life: 4000,
                });
                history.push("/login");
            },
        };

        showLoading();
        await httpPost(
            {
                url: "/auth/users/reset_password_confirm/",
                body: {
                    uid: props.match.params.id,
                    new_password: novaSenha,
                    token: props.match.params.token,
                },
            },
            handlers
        );
        hideLoading();
    }

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src={LOGO_SHARKDEV} className="logo" alt="sharkdev-logo" />
                    <div className="login-form" style={{ width: "90%" }}>
                        <label>
                            <b>Insira sua nova senha:</b>
                        </label>
                        <Password
                            id="password"
                            placeholder="Nova senha"
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            feedback={false}
                            value={novaSenha}
                            autoFocus
                            onChange={(e) => setNovaSenha(e.target.value)}
                        />
                        <Button label="Gravar" icon="pi pi-save" type="button" onClick={confirmarSenha} />
                    </div>
                    <p>
                        <a href="/login">Voltar a página inicial.</a>
                    </p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        <h1>Olá,</h1>
                        <h1>seja muito bem vindo </h1>
                        <h1>ao SysMako!</h1>
                    </div>
                    <div className="image-footer">
                        <p>FACEBOOK</p>
                        <p>INSTAGRAM</p>
                        <p>
                            {`${new Date().getFullYear()} - Desenvolvido por `}
                            <a href="https://sharkdev.com.br" target="blank">
                                SharkDev
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
