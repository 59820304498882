import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputNumber } from "primereact/inputnumber";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";

export const ListaPendencias = ({ exibir }) => {
    const [selecionados, setSelecionados] = useState([]);
    const [pendencias, setPendencias] = useState([]);
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPost, httpPut, httpGet } = useHttp();
    const listagemRef = useRef(null);

    async function handleGravaPendencias() {
        try {
            const handlers = {
                200: async ({ data }) => {
                    if (data.produtos_importados)
                        showSuccess({
                            summary: "Sucesso",
                            detail: `Produtos importados com sucesso: ${data.produtos_importados}`,
                            sticky: true,
                        });
                    if (data.produtos_pendentes)
                        showWarning({
                            summary: "Pêndencias",
                            detail: `Produtos incompletos: ${data.produtos_pendentes}`,
                            sticky: true,
                        });
                    listarPendencias();
                    setSelecionados([]);
                },
                400: () =>
                    showError({
                        severity: "error",
                        summary: "Erro",
                        detail: "Falha ao processar pendências, tente novamente mais tarde.",
                        life: 3000,
                    }),
            };

            await httpPost(
                {
                    url: "/produtos/importar-produtos/",
                    body: selecionados,
                },
                handlers
            );
        } catch {
            showError();
        }
    }

    async function removePendencias() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Pendência(s) removida(s) com sucesso!",
                        life: 3000,
                    });
                    listarPendencias();
                    setSelecionados([]);
                },
                400: () =>
                    showError({
                        severity: "error",
                        summary: "Erro",
                        detail: "Falha ao remover pendências, tente novamente mais tarde.",
                        life: 3000,
                    }),
            };

            await httpPut(
                {
                    url: "/produtos/remover-pendencias-importacao/",
                    body: selecionados.map(({ id }) => id),
                },
                handlers
            );
        } catch {
            showError();
        }
    }

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_INCLUIR]}
                componente={Button}
                label={`Gravar Produtos ( ${selecionados.length} )`}
                icon="pi pi-save"
                className="p-button-success p-mr-2 p-mb-2"
                disabled={selecionados?.length === 0}
                onClick={() => handleGravaPendencias()}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EXCLUIR]}
                componente={Button}
                label={`Remover pendências ( ${selecionados.length} )`}
                icon="pi pi-trash"
                className="p-button-danger p-mr-2 p-mb-2"
                disabled={selecionados?.length === 0}
                onClick={() => removePendencias()}
            />
        </>
    );

    const editar = (options, value, id, field) => {
        options.editorCallback(value);
        setPendencias(
            pendencias.map((item) => {
                if (item.id === id) item[field] = value;
                return item;
            })
        );
    };

    const EditorTexto = (options, field) => {
        return (
            <InputText
                type="text"
                value={options.value}
                placeholder="Informe..."
                onChange={(e) => editar(options, e.target.value, options.rowData.id, field)}
            />
        );
    };

    const EditorNumero = (options, field) => {
        return (
            <InputNumber
                type="decimal"
                value={options.value}
                onValueChange={(e) => editar(options, e.target.value, options.rowData.id, field)}
            />
        );
    };

    const EditorDropdown = (options, url, field, label = "nome") => {
        return (
            <Dropdown
                optionValue="id"
                optionLabel={label}
                url={url}
                filter
                filterBy={label}
                showClear
                placeholder="Selecione..."
                value={options.value}
                onChange={(e) => editar(options, e.target.value, options.rowData.id, field)}
                style={{ width: "100%" }}
            />
        );
    };

    const EditorCategoriaDropdown = (options, field) => {
        return (
            <MakoDropdownCategoriasHierarquicas
                id={field}
                name={field}
                value={options.value}
                //getCategoriaCompleta={onChangeCategoria}
                onChange={(e) => editar(options, e.target.value, options.rowData.id, field)}
            />
        );
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "50px" } },
        { field: "codigo_externo", header: "Código externo", style: { minWidth: "150px" } },
        {
            field: "codigo_sku",
            header: "Código interno *",
            style: { minWidth: "200px" },
            editor: (options) => EditorTexto(options, "codigo_sku"),
        },
        { field: "descricao", header: "Descrição *", style: { minWidth: "300px" } },
        {
            field: "descricao_reduzida",
            header: "Descrição Reduzida *",
            style: { minWidth: "250px" },
            editor: (options) => EditorTexto(options, "descricao_reduzida"),
        },
        {
            field: "ncm",
            header: "Código NCM *",
            style: { minWidth: "250px" },
            editor: (options) => EditorTexto(options, "ncm"),
        },
        {
            field: "cest",
            header: "Código Cest",
            style: { minWidth: "250px" },
            editor: (options) => EditorTexto(options, "cest"),
        },
        {
            field: "unidade_padrao",
            header: "Unidade Padrão (Sigla) *",
            style: { minWidth: "250px" },
            editor: (options) => EditorTexto(options, "unidade_padrao"),
        },
        {
            field: "marca.nome",
            header: "Marca",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(options, "/produtos/marcas?query={id, nome}&limit=3000", "marca", "nome"),
        },

        {
            field: "modelo.nome",
            header: "Modelo",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(options, "/produtos/modelos?query={id, nome}&limit=3000", "modelo", "nome"),
        },
        {
            field: "comissao_venda.nome",
            header: "Comissão de Venda",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/vendas/comissoes-vendas?query={id, descricao}&limit=3000",
                    "comissao_venda",
                    "nome"
                ),
        },
        {
            field: "formacao_preco.nome",
            header: "Formação de Preço",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/produtos/formacoes-precos?query={id, descricao}&limit=3000",
                    "formacao_preco",
                    "nome"
                ),
        },
        {
            field: "unidade_venda.nome",
            header: "Unidade de Venda",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/produtos/unidades-medida?query={id, nome}&limit=3000",
                    "unidade_venda",
                    "nome"
                ),
        },
        {
            field: "unidade_compra.nome",
            header: "Unidade de Compra",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/produtos/unidades-medida?query={id, nome}&limit=3000",
                    "unidade_compra",
                    "nome"
                ),
        },
        {
            field: "categoria_sku.descricao",
            header: "Categoria",
            style: { minWidth: "250px" },
            editor: (options) => EditorCategoriaDropdown(options, "categoria_sku"),
        },
        {
            field: "preco_sku",
            header: "Preço",
            style: { minWidth: "250px" },
            editor: (options) => EditorNumero(options, "preco_sku"),
        },
        {
            field: "vigencia_preco.descricao",
            header: "Vigência do Preço",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/produtos/precos-vigencia?query={id, descricao}&limit=3000",
                    "vigencia_preco",
                    "descricao"
                ),
        },
        {
            field: "centro_estoque.descricao",
            header: "Centro de Estocagem",
            style: { minWidth: "250px" },
            editor: (options) =>
                EditorDropdown(
                    options,
                    "/produtos/centros-estocagem?query={id, descricao}&limit=3000",
                    "centro_estoque",
                    "descricao"
                ),
        },
        { field: "status", header: "Status", style: { minWidth: "250px" } },
        { field: "erros", header: "Erros", style: { minWidth: "950px" } },
        { field: "action", rowEditor: true, header: "Ações", style: { minWidth: "100px" } },
    ];

    const listarPendencias = useCallback(async () => {
        if (exibir) {
            const handlers = {
                200: ({ data }) => {
                    setPendencias(data.results);
                },
            };

            await httpGet(
                {
                    url: "/produtos/produtos-importacao?limit=30000",
                },
                handlers
            );
        }
    }, [httpGet, exibir]);

    useEffect(() => {
        listarPendencias();
    }, [listarPendencias]);

    const onRowEditComplete = () => {
        console.log("sucesso");
    };

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={`Pendências (${pendencias?.length || 0})`}
            colunas={colunas}
            painelEsquerdo={cabecalhoTabela}
            dadosLocal={pendencias}
            configTabela={{
                selection: selecionados,
                onSelectionChange: (e) => setSelecionados(e.value),
                editMode: "row",
                dataKey: "id",
                onRowEditComplete: { onRowEditComplete },
            }}
        />
    );
};
