import React, { useCallback, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { TabPanel, TabView } from "primereact/tabview";
import { CriterioCrediarioForm } from "./formCriterioCrediario";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";

const url = "/crediario/regra-crediario/";

export const RegraCrediarioForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { showSuccess, showError, showInfo } = useToast();
    const { regra } = useLocation();
    const { httpPost, httpPatch } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            descricao: "",
            natureza_ocupacao: null,
            valor_maximo: null,
            valor_minimo: null,
            percentual_limite: 0,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().max(40).required("O campo 'descrição' é obrigatório."),
                natureza_ocupacao: Yup.number().required("O campo 'natureza de ocupação' é obrigatório."),
                valor_maximo: Yup.number()
                    .required("O campo 'valor mínimo' é obrigatório.")
                    .min(values.valor_minimo, "O valor máximo não pode ser menor que o valor mínimo.")
                    .typeError("Informe um 'valor máximo' válido."),
                valor_minimo: Yup.number()
                    .required("O campo 'valor máximo' é obrigatório.")
                    .max(values.valor_maximo, "O valor mínimo não pode ser maior que o valor máximo.")
                    .typeError("Informe um 'valor minimo' válido."),
                percentual_limite: Yup.number()
                    .required("O campo 'percentual limite' é obrigatório.")
                    .typeError("Informe um 'percentual de limite' válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Regra de crediário cadastrada com sucesso!",
                            life: 1500,
                        });
                        showInfo({
                            summary: "Critérios liberados",
                            detail: "Agora você já pode ir até a aba de critérios e personalizar esta regra de crediário.",
                            sticky: true,
                        });
                        history.push({
                            pathname: "/vendas/venda/crediario/regra-crediario/form",
                            regra: data,
                        });
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                delete values.criteriocrediario_set;

                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Regra de crediário alterada com sucesso!",
                            life: 500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/regra-crediario");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/vendas/venda/crediario/regra-crediario");
    };

    useEffect(() => {
        if (regra)
            setValues({
                ...regra,
                natureza_ocupacao: regra.natureza_ocupacao?.id || null,
            });
    }, [regra, setValues]);

    const aposBuscar = useCallback((results) => {
        return results.map((nat) => {
            return { ...nat, label: `${nat.id} - ${nat.descricao}` };
        });
    }, []);

    return (
        <PageBase>
            {
                <h3>
                    {!formik.values.id
                        ? "Nova Regra de Crediário"
                        : `Manutenção de Regra de Crediário${
                              formik.values?.descricao ? `: ${formik.values?.descricao}` : ""
                          }`}
                </h3>
            }
            <TabView>
                <TabPanel header="Regra" leftIcon="pi pi-shield">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="descricao" label="Descrição" obrigatorio />
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="valor_minimo" label="Valor mínimo" obrigatorio />
                                <InputNumber
                                    id="valor_minimo"
                                    name="valor_minimo"
                                    mode="currency"
                                    currency="BRL"
                                    min={0}
                                    value={formik.values.valor_minimo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_minimo })}
                                />
                                {formik.errors.valor_minimo && (
                                    <small className="p-error">{formik.errors.valor_minimo}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="valor_maximo" label="Valor máximo" obrigatorio />
                                <InputNumber
                                    id="valor_maximo"
                                    name="valor_maximo"
                                    mode="currency"
                                    currency="BRL"
                                    min={0}
                                    value={formik.values.valor_maximo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_maximo })}
                                />
                                {formik.errors.valor_maximo && (
                                    <small className="p-error">{formik.errors.valor_maximo}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="percentual_limite" label="Percentual limite" obrigatorio />
                                <InputNumber
                                    id="percentual_limite"
                                    name="percentual_limite"
                                    mode="decimal"
                                    prefix="%"
                                    min={0}
                                    minFractionDigits={2}
                                    value={formik.values.percentual_limite}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percentual_limite })}
                                />
                                {formik.errors.percentual_limite && (
                                    <small className="p-error">{formik.errors.percentual_limite}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <Label htmlFor="natureza_ocupacao" label="Natureza de ocupação" obrigatorio />
                                <Dropdown
                                    id="natureza_ocupacao"
                                    name="natureza_ocupacao"
                                    placeholder="Selecione uma natureza"
                                    url="/crediario/natureza-ocupacao?limit=3000&query={id,descricao}"
                                    optionValue="id"
                                    optionLabel="label"
                                    filter
                                    filterBy="descricao,id"
                                    showClear={false}
                                    aposBuscar={aposBuscar}
                                    value={formik.values.natureza_ocupacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.natureza_ocupacao })}
                                />
                                {formik.errors.natureza_ocupacao && (
                                    <small className="p-error">{formik.errors.natureza_ocupacao}</small>
                                )}
                            </div>
                        </div>
                        <CamposObrigatorios />
                        <MakoActionsButtons>
                            <Button
                                label={formik.values.id ? "Gravar" : "Criar regra"}
                                type="submit"
                                icon={formik.values.id ? "pi pi-save" : "pi pi-plus"}
                                className="p-button-info"
                            />
                            <Button
                                label={formik.values.id ? "Voltar" : "Cancelar"}
                                type="reset"
                                icon="pi pi-angle-double-left"
                                className="p-button-danger"
                                onClick={cancelar}
                            />
                        </MakoActionsButtons>
                    </form>
                </TabPanel>
                <TabPanel header="Critérios" leftIcon="pi pi-exclamation-triangle" disabled={!formik.values.id}>
                    <CriterioCrediarioForm regra={formik.values.id ? formik.values.id : null} />
                </TabPanel>
            </TabView>
        </PageBase>
    );
};
