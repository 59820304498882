import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/pessoas/permissoes-perfis/";

export const PermissoesPerfilForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPut } = useHttp();
    const history = useHistory();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            chave: "",
            descricao: "",
            modulo: null,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (!props.location.state?.permissao) return;
        setValues(props.location.state.permissao);
    }, [props.location.state, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                modulo: Yup.string().required("O campo 'módulo' é obrigatório.").typeError("Selecione um módulo."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissao atualizada com sucesso!",
                        life: 1500,
                    });
                    cancelar();
                },
            };
            showLoading();
            await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/configuracoes/permissoes-perfis/");
    };

    return (
        <PageBase>
            <h3>Alterar permissão</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="modulo" label="Módulo" obrigatorio />
                        <Dropdown
                            id="modulo"
                            name="modulo"
                            url="/configuracoes/modulos/"
                            optionValue="id"
                            optionLabel="descricao"
                            autoFocus
                            value={formik.values.modulo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.modulo })}
                        />
                        {formik.errors.modulo && <small className="p-error">{formik.errors.modulo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="chave" label="Chave" />
                        <InputText id="chave" name="chave" disabled value={formik.values.chave} />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <MakoActionsButtons>
                    <MakoButton
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <MakoButton
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
