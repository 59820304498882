import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownCompetenciaFinanceira, MakoDropdownHistoricoPadrao } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { dataToStr, parseData } from "@/assets/util/datas";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const INITIAL_VALUES = {
    id: null,
    conta_financeira: null,
    historico_original: null,
    historico_padrao: "",
    competencia_financeira: null,
    data_recebimento: null,
    empresa: null,
};

const BASE_PERIODO = { start: null, end: null };

export const Corrigir = ({ recebimento, successCallback = () => {}, cancelCallback = () => {} }) => {
    const [periodo, setPeriodo] = useState(BASE_PERIODO);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();

    const competencias = useRef([]);

    const { values, errors, setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number().nullable().notRequired().default(null),
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                data_recebimento: Yup.date()
                    .required("O campo 'data de emissão' é obrigatório.")
                    .typeError("Informe uma data válida."),
                competencia_financeira: Yup.number().nullable().notRequired().default(null),
            });
            formSchema.validateSync(values, { abortEarly: false });

            const {
                id,
                conta_financeira,
                historico_original,
                historico_padrao,
                competencia_financeira,
                data_recebimento,
            } = values;

            const json = {
                id,
                conta_financeira,
                competencia_financeira,
                data_recebimento:
                    typeof data_recebimento === "string" ? data_recebimento : dataToStr(data_recebimento, "yyyy-MM-dd"),
                historico: { historico_original, historico_padrao: historico_padrao?.id },
            };

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Recebimento corrigido com sucesso",
                        life: 3000,
                    });
                    successCallback();
                },
            };
            showLoading();
            await httpPost({ url: `/financeiro/efetivado/corrigir/${values.id}`, body: json }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeContaFinanceira = (e) => {
        const competencia = competencias.current.find(({ id }) => id === e.value);
        if (competencia && e.value) {
            const { data_inicio, data_fim } = competencia;
            setPeriodo({ start: parseData(data_inicio), end: parseData(data_fim) });
        } else setPeriodo({ ...BASE_PERIODO });
        formik.handleChange(e);
    };

    const aposBuscar = useCallback((data) => {
        competencias.current = data;
        return data;
    }, []);

    useEffect(() => {
        if (recebimento) {
            const {
                id,
                conta_financeira,
                historico_original,
                historico_padrao,
                competencia,
                data_recebimento,
                empresa,
            } = recebimento || {};
            setValues({
                id,
                historico_original,
                historico_padrao,
                data_recebimento,
                conta_financeira,
                empresa: empresa.id,
                competencia_financeira: competencia?.id,
            });
        }
    }, [recebimento, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="competencia_financeira" label="Competência financeira" />
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia_financeira"
                        name="competencia_financeira"
                        value={values.competencia_financeira}
                        habilitarInativas
                        onChange={onChangeContaFinanceira}
                        aposBuscar={aposBuscar}
                        className={classNames({ "p-invalid": errors.competencia_financeira })}
                    />
                    {errors.competencia_financeira && (
                        <small className="p-error">{errors.competencia_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_recebimento" label="Data recebimento" obrigatorio />
                    <MakoCalendar
                        id="data_recebimento"
                        name="data_recebimento"
                        value={values.data_recebimento}
                        minDate={periodo.start}
                        maxDate={periodo.end}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": errors.data_recebimento })}
                    />
                    {errors.data_recebimento && <small className="p-error">{errors.data_recebimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="historico" label="Histórico" obrigatorio />
                    <MakoDropdownHistoricoPadrao
                        filtros={{ tipo: "R" }}
                        id="historico"
                        name="historico_padrao"
                        value={values.historico_padrao}
                        setFieldValueForm={setFieldValue}
                        handleChangeForm={formik.handleChange}
                        showButtonNew={false}
                        inputTextProps={{
                            value: values.historico_original,
                        }}
                        className={classNames({ "p-invalid": errors.historico_padrao })}
                    />
                    {errors.historico_padrao && <small className="p-error">{errors.historico_padrao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="conta-financeira" label="Conta financeira" />
                    <Dropdown
                        id="conta-financeira"
                        name="conta_financeira"
                        url={`/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta__in=2,3&perfil=${
                            values.empresa || empresaSelecionadaId
                        }`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={values.conta_financeira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": errors.conta_financeira })}
                    />
                    {errors.conta_financeira && <small className="p-error">{errors.conta_financeira}</small>}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    className="p-button-danger"
                    onClick={cancelCallback}
                    loading={loading}
                />
                <Button
                    label="Gravar"
                    icon={MAKO_ICONS.GRAVAR}
                    className="p-button-info"
                    type="submit"
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
