import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoInputFornecedor } from "@/components/MakoInputs";

import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { formatarCasasDecimais, gerarFileName } from "@/assets/util/util";
import { RELATORIOS_PRODUTOS_CUSTOMONTAGEM } from "@/assets/constants/relatorios";

import useClearRefs from "@/hooks/useClearRefs";

const { ExportCSV } = R.Buttons;

const FILTROS_VIEWSET = {
    fornecedor: "fornecedor",
    categoria: "categoriasku__categoria",
};

const BASE_URL = "/relatorios/custo-montagem/";

const FILE_NAME = gerarFileName("Custo Montagem");

export const RelatorioCustoMontagem = () => {
    const [url, setUrl] = useState(null);
    const [buscarListagem, setBuscarListagem] = useState(false);

    const { solicitarRelatorio } = useRelatorio();

    const botaoCsv = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const formRef = useRef();

    useClearRefs(categoriaRef, listagemRef, formRef, botaoCsv);

    const submit = (e = null, limpar = true) => {
        if (limpar) limparVisualizacao();
        return formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
        categoriaRef.current = null;
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const filtrosTemplate = [
        {
            key: "categoria",
            label: "Grupo",
            path: "nome",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
    ];

    const templateTempoMontagem = ({ minutos_montagem }) => {
        const horas = formatarCasasDecimais(parseFloat(minutos_montagem) / 60);
        if (horas === 1) return `${horas} hora`;
        return `${horas} horas`;
    };

    const colunas = [
        { field: "codigo", header: "Código", style: { width: "8%" } },
        { field: "descricao", header: "Descrição" },
        { field: "unidade_padrao.sigla", header: "UN", align: "center", style: { width: "6%" } },
        { field: "minutos_montagem", header: "Tempo montagem", action: templateTempoMontagem },
        { field: "auxiliares_montagem", header: "Auxiliares", style: { width: "5%" }, align: "center" },
        { field: "custo_montagem", header: "Custo montagem", style: { width: "10%" }, money: true },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                categoria: Yup.number().nullable().default(null).typeError("Informe um 'grupo' válido"),
                fornecedor: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: categoriaRef.current,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            delete dadosValidados.background;
            delete dadosValidados.emails;

            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;

            if (!buscarListagem) {
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key] !== null && dadosValidados[key] !== "")
                            filtros[key] = dadosValidados[key];
                    });
                }
                solicitarRelatorio({
                    chave: RELATORIOS_PRODUTOS_CUSTOMONTAGEM,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;

        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (categoriaRef.current) dadosValidados.categoria = categoriaRef.current;

        let filtros = {};
        if (dadosValidados) {
            Object.keys(dadosValidados).forEach((key) => {
                if (dadosValidados[key] !== null && dadosValidados[key] !== "") filtros[key] = dadosValidados[key];
            });
        }
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIOS_PRODUTOS_CUSTOMONTAGEM}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };
    return (
        <R.Wrapper titulo={"de custo montagem"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        categoria: null,
                        fornecedor: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 4,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIOS_PRODUTOS_CUSTOMONTAGEM}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                botaoExportar={BotaoExportar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    exportFilename: FILE_NAME,
                }}
            />
        </R.Wrapper>
    );
};
