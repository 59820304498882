import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Tag } from "primereact/tag";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import useToast from "@/hooks/useToast";

const url = "/fiscal/cfop/";

export const FiscalCfopPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showSuccess } = useToast();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CFOP_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de CFOP"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/cfop/form",
                            state: { cfop: rowData },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/fiscal/cfop"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="o"
                    entidade="CFOP"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        onDelete: () => {
                            listagemRef.current?.buscarDados();
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Cfop deletado com sucesso!",
                                life: 3000,
                            });
                        },
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o CFOP <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O serviço não pode ser excluído.",
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_CFOP_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/cfop/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Ativo" },
                    { value: false, label: "Inativo" },
                ]}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "50px" },
            filter: true,
            showFilterMatchModes: false,
            showFilterMenu: false,
        },
        {
            field: "codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "800px" },
            filter: true,
        },
        {
            field: "tipo_movimentacao",
            header: "Movimentação",
            action: ({ tipo_movimentacao }) => {
                if (tipo_movimentacao === "E") return "Entrada";
                return "Saída";
            },
            style: { minWidth: "120px" },
        },
        {
            header: "Situação",
            field: "ativo",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: ({ ativo }) => {
                if (ativo) return <Tag severity="success" value="ATIVO" />;
                return <Tag severity="danger" value="INATIVO" />;
            },
            style: { width: "10%" },
        },
        {
            frozen: true,
            alignFrozen: "rigth",
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "100px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                style={{ width: "10px" }}
                ref={listagemRef}
                titulo="CFOP's"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    tipo: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
        </PageBase>
    );
};
