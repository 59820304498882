import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/financeiro/competencias/";

export const FinanceiroCompetenciaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro da competencia"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/financeiro/cadastros/competencia/form",
                            state: { competencia: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_EXCLUIR]}
                    componente={BotaoDelete}
                    objetoId={rowData.id}
                    url={url}
                    msgConfirmacao={
                        <span>
                            {`Deseja realmente excluir a competência `}
                            <b>{rowData.codigo}</b>?
                        </span>
                    }
                    tooltip="Deletar competência"
                    exigeConfirmacao
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/financeiro/cadastros/competencia/form")}
            />
        </>
    );

    const colunas = [
        { field: "codigo", header: "Código", filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "grupo_competencia",
            header: "Grupo de competência",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "data_inicio",
            header: "Data inicial",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_fim",
            header: "Data final",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        { field: "ativa", style: { width: "0" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "20%", textAlign: "center" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Competências"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: { value: null, matchMode: "equals" },
                    grupo_competencia: { value: null, matchMode: "equals" },
                    data_inicio: { value: null, matchMode: "equals" },
                    data_fim: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
