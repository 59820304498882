import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_APURACAO_COMISSAO_CHOICE, TIPO_STATUS_APURACAO_CHOICE } from "@/assets/constants/constants";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { CalculoComissaoForm } from "./modals/calculoComissao";
import useAuth from "@/hooks/useAuth";
import { Dropdown } from "@/components/Dropdown";
import { dataToStr } from "@/assets/util/datas";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/vendas/apuracao-comissao-venda/";
const urlvoltar = "/vendas/comissao/apuracao";

export const ApuracaoComissaoForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showError } = useToast();
    const calculaRef = useRef(null);
    const history = useHistory();
    const { user } = useAuth();

    const apuracaoVazia = {
        id: null,
        regra_apuracao: null,
        tipo_apuracao: null,
        status: "A",
        data_final: null,
        data_inicial: null,
        hierarquia: null,
    };

    const formik = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.apuracao,
                  regra_apuracao: props.location.state.apuracao.regra_apuracao?.id,
                  tipo_apuracao: props.location.state.apuracao.tipo_apuracao?.codigo,
                  status: props.location.state.apuracao.status?.codigo,
              }
            : apuracaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                regra_apuracao: Yup.number()
                    .required("O campo 'regra de apuração' é obrigatório.")
                    .typeError("Informe uma regra válida."),
                tipo_apuracao: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Informe um tipo válido."),
                data_inicial: Yup.date()
                    .required("O campo 'data inicial' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_final: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .typeError("Informe uma data válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Apuração de comissão cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url,
                        body: {
                            ...values,
                            data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                            data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
                            usuario_criacao: user.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Ocupação alterada com sucesso!",
                            life: 1500,
                        });

                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                            data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova Apuração de Comissão" : "Manutenção de Apuração de Comissão"}</h3>
            <form onSubmit={formik.handleSubmit}>
                {formik.values?.id ? (
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_CALCULAR]}
                        componente={Button}
                        icon="pi pi-percentage"
                        className="p-button-help p-mr-2 p-mb-2"
                        onClick={() => calculaRef.current?.setVisible(true)}
                        disabled={formik.values.status === "P"}
                        label="Cálculo"
                        type="button"
                    />
                ) : null}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="regra_apuracao">Regra *</label>
                        <Dropdown
                            id="regra_apuracao"
                            name="regra_apuracao"
                            placeholder="Selecione uma natureza"
                            url="/vendas/regra-apuracao-comissao-venda?limit=1000"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.regra_apuracao}
                            disabled={formik.values.status === "P"}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.regra_apuracao })}
                        />
                        {formik.errors.regra_apuracao && (
                            <small className="p-error">{formik.errors.regra_apuracao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tipo_apuracao">Tipo *</label>
                        <Dropdown
                            id="tipo_apuracao"
                            name="tipo_apuracao"
                            placeholder="Selecione um tipo"
                            options={TIPO_APURACAO_COMISSAO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_apuracao}
                            disabled={formik.values.status === "P"}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_apuracao })}
                        />
                        {formik.errors.tipo_apuracao && (
                            <small className="p-error">{formik.errors.tipo_apuracao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_inicial">Data Inicial *</label>
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            value={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            disabled={formik.values.status === "P"}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicial,
                            })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_final">Data Final *</label>
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            value={formik.values.data_final}
                            onChange={formik.handleChange}
                            disabled={formik.values.status === "P"}
                            className={classNames({
                                "p-invalid": formik.errors.data_final,
                            })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status *</label>
                        <Dropdown
                            id="status"
                            name="status"
                            options={TIPO_STATUS_APURACAO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            disabled
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="hierarquia">Hierarquia funcional</label>
                        <Dropdown
                            id="hierarquia"
                            name="hierarquia"
                            placeholder="Selecione uma hierarquia"
                            url="/pessoas/hierarquia-funcional?limit=3000"
                            filter
                            filterBy="cargo.nome"
                            optionValue="id"
                            optionLabel="cargo.nome"
                            value={formik.values.hierarquia}
                            disabled={formik.values.status === "P"}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.hierarquia })}
                        />
                        {formik.errors.hierarquia && <small className="p-error">{formik.errors.hierarquia}</small>}
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    {formik.values.status !== "P" && (
                        <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    )}
                    <Button
                        label={formik.values.status !== "P" ? "Cancelar" : "Fechar"}
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
            <CalculoComissaoForm apuracao={formik.values} ref={calculaRef} />
        </PageBase>
    );
};
