import React, { useCallback } from "react";

import { startOfDay, isEqual, getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoInputEntregador } from "@/components/MakoInputs/MakoInputEntregador";
import { Calendar } from "@/components/Calendar";
import { MakoTime } from "@/components/MakoTime";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

import useToast from "@/hooks/useToast";

const AGORA = new Date();

const formatarDataAgora = (value) => {
    let newDate = value;
    newDate = setHours(newDate, getHours(AGORA));
    newDate = setMinutes(newDate, getMinutes(AGORA));
    return newDate;
};

export const Form = ({ onSuccessCallback = () => {}, previsaoRecente }) => {
    const { showWarning } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            datahora_entrega: formatarDataAgora(previsaoRecente),
            nome_recebedor: "",
            rota_entrega: null,
            entregador_id: null,
            replicar: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                datahora_entrega: Yup.date()
                    .required("O campo 'data/hora entrega' é obrigatório")
                    .typeError("Informe uma 'data/hora entrega' válida"),
                nome_recebedor: Yup.string()
                    .required("O campo 'nome recenedor' é obrigatório")
                    .typeError("Informe um 'recebedor' válido"),
                entregador_id: Yup.object()
                    .required("O campo 'entregador' é obrigatório.")
                    .typeError("Informe um 'entregador' válido."),
                rota_entrega: Yup.object().nullable().default(null).typeError("Informe um 'rota' válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            onSuccessCallback({ ...values });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleChangeDataEntrega = useCallback(
        (e) => {
            console.log(e.value);
            if (!isEqual(startOfDay(e.value), startOfDay(previsaoRecente))) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Data de entrega não confere com a data da previsão de entrega",
                    life: 3000,
                });
            }
            formik.handleChange(e);
        },
        [formik, previsaoRecente, showWarning]
    );

    return (
        <form onSubmit={formik.handleSubmit} className="p-mb-2">
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="entregador_id" label="Entregador" obrigatorio />
                    <MakoInputEntregador
                        id="entregador_id"
                        name="entregador_id"
                        value={formik.values.entregador_id}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entregador_id })}
                    />
                    {formik.errors.entregador_id && <small className="p-error">{formik.errors.entregador_id}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="nome_recebedor" label="Recebido / Retirado por" obrigatorio />
                    <InputText
                        id="nome_recebedor"
                        name="nome_recebedor"
                        placeholder="Informe um nome..."
                        onChange={formik.handleChange}
                        value={formik.values.nome_recebedor}
                        autoComplete="off"
                        maxLength={100}
                        className={classNames({ "p-invalid": formik.errors.nome_recebedor })}
                    />
                    {formik.errors.nome_recebedor && <small className="p-error">{formik.errors.nome_recebedor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrega" label="Data entrega" obrigatorio />
                    <Calendar
                        id="datahora_entrega"
                        name="datahora_entrega"
                        value={formik.values.datahora_entrega}
                        onChange={handleChangeDataEntrega}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrega })}
                    />
                    {formik.errors.datahora_entrega && (
                        <small className="p-error">{formik.errors.datahora_entrega}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrega" label="Hora entrega" />
                    <MakoTime
                        id="datahora_entrega"
                        name="datahora_entrega"
                        valueTime={formik.values.datahora_entrega}
                        onChange={handleChangeDataEntrega}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrega })}
                    />
                    {formik.errors.datahora_entrega && (
                        <small className="p-error">{formik.errors.datahora_entrega}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="rota_entrega" label="Rota" />
                    <Dropdown
                        id="rota_entrega"
                        name="rota_entrega"
                        url="/vendas/rotas-entrega/"
                        optionLabel="descricao"
                        value={formik.values.rota_entrega}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.rota_entrega && <small className="p-error">{formik.errors.rota_entrega}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2 p-py-0 p-my-0">
                    <Label htmlFor="replicar" label="Definir para todos" />
                    <SelectButton
                        id="replicar"
                        name="replicar"
                        value={formik.values.replicar}
                        onChange={formik.handleChange}
                        options={SIM_NAO_BOOLEAN}
                        optionLabel="label"
                        optionValue="id"
                    />
                </div>
                <div className="p-field p-col-12 p-md-2"></div>
                <div className="p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "4px" }}>
                    <Button
                        type="submit"
                        label="Confirmar"
                        className="p-mr-2 p-mb-2 p-button-info"
                        disabled={!formik.values?.datahora_entrega}
                    />
                </div>
            </div>
            {!formik.values.replicar && (
                <p className="p-text-bold p-my-0">Apenas os itens expandidos serão atualizados</p>
            )}
        </form>
    );
};
