/* eslint-disable no-template-curly-in-string */
import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { parseData } from "@/assets/util/datas";
import useEntrada from "@/hooks/useEntrada";

const Modal = ({ parcelas, onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const { dadosBasicos } = useEntrada();

    const formik = useFormik({
        initialValues: {
            numero_parcela: "",
            emissao: null,
            vencimento: null,
            valor_parcela: 0,
            forma_pagamento: null,
            historico_padrao: null,
            template_rateio: null,
            numero_boleto: "",
            numero_cheque: "",
            conta_bancaria: null,
            chave_pix: null,
            alterar_todas: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const vencParcelaAnterior =
                values.rowIndex === 0 ? values.emissao : parcelas[values.rowIndex - 1].vencimento;
            const parsedVencParcelaAnterior =
                vencParcelaAnterior instanceof Date ? vencParcelaAnterior : parseData(vencParcelaAnterior);
            const minVencimento = values.rowIndex === 0 ? values.emissao : parsedVencParcelaAnterior;
            const formSchema = Yup.object().shape({
                emissao: Yup.date()
                    .required("O campo 'emissão' é obrigatório.")
                    .max(values.vencimento, "A emissão não pode ser posterior ao vencimento.")
                    .typeError("Informe uma data válida."),
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .min(
                        minVencimento,
                        "O vencimento não pode ser inferior a emissão ou ao vencimento da parcela anterior."
                    )
                    .typeError("Informe uma data válida."),
                valor_parcela: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "O campo 'valor' não pode ser ZERO."),
                forma_pagamento: Yup.object()
                    .required("Escolha uma forma de pagamento.")
                    .typeError("Escolha uma forma de pagamento."),
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                template_rateio: Yup.object()
                    .required("O campo 'template de rateio' é obrigatório.")
                    .typeError("Selecione uma regra de rateio."),
                chave_pix: Yup.object()
                    .when("forma_pagamento", {
                        is: (val) => val === 3,
                        then: Yup.object().notRequired().typeError("Escolha uma chave PIX."),
                    })
                    .nullable(),
                numero_cheque: Yup.string()
                    .when("forma_pagamento", {
                        is: (val) => val === 4,
                        then: Yup.string()
                            .nullable()
                            .test(
                                "validation-cheque",
                                "Esse campo precisa ter 31 caracteres.",
                                (value) => !value || value.length === 31
                            ),
                    })
                    .nullable(),
                numero_boleto: Yup.string()
                    .when("forma_pagamento", {
                        is: (val) => val === 5,
                        then: Yup.string()
                            .nullable()
                            .test(
                                "validation-boleto",
                                "Esse campo precisa ter 48 caracteres.",
                                (value) => !value || value.length === 48
                            ),
                    })
                    .nullable(),
                conta_bancaria: Yup.object()
                    .when("forma_pagamento", {
                        is: (val) => val === 7,
                        then: Yup.object().notRequired().typeError("Escolha uma conta bancária."),
                    })
                    .nullable(),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (typeof onSuccess === "function") onSuccess({ ...values, _editada: true });
            fecharModal();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (parcela) => {
        formik.setValues({
            ...parcela,
            template_rateio: parcela.template_rateio
                ? {
                      id: parcela.template_rateio.id,
                      descricao: parcela.template_rateio.descricao,
                  }
                : null,
        });
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return <Button type="submit" label="Salvar" icon="pi pi-save" onClick={formik.handleSubmit} />;
    };

    const chavePixItemTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.descricao} - ${option.chave}`}</div>
            </div>
        );
    };

    const contaBancariaItemTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`BCO: ${option.banco.descricao} - AG: ${option.agencia} - CC: ${option.numero_conta}-${option.numero_conta_dv}`}</div>
            </div>
        );
    };

    const contaBancariaSelecionadaTemplate = (option, props) => {
        if (option) return contaBancariaItemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dialog
            header="Editar parcela da entrada de produtos"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "50vw" }}
            footer={renderFooter}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="num-parcela">Nº parcela</label>
                        <InputText
                            id="num-parcela"
                            name="numero_parcela"
                            disabled
                            value={formik.values.numero_parcela}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="emissao">Emissão *</label>
                        <MakoCalendar
                            id="emissao"
                            name="emissao"
                            disabled={formik.values.numero_parcela > 1}
                            value={formik.values.emissao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.emissao })}
                        />
                        {formik.errors.emissao && <small className="p-error">{formik.errors.emissao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vencimento">Vencimento *</label>
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            autoFocus
                            value={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-parcela">Valor parcela *</label>
                        <MakoInputMoeda
                            id="valor-parcela"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_parcela })}
                        />
                        {formik.errors.valor_parcela && (
                            <small className="p-error">{formik.errors.valor_parcela}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="historico-padrao">Histórico *</label>
                        <MakoAutoComplete
                            id="historico"
                            name="historico_padrao"
                            urlSearch="/financeiro/historico-padrao/?descricao__unaccent__icontains="
                            field="descricao"
                            value={formik.values.historico_padrao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="template-rateio">Regra de rateio *</label>
                        <Dropdown
                            id="template-rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${dadosBasicos?.empresa}`}
                            optionLabel="descricao"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="forma-pagamento">Forma de pagamento *</label>
                        <Dropdown
                            id="forma-pagamento"
                            name="forma_pagamento"
                            url="/financeiro/formas-recebimentos/?escopo__in=P,X"
                            optionLabel="descricao"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    {formik.values.forma_pagamento?.tipo.id === 3 && (
                        <div className="p-field p-col-12">
                            <label htmlFor="chave-pix">Chave PIX</label>
                            <Dropdown
                                id="chave-pix"
                                name="chave_pix"
                                url={`/financeiro/chaves-pix/?perfil=${dadosBasicos.fornecedor?.id}`}
                                optionLabel="chave"
                                itemTemplate={chavePixItemTemplate}
                                showClear
                                value={formik.values.chave_pix}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.chave_pix })}
                            />
                            {formik.errors.chave_pix && <small className="p-error">{formik.errors.chave_pix}</small>}
                        </div>
                    )}
                    {formik.values.forma_pagamento?.tipo.id === 4 && (
                        <div className="p-field p-col-12">
                            <label htmlFor="num-cheque">Número do cheque</label>
                            <InputText
                                id="num-cheque"
                                name="numero_cheque"
                                value={formik.values.numero_cheque}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.numero_cheque })}
                            />
                            {formik.errors.numero_cheque && (
                                <small className="p-error">{formik.errors.numero_cheque}</small>
                            )}
                        </div>
                    )}
                    {formik.values.forma_pagamento?.tipo.id === 5 && (
                        <div className="p-field p-col-12">
                            <label htmlFor="num-boleto">Código de barras do boleto</label>
                            <InputText
                                id="num-boleto"
                                name="numero_boleto"
                                value={formik.values.numero_boleto}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.numero_boleto })}
                            />
                            {formik.errors.numero_boleto && (
                                <small className="p-error">{formik.errors.numero_boleto}</small>
                            )}
                        </div>
                    )}
                    {formik.values.forma_pagamento?.tipo.id === 7 && (
                        <div className="p-field p-col-12">
                            <label htmlFor="conta-bancaria">Conta bancária</label>
                            <Dropdown
                                id="conta-bancaria"
                                name="conta_bancaria"
                                url={`/financeiro/contas-bancarias/?perfil=${dadosBasicos.fornecedor?.id}&query={id,banco,agencia,numero_conta,numero_conta_dv}`}
                                optionLabel="numero_conta"
                                valueTemplate={contaBancariaSelecionadaTemplate}
                                itemTemplate={contaBancariaItemTemplate}
                                showClear
                                value={formik.values.conta_bancaria}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.conta_bancaria })}
                            />
                            {formik.errors.conta_bancaria && (
                                <small className="p-error">{formik.errors.conta_bancaria}</small>
                            )}
                        </div>
                    )}
                    {formik.values.rowIndex === 0 && (
                        <div className="p-field-checkbox p-col-12">
                            <Checkbox
                                id="alterar-todas"
                                name="alterar_todas"
                                checked={formik.values.alterar_todas}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="alterar-todas">Alterar em todas as parcelas?</label>
                        </div>
                    )}
                </div>
                <CamposObrigatorios />
            </form>
        </Dialog>
    );
};

export const ModalEditarParcela = forwardRef(Modal);
