import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";

import { TIPO_SIM_NAO_TODOS_BOOL_CHOICE } from "@/assets/constants/constants";
import { InputTextarea } from "primereact/inputtextarea";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";
import { MakoInputCliente } from "@/components/MakoInputs";

export const FiltroAvancadoNecessidadeCompra = ({ onConfirm, onCancel, url }) => {
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            cliente: null,
            descritivo__contains: "",
            possui_custo: -1,
            sku__isnull: -1,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                solicitante: Yup.string().nullable().typeError("Informe um 'solicitante' válido"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório"),
                    })
                    .notRequired()
                    .nullable()
                    .typeError("Informe um 'cliente' válida"),
                descritivo__contains: Yup.string().nullable().typeError("Informe uma 'descrição' válida"),
                ativo: Yup.number().nullable().typeError("Informe um 'status' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            let params = [];
            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (!["possui_custo", "sku__isnull", "cliente"].includes(key)) {
                        return params.push(`${key}=${values[key]}`);
                    }
                    if (key === "cliente") return params.push(`${key}=${values[key].id}`);
                    if (["possui_custo", "sku__isnull"].includes(key) && values[key] !== -1) {
                        return params.push(`${key}=${values[key]}`);
                    }
                }
            });
            if (params.length > 0) {
                const _url = baseUrl + "?" + params.join("&");
                if (typeof onConfirm === "function") onConfirm(_url);
            } else {
                if (typeof onConfirm === "function") onConfirm(baseUrl);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="cliente" label="Cliente" />
                    <MakoInputCliente
                        id="cliente"
                        name="cliente"
                        value={formik.values.cliente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cliente })}
                    />
                    {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="descritivo__contains" label="Descrição" />
                    <InputTextarea
                        id="descritivo__contains"
                        name="descritivo__contains"
                        value={formik.values.descritivo__contains}
                        onChange={formik.handleChange}
                        maxLength={255}
                        autoResize
                        rows={1}
                        className={classNames({ "p-invalid": formik.errors.descritivo__contains })}
                    />
                    {formik.errors.descritivo__contains && (
                        <small className="p-error">{formik.errors.descritivo__contains}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="possui_custo" label="Possui custo" />
                    <Dropdown
                        id="possui_custo"
                        name="possui_custo"
                        options={TIPO_SIM_NAO_TODOS_BOOL_CHOICE}
                        optionLabel="label"
                        optionValue="value"
                        value={formik.values.possui_custo}
                        onChange={formik.handleChange}
                        showClear={false}
                        className={classNames({ "p-invalid": formik.errors.possui_custo })}
                    />
                    {formik.errors.possui_custo && <small className="p-error">{formik.errors.possui_custo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="sku__isnull" label="Possui vinculo SKU" />
                    <Dropdown
                        id="sku__isnull"
                        name="sku__isnull"
                        options={TIPO_SIM_NAO_TODOS_BOOL_CHOICE}
                        optionLabel="label"
                        optionValue="value"
                        value={formik.values.sku__isnull}
                        onChange={formik.handleChange}
                        showClear={false}
                        className={classNames({ "p-invalid": formik.errors.sku__isnull })}
                    />
                    {formik.errors.sku__isnull && <small className="p-error">{formik.errors.sku__isnull}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid"></div>
            <MakoActionsButtons className="p-jc-end">
                <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                    label="Limpar"
                    onClick={formik.resetForm}
                    className="p-button-warning"
                />
                <Button
                    type="reset"
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    onClick={onCancel}
                    className="p-button-danger"
                />
            </MakoActionsButtons>
        </form>
    );
};
