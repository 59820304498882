import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

export const FormMovimentado = () => {
    const { setValues, ...formik } = useFormik({
        initialValues: {
            sdk: "",
            quantidade: 1,
            custo_unitario: 0,
            custo_total: 0,
            data_movimentacao: null,
            entregue_por: "",
            observacao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {}

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="sdk">Sdk</label>
                        <InputText
                            id="sdk"
                            name="sdk"
                            autoFocus
                            value={formik.values.sdk}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.sdk })}
                            disabled
                        />
                        {formik.errors.sdk && <small className="p-error">{formik.errors.sdk}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <InputText
                            id="quantidade"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                            disabled
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo_unitario">Custo unitário *</label>
                        <MakoInputMoeda
                            id="custo_unitario"
                            name="custo_unitario"
                            valueMoeda={formik.values.custo_unitario}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.custo_unitario })}
                        />
                        {formik.errors.custo_unitario && (
                            <small className="p-error">{formik.errors.custo_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo_total">Custo total*</label>
                        <MakoInputMoeda
                            id="custo_total"
                            name="custo_total"
                            valueMoeda={formik.values.custo_total}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.custo_total })}
                        />
                        {formik.errors.custo_total && <small className="p-error">{formik.errors.custo_total}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_movimentacao">Data movimentação*</label>
                        <MakoCalendar
                            id="data_movimentacao"
                            name="data_movimentacao"
                            value={formik.values.data_movimentacao}
                            onChangeMoeda={formik.handleChange}
                        />
                        {formik.errors.data_movimentacao && (
                            <small className="p-error">{formik.errors.data_movimentacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="entregue_por">Entregue por *</label>
                        <InputText
                            id="entregue_por"
                            name="entregue_por"
                            value={formik.values.entregue_por}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.entregue_por })}
                            disabled
                        />
                        {formik.errors.entregue_por && <small className="p-error">{formik.errors.entregue_por}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea
                            id="observacao"
                            name="observacao"
                            value={formik.values.observacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.observacao })}
                            autoResize
                            rows={3}
                            maxLength={255}
                        />
                        {formik.errors.observacao && <small className="p-error">{formik.errors.observacao}</small>}
                    </div>
                </div>
            </form>
        </>
    );
};
