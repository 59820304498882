import React, { useCallback, useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_SALDOPRODUTOS } from "@/assets/constants/relatorios";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";

const { ExportCSV } = R.Buttons;

const OPTIONS_PRECO = SIM_NAO_BOOLEAN;
const OPTIONS_ATIVO = SIM_NAO_BOOLEAN.concat({ id: -1, label: "Qualquer" });
const OPTIONS_VALOR_SALDO = [
    { value: "Q", label: "Qualquer" },
    { value: "P", label: "Positivo" },
    { value: "Z", label: "Zerado" },
    { value: "N", label: "Negativo" },
];

const FILTROS_VIEWSET = {
    marca: "item__marca",
    saldo: "saldosku__data",
    empresa: "item__empresa",
    formula: "saldosku__centro_estocagem__saldoestoqueformulacentroestocagem__formula",
    categoria: "categoriasku__categoria__in",
    fornecedor: "vinculoprodutofornecedor__fornecedor",
    comissao: "item__comissao_venda",
    descricao_derivada: "descricao_derivada",
    destinacao: "saldosku__centro_estocagem__tipo_sku",
    item__ncm: "item__ncm",
    exibir_custo: "exibir_custo",
    ativo: "ativo",
    valor_saldo: "valor_saldo",
};

const BASE_URL = "/relatorios/saldo-produtos/";

const FILE_NAME = gerarFileName("Saldo Produtos");

export const RelatorioSaldoProdutos = () => {
    const [empresa, setEmpresa] = useState(null);

    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const [showCusto, setShowCusto] = useState(false);

    const { solicitarRelatorio } = useRelatorio();
    const { empresaSelecionadaId } = useEmpresa();

    const empresaSelecionada = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, categoriaRef, empresaSelecionada, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.comissao) dadosValidados.comissao = dadosValidados.comissao.id;
        if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
        if (dadosValidados.item__ncm) dadosValidados.item__ncm = dadosValidados.item__ncm.id;
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
        if (dadosValidados.saldo) dadosValidados.saldo = dataToStr(dadosValidados.saldo, "yyyy-MM-dd");
        if (dadosValidados.ativo === -1) delete dadosValidados.ativo;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "formula",
            label: "Formula",
            path: "descricao",
        },
        {
            key: "saldo",
            label: "Saldo em",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "categoria",
            label: "Grupo",
            path: "nome",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "comissao",
            label: "Grupo comissão",
            path: "descricao",
        },
        {
            key: "descricao_derivada",
            label: "Descrição",
        },
        {
            key: "destinacao",
            label: "Destinação",
            optionKey: "value",
            optionLabel: "label",
            options: TIPO_SKU_ESTOCADO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "valor_saldo",
            label: "Saldo",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_VALOR_SALDO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "item__ncm",
            label: "Ncm",
            path: "codigo",
        },
        {
            key: "exibir_custo",
            label: "Com custo",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "ativo",
            label: "Ativo",
            optionKey: "id",
            optionLabel: "label",
            options: OPTIONS_ATIVO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .integer()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Selecione uma 'empresa' válida"),
                marca: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'marca' válida"),
                formula: Yup.number()
                    .required("O campo 'formula' é obrigatório")
                    .typeError("Informe uma 'formula' válida"),
                categoria: Yup.array().nullable().typeError("Informe 'categorias' válidas"),
                fornecedor: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida"),
                comissao: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'grupo comissão' válido"),
                destinacao: Yup.string().nullable().default(null).typeError("Informe uma 'destinação' válido"),
                saldo: Yup.date()
                    .required("O campo 'saldo em' é obrigatório")
                    .typeError("Seleciona um 'saldo em' válido"),
                item__ncm: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'ncm' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.comissao) dadosValidados.comissao = dadosValidados.comissao.id;
            if (dadosValidados.item__ncm) dadosValidados.item__ncm = dadosValidados.item__ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.saldo) dadosValidados.saldo = dataToStr(dadosValidados.saldo, "yyyy-MM-dd");
            if (dadosValidados.ativo === -1) delete dadosValidados.ativo;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);

                solicitarRelatorio({
                    chave: RELATORIO_ESTOQUE_SALDOPRODUTOS,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = showCusto
        ? [
              { field: "codigo", header: "Código", style: { width: "8%" } },
              { field: "descricao", header: "Descrição" },
              { field: "unidade_medida", header: "UN", style: { width: "5%" }, align: "center" },
              { field: "destinacao", header: "Destinação" },
              { field: "grupo", header: "Grupo" },
              { field: "saldo", header: "Saldo", style: { width: "5%" }, align: "center" },
              { field: "custo", header: "Custo", money: true },
          ]
        : [
              { field: "codigo", header: "Código", style: { width: "8%" } },
              { field: "descricao", header: "Descrição" },
              { field: "unidade_medida", header: "UN", style: { width: "5%" }, align: "center" },
              { field: "destinacao", header: "Destinação" },
              { field: "grupo", header: "Grupo" },
              { field: "saldo", header: "Saldo", style: { width: "5%" }, align: "center" },
          ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_ESTOQUE_SALDOPRODUTOS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const watchValues = useCallback((data = {}) => {
        const { empresa = null, exibir_custo = false } = data;
        setEmpresa(empresa);
        setShowCusto(exibir_custo);
    }, []);

    return (
        <R.Wrapper titulo={"de extrato de saldo de produtos"}>
            <MakoFormGerador
                ref={formRef}
                watchValues={watchValues}
                formikProps={{
                    initialValues: {
                        marca: null,
                        empresa: empresaSelecionadaId,
                        categoria: null,
                        fornecedor: null,
                        comissao: null,
                        descricao_derivada: "",
                        destinacao: null,
                        item__ncm: null,
                        exibir_custo: false,
                        ativo: -1,
                        valor_saldo: "Q",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        required: true,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Saldo em",
                        inputId: "saldo",
                        inputName: "saldo",
                        component: MakoCalendar,
                        required: true,
                        fieldSize: 2,
                    },
                    {
                        label: "Formula saldo",
                        inputId: "formula",
                        inputName: "formula",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione uma formula...",
                            url: `/produtos/saldo-estoque-formula/?limit=1000&ativa=true&empresa=${empresa}`,
                            buscar: !!empresa,
                            optionLabel: "descricao",
                            filterBy: "descricao",
                            optionValue: "id",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Marca",
                        inputId: "marca",
                        inputName: "marca",
                        component: MakoAutoComplete,
                        componentProps: {
                            placeholder: "Digite para pesquisar... (min 4 caracteres)",
                            urlSearch: "/produtos/marcas/?search=",
                            key: "id",
                            field: "nome",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Destinação",
                        inputId: "destinacao",
                        inputName: "destinacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            options: TIPO_SKU_ESTOCADO,
                            optionLabel: "label",
                            optionValue: "value",
                            filter: true,
                            filterBy: "value",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Grupo",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                            selectionMode: "checkbox",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Grupo comissão",
                        inputId: "comissao",
                        inputName: "comissao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            url: "/vendas/comissoes-vendas/",
                            optionLabel: "descricao",
                            filter: true,
                            filterBy: "descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 4,
                    },
                    {
                        label: "Com custo",
                        inputId: "exibir_custo",
                        inputName: "exibir_custo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_PRECO,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "NCM",
                        inputId: "item__ncm",
                        inputName: "item__ncm",
                        component: MakoInputNcm,
                        componentEspecial: COMPONENTES_ESPECIAIS.NCM,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 10,
                    },
                    {
                        label: "Ativo",
                        inputId: "ativo",
                        inputName: "ativo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_ATIVO,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Descrição contém",
                        inputId: "descricao_derivada",
                        inputName: "descricao_derivada",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            maxLength: 100,
                        },
                        fieldSize: 10,
                    },
                    {
                        label: "Saldo",
                        inputId: "valor_saldo",
                        inputName: "valor_saldo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_VALOR_SALDO,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_ESTOQUE_SALDOPRODUTOS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
