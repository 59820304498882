import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { BlockUI } from "@/components/BlockUI";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputCFOP } from "@/components/MakoInputCFOP";
import { ModalRegraOperacaoFiscalNCM } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe/regras/ncm";
import { ModalRegraOperacaoFiscalSKU } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe/regras/sku";
import { parseNumber } from "@/assets/helpers/number";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const TabComplementoItem = ({ itemNotaFiscalId, skuItemEntrada, itemEntradaId, readOnly }) => {
    const operacaoFiscalRef = useRef(null);
    const modalRegraNcmRef = useRef(null);
    const modalRegraSkuRef = useRef(null);
    const { dadosBasicos } = useEntrada();
    const { showError } = useToast();
    const { httpGet, httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    useClearRefs(operacaoFiscalRef, modalRegraNcmRef, modalRegraSkuRef);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            operacao_fiscal: null,
            cfop: null,
            quantidade: 0,
            valor_unitario: 0,
            valor_ipi_apropriado: 0,
            valor_icms_apropriado: 0,
            valor_pis_apropriado: 0,
            valor_cofins_apropriado: 0,
            valor_st_custo: 0,
            valor_st_complementar: 0,
            valor_dif_aliq_pago: 0,
            valor_rateio_frete: 0,
            valor_custo_unitario: 0,
            valor_custo_total: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao_fiscal: Yup.number()
                    .typeError("Selecione uma operação fiscal.")
                    .required("O campo 'operação fiscal' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const body = {
                ...values,
                cfop: values.cfop.id,
            };
            const handlers = {
                error: () => {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível atualizar o complemento do item de entrada.",
                        life: 3000,
                    });
                },
            };
            showLoading();
            await httpPut({ url: `/compras/notafiscal-entradas-itens/${values.id}/`, body }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const getNotaFiscalEntradaItem = useCallback(async () => {
        if (!dadosBasicos?.id || !itemNotaFiscalId) return;
        const params = {
            nota_fiscal_entrada__entrada: dadosBasicos?.id,
            item_nota_fiscal: itemNotaFiscalId,
        };
        const handlers = {
            200: ({ data }) => {
                const [item] = data.results;
                const { operacao_fiscal, ...rest } = item;
                operacaoFiscalRef.current = operacao_fiscal;
                setValues({
                    ...rest,
                    operacao_fiscal: operacao_fiscal.id,
                });
            },
        };
        showLoading();
        await httpGet({ url: "/compras/notafiscal-entradas-itens/", params }, handlers);
        hideLoading();
    }, [dadosBasicos, itemNotaFiscalId, setValues, showLoading, hideLoading, httpGet]);

    useEffect(() => {
        getNotaFiscalEntradaItem();
    }, [getNotaFiscalEntradaItem]);

    // async function gerarRegraOperacaoFiscal(escopo) {
    //     const body = {
    //         escopo,
    //         item_entrada: itemEntradaId,
    //     };
    //     const { status, data } = await axiosPost(`/compras/gerar-regra-operacao-fiscal/`, body);
    //     if (status === 200 && escopo === "sku") {
    //         modalRegraSkuRef.current.abrirModal(skuItemEntrada, data.regra.sku.br, data.regra.sku.uf);
    //     } else if (status === 200 && escopo === "ncm") {
    //         modalRegraNcmRef.current.abrirModal(data.regra.ncm);
    //     }
    // }

    // async function incluirCfopOperacaoFiscal() {
    //     const body = {
    //         operacao_fiscal: operacaoFiscalRef.current?.id,
    //         cfop: formik.values.cfop?.id,
    //     };
    //     setLoadingCfop(true);
    //     const { status } = await axiosPost("/fiscal/cfop-operacoes-fiscais/", body);
    //     setLoadingCfop(false);
    //     if (status === 201) {
    //         showSuccess({
    //             summary: "Sucesso!",
    //             detail: "CFOP incluido com sucesso na operação fiscal.",
    //             life: 1500,
    //         });
    //         setTimeout(() => {
    //             getNotaFiscalEntradaItem();
    //         }, 2000);
    //     } else {
    //         showError({
    //             summary: "Erro :(",
    //             detail: "Desculpe, não conseguimos incluir o CFOP na operação fiscal.",
    //             life: 3000,
    //         });
    //     }
    // }

    return (
        <>
            <ModalRegraOperacaoFiscalNCM
                ref={modalRegraNcmRef}
                operacaoFiscal={operacaoFiscalRef.current}
                onGravar={() => getNotaFiscalEntradaItem()}
            />
            <ModalRegraOperacaoFiscalSKU
                ref={modalRegraSkuRef}
                operacaoFiscal={operacaoFiscalRef.current}
                onGravar={() => getNotaFiscalEntradaItem()}
            />
            <BlockUI loading={loading}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="operacao-fiscal">Operação fiscal *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="operacao-fiscal"
                                    name="operacao_fiscal"
                                    url={`/fiscal/operacoes-fiscais/?empresa=${dadosBasicos.empresa}&tipo_movimento=E&ativo=true`}
                                    disabled={readOnly}
                                    optionValue="id"
                                    optionLabel="descricao"
                                    filter
                                    filterBy="descricao"
                                    showFilterClear
                                    autoFocus
                                    value={formik.values.operacao_fiscal}
                                    className={classNames({ "p-invalid": formik.errors.operacao_fiscal })}
                                />
                                {/* <Button
                                    icon="pi pi-book"
                                    label={`Incluir NCM ${skuItemEntrada?.item.ncm.codigo}`}
                                    type="button"
                                    tooltip="Incluir este NCM na operação fiscal"
                                    tooltipOptions={{ position: "top" }}
                                    disabled={operacaoFiscalRef.current?.ncmoperacaofiscal_set.some(
                                        (ncmOf) => ncmOf.ncm.id === skuItemEntrada?.item.ncm.id
                                    )}
                                    onClick={() => gerarRegraOperacaoFiscal("ncm")}
                                    className="p-button-secondary"
                                />
                                <Button
                                    icon="fas fa-boxes"
                                    label="Incluir produto"
                                    type="button"
                                    tooltip="Incluir este produdo na operação fiscal"
                                    tooltipOptions={{ position: "top" }}
                                    disabled={operacaoFiscalRef.current?.itemoperacaofiscal_set.some(
                                        (itemOf) => itemOf.sku.id === skuItemEntrada?.id
                                    )}
                                    onClick={() => gerarRegraOperacaoFiscal("sku")}
                                    className="p-button-help"
                                /> */}
                            </div>
                            {formik.errors.operacao_fiscal && (
                                <small className="p-error">{formik.errors.operacao_fiscal}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="cfop">CFOP *</label>
                            <div className="p-inputgroup">
                                <MakoInputCFOP
                                    id="cfop"
                                    name="cfop"
                                    disabled
                                    value={formik.values.cfop}
                                    className={classNames({ "p-invalid": formik.errors.cfop })}
                                />
                                {/* <Button
                                    icon="pi pi-plus"
                                    label="Incluir CFOP"
                                    type="button"
                                    loading={loadingCfop}
                                    disabled={operacaoFiscalRef.current?.cfopoperacaofiscal_set.some(
                                        (cfopOf) => cfopOf.cfop.id === formik.values.cfop?.id
                                    )}
                                    onClick={incluirCfopOperacaoFiscal}
                                    className="p-button-help"
                                /> */}
                            </div>
                            {formik.errors.cfop && <small className="p-error">{formik.errors.cfop}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="quantidade">Quantidade *</label>
                            <MakoInputQuantidadeSku
                                id="quantidade"
                                name="quantidade"
                                disabled
                                permiteFracionario
                                value={formik.values.quantidade}
                                className={classNames({ "p-invalid": formik.errors.quantidade })}
                            />
                            {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-unitario">Valor unitário *</label>
                            <MakoInputMoeda
                                id="valor-unitario"
                                name="valor_unitario"
                                disabled
                                valueMoeda={formik.values.valor_unitario}
                                className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                            />
                            {formik.errors.valor_unitario && (
                                <small className="p-error">{formik.errors.valor_unitario}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-ipi-apropriado">IPI apropriado</label>
                            <MakoInputMoeda
                                id="valor-ipi-apropriado"
                                name="valor_ipi_apropriado"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_ipi_apropriado}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-icms-apropriado">ICMS apropriado</label>
                            <MakoInputMoeda
                                id="valor-icms-apropriado"
                                name="valor_icms_apropriado"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_icms_apropriado}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-pis-apropriado">PIS apropriado</label>
                            <MakoInputMoeda
                                id="valor-pis-apropriado"
                                name="valor_pis_apropriado"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_pis_apropriado}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-cofins-apropriado">COFINS apropriado</label>
                            <MakoInputMoeda
                                id="valor-cofins-apropriado"
                                name="valor_cofins_apropriado"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_cofins_apropriado}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-st-custo">ST custo</label>
                            <MakoInputMoeda
                                id="valor-st-custo"
                                name="valor_st_custo"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_st_custo}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-st-complementar">ST complementar</label>
                            <MakoInputMoeda
                                id="valor-st-complementar"
                                name="valor_st_complementar"
                                disabled={readOnly}
                                valueMoeda={formik.values.valor_st_complementar}
                                onChangeMoeda={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-dif-aliq-pago">Dif. pago de alíquota</label>
                            <MakoInputMoeda
                                id="valor-dif-aliq-pago"
                                name="valor_dif_aliq_pago"
                                disabled
                                valueMoeda={formik.values.valor_dif_aliq_pago}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-rateio-frete">Valor frete rateado</label>
                            <MakoInputMoeda
                                id="valor-rateio-frete"
                                name="valor_rateio_frete"
                                disabled
                                valueMoeda={formik.values.valor_rateio_frete}
                            />
                        </div>
                        {parseNumber(formik.values.valor_custo_unitario) > 0 ? (
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="valor-custo-unitario">Valor unitário</label>
                                <MakoInputMoeda
                                    id="valor-custo-unitario"
                                    name="valor_custo_unitario"
                                    disabled
                                    valueMoeda={formik.values.valor_custo_unitario}
                                />
                            </div>
                        ) : null}
                        {parseNumber(formik.values.valor_custo_total) > 0 ? (
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="valor-custo-total">Subtotal</label>
                                <MakoInputMoeda
                                    id="valor-custo-total"
                                    name="valor_custo_total"
                                    disabled
                                    valueMoeda={formik.values.valor_custo_total}
                                />
                            </div>
                        ) : null}
                    </div>
                    <CamposObrigatorios />
                    <div className="p-grid p-col-12">
                        <Button
                            type="submit"
                            label="Gravar"
                            icon="pi pi-save"
                            disabled={readOnly}
                            className="p-button-info p-mr-2"
                        />
                    </div>
                </form>
            </BlockUI>
        </>
    );
};
