import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";

export const CarteiraClienteForm = (props) => {
    const [profissionais, setProfissionais] = useState([]);
    const [chavesPapelPerfil, setChavesPapelPerfil] = useState([]);
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            profissional: null,
            chave_papel_perfil: null,
            cliente: null,
            prioridade: 1,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                profissional: Yup.string()
                    .required("O campo 'profissional' é obrigatório.")
                    .typeError("Selecione um profissional."),
                chave_papel_perfil: Yup.string()
                    .required("O campo 'chave' é obrigatório.")
                    .typeError("Selecione uma chave de papel perfil."),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Você precisa pesquisar e escolher um cliente."),
                prioridade: Yup.number()
                    .required("O campo 'prioridade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const carteiraCliente = {
                ...values,
                cliente: values.cliente.id,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carteira de cliente cadastrada com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                };
                showLoading();
                await httpPost({ url: "/pessoas/carteiras-clientes/", body: carteiraCliente }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Carteira de cliente alterada com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                };
                showLoading();
                await httpPut(
                    { url: `/pessoas/carteiras-clientes/${carteiraCliente.id}/`, body: carteiraCliente },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltar = () => {
        history.push("/vendas/cadastros/carteira-clientes");
    };

    const aposBuscarProfissionais = useCallback((data) => {
        setProfissionais(data);
        return data;
    }, []);

    const listarChavesPapelPerfilPorProfissional = useCallback(() => {
        if (formik.values.profissional) {
            const profissional = profissionais.find((e) => e.id === formik.values.profissional);
            if (profissional) {
                const chaves = profissional.papeis_vigentes.map((e) => ({ value: e.chave.id, label: e.chave.nome }));
                setChavesPapelPerfil(chaves);
            }
        }
    }, [formik.values.profissional, profissionais]);

    const autoCompleteClienteTemplate = (item) => {
        return <div>{`${item.nome} - ${item.identificacao}${item.telefone ? ` - ${item.telefone}` : ""}`}</div>;
    };

    useEffect(() => {
        listarChavesPapelPerfilPorProfissional();
    }, [listarChavesPapelPerfilPorProfissional]);

    useEffect(() => {
        if (props.location.state) {
            const { profissional, ...values } = props.location.state;
            const chaves = profissional.papeis_vigentes.map((e) => ({ value: e.chave.id, label: e.chave.nome }));
            setChavesPapelPerfil(chaves);
            setValues({
                ...values,
                profissional: profissional.id,
            });
        }
    }, [props, setValues]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova carteira de cliente" : "Manutenção de carteira de cliente"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="profissional" label="Profissional" obrigatorio />
                        <Dropdown
                            id="profissional"
                            name="profissional"
                            url="/pessoas/perfis/?usuario__isnull=False&query={id,usuario,nome,identificacao,papeis_vigentes}&limit=100"
                            placeholder="Selecione"
                            filter
                            filterBy="nome,usuario.username"
                            aposBuscar={aposBuscarProfissionais}
                            optionValue="id"
                            optionLabel="nome"
                            emptyMessage="Nenhum registro disponível"
                            emptyFilterMessage="Nenhum registro encontrado"
                            autoFocus
                            value={formik.values.profissional}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.profissional })}
                        />
                        {formik.errors.profissional && <small className="p-error">{formik.errors.profissional}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="cliente" label="Cliente" obrigatorio />
                        <MakoAutoComplete
                            id="cliente"
                            name="cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteClienteTemplate}
                            field="nome"
                            urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                            placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="chave" label="Chave" obrigatorio />
                        <Dropdown
                            id="chave"
                            name="chave_papel_perfil"
                            options={chavesPapelPerfil}
                            disabled={!!!formik.values.profissional}
                            placeholder="Selecione"
                            emptyMessage="Nenhum registro disponível"
                            value={formik.values.chave_papel_perfil}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave_papel_perfil })}
                        />
                        {formik.errors.chave_papel_perfil && (
                            <small className="p-error">{formik.errors.chave_papel_perfil}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="prioridade" label="Prioridade" obrigatorio />
                        <InputNumber
                            inputId="prioridade"
                            name="prioridade"
                            mode="decimal"
                            useGrouping={false}
                            min={1}
                            value={formik.values.prioridade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.prioridade })}
                        />
                        {formik.errors.prioridade && <small className="p-error">{formik.errors.prioridade}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                    <Button
                        label="Cancelar"
                        type="button"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={voltar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
