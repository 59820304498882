import React, { createContext, useCallback, useState } from "react";
import { addDays } from "date-fns";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";
import { parseNumber } from "@/assets/helpers/number";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const PagamentoContext = createContext({});

export const PagamentoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [submitParcelas, setSubmitParcelas] = useState([]);
    const [pagamento, setPagamento] = useState(null);
    const [pagamentos, setPagamentos] = useState(null);
    const [previsoes, setPrevisoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const [hasParcelas, setHasParcelas] = useState(true);
    const { httpGet, httpPatch, httpPost } = useHttp();
    const { showError } = useToast();

    const handleSubmitPagamento = useCallback((dados) => {
        setPagamento(dados);
        setSubmit(true);
        setPagamentos([]);
    }, []);

    const handleLancarParcelas = (value) => {
        const { tipo_parcela, repeticao, quantidade, data_primeira_parcela, ...configParcela } = value;
        const qtdParcelas = parseNumber(quantidade);
        let valorParcelas = [];
        if (tipo_parcela === "dividir") {
            valorParcelas = gerarParcelas(pagamento.valor_total, qtdParcelas);
        } else {
            valorParcelas.push(pagamento.valor_total);
        }
        const _parcelas = [];
        const { historico_padrao, valor_total, ...pag } = pagamento;
        const agrupador = new Date().getTime();
        for (let numParcela = 0; numParcela < qtdParcelas; numParcela++) {
            _parcelas.push({
                ...pag,
                ...configParcela,
                agrupador,
                historico_padrao: historico_padrao.id,
                historico_original: historico_padrao.descricao,
                vencimento: addDays(data_primeira_parcela, parseNumber(repeticao) * numParcela),
                quantidade_parcelas: qtdParcelas,
                numero_parcela: numParcela + 1,
                valor_total: tipo_parcela === "dividir" ? valor_total : valor_total * qtdParcelas,
                valor: tipo_parcela === "dividir" ? valorParcelas[numParcela] : valorParcelas[0],
            });
        }
        setPagamentos(_parcelas);
        setSubmitParcelas(true);
    };

    const handleEditarPagamento = useCallback(
        async (values, index) => {
            const {
                vencimento,
                valor,
                historico_original,
                template_rateio,
                forma_pagamento,
                conta_financeira,
                plano_orcamentario,
                previsao,
            } = values;

            const _pagamentos = [...pagamentos];
            _pagamentos[index] = {
                ...pagamentos[index],
                vencimento,
                valor,
                historico_original,
                forma_pagamento: forma_pagamento?.id ? forma_pagamento.id : forma_pagamento,
                conta_financeira,
                plano_orcamentario,
                previsao,
                template_rateio: template_rateio?.id ? template_rateio.id : template_rateio,
                _editado: true,
            };

            const totalParcelas = _pagamentos.reduce((total, pag) => total + parseNumber(pag.valor), 0);
            setSubmitParcelas(parseNumber(totalParcelas) === parseNumber(_pagamentos[0].valor_total));
            setPagamento(values);
            setPagamentos(_pagamentos);
        },
        [pagamentos]
    );

    const handlePreencherPagamento = useCallback(
        async (idPagamento) => {
            const handlers = {
                200: ({ data }) => {
                    if (data.results?.length > 0) {
                        setPagamento({
                            ...data.results[0],
                            template_rateio: data.results[0].template_rateio?.id,
                            forma_pagamento: data.results[0].forma_pagamento?.id,
                        });
                        setPagamentos(data.results);
                        setSubmit(true);
                        setSubmitParcelas(true);
                    } else {
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível carregar os pagamentos.",
                            life: 3000,
                        });
                    }
                },
            };

            showLoading();
            await httpGet({ url: `/financeiro/pagamentos?documento=${idPagamento}` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, showError, httpGet]
    );

    const handlePagamentos = useCallback(async () => {
        let body = {};
        if (hasParcelas === true) {
            body = pagamentos.map(
                ({ fracionamentopagamento_set, pagamentoefetivado_set, previsaopagamento_set, ...pag }) => ({
                    ...pag,
                    credor: pag.credor.id,
                    data_lancamento: dataToStr(pag.data_lancamento, "yyyy-MM-dd"),
                    data_emissao: dataToStr(pag.data_emissao, "yyyy-MM-dd"),
                    vencimento: dataToStr(pag.vencimento, "yyyy-MM-dd"),
                    historico_padrao:
                        typeof pag.historico_padrao === "object" ? pag.historico_padrao.id : pag.historico_padrao,
                    valor: formatarCasasDecimais(pag.valor),
                })
            );
        } else {
            body = pagamentos[0];
        }
        if (!pagamento.id) {
            let status = 400,
                json = {};

            const handlers = {
                201: ({ data }) => {
                    status = 201;
                    json = data;
                },
            };

            await httpPost({ url: "/financeiro/pagamentos/", body }, handlers);

            return { status, data: json };
        } else {
            const alterados = body.filter((pag) => pag._editado);
            return await Promise.all(
                alterados.map(async (pag) => {
                    let status = 400,
                        json = {};

                    const handlers = {
                        200: ({ data }) => {
                            status = 200;
                            json = data;
                        },
                    };

                    await httpPatch({ url: `/financeiro/pagamentos/${pag.id}/`, body: pag }, handlers);

                    return { status, data: json };
                })
            );
        }
    }, [pagamento, pagamentos, hasParcelas, httpPatch, httpPost]);

    const handleHasParcelas = useCallback(() => {
        setHasParcelas((prevValue) => !prevValue);
    }, [setHasParcelas]);

    return (
        <PagamentoContext.Provider
            value={{
                submit,
                submitParcelas,
                pagamento,
                pagamentos,
                previsoes,
                hasParcelas,
                setPagamento,
                setPagamentos,
                setPrevisoes,
                setHasParcelas,
                setSubmit,
                handleSubmitPagamento,
                handleLancarParcelas,
                handleEditarPagamento,
                handlePagamentos,
                handlePreencherPagamento,
                handleHasParcelas,
            }}
        >
            {children}
        </PagamentoContext.Provider>
    );
};

export default PagamentoContext;
