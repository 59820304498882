import React, { useEffect, useMemo, useRef, useState } from "react";
import { addLocale } from "primereact/api";
import { InputMask } from "primereact/inputmask";
import { Calendar as CalendarPR } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";

import { dataToStr, parseData } from "@/assets/util/datas";

const parseLimiter = (date) => {
    if (!date) return null;
    if (date instanceof Date) return date;
    if (typeof date === "string" && date.length > 0) return parseData(date);
    return null;
};

export const Calendar = ({ onChange: onChangeCalendar, minDate = null, maxDate = null, ...props }) => {
    const [dataStr, setDataStr] = useState("");
    const panelRef = useRef(null);

    const min = useMemo(() => {
        return parseLimiter(minDate);
    }, [minDate]);

    const max = useMemo(() => {
        return parseLimiter(maxDate);
    }, [maxDate]);

    useEffect(() => {
        try {
            const parsedData = parseData(props.value);
            if (parsedData) setDataStr(dataToStr(parsedData, "dd/MM/yyyy"));
            else setDataStr("");
        } catch (error) {
            setDataStr("");
        }
    }, [props.value]);

    addLocale("br", {
        firstDayOfWeek: 0,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        today: "Hoje",
        clear: "Limpar",
    });

    const valueDateCalendar = (e) => {
        const { value } = e;
        const [dia, mes, ano] = value.split("/");
        const data = parseData(`${ano}-${mes}-${dia}`);
        const event = {
            ...e,
            value: data,
            target: {
                id: props?.id,
                name: props?.name,
                value: data,
            },
        };
        onChangeCalendar(event);
    };

    return (
        <>
            <div className="p-inputgroup">
                <InputMask
                    mask="99/99/9999"
                    placeholder="dd/mm/aaaa"
                    disabled={props.disabled}
                    onComplete={valueDateCalendar}
                    value={dataStr}
                    onChange={(e) => setDataStr(e.value)}
                />
                <Button
                    type="button"
                    icon="pi pi-calendar"
                    severity="info"
                    onClick={(e) => panelRef.current.toggle(e)}
                    disabled={props.disabled}
                />
            </div>
            <OverlayPanel ref={panelRef}>
                <CalendarPR
                    {...props}
                    minDate={min}
                    maxDate={max}
                    dateFormat="dd/mm/yy"
                    showIcon
                    showButtonBar
                    locale="br"
                    inline
                    onChange={(e) => {
                        onChangeCalendar(e);
                        setDataStr(dataToStr(e.value, "dd/MM/yyyy"));
                        panelRef.current.toggle(e);
                    }}
                />
            </OverlayPanel>
        </>
    );
};
