import React, { useRef } from "react";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import useClearRefs from "@/hooks/useClearRefs";
import {
    AtivoFiltroTemplate,
    ClienteFiltroTemplate,
    NumeroFiltroTemplate,
    PerfilFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

export const CarteiraClientePage = () => {
    const listagemRef = useRef(null);
    useClearRefs(listagemRef);

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_INCLUIR]}
            componente={Button}
            label="Nova"
            icon={MAKO_ICONS.NOVO}
            className="p-button-success"
            to={"/vendas/cadastros/carteira-clientes/form"}
        />
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/vendas/cadastros/carteira-clientes/form",
                        state: rowData,
                    }}
                />
                <ButtonListagemOpcoes
                    url={"/pessoas/carteiras-clientes"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.cliente.nome}
                    prefixo="a"
                    entidade="carteira de cliente"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: <span>Deseja mesmo excluir a carteira de clientes?</span>,
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const PrioridadeFiltroTemplate = (options) => {
        return (
            <NumeroFiltroTemplate
                inputProps={{
                    mode: "decimal",
                    useGrouping: false,
                    min: 1,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "profissional.nome",
            header: "Profissional",
            style: { width: "35%" },
            filter: true,
            filterElement: PerfilFiltroTemplate,
            filterField: "profissional",
        },
        {
            field: "cliente.nome",
            header: "Cliente",
            style: { width: "35%" },
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "cliente",
        },
        {
            field: "prioridade",
            header: "Prioridade",
            style: { width: "10%" },
            filter: true,
            filterElement: PrioridadeFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "8%" },
            align: "center",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Carteira de Clientes"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/pessoas/carteiras-clientes/"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    profissional: { value: null, matchMode: "equals" },
                    cliente: { value: null, matchMode: "equals" },
                    prioridade: { value: null, matchMode: "equals" },
                    ativo: { value: true, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
