import React from "react";

import { MakoListagem } from "@/components/MakoListagem";
import usePagamento from "@/hooks/usePagamento";

export const PagamentoPrevisaoForm = () => {
    const { pagamento } = usePagamento();

    const colunas = [
        { field: "data_prevista", header: "Data prevista", style: { width: "10%" }, dateFormat: "dd/MM/yyyy" },
        { field: "plano_orcamentario.descricao", header: "Plano orçamentário" },
        { field: "valor_previsto", header: "Valor previsto", style: { width: "10%" }, money: true },
    ];

    return (
        <MakoListagem
            urlPesquisa={`/financeiro/previsoes-pagamentos?pagamento__documento=${pagamento?.documento}`}
            colunas={colunas}
        />
    );
};
