import React, { forwardRef, useImperativeHandle, useState } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const URL_BASE = "/produtos/estoques-reguladores/";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [sku, setSku] = useState(null);
    const [estoqueReguladorId, setEstoqueReguladorId] = useState(0);
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPost, httpPut } = useHttp();
    const { showSuccess, showWarning } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const formik = useFormik({
        initialValues: {
            estoque_empresa: null,
            quantidade_minima: 0,
            quantidade_maxima: 0,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                estoque_empresa: Yup.number()
                    .required("O campo 'estoque' é obrigatório.")
                    .typeError("Selecione um estoque."),
                quantidade_minima: Yup.number()
                    .required("O campo 'quantidade mínima' é obrigatório.")
                    .min(0, "Esse campo não pode ser inferior a 0 (ZERO)."),
                quantidade_maxima: Yup.number()
                    .required("O campo 'quantidade máxima' é obrigatório.")
                    .min(0, "Esse campo não pode ser inferior a 0 (ZERO).")
                    .min(values.quantidade_minima, "A quantidade máxima não pode ser inferior à mínima."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Estoque regulador alterado com sucesso.",
                        life: 2000,
                    });
                    setTimeout(() => {
                        fecharModal();
                    }, 2000);
                },
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Estoque regulador cadastrado com sucesso.",
                        life: 2000,
                    });
                    setTimeout(() => {
                        fecharModal();
                    }, 2000);
                },
                400: ({ err }) => {
                    if (err?.unique) {
                        showWarning({
                            summary: "Aviso",
                            detail: err.unique,
                            life: 3000,
                        });
                    }
                },
            };
            const body = { ...values, sku: sku?.id };
            if (!estoqueReguladorId) {
                showLoading();
                await httpPost({ url: URL_BASE, body }, handlers);
                hideLoading();
            } else {
                showLoading();
                await httpPut({ url: `${URL_BASE}${estoqueReguladorId}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (sku) => {
        setSku(sku);
        setVisible(true);
    };

    const fecharModal = () => {
        setSku(null);
        setEstoqueReguladorId(0);
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const getEstoqueRegulador = async () => {
        if (!sku) return;
        const handlers = {
            200: ({ data }) => {
                if (!data.count) return;
                const [estoqueRegulador] = data.results;
                setEstoqueReguladorId(estoqueRegulador.id);
                formik.setValues({
                    estoque_empresa: estoqueRegulador.estoque_empresa,
                    quantidade_minima: estoqueRegulador.quantidade_minima,
                    quantidade_maxima: estoqueRegulador.quantidade_maxima,
                    ativo: estoqueRegulador.ativo,
                });
            },
        };
        const url = `${URL_BASE}?sku=${sku.id}&estoque_empresa__empresa=${empresaSelecionadaId}&ativo=true`;
        showLoading();
        await httpGet({ url }, handlers);
        hideLoading();
    };

    return (
        <Dialog header="Estoque regulador" visible={visible} onHide={fecharModal} onShow={getEstoqueRegulador}>
            <form onSubmit={formik.handleSubmit} onReset={fecharModal}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="estoque">Estoque *</label>
                        <Dropdown
                            id="estoque"
                            name="estoque_empresa"
                            url={`/produtos/estoques-empresas/?empresa=${empresaSelecionadaId}&query={id,nome,descricao}`}
                            optionValue="id"
                            optionLabel="nome"
                            disabled={!!estoqueReguladorId}
                            value={formik.values.estoque_empresa}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.estoque_empresa })}
                        />
                        {formik.errors.estoque_empresa && (
                            <small className="p-error">{formik.errors.estoque_empresa}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-minima">Qtd mínima *</label>
                        <MakoInputQuantidadeSku
                            id="qtd-minima"
                            name="quantidade_minima"
                            min={0}
                            permiteFracionario={sku?.item.movimenta_cubagem}
                            value={formik.values.quantidade_minima}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_minima })}
                        />
                        {formik.errors.quantidade_minima && (
                            <small className="p-error">{formik.errors.quantidade_minima}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-maxima">Qtd máxima *</label>
                        <MakoInputQuantidadeSku
                            id="qtd-maxima"
                            name="quantidade_maxima"
                            min={0}
                            permiteFracionario={sku?.item.movimenta_cubagem}
                            value={formik.values.quantidade_maxima}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_maxima })}
                        />
                        {formik.errors.quantidade_maxima && (
                            <small className="p-error">{formik.errors.quantidade_maxima}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="ativo">Ativo?</label>
                        <SelectButton
                            id="ativo"
                            name="ativo"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            disabled={!estoqueReguladorId}
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <MakoActionsButtons className="p-jc-end">
                    <Button
                        type="reset"
                        icon="pi pi-times"
                        label="Cancelar"
                        loading={loading}
                        className="p-button-danger"
                    />
                    <Button
                        type="submit"
                        icon="pi pi-save"
                        label="Gravar"
                        loading={loading}
                        className="p-button-info"
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalEstoqueRegulador = forwardRef(Modal);
