import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Tooltip } from "primereact/tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoButton as Button } from "@/components/MakoButton";
import { dataToStr, parseData } from "@/assets/util/datas";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Calendar } from "@/components/Calendar";

export const VigenciaPrecoForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            nivel: 1,
            data_inicio: new Date(),
            data_fim: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                nivel: Yup.number().required("Escolha uma opção de nível."),
                data_inicio: Yup.date()
                    .typeError("Informe uma data válida.")
                    .required("O campo 'início da vigência' é obrigatório."),
                data_fim: Yup.date()
                    .nullable()
                    .min(formik.values.data_inicio, "O vigência final não pode ser menor que a inicial."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const vigencia = {
                ...values,
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
                data_fim: values.data_fim ? dataToStr(values.data_fim, "yyyy-MM-dd") : null,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Vigência de preço cadastrada com sucesso.",
                            life: 1500,
                        });
                        history.push("/vendas/cadastros/vigencia-preco");
                    },
                };
                showLoading();
                await httpPost({ url: "/produtos/precos-vigencia/", body: vigencia }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Vigência de preço alterada com sucesso.",
                            life: 1500,
                        });
                        history.push("/vendas/cadastros/vigencia-preco");
                    },
                };
                showLoading();
                await httpPut({ url: `/produtos/precos-vigencia/${vigencia.id}/`, body: vigencia }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (props.location.state) {
            setValues({
                ...props.location.state,
                data_inicio: parseData(props.location.state.data_inicio),
                data_fim: parseData(props.location.state.data_fim),
            });
        }
    }, [props.location.state, setValues]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova vigência de preço" : "Manutenção de vigência de preço"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-inicio" label="Início da vigência" obrigatorio />
                        <Calendar
                            id="data-inicio"
                            name="data_inicio"
                            value={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicio })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-fim" label="Final da vigência" />
                        <Calendar
                            id="data-fim"
                            name="data_fim"
                            value={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_fim })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Tooltip target=".custom-tooltip-lbl">
                            <span>Tooltip de orientação de nivel de preço</span>
                        </Tooltip>
                        <Label htmlFor="custom-tooltip-lbl" label="Prioridade da vigência" obrigatorio />
                        <div className="p-formgroup-inline">
                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="nivel-um"
                                    name="nivel"
                                    value={1}
                                    onChange={formik.handleChange}
                                    checked={formik.values.nivel === 1}
                                />
                                <label htmlFor="nivel-um">Baixa</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="nivel-dois"
                                    name="nivel"
                                    value={2}
                                    onChange={formik.handleChange}
                                    checked={formik.values.nivel === 2}
                                />
                                <label htmlFor="nivel-dois">Média</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="nivel-tres"
                                    name="nivel"
                                    value={3}
                                    onChange={formik.handleChange}
                                    checked={formik.values.nivel === 3}
                                />
                                <label htmlFor="nivel-tres">Alta</label>
                            </div>
                        </div>
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                    <Button
                        type="button"
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        className="p-button-danger"
                        to={"/vendas/cadastros/vigencia-preco"}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
