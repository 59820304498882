import React from "react";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";

export const ModalApontamentoVisualizar = ({ apontamento }) => {
    return (
        <form>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-9">
                    <Label htmlFor="servico_contratado" label="Identificação do serviço" />
                    <MakoAutoComplete
                        id="servico_contratado"
                        name="servico_contratado"
                        field="label"
                        value={apontamento?.servico_contratado}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="tipo" label="Tipo" />
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPOS_CHOICE_SERVICOS_SERVICO}
                        optionValue="value"
                        optionLabel="label"
                        disabled
                        value={apontamento?.tipo}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_inicial" label="Data Inicial" />
                    <MakoCalendar id="data_inicial" name="data_inicial" value={apontamento?.data_inicial} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_final" label="Data final" />
                    <MakoCalendar id="data_final" name="data_final" value={apontamento?.data_final} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total_horas" label="Número de horas" />
                    <InputNumber id="total_horas" name="total_horas" value={apontamento?.total_horas} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="custo" label="Custo do serviço" />
                    <MakoInputMoeda id="custo" name="custo" valueMoeda={apontamento?.custo} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor do serviço" />
                    <MakoInputMoeda id="valor" name="valor" valueMoeda={apontamento?.valor} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor_nfs" label="Valor para NFSe" />
                    <MakoInputMoeda id="valor_nfs" name="valor_nfs" valueMoeda={apontamento?.valor_nfs} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="descricao" label="Detalhes do serviço" />
                    <InputTextarea
                        id="descricao"
                        name="descricao"
                        value={apontamento?.descricao}
                        disabled
                        rows={2}
                        autoResize
                        maxLength={255}
                    />
                </div>
            </div>
        </form>
    );
};
