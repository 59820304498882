import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoButton as Button } from "@/components/MakoButton";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoMultiSelect } from "@/components/MakoInputs";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

export const RegraArredondamentoValorForm = ({ data, aposSalvar }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            minimo: 0,
            maximo: 0,
            empresas: null,
            acima: false,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (data) setValues(data);
    }, [data, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                minimo: Yup.number().min(0, "Esse campo não pode ser menor que 0 (ZERO)."),
                maximo: Yup.number().min(values.minimo, "Esse campo não pode ser menor que o campo 'valor mínimo'."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Regra de arredondamento de preço cadastrada com sucesso!",
                            life: 1500,
                        });
                        if (aposSalvar) aposSalvar(data);
                    },
                };
                showLoading();
                await httpPost({ url: "/vendas/regra-arredondamento-valor/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Regra de arredondamento de preço alterada com sucesso!",
                            life: 1500,
                        });
                        if (aposSalvar) aposSalvar(data);
                    },
                };
                showLoading();
                await httpPut({ url: `/vendas/regra-arredondamento-valor/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscar = useCallback((data) => {
        return data.map((item) => item.perfil);
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="empresas" label="Empresas" obrigatorio />
                        <MakoMultiSelect
                            id="empresas"
                            name="empresas"
                            url="/pessoas/grupos-perfis/"
                            aposBuscar={aposBuscar}
                            optionValue="id"
                            optionLabel="nome"
                            selectedItemsLabel="{0} empresas selecionadas"
                            maxSelectedLabels={2}
                            value={formik.values.empresas}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vlr-minimo" label="Valor mínimo" obrigatorio />
                        <MakoInputMoeda
                            id="vlr-minimo"
                            name="minimo"
                            valueMoeda={formik.values.minimo}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.minimo })}
                        />
                        {formik.errors.minimo && <small className="p-error">{formik.errors.minimo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vlr-maximo" label="Valor máximo" obrigatorio />
                        <MakoInputMoeda
                            id="vlr-maximo"
                            name="maximo"
                            valueMoeda={formik.values.maximo}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.maximo })}
                        />
                        {formik.errors.maximo && <small className="p-error">{formik.errors.maximo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="acima">Arredonda para cima?</label>
                        <SelectButton
                            id="acima"
                            name="acima"
                            value={formik.values.acima}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        type="button"
                        className="p-button-danger"
                        to={"/vendas/cadastros/arredondamento-valor"}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </>
    );
};
