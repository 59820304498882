import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RELATORIO_VENDAS_CURVAABCPDF } from "@/assets/constants/relatorios";
import { TIPOS_REFERENCIA_CURVA_CHOICE } from "@/assets/constants/constants";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { dataToStr } from "@/assets/util/datas";
import { FormFiltroAvancado } from "./filtro";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useRelatorio from "@/hooks/useRelatorio";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import * as Yup from "yup";

const BASE_URL = "/relatorios/curva-abc/";

export const RelatorioCurvaAbcPage = () => {
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [exibirFiltro, setExibirFiltro] = useState(false);
    const [exibirCurva, setExibirCurva] = useState(false);
    const [curva, setCurva] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const { showError, showSuccess } = useToast();
    const { httpPost } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_inicial: null,
            data_final: null,
            empresa: null,
            referencia: null,
            descricao: "",
            percentual_c: null,
            percentual_a: null,
            percentual_b: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                data_inicial: Yup.date()
                    .required("O campo 'período inicial' é obrigatório.")
                    .typeError("Informe um 'período inicial' válido."),
                data_final: Yup.date()
                    .min(values.data_inicial, "O 'período final' não pode ser menor que o inicial.")
                    .required("O campo 'período final' é obrigatório."),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório.")
                    .typeError("Informe uma 'empresa' válida."),
                referencia: Yup.number()
                    .required("O campo 'referência' é obrigatório.")
                    .typeError("Seleciona uma 'referência' válida."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                percentual_a: Yup.number().required("O campo 'percentual a' é obrigatório."),
                percentual_b: Yup.number()
                    .required("O campo 'percentual b' é obrigatório.")
                    .min(values.percentual_a, "O 'percentual b' não pode ser menor que o 'percentual a'."),
                percentual_c: Yup.number()
                    .required("O campo 'percentual c' é obrigatório.")
                    .min(values.percentual_b, "O 'percentual b' não pode ser menor que o 'percentual b'.")
                    .max(100, "O 'percentual c' não pode ser menor que 100."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Relatório de curva ABC gerado com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
            };

            showLoading();
            await httpPost(
                {
                    url: "/relatorios/processa-curva-abc/",
                    body: {
                        ...values,
                        data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                        data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const fecharModal = () => {
        setExibirCurva(false);
    };

    const fecharModalFiltro = (newUrl = null, contador) => {
        setExibirFiltro(false);
        if (typeof newUrl === "string") setUrl(newUrl);
        setTotalizadorFiltros(contador);
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                type="button"
                className="p-button-help p-mr-2 p-mb-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => setExibirFiltro(true)}
            />
        </>
    );

    const limparFiltro = () => {
        formik.resetForm();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    type="button"
                    permissao={[permissoes.ESTOQUE_CADASTRO_ESTOQUES_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-eye-slash"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => {
                        setExibirCurva(true);
                        setCurva(rowData.id);
                    }}
                    tooltip="Visualizar Curva ABC"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    type="button"
                    permissao={[permissoes.RELATORIOS_EMITIR_VENDAS_CURVAABCPDF]}
                    componente={Button}
                    icon="pi pi-file-pdf"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Gerar PDF"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        solicitarRelatorio({
                            chave: RELATORIO_VENDAS_CURVAABCPDF,
                        })
                    }
                />
            </div>
        );
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "data_inicial",
            header: "Período Inicial",
            filter: true,
            filterElement: dataFiltroTemplate,
            style: { width: "15%" },
            action: (e) => formatData(e.data_inicial),
        },
        {
            field: "data_final",
            header: "Período Final",
            filter: true,
            filterElement: dataFiltroTemplate,
            style: { width: "15%" },
            action: (e) => formatData(e.data_final),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const formatData = (data) => {
        const splitedData = data.split("-");
        return splitedData[2] + "-" + splitedData[1] + "-" + splitedData[0];
    };

    const colunasItens = [
        { field: "sku", header: "Produto/Mercadoria" },
        { field: "quantidade_vendas", header: "Quantidade" },
        { field: "valor_unitario", header: "Valor Unitário" },
        { field: "valor_total", header: "Valor Total" },
        { field: "percentual", header: "%" },
        { field: "percentual_acumulado", header: "% Acumulado" },
        { field: "letra", header: "Classificação" },
    ];

    const rowClass = (item) => {
        return {
            "mako-table-effective": item.letra === "A",
            "mako-table-pending": item.letra === "B",
            "mako-table-overdue": item.letra === "C",
        };
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <h5>Selecione as informações para gerar o relatório de curva ABC:</h5>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="empresa">Empresa: *</label>
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            placeholder="Selecione uma empresa"
                            value={formik.values.empresa}
                            onChange={(e) => setFieldValue("empresa", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa }) + "p-mt-2"}
                        />
                        {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.descricao,
                            })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_inicial">Periodo inicial: *</label>
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            value={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicial })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_final">Periodo final: *</label>
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            value={formik.values.data_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_final })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="percentual_a">Percentual A *</label>
                        <InputNumber
                            id="percentual_a"
                            name="percentual_a"
                            type="decimal"
                            prefix="%"
                            min={0}
                            max={100}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.percentual_a}
                            onValueChange={formik.handleChange}
                        />
                        {formik.errors.percentual_a && <small className="p-error">{formik.errors.percentual_a}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="percentual_b">Percentual B *</label>
                        <InputNumber
                            id="percentual_b"
                            name="percentual_b"
                            type="decimal"
                            prefix="%"
                            min={0}
                            max={100}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.percentual_b}
                            onValueChange={formik.handleChange}
                        />
                        {formik.errors.percentual_b && <small className="p-error">{formik.errors.percentual_b}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="percentual_c">Percentual C *</label>
                        <InputNumber
                            id="percentual_c"
                            name="percentual_c"
                            type="decimal"
                            prefix="%"
                            min={0}
                            max={100}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.percentual_c}
                            onValueChange={formik.handleChange}
                        />
                        {formik.errors.percentual_c && <small className="p-error">{formik.errors.percentual_c}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="referencia">Referência: *</label>
                        <Dropdown
                            id="referencia"
                            name="referencia"
                            placeholder="Selecione uma referência para a curva"
                            optionLabel="label"
                            optionValue="value"
                            options={TIPOS_REFERENCIA_CURVA_CHOICE}
                            value={formik.values.referencia}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.referencia && <small className="p-error">{formik.errors.referencia}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6 p-mt-2">
                    <Button label="Gerar Relatório" icon="pi pi-file" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => limparFiltro()}
                        className="p-button-warning p-mr-2"
                    />
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    data_inicial: { value: "", matchMode: "equals" },
                    data_final: { value: "", matchMode: "equals" },
                    descricao: { value: "", matchMode: "icontains" },
                }}
            />
            <Dialog
                visible={exibirCurva}
                blockScroll
                onHide={fecharModal}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "80vw", display: "block" }}
                header="Curva ABC"
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunasItens}
                    urlPesquisa={`/relatorios/itens-curva-abc?curva=${curva}`}
                    botaoExportar
                    configTabela={{
                        rowClassName: rowClass,
                    }}
                />
            </Dialog>
            <Dialog
                visible={exibirFiltro}
                blockScroll
                onHide={fecharModalFiltro}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "80vw" }}
                header="Filtro Avançado"
            >
                <FormFiltroAvancado onClose={fecharModalFiltro} />
            </Dialog>
        </PageBase>
    );
};
