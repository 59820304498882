import React from "react";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";

export const ListagemModal = ({ dados, onClickBotaoRemover, listagemRef }) => {
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/*<Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => {
                        if (typeof onClickBotaoEditar === "function") onClickBotaoEditar(rowData);
                    }}
                />*/}
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => onClickBotaoRemover(rowData.item.id)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "item.codigo", header: "Código" },
        { field: "item.descricao", header: "Descrição", style: { minWidth: "200px" } },
        { field: "saldo", header: "Saldo" },
        { field: "saldo_minimo", header: "Saldo mínimo" },
        { field: "saldo_maximo", header: "Saldo máximo" },
        { field: "venda_diaria", header: "Venda diária", decimal: true },
        { field: "necessidade", header: "Necessidade", decimal: true },
        {
            field: "action",
            header: "Ações",
            style: { width: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return <MakoListagem ref={listagemRef} colunas={colunas} dadosLocal={dados} />;
};
