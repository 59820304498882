import { EstoquesPage } from "@/pages/Estoque/Cadastros/Estoques";
import { EstoquesForm } from "@/pages/Estoque/Cadastros/Estoques/form";
import { CentroEstocagemPage } from "@/pages/Estoque/Cadastros/CentroEstocagem";
import { CentroEstocagemForm } from "@/pages/Estoque/Cadastros/CentroEstocagem/form";
import { TipoMovimentacaoEstoquePage } from "@/pages/Estoque/Movimentacoes/TiposMovimentacao";
import { TipoMovimentacaoEstoqueForm } from "@/pages/Estoque/Movimentacoes/TiposMovimentacao/form";
import { SaldoInicialSKUPage } from "@/pages/Estoque/Movimentacoes/SaldoInicial";
import { SaldoInicialSKUForm } from "@/pages/Estoque/Movimentacoes/SaldoInicial/form";
import { AcertoEstoquePage } from "@/pages/Estoque/Movimentacoes/AcertoEstoque";
import { CompetenciaMovEstoquePage } from "@/pages/Estoque/Cadastros/CompetenciaMovEstoque";
import { CompetenciaMovEstoqueForm } from "@/pages/Estoque/Cadastros/CompetenciaMovEstoque/form";
import { LoteInventarioPage } from "@/pages/Estoque/Inventario/LotesInventario";
import { LoteInventarioForm } from "@/pages/Estoque/Inventario/LotesInventario/formLoteInventario";
import { ItemInventarioForm } from "@/pages/Estoque/Inventario/LotesInventario/formItem";
import { ConsultarMovimentacoesPage } from "@/pages/Estoque/Movimentacoes/ConsultarMovimentacoes";
import { RelatorioSaldoOC } from "@/pages/Relatorios/Estoque/SaldoOC";
import { RelatorioSaldoInicial } from "@/pages/Relatorios/Estoque/SaldoInicial";
import { RelatorioNecessidadeOC } from "@/pages/Relatorios/Estoque/NecessidadeOC";
import { RelatorioSaldoProdutos } from "@/pages/Relatorios/Estoque/SaldoProdutos";
import { TransferenciaMercadoriaPage } from "@/pages/Estoque/Movimentacoes/TransferenciaMercadoria";
import { EstoqueCargaPage } from "@/pages/Estoque/Cadastros/Carga";
import { EstoqueCargaForm } from "@/pages/Estoque/Cadastros/Carga/form";
import { TabTransferenciaContext } from "@/pages/Estoque/Movimentacoes/TransferenciaMercadoria/tabTransferencia";
import { TipoTransferenciaPage } from "@/pages/Estoque/Cadastros/TipoTransferencia";
import { TipoTransferenciaForm } from "@/pages/Estoque/Cadastros/TipoTransferencia/form";
import { RelatorioTransferenciaFilial } from "@/pages/Relatorios/Estoque/TransferenciaFilial";
import { RequisicoesTransferenciaPage } from "@/pages/Estoque/Movimentacoes/RequisicoesTranferencia";
import { RequisicoesTransferenciaForm } from "@/pages/Estoque/Movimentacoes/RequisicoesTranferencia/form";
import { SaldoEstoqueFormulaPage } from "@/pages/Estoque/Cadastros/SaldoEstoqueFormula";
import { TabSaldoEstoqueFormula } from "@/pages/Estoque/Cadastros/SaldoEstoqueFormula/form";
import { RelatorioCustoSaldoEstoques } from "@/pages/Relatorios/Estoque/SaldoEstoque";
import { TransferenciaProdutos } from "@/pages/Fiscal/Fiscal/TransferenciaProdutos";
import { RelatorioSaldoGeral } from "@/pages/Relatorios/Estoque/SaldoGeral";
import { RelatorioSaldoEstoque } from "@/pages/Relatorios/Produtos/CustosSaldosEstoque";
import { TransferenciaExposicaoPage } from "@/pages/Estoque/Movimentacoes/TransferenciaExposicao";
import { RelatorioEstoqueRegulador } from "@/pages/Relatorios/Estoque/EstoqueRegulador";

export const estoqueRoutes = [
    { path: "/estoque/cadastros/estoques-empresas", component: EstoquesPage },
    { path: "/estoque/cadastros/estoques-empresas/form", component: EstoquesForm },
    { path: "/estoque/cadastros/centro-estocagem", component: CentroEstocagemPage },
    { path: "/estoque/cadastros/centro-estocagem/form", component: CentroEstocagemForm },
    { path: "/estoque/cadastros/competencias-movimentacoes-estoque", component: CompetenciaMovEstoquePage },
    { path: "/estoque/cadastros/competencias-movimentacoes-estoque/form", component: CompetenciaMovEstoqueForm },
    { path: "/estoque/cadastros/carga", component: EstoqueCargaPage },
    { path: "/estoque/cadastros/carga/form", component: EstoqueCargaForm },
    { path: "/estoque/cadastros/tipo-transferencia", component: TipoTransferenciaPage },
    { path: "/estoque/cadastros/tipo-transferencia/form", component: TipoTransferenciaForm },
    { path: "/estoque/cadastros/tipos-movimentacoes", component: TipoMovimentacaoEstoquePage },
    { path: "/estoque/cadastros/tipos-movimentacoes/form", component: TipoMovimentacaoEstoqueForm },
    { path: "/estoque/movimentacoes/consultar", component: ConsultarMovimentacoesPage },
    { path: "/estoque/movimentacoes/saldo-inicial-sku", component: SaldoInicialSKUPage },
    { path: "/estoque/movimentacoes/saldo-inicial-sku/form", component: SaldoInicialSKUForm },
    { path: "/estoque/movimentacoes/exposicao", component: TransferenciaExposicaoPage },
    { path: "/estoque/movimentacoes/transferencia-mercadoria", component: TransferenciaMercadoriaPage },
    { path: "/estoque/movimentacoes/transferencia-mercadoria/form", component: TabTransferenciaContext },
    { path: "/estoque/movimentacoes/acerto-estoque", component: AcertoEstoquePage },
    { path: "/estoque/inventario/lotes-inventario", component: LoteInventarioPage },
    { path: "/estoque/inventario/lotes-inventario/form", component: LoteInventarioForm },
    { path: "/estoque/inventario/lotes-inventario/itens", component: ItemInventarioForm },
    { path: "/estoque/relatorios/saldo-ordem-compra", component: RelatorioSaldoOC },
    { path: "/estoque/relatorios/saldo-inicial-produtos", component: RelatorioSaldoInicial },
    { path: "/estoque/relatorios/saldo-produtos", component: RelatorioSaldoProdutos },
    { path: "/estoque/relatorios/necessidade-compra", component: RelatorioNecessidadeOC },
    { path: "/estoque/relatorios/transferencia-filial", component: RelatorioTransferenciaFilial },
    { path: "/estoque/movimentacoes/requisicoes-transferencia", component: RequisicoesTransferenciaPage },
    { path: "/estoque/movimentacoes/requisicoes-transferencia/form", component: RequisicoesTransferenciaForm },
    { path: "/estoque/cadastros/saldo-estoque-formula", component: SaldoEstoqueFormulaPage },
    { path: "/estoque/cadastros/saldo-estoque-formula/form", component: TabSaldoEstoqueFormula },
    { path: "/estoque/relatorios/custo-saldo-estoques", component: RelatorioCustoSaldoEstoques },
    { path: "/fiscal/relatorios/transferencia-produtos/", component: TransferenciaProdutos },
    { path: "/estoque/relatorios/saldo-geral/", component: RelatorioSaldoGeral },
    { path: "/produto/relatorios/saldos-estoque", component: RelatorioSaldoEstoque },
    { path: "/produto/relatorios/estoque-regulador", component: RelatorioEstoqueRegulador },
];
