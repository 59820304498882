import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { useHistory } from "react-router-dom";
import { TIPO_MOVIMENTO_FISCAL_CHOICE } from "@/assets/constants/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

const url = "/fiscal/cfop/";
const urlvoltar = "/fiscal/cadastros/cfop";

export const FiscalCfopForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError, showWarning } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPut } = useHttp();
    const history = useHistory();

    const { resetForm, ...formik } = useFormik({
        initialValues: state?.cfop || {
            codigo: "",
            descricao: "",
            descricao_reduzida: "",
            vigencia_inicial: null,
            vigencia_final: null,
            tipo_movimentacao: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório.").max(5),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                descricao_reduzida: Yup.string().required("O campo 'descrição reduzida' é obrigatório.").max(50),
                tipo_movimentacao: Yup.string().required("O campo 'tipo de movimentação' é obrigatório."),
                vigencia_inicial: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = {
                ...values,
                vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
            };

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "CFOP cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                    400: ({ data }) => {
                        if (data?.codigo)
                            showWarning({
                                summary: "CFOP Existente!",
                                detail: "O CFOP com este Código já existe.",
                                life: 1500,
                            });
                        else showError();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "CFOP alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo CFOP" : "Manutenção de CFOP"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <InputText
                            id="codigo"
                            name="codigo"
                            keyfilter="int"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            tooltip="Código do CFOP Exemplo: Código '999' da descrição 'Outros'."
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descricao" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            tooltip="Descricao que será dado ao CFOP."
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="descricao_reduzida" label="Descricao reduzida" obrigatorio />
                        <InputText
                            id="descricao_reduzida"
                            name="descricao_reduzida"
                            value={formik.values.descricao_reduzida}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao_reduzida })}
                            tooltip="Descricao reduzida que aparecerá na nota fiscal."
                            autoComplete="off"
                        />
                        {formik.errors.descricao_reduzida && (
                            <small className="p-error">{formik.errors.descricao_reduzida}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo_movimentacao" label="Tipo de movimentação" obrigatorio />
                        <Dropdown
                            id="tipo_movimentacao"
                            name="tipo_movimentacao"
                            placeholder="Selecione um tipo de movimentação"
                            options={TIPO_MOVIMENTO_FISCAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_movimentacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_movimentacao })}
                        />
                        {formik.errors.tipo_movimentacao && (
                            <small className="p-error">{formik.errors.tipo_movimentacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vigencia_inicial" label="Vigência inicial" obrigatorio />
                        <MakoCalendar
                            id="vigencia_inicial"
                            name="vigencia_inicial"
                            value={formik.values.vigencia_inicial}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.vigencia_inicial,
                            })}
                        />
                        {formik.errors.vigencia_inicial && (
                            <small className="p-error">{formik.errors.vigencia_inicial}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vigencia_final" label="Vigência final" />
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            value={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.vigencia_final,
                            })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button label="Gravar" icon={MAKO_ICONS.GRAVAR} type="submit" className="p-button-info" />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
