import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoButton as Button } from "@/components/MakoButton";
import { SIM_NAO_BOOLEAN, TIPO_CONTA_FINANCEIRA_CHOICE } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { SelectButton } from "primereact/selectbutton";

const url = "/financeiro/contas-financeiras/";
const urlvoltar = "/financeiro/cadastros/contas-financeiras";

const INITIAL_VALUES = {
    codigo: "",
    descricao: "",
    data_abertura: null,
    saldo_atual: 0,
    saldo_inicial: 0,
    perfil: null,
    carteira: null,
    tipo_conta: null,
    disponivel_venda: false,
    ativa: true,
};

export const FinanceiroContaFinanceiraForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPatch, httpPost } = useHttp();
    const { showSuccess } = useToast();
    const { state } = useLocation();
    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: !state?.contaFinanceira
            ? INITIAL_VALUES
            : {
                  ...state?.contaFinanceira,
                  carteira: state?.contaFinanceira?.carteira.id,
                  tipo_conta: state?.contaFinanceira?.tipo_conta.id,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_conta: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Selecione um tipo válido."),
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                data_abertura: Yup.date()
                    .required("O campo 'data de abertura' é obrigatório.")
                    .typeError("Informe uma data válida."),
                perfil: Yup.string()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("Selecione um perfil válido."),
                carteira: Yup.string()
                    .required("O campo 'carteira' é obrigatório.")
                    .typeError("Selecione uma carteira válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const body = {
                    ...values,
                    data_abertura: dataToStr(values.data_abertura, "yyyy-MM-dd"),
                };
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta financeira cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url, body }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta financeira alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeSaldoInicial = (e) => {
        setFieldValue("saldo_atual", e.value);
        formik.handleChange(e);
    };

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova conta financeira" : "Manutenção de conta financeira"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo_conta" label="Tipo" obrigatorio />
                        <Dropdown
                            id="tipo_conta"
                            name="tipo_conta"
                            placeholder="Selecione um tipo"
                            autoFocus
                            options={TIPO_CONTA_FINANCEIRA_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_conta}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_conta })}
                        />
                        {formik.errors.tipo_conta && <small className="p-error">{formik.errors.tipo_conta}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="perfil" label="Pessoa" obrigatorio />
                        <MakoDropdownEmpresas
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            className={classNames({ "p-invalid": formik.errors.perfil })}
                            onChange={(e) => setFieldValue("perfil", e.id)}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="carteira" label="Carteira" obrigatorio />
                        <Dropdown
                            id="carteira"
                            name="carteira"
                            placeholder="Selecione uma carteira"
                            url="/financeiro/carteiras-contas-financeiras/"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.carteira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.carteira })}
                        />
                        {formik.errors.carteira && <small className="p-error">{formik.errors.carteira}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_abertura" label="Data abertura" obrigatorio />
                        <MakoCalendar
                            id="data_abertura"
                            name="data_abertura"
                            value={formik.values.data_abertura}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_abertura })}
                        />
                        {formik.errors.data_abertura && (
                            <small className="p-error">{formik.errors.data_abertura}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="saldo_inicial" label="Saldo inicial" />
                        <MakoInputMoeda
                            id="saldo_inicial"
                            name="saldo_inicial"
                            valueMoeda={formik.values.saldo_inicial}
                            onChangeMoeda={onChangeSaldoInicial}
                            className={classNames({ "p-invalid": formik.errors.saldo_inicial })}
                        />
                        {formik.errors.saldo_inicial && (
                            <small className="p-error">{formik.errors.saldo_inicial}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="disponivel_venda" label="Disponível para venda?" />
                        <SelectButton
                            id="disponivel_venda"
                            name="disponivel_venda"
                            value={formik.values.disponivel_venda}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
