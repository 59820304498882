import React, { useMemo } from "react";

import { InputText } from "primereact/inputtext";

import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoTime } from "@/components/MakoTime";
import { Label } from "@/components/Label";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { formatarEndereco } from "@/assets/util/util";

export const Content = ({ item = {} }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const formated = useMemo(() => {
        const {
            cliente,
            data_previsao,
            datahora_entrega,
            quantidade,
            retirar,
            endereco_entrega,
            rota_entrega,
            item_observacoes,
            entregador_id,
            nome_recebedor,
        } = item || {};
        let endereco = "Retirado na loja";
        if (!retirar) endereco = formatarEndereco(endereco_entrega);
        return {
            data_previsao,
            datahora_entrega,
            quantidade,
            endereco,
            cliente: `${cliente.nome} - ${formatarDocumento(cliente.identificacao)}`,
            rota: rota_entrega?.descricao || "",
            observacoes: item_observacoes,
            entregador: entregador_id?.nome,
            recebedor: nome_recebedor,
        };
    }, [item, formatarDocumento]);

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="cliente" label="Cliente" />
                    <InputText id="cliente" name="cliente" value={formated.cliente} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_previsao" label="Previsto para" />
                    <MakoCalendar id="data_previsao" name="data_previsao" value={formated.data_previsao} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrega" label="Data da entrega" />
                    <MakoCalendar
                        id="datahora_entrega"
                        name="datahora_entrega"
                        value={formated.datahora_entrega}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrega" label="Hora da entrega" />
                    <MakoTime
                        id="datahora_entrega"
                        name="datahora_entrega"
                        valueTime={formated.datahora_entrega}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="quantidade" label="Quantidade" />
                    <MakoInputQuantidadeSku id="quantidade" name="quantidade" value={formated.quantidade} disabled />
                </div>
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="endereco" label="Endereço da entrega" />
                    <InputText id="endereco" name="endereco" value={formated.endereco} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="rota" label="Rota" />
                    <InputText id="rota" name="rota" value={formated.rota} disabled />
                </div>
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="observacoes" label="Observações do item da venda" />
                    <InputText id="observacoes" name="observacoes" value={formated.observacoes} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="entregador" label="Entregador" />
                    <InputText id="entregador" name="entregador" value={formated.entregador} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="recebedor" label="Recebido / Retirado por" />
                    <InputText id="recebedor" name="recebedor" value={formated.recebedor} disabled />
                </div>
            </div>
        </div>
    );
};
