import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { compareAsc } from "date-fns";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PainelDetalheLoteSKU } from "./panel";
import { ListagemModal } from "./listagem";
import { Label } from "@/components/Label";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const URL_API = "/produtos/lotes-sku/";

const LoteSKU = ({ onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [itemEntrada, setItemEntrada] = useState(null);
    const listagemRef = useRef(null);
    const painelRef = useRef(null);
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showWarning } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const formik = useFormik({
        initialValues: {
            lote_serial: "",
            sku: null,
            item_entrada: null,
            fabricacao: null,
            vencimento: null,
            quantidade: 0,
            enderecamento: "",
            unico: false,
            complemento: "",
        },
        onSubmit: handleSubmit,
    });

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            if (k !== "non_field_errors") {
                errorMessages[k] = v;
            } else {
                if (v[0] === "Os campos lote_serial, sku, item_entrada devem criar um set único.") {
                    showWarning({
                        summary: "Opss",
                        detail: "O lote não deve se repetir para um mesmo item de entrada.",
                        life: 3000,
                    });
                }
            }
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        const body = {
            ...values,
            item_entrada: itemEntrada?.id,
            sku: itemEntrada?.sku.id,
            fabricacao: dataToStr(values.fabricacao, "yyyy-MM-dd"),
            vencimento: dataToStr(values.vencimento, "yyyy-MM-dd"),
        };
        if (!values.id) {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nº série / lote cadastrado com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => {
                        limparFormulario();
                        listagemRef.current?.buscarDados();
                        painelRef.current?.calcularRemanescente();
                    }, 1500);
                },
                400: ({ err }) => handleErrors(err),
            };
            showLoading();
            await httpPost({ url: URL_API, body }, handlers);
            hideLoading();
        } else {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nº série / lote alterado com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => {
                        limparFormulario();
                        listagemRef.current?.buscarDados();
                        painelRef.current?.calcularRemanescente();
                    }, 1500);
                },
                400: ({ err }) => handleErrors(err),
            };
            showLoading();
            await httpPut({ url: `${URL_API}${values.id}/`, body }, handlers);
            hideLoading();
        }
    }

    const limparFormulario = () => {
        formik.resetForm();
    };

    const abrirModal = (itemEntrada) => {
        setVisible(true);
        setItemEntrada(itemEntrada);
    };

    const fecharModal = () => {
        if (typeof onFechar === "function") onFechar();
        limparFormulario();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const header = itemEntrada?.sku.lote_serial === "S" ? "nº de série" : "lote";

    const handleVencimento = (e) => {
        const { value } = e;
        formik.setFieldValue("vencimento", value);
        const menorQueHoje = compareAsc(value, new Date()) === -1;
        if (menorQueHoje) {
            showWarning({
                summary: "Atenção",
                detail: "Data de vencimento suspeita. Confira com atenção.",
                life: 3000,
            });
        }
    };

    return (
        <Dialog
            header={`Detalhamento de ${header} de entrada em estoque`}
            visible={visible}
            onHide={fecharModal}
            style={{ width: "50vw" }}
        >
            <PainelDetalheLoteSKU ref={painelRef} itemEntrada={itemEntrada} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="n-lote" label="Nº do lote" obrigatorio />
                        <InputText
                            id="n-lote"
                            name="lote_serial"
                            value={formik.values.lote_serial}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.lote_serial })}
                        />
                        {formik.errors.lote_serial && <small className="p-error">{formik.errors.lote_serial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data-fabricacao" label="Data da fabricação" />
                        <MakoCalendar
                            id="data-fabricacao"
                            name="fabricacao"
                            maxDate={new Date()}
                            value={formik.values.fabricacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fabricacao })}
                        />
                        {formik.errors.fabricacao && <small className="p-error">{formik.errors.fabricacao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vencimento" label="Vencimento" />
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            value={formik.values.vencimento}
                            onChange={handleVencimento}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="quantidade" label="Quantidade" obrigatorio />
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="enderecamento" label="Endereçamento" />
                        <InputText
                            id="enderecamento"
                            name="enderecamento"
                            value={formik.values.enderecamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.enderecamento })}
                        />
                        {formik.errors.enderecamento && (
                            <small className="p-error">{formik.errors.enderecamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="complemento" label="Complemento" />
                        <InputText
                            id="complemento"
                            name="complemento"
                            value={formik.values.complemento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.complemento })}
                        />
                        {formik.errors.complemento && <small className="p-error">{formik.errors.complemento}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <Divider />
                <div className="p-d-flex p-jc-end p-mb-2 p-mt-2">
                    <Button
                        type="submit"
                        label={!formik.values.id ? "Adicionar" : "Atualizar"}
                        icon="pi pi-save"
                        loading={loading}
                        severity="success"
                        className="p-mr-2"
                    />
                    <Button
                        type="button"
                        label="Fechar"
                        icon="pi pi-times"
                        loading={loading}
                        severity="danger"
                        onClick={fecharModal}
                    />
                </div>
                <Divider />
            </form>
            <ListagemModal
                listagemRef={listagemRef}
                itemEntradaId={itemEntrada?.id}
                onClickBotaoEditar={(e) => formik.setValues(e)}
                onDeleteLoteSKU={() => painelRef.current?.calcularRemanescente()}
            />
        </Dialog>
    );
};

export const ModalLoteSKU = forwardRef(LoteSKU);
