import React, { memo } from "react";
import classNames from "classnames";

import { dataToStr } from "@/assets/util/datas";
import { Label } from "@/components/Label";
import { Calendar as MakoCalendar } from "@/components/Calendar";

const Componente = ({ fieldOptions, valueOptions, instance, onChange, error }) => {
    const { id: name, label, is_required, default_value, content_type } = fieldOptions;

    const valueField = valueOptions || {
        id: null,
        field: name,
        value: default_value || null,
        object_id: instance,
        content_type,
    };

    const onChangeValue = (e) => {
        onChange({ ...valueField, value: dataToStr(e.target.value, "yyyy-MM-dd") });
    };

    return (
        <>
            {label && <Label htmlFor={name} label={label} obrigatorio={is_required} />}
            <MakoCalendar
                id={name}
                name={name}
                value={valueField.value}
                onChange={onChangeValue}
                className={classNames({ "p-invalid": error })}
            />
            {error && <small className="p-error">{error}</small>}
        </>
    );
};

export const CustomFieldCalendar = memo(Componente);
