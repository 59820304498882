import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoInputCFOP } from "@/components/MakoInputCFOP";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CHAVE_DESTINACAO_SKU_CHOICE, TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE } from "@/assets/constants/constants";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const DestinacaoEntradaForm = ({ location }) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPost, httpPut } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: location?.state
            ? {
                  ...location.state,
                  centro_estocagem_destino: location.state.centro_estocagem_destino?.id,
                  categoria: location.state.categoria?.id,
                  chave: location.state.chave?.id,
              }
            : {
                  chave: null,
                  nome: "",
                  descricao: "",
                  operacao: null,
                  cfop_interno: null,
                  cfop_interestadual: null,
                  centro_estocagem_destino: null,
                  categoria: null,
                  empresa: empresaSelecionadaId,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().required("O campo 'chave' é obrigatório.").typeError("Selecione uma chave."),
                nome: Yup.string().required("O campo 'descricao' é obrigatório."),
                descricao: Yup.string().notRequired().default(""),
                operacao: Yup.number()
                    .required("O campo 'operação' é obrigatório.")
                    .typeError("Selecione uma operação."),
                cfop_interno: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .nullable(),
                cfop_interestadual: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .nullable(),
                categoria: Yup.number().notRequired().nullable().default(null),
                centro_estocagem_destino:
                    values.chave === "COI"
                        ? null
                        : Yup.number()
                              .required("O campo 'centro de estocagem' é obrigatório.")
                              .typeError("Selecione um centro de estocagem."),
            });
            const { cfop_interno, cfop_interestadual, ...rest } = await formSchema.validate(values, {
                abortEarly: false,
            });
            const dadosValidos = {
                ...rest,
                cfop_interno: cfop_interno?.id,
                cfop_interestadual: cfop_interestadual?.id,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Destinação de entrada cadastrada com sucesso!",
                            life: 1500,
                        });
                        history.push("/compras/cadastros/destinacoes");
                    },
                };
                showLoading();
                await httpPost({ url: "/compras/destinacoes-entradas/", body: dadosValidos }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Destinação de entrada alterada com sucesso!",
                            life: 1500,
                        });
                        history.push("/compras/cadastros/destinacoes");
                    },
                };
                showLoading();
                await httpPut({ url: `/compras/destinacoes-entradas/${values.id}/`, body: dadosValidos }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeTipo = (tipo) => {
        setFieldValue("chave", tipo);
        if (tipo === "COI") setFieldValue("centro_estocagem_destino", null);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova destinação de entrada" : "Manutenção de destinação de entrada"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            autoFocus
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label
                            htmlFor="centro-estocagem"
                            label="Centro de estocagem destino"
                            obrigatorio={formik.values.chave !== "COI"}
                        />
                        <Dropdown
                            id="centro-estocagem"
                            name="centro_estocagem_destino"
                            url="/produtos/centros-estocagem/?query={id,nome}"
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.centro_estocagem_destino}
                            onChange={formik.handleChange}
                            disabled={formik.values.chave === "COI"}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem_destino })}
                        />
                        {formik.errors.centro_estocagem_destino && (
                            <small className="p-error">{formik.errors.centro_estocagem_destino}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="categoria" label="Categoria" />
                        <MakoDropdownCategoriasHierarquicas
                            id="categoria"
                            name="categoria"
                            categoriaTituloSelecionavel
                            apenasTitulo
                            showClear
                            value={formik.values.categoria}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="chave" label="Tipo de produto" obrigatorio />
                        <Dropdown
                            id="chave"
                            name="chave"
                            options={TIPO_CHAVE_DESTINACAO_SKU_CHOICE}
                            value={formik.values.chave}
                            onChange={(e) => onChangeTipo(e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="operacao" label="Classe de movimento fiscal" obrigatorio />
                        <Dropdown
                            id="operacao"
                            name="operacao"
                            options={TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE}
                            value={formik.values.operacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao })}
                        />
                        {formik.errors.operacao && <small className="p-error">{formik.errors.operacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="opercfop-internoacao" label="CFOP interno" />
                        <MakoInputCFOP
                            id="cfop-interno"
                            name="cfop_interno"
                            value={formik.values.cfop_interno}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="cfop-interestadual" label="CFOP interestadua" />
                        <MakoInputCFOP
                            id="cfop-interestadual"
                            name="cfop_interestadual"
                            value={formik.values.cfop_interestadual}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Voltar"
                        icon={MAKO_ICONS.VOLTAR}
                        type="button"
                        className="p-button-danger"
                        loading={loading}
                        to={"/compras/cadastros/destinacoes"}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
