import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/financeiro/versoes-planos-contas-financeiras/";

export const FinVersaoPlanoContasPage = () => {
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [versaoContas, setVersaoContas] = useState(null);
    const [listEmpresaVinculada, setListEmpresaVinculada] = useState([]);
    const [empresaVinculada, setEmpresaVinculada] = useState(null);
    const [exibirEmpresasVinc, setExibirEmpresasVinc] = useState(false);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPatch } = useHttp();
    const listagemRef = useRef(null);

    async function handleVincular() {
        try {
            if (empresaVinculada !== null) {
                const body = {
                    descricao: versaoContas.descricao,
                    empresas: { add: [empresaVinculada] },
                    inicio_vigencia: versaoContas.inicio_vigencia,
                    final_vigencia: versaoContas.final_vigencia,
                    ativo: versaoContas.ativo,
                };
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Versão de plano de contas cadastrada com sucesso!",
                            life: 1500,
                        });
                        listagemRef.current?.buscarDados();
                        setEmpresaVinculada(null);
                    },
                };
                showLoading();
                await httpPatch({ url: `${url}${versaoContas.id}/`, body }, handlers);
                hideLoading();
            } else {
                showWarning({
                    summary: "Ops",
                    detail: "Selecione uma empresa para realizar o vinculo.",
                    life: 1500,
                });
            }
        } catch (error) {
            showError();
        }
    }

    const esconderVincularEmpresa = () => {
        setVincularEmpresa(false);
        setEmpresaVinculada(null);
    };

    const exibirVincularEmpresa = (rowData) => {
        setVincularEmpresa(true);
        setVersaoContas(rowData);
    };

    const renderFooter = (
        <MakoActionsButtons className="p-jc-end">
            <Button
                label="Cancelar"
                icon={MAKO_ICONS.CANCEL}
                onClick={() => esconderVincularEmpresa()}
                className="p-button-text"
                loading={loading}
            />
            <Button
                label="Confirmar"
                icon={MAKO_ICONS.GRAVAR}
                className="p-button"
                onClick={() => handleVincular()}
                loading={loading}
            />
        </MakoActionsButtons>
    );

    const opcoesVersaoPlano = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de versão do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/versao-plano-contas/form",
                        state: { versaoContas: rowData },
                    }}
                />

                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-sitemap"
                    className="p-button-rounded p-button-info"
                    onClick={() => exibirVincularEmpresa(rowData)}
                    tooltip="Vincular plano de contas a empresas"
                    tooltipOptions={{ position: "left" }}
                />
                <ButtonListagemOpcoes
                    url={"/financeiro/versoes-planos-contas-financeiras"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="a"
                    entidade="versão"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a versão <b>{rowData.nome}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    async function desvincularEmpresa(empresa) {
        const body = {
            descricao: versaoContas.descricao,
            empresas: { remove: [empresa.id] },
            inicio_vigencia: versaoContas.inicio_vigencia,
            final_vigencia: versaoContas.final_vigencia,
            ativo: versaoContas.ativo,
        };

        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Empresa desvinculada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                setExibirEmpresasVinc(false);
                setEmpresaVinculada(null);
            },
        };
        showLoading();
        await httpPatch({ url: `${url}${versaoContas.id}/`, body }, handlers);
        hideLoading();
    }

    const opcoesEmpresasVinculadas = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    onClick={() => desvincularEmpresa(rowData)}
                    tooltip="Desvincular empresa da versao do plano de contas"
                    loading={loading}
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/versao-plano-contas/form"}
            />
        </>
    );

    const exibirListaEmpresasVinc = (row) => {
        setVersaoContas(row);
        setListEmpresaVinculada(row.empresas);
        setExibirEmpresasVinc(true);
    };

    const esconderEmpresasVinc = () => {
        listagemRef.current?.buscarDados();
        setExibirEmpresasVinc(false);
    };

    const qtdEmpresasTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    label={rowData.empresas.length === 0 ? "0" : rowData.empresas.length}
                    onClick={() => exibirListaEmpresasVinc(rowData)}
                    className="p-button-outlined p-button-primary"
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "6%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "empresas",
            header: "Empresas",
            action: (e) => qtdEmpresasTemplate(e),
            filter: true,
            style: { width: "8%" },
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "inicio_vigencia",
            dateFormat: "dd/MM/yyyy",
            header: "Vigência Inicial",
            style: { width: "9%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "final_vigencia",
            header: "Vigência Final",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            style: { width: "9%" },
            filterElement: DateFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            align: "center",
            action: (e) => opcoesVersaoPlano(e),
            style: { width: "10%" },
        },
    ];

    const colunasEmpresa = [
        { field: "nome", header: "Nome da empresa", style: { width: "60%", paddingLeft: "1%" } },
        {
            field: "action",
            header: "Remover",
            align: "center",
            action: (e) => opcoesEmpresasVinculadas(e),
            style: { width: "5%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Versões do Plano de Contas Financeiras"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    empresas: { value: null, matchMode: "equals" },
                    inicio_vigencia: { value: null, matchMode: "equals" },
                    final_vigencia: { value: null, matchMode: "equals" },
                }}
            />
            <Dialog
                header="Vincular Empresas"
                visible={vincularEmpresa}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw" }}
                footer={renderFooter}
                onHide={() => esconderVincularEmpresa()}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="empresa_vinculada" label="Empresa" obrigatorio />
                        <MakoDropdownEmpresas
                            id="empresa_vinculada"
                            name="empresa_vinculada"
                            value={empresaVinculada}
                            onChange={(e) => setEmpresaVinculada(e.id)}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Empresas vinculadas"
                visible={exibirEmpresasVinc}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw" }}
                onHide={() => esconderEmpresasVinc()}
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunasEmpresa}
                    dadosLocal={listEmpresaVinculada}
                    responsiva
                    configTabela={{ paginator: false }}
                />
            </Dialog>
        </PageBase>
    );
};
