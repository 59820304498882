import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { MakoTime } from "@/components/MakoTime";
import { MakoComponenteRegra } from "../MakoComponenteRegra";
import { RegraCrediarioModalForm } from "./modal/regraCrediarioModalForm";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import classNames from "classnames";
import useCrediario from "@/hooks/useCrediario";
import useParam from "@/hooks/useParam";
import { VENDA_CREDIARIO_PRIMEIRAVENDA_VALORMAXIMO, VENDA_CREDIARIO_VALORMAXIMO } from "@/assets/constants/parametros";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

export const ProcoloCrediarioForm = () => {
    const [regra, setRegra] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [venda, setVenda] = useState(null);
    const [modalRegra, setModalRegra] = useState(false);
    const [primeiraCompra, setPrimeiraCompra] = useState(false);
    const [listaVendas, setListaVendas] = useState([]);
    const [opcao, setOpcao] = useState("Pessoa");
    const { crediario, submit, handleDadosCrediario } = useCrediario();
    const opcoesIniciacao = ["Pessoa", "Venda"];
    const { getParam } = useParam();
    const { showError, showWarning } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const paramValorMaximo = useMemo(() => {
        const param = getParam(VENDA_CREDIARIO_VALORMAXIMO);
        return parseNumber(param?.valor, 1);
    }, [getParam]);

    const paramPrimeiraCompra = useMemo(() => {
        const param = getParam(VENDA_CREDIARIO_PRIMEIRAVENDA_VALORMAXIMO);
        return parseNumber(param?.valor, 1);
    }, [getParam]);

    const { setValues, resetForm, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            nome: "",
            identificacao: "",
            rg: "",
            uf_emissor: "",
            orgao_expeditor: "",
            numero_orcamento: "",
            preco_orcamento: null,
            data_nascimento: null,
            tipo_pessoa: "PF",
            data_orcamento: "",
            hora_orcamento: "",
            ocupacao: null,
            protocolocrediario_dyn: [],
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (!submit && (!cliente || !venda) && !formik.values.id) {
                showWarning({
                    summary: "Informações incompletas",
                    detail: "Por favor, insira as informações de cliente e orçamento antes de gravar o crediário.",
                    life: 3000,
                });
            } else {
                const formSchema = Yup.object().shape({
                    nome: Yup.string().required("O campo 'nome completo' é obrigatório."),
                    identificacao: Yup.string().required("O campo 'cpf/cnpj' é obrigatório."),
                    ocupacao: Yup.object().required("O campo 'ocupação' é obrigatório."),
                });

                await formSchema.validate(values, {
                    abortEarly: false,
                });

                handleDadosCrediario({
                    ...values,
                    orcamento: !crediario.orcamento ? venda : values.orcamento,
                    perfil_titular: !crediario.perfil_titular ? cliente : values.perfil_titular,
                });

                if (!regra) setModalRegra(true);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const listarVendas = async (cliente) => {
        const handlers = {
            200: async ({ data }) => {
                setListaVendas(
                    await data.results.map((venda) => {
                        return {
                            ...venda,
                            label: `${venda.id}: ${venda.cliente.nome} - ${venda.cliente.identificacao} (R$${venda.valor_total_av} | ${venda.data})`,
                        };
                    })
                );
            },
        };

        await httpGet(
            {
                url: `/vendas/vendas?${
                    primeiraCompra
                        ? `valor_total_ap__lte=${paramPrimeiraCompra}&`
                        : paramValorMaximo
                        ? `valor_total_ap__lte=${paramValorMaximo}&`
                        : ""
                }query={id, cliente, valor_total_av, data, hora}&situacao=P&cliente__id=${
                    cliente.id
                }&empresa=${empresaSelecionadaId}&limit=100`,
            },
            handlers
        );
    };

    const pesquisaPrimeiraCompra = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data?.results?.length === 0) setPrimeiraCompra(true);
                else setPrimeiraCompra(false);
            },
        };

        await httpGet({ url: `/vendas/vendas?query={id}&limit=1&cliente__id=${await cliente.id}` }, handlers);
    }, [cliente, httpGet]);

    const clienteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const vendaTemplate = (valor) => {
        try {
            valor.label = `${valor.id}: ${valor.cliente.nome} - ${valor.cliente.identificacao} (R$${valor.valor_total_av} | ${valor.data})`;
            return `${valor.id}: ${valor.cliente.nome} - ${valor.cliente.identificacao} (R$${valor.valor_total_av} | ${valor.data})`;
        } catch (e) {}
    };

    const preencheFormulario = async (dados) => {
        setValues({
            ...(await formik.values),
            ...dados,
        });
    };

    const selecionaCliente = async (cliente) => {
        setCliente(cliente);
        if (typeof cliente === "object") {
            await preencheFormulario({
                ...cliente,
                id: null,
                cliente: cliente.id,
                identificacao: cliente.identificacao || "",
                nome: cliente.nome || "",
                rg: cliente.perfil_pf?.rg || "",
                uf_emissor: cliente.perfil_pf?.rg_estado_emissor || null,
                data_nascimento: cliente.perfil_pf?.data_nascimento || null,
                inscricao_estadual: cliente.perfil_pj?.inscricao_estadual || null,
                estado: cliente.perfil_pj?.estado || null,
                data_constituicao: cliente.perfil_pj?.data_constituicao || null,
                tipo_pessoa: cliente.tipo_pessoa,
            });
            listarVendas(await cliente);
        }
    };

    const selecionaVenda = async (venda, infoCliente = true) => {
        setVenda(venda);
        if (typeof venda === "object") {
            if (infoCliente) {
                const cliente = {
                    ...venda.cliente,
                    label: `${venda.cliente.nome} - ${venda.cliente.identificacao}`,
                    numero_orcamento: venda.id,
                    preco_orcamento: venda.valor_total_av,
                    data_orcamento: venda.data,
                    hora_orcamento: venda.hora,
                };

                selecionaCliente(cliente);
            } else
                await preencheFormulario({
                    ...formik.values,
                    id: null,
                    label: `${venda.cliente.nome} - ${venda.cliente.identificacao}`,
                    numero_orcamento: venda.id,
                    preco_orcamento: venda.valor_total_av,
                    data_orcamento: venda.data,
                    hora_orcamento: venda.hora,
                });
        }
    };

    const selecionaOpcao = (opcao) => {
        if (opcao === "Venda" && venda && typeof venda === "object") {
            setVenda({
                ...venda,
                label: `${venda.id}: ${venda.cliente.nome} - ${venda.cliente.identificacao} (R$${venda.valor_total_av} | ${venda.data})`,
            });
        }
        setOpcao(opcao);
    };

    const verificarParametros = useCallback(async () => {
        if (paramValorMaximo === null) {
            showWarning({
                summary: "Alerta!",
                detail: "Falta parametrizar o parâmetro 'venda.crediario.valormaximo'",
                life: 3000,
            });
        }
        if (paramPrimeiraCompra === null) {
            showWarning({
                summary: "Alerta!",
                detail: "Falta parametrizar o parâmetro 'venda.crediario.primeiravenda.valormaximo'",
                life: 3000,
            });
        }
    }, [paramValorMaximo, paramPrimeiraCompra, showWarning]);

    useEffect(() => {
        if (crediario)
            setValues({
                ...crediario,
                cliente: crediario.perfil_titular?.id || null,
                identificacao: crediario.perfil_titular?.identificacao || "",
                nome: crediario.perfil_titular?.nome || "",
                rg: crediario.perfil_titular?.perfil_pf?.rg || "",
                uf_emissor: crediario.perfil_titular?.perfil_pf?.rg_estado_emissor || null,
                data_nascimento: crediario.perfil_titular?.perfil_pf?.data_nascimento || null,
                inscricao_estadual: crediario.perfil_titular?.perfil_pj?.inscricao_estadual || null,
                estado: crediario.perfil_titular?.perfil_pj?.estado || null,
                data_constituicao: crediario.perfil_titular?.perfil_pj?.data_constituicao || null,
                tipo_pessoa: crediario.perfil_titular?.tipo_pessoa,
            });
    }, [crediario, setValues]);

    useEffect(() => {
        if (crediario?.regra) setRegra(crediario.regra);
    }, [crediario.regra]);

    useEffect(() => {
        if (cliente?.id) pesquisaPrimeiraCompra();
    }, [cliente, pesquisaPrimeiraCompra]);

    useEffect(() => {
        verificarParametros();
    }, [verificarParametros]);

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                {!crediario.id ? (
                    <div>
                        <div className="p-grid p-fluid p-justify-center">
                            <div className="p-col-12 p-md-5">
                                <SelectButton
                                    value={opcao}
                                    options={opcoesIniciacao}
                                    onChange={(e) => {
                                        resetForm();
                                        setVenda(null);
                                        setCliente(null);
                                        selecionaOpcao(e.value);
                                    }}
                                    disabled={regra}
                                />
                            </div>
                        </div>
                        {paramPrimeiraCompra || paramValorMaximo ? (
                            cliente?.id ? (
                                <h6>
                                    <b>
                                        Valor máximo permitido no orçamento:{" "}
                                        {parseNumberToMoneyHTML(
                                            paramValorMaximo
                                                ? paramValorMaximo
                                                : primeiraCompra
                                                ? paramPrimeiraCompra
                                                : ""
                                        )}{" "}
                                        {primeiraCompra ? `(Primeira compra)` : ""}
                                    </b>
                                </h6>
                            ) : null
                        ) : null}
                        {opcao === "Pessoa" ? (
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="cliente">Nome do cliente *</label>
                                    <MakoAutoComplete
                                        id="cliente"
                                        name="cliente"
                                        placeholder="Pesquise pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                                        minCaracteresBusca={4}
                                        value={cliente}
                                        onChange={(e) => selecionaCliente(e.target.value)}
                                        itemTemplate={clienteTemplate}
                                        field="label"
                                        urlSearch={
                                            "/pessoas/perfis?tipo_pessoa__isnot=PE&query={id, nome, identificacao, tipo_pessoa, perfil_pf, perfil_pj}&search="
                                        }
                                        disabled={regra}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="venda">Orçamento *</label>
                                    <Dropdown
                                        id="venda"
                                        name="venda"
                                        placeholder="Selecione uma venda"
                                        options={listaVendas}
                                        disabled={!listaVendas || regra}
                                        filter
                                        filterBy="label"
                                        optionLabel="label"
                                        value={venda}
                                        onChange={(e) => selecionaVenda(e.target.value, false)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="venda">Orçamento *</label>
                                    <MakoAutoComplete
                                        id="venda"
                                        name="venda"
                                        placeholder="Pesquise pela venda..."
                                        minCaracteresBusca={1}
                                        value={venda}
                                        onChange={(e) => selecionaVenda(e.target.value)}
                                        itemTemplate={vendaTemplate}
                                        field="label"
                                        urlSearch={`/vendas/vendas?${
                                            primeiraCompra
                                                ? paramPrimeiraCompra
                                                    ? `valor_total_ap__lte=${paramPrimeiraCompra}&`
                                                    : ""
                                                : paramValorMaximo
                                                ? `valor_total_ap__lte=${paramValorMaximo}&`
                                                : ""
                                        }query={id, cliente, valor_total_av, valor_total_ap, data, hora}&situacao=P&limit=100&empresa=${empresaSelecionadaId}&id__contains=`}
                                        disabled={regra}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
                <div className="card">
                    <h6>Cliente</h6>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="identificacao">CPF/CNPJ *</label>
                            <InputText
                                id="identificacao"
                                name="identificacao"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                                autoComplete="off"
                                disabled
                            />
                            {formik.errors.identificacao && (
                                <small className="p-error">{formik.errors.identificacao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-9">
                            <label htmlFor="nome">Nome completo *</label>
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                                autoComplete="off"
                                disabled
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                    </div>
                    {formik.values.tipo_pessoa === "PJ" ? (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="inscricao_estadual">Inscrição estadual</label>
                                <InputText
                                    id="inscricao_estadual"
                                    name="inscricao_estadual"
                                    value={formik.values.inscricao_estadual}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="estado">UF</label>
                                <Dropdown
                                    id="estado"
                                    name="estado"
                                    placeholder="Selecione um estado"
                                    url="/pessoas/estados?query={id, nome}&limit=300"
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.estado}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_constituicao">Data de constituição</label>
                                <MakoCalendar
                                    id="data_constituicao"
                                    name="data_constituicao"
                                    maxDate={new Date()}
                                    value={formik.values.data_constituicao}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="rg">RG</label>
                                <InputText
                                    id="rg"
                                    name="rg"
                                    value={formik.values.rg}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="uf_emissor">UF emissor</label>
                                <Dropdown
                                    id="uf_emissor"
                                    name="uf_emissor"
                                    placeholder="Selecione um estado"
                                    url="/pessoas/estados?query={id, nome}&limit=300"
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.uf_emissor}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_nascimento">Data de nascimento</label>
                                <MakoCalendar
                                    id="data-nascimento"
                                    name="data_nascimento"
                                    maxDate={new Date()}
                                    value={formik.values.data_nascimento}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="card">
                    <h6>Orçamento</h6>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="numero_orcamento">Número *</label>
                            <InputText
                                id="numero_orcamento"
                                name="numero_orcamento"
                                disabled
                                value={formik.values.numero_orcamento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.numero_orcamento })}
                                autoComplete="off"
                            />
                            {formik.errors.numero_orcamento && (
                                <small className="p-error">{formik.errors.numero_orcamento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="preco_orcamento">Valor *</label>
                            <InputNumber
                                id="preco_orcamento"
                                name="preco_orcamento"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={formik.values.preco_orcamento}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.preco_orcamento })}
                            />
                            {formik.errors.preco_orcamento && (
                                <small className="p-error">{formik.errors.preco_orcamento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-6 p-md-3">
                            <label htmlFor="data_orcamento">Data *</label>
                            <MakoCalendar
                                id="data_orcamento"
                                name="data_orcamento"
                                disabled
                                value={formik.values.data_orcamento}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.data_orcamento,
                                })}
                            />
                            {formik.errors.data_orcamento && (
                                <small className="p-error">{formik.errors.data_orcamento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="hora_orcamento">Hora</label>
                            <MakoTime
                                id="hora_orcamento"
                                name="hora_orcamento"
                                disabled
                                valueTime={formik.values.hora_orcamento}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <h6>Informação profissional</h6>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="ocupacao">Ocupação *</label>
                            <Dropdown
                                id="ocupacao"
                                name="ocupacao"
                                placeholder="Selecione uma ocupação"
                                url="/crediario/ocupacao?query={id, descricao, natureza_ocupacao}&limit=300"
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                disabled={regra}
                                showClear={false}
                                value={formik.values.ocupacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.ocupacao })}
                            />
                            {formik.errors.ocupacao && <small className="p-error">{formik.errors.ocupacao}</small>}
                        </div>
                    </div>
                </div>
                {regra ? (
                    <div className="card">
                        <h6>Outras informações</h6>
                        <div className="p-fluid p-formgrid p-grid">
                            <MakoComponenteRegra
                                dados={formik.values.protocolocrediario_dyn}
                                aba="Protocolo"
                                regra={regra}
                                campoPai="protocolocrediario_dyn"
                                setValores={setFieldValue}
                            />
                        </div>
                    </div>
                ) : null}
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                {!submit ? (
                    <div className="p-grid p-col-12 p-md-12">
                        <Button
                            icon="pi pi-save"
                            label="Incluir dados básicos"
                            type="submit"
                            className="p-button-info"
                        />
                    </div>
                ) : (
                    <FormikAutoSave intervalo={700} autosave={submit} formik={formik} />
                )}
            </form>
            <Dialog
                showHeader={false}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                visible={modalRegra}
                onHide={() => setModalRegra(false)}
            >
                <RegraCrediarioModalForm
                    modal={setModalRegra}
                    selecionaRegra={setRegra}
                    ocupacao={formik.values.ocupacao}
                    orcamento={formik.values.preco_orcamento}
                />
            </Dialog>
        </PageBase>
    );
};
