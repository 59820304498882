import React, { memo, useCallback, useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { Tag } from "primereact/tag";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { MakoButton } from "@/components/MakoButton";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { TIPO_OPERACAO_FORMULA_SALDO_ESTOQUE_CHOICE } from "@/assets/constants/constants";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";
import * as Yup from "yup";
import useHttp from "@/hooks/useHttp";

const INITIAL_VALUES = {
    id: null,
    centro_estocagem: null,
    operacao: "+",
    formula: true,
};

const BASE_URL = "/produtos/saldo-estoque-formula-centro-estocagem/";

const Component = ({ formula, handleCentros }) => {
    const [loading, setLoading] = useState(false);
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef();

    useClearRefs(listagemRef);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: { ...INITIAL_VALUES, formula: formula?.id },
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao: Yup.string().required("O campo 'operação' é obrigatório."),
                centro_estocagem: Yup.number().required("O campo 'centro estocagem' é obrigatório."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!dadosValidados?.id) {
                setLoading(true);
                delete dadosValidados.id;

                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Formula de saldo cadastrada com sucesso.",
                            life: 3000,
                        });
                        listagemRef.current?.buscarDados();
                        formik.resetForm();
                    },
                };

                await httpPost({ url: BASE_URL, body: dadosValidados }, handlers);
                setLoading(false);
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Formula de saldo alterada com sucesso.",
                            life: 3000,
                        });
                        listagemRef.current?.buscarDados();
                        formik.resetForm();
                    },
                };

                setLoading(true);
                await httpPatch({ url: `${BASE_URL}${dadosValidados.id}/`, body: dadosValidados }, handlers);
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const centroEstocagemTemplate = (option) => {
        const tipoCE = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === option.tipo);
        return <span>{`${option.nome} [${tipoCE ? tipoCE.label : "Desconhecido"}]`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoButton
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setValues({
                            id: rowData.id,
                            centro_estocagem: rowData.centro_estocagem.id,
                            operacao: rowData.operacao,
                            formula: rowData.formula,
                        })
                    }
                />
                <BotaoDelete
                    url={BASE_URL}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir esta formula <b>{rowData.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A formula não pode ser excluída"
                    tooltip="Deletar formula"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "centro_estocagem",
            header: "Centro Estocagem",
            action: ({ centro_estocagem }) => centroEstocagemTemplate(centro_estocagem),
        },
        {
            field: "operacao",
            header: "Operação",
            action: ({ operacao }) => {
                const { value, label } = TIPO_OPERACAO_FORMULA_SALDO_ESTOQUE_CHOICE.find(
                    ({ value }) => value === operacao
                );
                return <Tag severity={value === "+" ? "success" : "danger"} value={label.toUpperCase()} />;
            },
            align: "center",
            style: { width: "8%" },
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const aposPesquisar = useCallback(
        (data) => {
            handleCentros(data.length > 0);
            return data;
        },
        [handleCentros]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-10">
                        <Label htmlFor="centro_estocagem" obrigatorio={true} label="Centro Estocagem" />
                        <MakoDropdownEstoques
                            id="centro_estocagem"
                            name="centro_estocagem"
                            // estoqueId={formula?.estoque_empresa}
                            autoFocus
                            optionValue="id"
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                            disabled={!formula?.id}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="operacao" obrigatorio={true} label="Operação" />
                        <Dropdown
                            id="operacao"
                            name="operacao"
                            optionValue="value"
                            optionLabel="label"
                            options={TIPO_OPERACAO_FORMULA_SALDO_ESTOQUE_CHOICE}
                            value={formik.values.operacao}
                            disabled={!formula?.id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao })}
                        />
                        {formik.errors.operacao && <small className="p-error">{formik.errors.operacao}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <MakoButton
                        loading={loading}
                        disabled={!formula?.id}
                        label="Adicionar"
                        type="submit"
                        icon="pi pi-plus"
                        className="p-button-success"
                    />
                </div>
            </form>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    aposPesquisar={aposPesquisar}
                    urlPesquisa={`${BASE_URL}?formula=${formula?.id}&ordering=id`}
                    fazerBusca={!!formula?.id}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
        </>
    );
};

export const CentrosEstocagemForm = memo(Component);
