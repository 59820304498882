import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const url = "/financeiro/bancos/";
const urlvoltar = "/financeiro/cadastros/bancos";

export const FinanceiroBancoForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const history = useHistory();
    const { showSuccess } = useToast();
    const { httpPatch, httpPost } = useHttp();
    const { state } = useLocation();

    const { resetForm, ...formik } = useFormik({
        initialValues: state?.bancos || {
            codigo: "",
            descricao: "",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Banco cadastrado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Banco alterado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo banco" : "Manutenção de banco"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <InputText
                            id="codigo"
                            name="codigo"
                            keyfilter="pint"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        type="reset"
                        label="Voltar"
                        icon={MAKO_ICONS.VOLTAR}
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
