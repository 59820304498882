import React, { useEffect, memo, useRef, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import useModeloContrato from "@/hooks/useModeloContrato";
import MakoListagem from "@/components/MakoListagem";
import useHttp from "@/hooks/useHttp";
import { Editor } from "primereact/editor";
import { Menu } from "primereact/menu";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { ModalAdicionarClausula } from "./modalAdicionarClausula";
import { ModalEditarClausula } from "./modalEditarClausula";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MakoButton } from "@/components/MakoButton";
import { TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const BASE_URL_REMOVE = "/servicos/clausulas-modelo-contrato/{id}/clausulas/{vinculo}";
const BASE_URL_TROCA = "/servicos/clausulas-modelo-contrato/{id}/trocar-ordem";

const ClausulasForm = () => {
    const [clausulas, setClausulas] = useState([]);
    const [clausula, setClausula] = useState(null);
    const { handleClausula, dadosBasicos } = useModeloContrato();
    const { httpGet, httpDelete, httpPost } = useHttp();
    const { showError, showSuccess } = useToast();

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [reorder, showReorder, hideReorder] = useLoadingLocal(true);

    const modalAdicionarRef = useRef();
    const modalEditarRef = useRef();
    const listagemRef = useRef();
    const menu = useRef();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            modelo: null,
            clausula: null,
            ordem: null,
            ativo: true,
            data_desativacao: null,
            usuario_desativou: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                clausula: Yup.object().required("O campo 'cláusula' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleClausula({
                ...values,
                clausula: values.clausula?.id,
                modelo: dadosBasicos.id,
                ordem: clausulas.length + 1,
            });
            resetForm();
            carregaClausulas();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const colunas = [
        { rowReorder: reorder, rowReorderIcon: "pi pi-arrows-v", style: { minWidth: "8px" } },
        {
            field: "clausula.parte.identificador",
            header: "Secção",
            action: ({ id, clausula, ...row }) => {
                return (
                    <MakoButton
                        disabled={loading}
                        text
                        label={`${id}) ${clausula.nome}`}
                        className="p-button-rounded p-button-info"
                        model={opcoes}
                        style={{ color: "black" }}
                        onClick={(event) => {
                            menu.current?.toggle(event);
                            setClausula({ ...row, clausula, id });
                        }}
                    />
                );
            },
        },
        { field: "ordem_formatada", header: "Nº", style: { minWidth: "50px" } },
        {
            field: "clausula.texto_clausula",
            header: "Início do texto...",
            style: { minWidth: "300px" },
            action: ({ clausula }) => (
                <Editor showHeader={false} value={clausula?.texto_clausula} style={{ height: "80px" }} readOnly />
            ),
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
    ];

    const carregaClausulas = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setClausulas(data.results),
        };

        await httpGet(
            {
                url: `/servicos/clausulas-modelo-contrato/${dadosBasicos?.id}/ordenar`,
            },
            handlers
        );
        return;
    }, [httpGet, dadosBasicos?.id]);

    const atualizaOrdem = useCallback(
        async ({ value, dragIndex, dropIndex }) => {
            const de = value[dragIndex];
            const para = value[dropIndex];
            const handlers = {
                200: async ({ data }) => {
                    setClausulas(data.results);
                },
            };
            hideReorder();
            await httpPost(
                { url: BASE_URL_TROCA.replace("{id}", dadosBasicos?.id), body: { de: de.id, para: para.id } },
                handlers
            );
            showReorder();
        },
        [dadosBasicos?.id, hideReorder, httpPost, showReorder]
    );

    const handleDesativar = useCallback(async () => {
        const handlers = {
            204: async () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `A cláusula do modelo de contrato foi desativada com sucesso.`,
                    life: 3000,
                });
                carregaClausulas();
            },
        };
        const url = BASE_URL_REMOVE.replace("{id}", dadosBasicos?.id).replace("{vinculo}", clausula?.id);
        showLoading();
        await httpDelete({ url }, handlers);
        hideLoading();
    }, [carregaClausulas, clausula?.id, dadosBasicos?.id, hideLoading, httpDelete, showLoading, showSuccess]);

    const opcoes = [
        {
            items: [
                {
                    label: "Adicionar em cima",
                    disabled: !!formik.values.clausula,
                    command: () => {
                        modalAdicionarRef.current?.show(
                            clausula.id,
                            clausula?.clausula?.id,
                            modalAdicionarRef.current?.TIPOS_POSICAO.ACIMA
                        );
                    },
                },
                {
                    label: "Adicionar em baixo",
                    disabled: !!formik.values.clausula,
                    command: () => {
                        modalAdicionarRef.current?.show(
                            clausula.id,
                            clausula?.clausula?.id,
                            modalAdicionarRef.current?.TIPOS_POSICAO.ABAIXO
                        );
                    },
                },
                {
                    label: "Editar",
                    command: () => modalEditarRef.current?.show(clausula),
                },
                {
                    label: "Remover",
                    command: () => handleDesativar(clausula),
                },
            ],
        },
    ];

    const onSuccessEdicao = useCallback(() => {
        carregaClausulas();
    }, [carregaClausulas]);

    useEffect(() => {
        carregaClausulas();
        modalEditarRef.current?.hide();
    }, [carregaClausulas]);

    return (
        <>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <div className="p-mb-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    dadosLocal={clausulas}
                    filtros={{
                        "clausula.texto_clausula": { value: null, matchMode: "contains" },
                    }}
                    configTabela={{
                        reorderableColumns: true,
                        reorderableRows: true,
                        onRowReorder: atualizaOrdem,
                    }}
                />
            </div>
            {!!clausulas.length && <span>OBS: É possível arrastar as cláusulas para alterar suas ordens.</span>}
            {!clausulas.length && (
                <div className="p-d-flex p-ai-center p-jc-center p-mt-4">
                    <MakoButton
                        icon={MAKO_ICONS.NOVO}
                        label="Adicionar 1° cláusula"
                        severity="success"
                        type="button"
                        onClick={() => modalAdicionarRef.current?.show()}
                    />
                </div>
            )}
            <ModalAdicionarClausula ref={modalAdicionarRef} onSuccessCallback={carregaClausulas} />
            <ModalEditarClausula ref={modalEditarRef} onSuccessCallback={onSuccessEdicao} />
        </>
    );
};

export default memo(ClausulasForm);
