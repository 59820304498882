import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import useCrediario from "@/hooks/useCrediario";
import * as Yup from "yup";
import useAuth from "@/hooks/useAuth";
import { Dialog } from "primereact/dialog";

const Modal = ({}, ref) => {
    const [visivel, setVisivel] = useState(false);
    const { handleDadosComplementares } = useCrediario();
    const { user } = useAuth();
    const toastRef = useRef(null);

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            usuario: user.usuario.username,
            data_verificacao: new Date(),
            usuario_verificacao: null,
            nota_verificacao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            handleDadosComplementares("editar", {
                ...values,
                data_verificacao: dataToStr(values.data_verificacao, "yyyy-MM-dd"),
                usuario_verificacao: user,
                op: "editar",
                validado: true,
            });
            resetForm();
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Informações validadas com sucesso!",
                life: 3000,
            });
            esconderDialog();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const exibirDialogValidar = (dados) => {
        setValues({ ...dados, usuario: user.usuario.username, data_verificacao: new Date() });
        setVisivel(true);
    };

    const esconderDialog = () => {
        setVisivel(false);
    };

    useImperativeHandle(ref, () => ({ abrir: exibirDialogValidar }));

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                header="Validação de Dados Complementares"
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                visible={visivel}
                onHide={() => esconderDialog()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="usuario">Usuário responsável</label>
                            <InputText id="usuario" name="usuario" value={formik.values.usuario} readOnly disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="data_verificacao">Data de verificação *</label>
                            <MakoCalendar
                                id="data_verificacao"
                                name="data_verificacao"
                                value={formik.values.data_verificacao}
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="nota_verificacao">Nota</label>
                            <InputTextarea
                                id="nota_verificacao"
                                name="nota_verificacao"
                                value={formik.values.nota_verificacao}
                                onChange={formik.handleChange}
                                rows={5}
                                autoComplete="off"
                                autoFocus
                                autoResize
                            />
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-mb-0 p-ml-1 p-mt-1">
                        <Button
                            type="submit"
                            icon="pi pi-check"
                            label="Confirmar"
                            className="p-button-primary p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="button"
                            icon="pi pi-times"
                            label="Voltar"
                            className="p-button-danger p-mr-2 p-mb-2"
                            onClick={() => esconderDialog()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const ValidarDadosModalForm = forwardRef(Modal);
