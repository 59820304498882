import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { SIM_NAO_BOOLEAN, TIPO_ESCOPO_CHOICE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { SelectButton } from "primereact/selectbutton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const url = "/crediario/tipo-renda/";

export const CrediarioTipoRendaForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const { state } = useLocation();
    const history = useHistory();

    const formik = useFormik({
        initialValues:
            props.location && state
                ? state.tipoRenda
                : {
                      id: "",
                      escopo: null,
                      descricao: "",
                      ativo: true,
                      auto_validada: false,
                  },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().max(20).required("O campo 'descrição' é obrigatório."),
                escopo: Yup.number().required("O campo 'escopo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tag cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push("/vendas/venda/crediario/tipos-renda");
                    },
                };

                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Escopo alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push("/vendas/venda/crediario/tipos-renda");
                    },
                };

                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/vendas/venda/crediario/tipos-renda");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Novo tipo de renda" : "Manutenção de tipo de renda"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="escopo" label="Escopo" obrigatorio />
                        <Dropdown
                            id="escopo"
                            name="escopo"
                            placeholder="Selecione um escopo"
                            options={TIPO_ESCOPO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.escopo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.escopo })}
                        />
                        {formik.errors.escopo && <small className="p-error">{formik.errors.escopo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="auto_validada" label="Auto validada?" />
                        <SelectButton
                            id="auto_validada"
                            name="auto_validada"
                            value={formik.values.auto_validada}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button label="Gravar" icon={MAKO_ICONS.GRAVAR} type="submit" className="p-button-info" />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
