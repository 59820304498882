import React, { useEffect } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/modelos/";

export const ProdutoModelosForm = ({ dialog, onSubmit, modelo }) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: state?.modelos
            ? state.modelos
            : {
                  id: "",
                  nome: "",
                  marca: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                marca: Yup.number().required("O campo 'marca' é obrigatório.").typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Modelo cadastrado com sucesso!",
                            life: 1500,
                        });
                        if (!modelo) {
                            resetForm();
                            history.push("/produto/cadastros/modelos");
                        } else {
                            if (typeof onSubmit === "function") onSubmit(data);
                            dialog();
                        }
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Modelo alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/modelos");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else showError();
        }
    }

    useEffect(() => {
        if (formik.values.marca?.id) setFieldValue("marca", formik.values.marca.id);
        else if (modelo?.marca) setFieldValue("marca", modelo.marca);
    }, [setFieldValue, modelo?.marca, formik.values.marca?.id]);

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/modelos");
    };

    return (
        <PageBase>
            {modelo ? null : <h4>{!formik.values.id ? "Novo Modelo" : "Manutenção de Modelo"}</h4>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className={!!modelo ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-4"}>
                        <label htmlFor="marca">Marca * </label>
                        <Dropdown
                            id="marca"
                            name="marca"
                            placeholder="Selecione uma marca"
                            filter
                            filterBy="nome"
                            url="/produtos/marcas?limit=1000"
                            optionValue="id"
                            optionLabel="nome"
                            autoFocus
                            showClear={false}
                            disabled={modelo}
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.marca })}
                        />
                        {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                    </div>
                    <div className={!!modelo ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-4"}>
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className={`p-grid p-col-12 ${modelo ? "p-md-12" : "p-md-6"}`}>
                    <Button label="Gravar" type="submit" className={`p-button-info p-mr-2 ${modelo ? "" : "p-mb-6"}`} />
                    {modelo ? (
                        <Button
                            label="Fechar"
                            type="reset"
                            className="p-button-danger p-mr-2"
                            onClick={() => dialog()}
                        />
                    ) : (
                        <Button
                            label="Cancelar"
                            type="reset"
                            className="p-button-danger p-mr-2 p-mb-6"
                            onClick={cancelar}
                        />
                    )}
                </div>
            </form>
        </PageBase>
    );
};
