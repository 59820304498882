import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { PageBase } from "@/components/PageBase";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import { TIPO_CENTRO_ESTOCAGEM_CHOICE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";

export const TipoTransferenciaForm = (props) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const { state } = useLocation();

    const options = [
        { value: false, label: "Não" },
        { value: true, label: "Sim" },
    ];
    const history = useHistory();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: state
            ? {
                  ...state,
                  tipo_sku_origem: state.tipo_sku_origem.id,
                  tipo_sku_destino: state.tipo_sku_destino.id,
                  tipo_ce_origem: state.tipo_ce_origem.id,
                  tipo_ce_destino: state.tipo_ce_destino.id,
              }
            : {
                  empresa: empresaSelecionadaId,
                  descricao: "",
                  ativo: true,
                  tipo_sku_origem: "M",
                  tipo_sku_destino: "M",
                  mesmo_estoque: null,
                  mesmo_centro_estocagem: null,
                  tipo_ce_origem: "N",
                  tipo_ce_destino: "N",
                  mesmo_cnpj_cpf: null,
                  mesma_raiz_cnpj: null,
                  para_matriz: null,
                  para_filial: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo é obrigatório."),
                tipo_sku_origem: Yup.string().required("O campo é obrigatório."),
                tipo_sku_destino: Yup.string().required("O campo é obrigatório."),
                tipo_ce_origem: Yup.string().required("O campo é obrigatório."),
                tipo_ce_destino: Yup.string().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Novo tipo de transferência cadastrado!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPost({ url: "/transferencias/tipo-transferencia/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de transferência alterado com sucesso!",
                            life: 3000,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPut({ url: `/transferencias/tipo-transferencia/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const selecionaCentro = (valor) => {
        setFieldValue("mesmo_centro_estocagem", valor);
        if (valor) setFieldValue("mesmo_estoque", valor);
    };

    const selecionaMatriz = (valor) => {
        setFieldValue("para_matriz", valor);
        if (valor) setFieldValue("para_filial", false);
    };

    const selecionaFilial = (valor) => {
        setFieldValue("para_filial", valor);
        if (valor) setFieldValue("para_matriz", false);
    };

    const resetarIndicadores = () => {
        resetForm();
    };

    const voltarParaListagem = () => {
        history.push("/estoque/cadastros/tipo-transferencia");
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Tipo de Transferência" : "Manutenção de Tipo de Transferência"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-10 p-md-12">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            autoFocus
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo_sku_origem" label="Tipo do produto de origem" obrigatorio />
                        <Dropdown
                            id="tipo_sku_origem"
                            name="tipo_sku_origem"
                            placeholder="Selecione..."
                            options={TIPO_SKU_ESTOCADO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_sku_origem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_sku_origem })}
                        />
                        {formik.errors.tipo_sku_origem && (
                            <small className="p-error">{formik.errors.tipo_sku_origem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo_sku_destino" label="Tipo do produto de destino" obrigatorio />
                        <Dropdown
                            id="tipo_sku_destino"
                            name="tipo_sku_destino"
                            placeholder="Selecione..."
                            options={TIPO_SKU_ESTOCADO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_sku_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_sku_destino })}
                        />
                        {formik.errors.tipo_sku_destino && (
                            <small className="p-error">{formik.errors.tipo_sku_destino}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="mesmo_estoque" label="Mesmo estoque" />
                        <SelectButton
                            value={formik.values.mesmo_estoque}
                            options={options}
                            disabled={!!formik.values.mesmo_centro_estocagem}
                            onChange={(e) => setFieldValue("mesmo_estoque", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="mesmo_centro_estocagem" label="Mesmo centro de estocagem" />
                        <SelectButton
                            value={formik.values.mesmo_centro_estocagem}
                            options={options}
                            onChange={(e) => selecionaCentro(e.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="tipo_ce_origem" label="Tipo de centro de estocagem de origem" obrigatorio />
                        <Dropdown
                            id="tipo_ce_origem"
                            name="tipo_ce_origem"
                            placeholder="Selecione..."
                            options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_ce_origem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_ce_origem })}
                        />
                        {formik.errors.tipo_ce_origem && (
                            <small className="p-error">{formik.errors.tipo_ce_origem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="tipo_ce_destino" label="Tipo do centro de estocagem de destino" obrigatorio />
                        <Dropdown
                            id="tipo_ce_destino"
                            name="tipo_ce_destino"
                            placeholder="Selecione..."
                            options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_ce_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_ce_destino })}
                        />
                        {formik.errors.tipo_ce_destino && (
                            <small className="p-error">{formik.errors.tipo_ce_destino}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="mesmo_cnpj_cpf" label="Mesmo Cnpj/CPF?" />
                        <SelectButton
                            value={formik.values.mesmo_cnpj_cpf}
                            options={options}
                            onChange={(e) => setFieldValue("mesmo_cnpj_cpf", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="mesma_raiz_cnpj" label="Mesma raiz de Cnpj?" />
                        <SelectButton
                            value={formik.values.mesma_raiz_cnpj}
                            options={options}
                            onChange={(e) => setFieldValue("mesma_raiz_cnpj", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="para_matriz" label="Para matriz?" />
                        <SelectButton
                            value={formik.values.para_matriz}
                            disabled={formik.values.para_filial}
                            options={options}
                            onChange={(e) => selecionaMatriz(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="para_filial" label="Para filial?" />
                        <SelectButton
                            value={formik.values.para_filial}
                            disabled={formik.values.para_matriz}
                            options={options}
                            onChange={(e) => selecionaFilial(e.value)}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button icon={MAKO_ICONS.GRAVAR} label="Gravar" type="submit" className="p-button-info" />
                    <Button
                        icon={MAKO_ICONS.DESFAZER}
                        label="Resetar indicadores"
                        type="button"
                        className="p-button-warning"
                        onClick={resetarIndicadores}
                    />
                    <Button
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
