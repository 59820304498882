import React, { useEffect } from "react";

import { useFormik } from "formik";

import { InputText } from "primereact/inputtext";

import {
    MakoDropdownCompetenciaFinanceira,
    MakoDropdownHistoricoPadrao,
    MakoInputPerfil,
} from "@/components/MakoInputs";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

export const Visualizar = ({ recebimento = {} }) => {
    const { values, setValues, setFieldValue } = useFormik({
        initialValues: {},
    });

    useEffect(() => {
        if (recebimento?.id) {
            let receber = parseFloat(recebimento.valor) - parseFloat(recebimento.valor_recebido);
            if (receber < 0) receber = 0;
            setValues({
                ...recebimento,
                competencia: recebimento.competencia?.id,
                empresa: recebimento.empresa?.id,
                para_receber: parseFloat(recebimento.valor) - parseFloat(recebimento.valor_recebido),
                template_rateio: recebimento.template_rateio?.id,
                forma_recebimento: recebimento.forma_recebimento?.id,
                somatorios_taxas: parseFloat(recebimento.multa) + parseFloat(recebimento.juros),
            });
        }
    }, [recebimento, setValues]);

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="empresa" label="Empresa" />
                    <MakoDropdownEmpresas id="empresa" name="empresa" value={values.empresa} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="sacado" label="Sacado" />
                    <MakoInputPerfil
                        id="sacado"
                        name="devedor"
                        disabled
                        somenteAtivo
                        query="id,nome,identificacao,tipo_pessoa"
                        value={values.devedor}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="conta-financeira" label="Conta financeira" />
                    <Dropdown
                        id="conta-financeira"
                        name="conta_financeira"
                        buscar={!!values.empresa}
                        url={`/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta__in=2,3`}
                        optionValue="id"
                        optionLabel="descricao"
                        disabled
                        value={values.conta_financeira}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="venda" label="Número da venda" />
                    <InputText id="venda" disabled value={values.venda} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="forma-recebimento" label="Forma" />
                    <Dropdown
                        id="forma-recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                        disabled
                        optionValue="id"
                        optionLabel="descricao"
                        value={values.forma_recebimento}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="documento" label="Documento" />
                    <InputText id="documento" name="documento" disabled value={values.documento} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="historico" label="Histórico" />
                    <MakoDropdownHistoricoPadrao
                        filtros={{ tipo: "R" }}
                        id="historico"
                        name="historico_padrao"
                        disabled
                        value={values.historico_padrao}
                        setFieldValueForm={setFieldValue}
                        showButtonNew={false}
                        inputTextProps={{
                            value: values.historico_original,
                            disabled: true,
                        }}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="competencia" label="Competência financeira" />
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia"
                        name="competencia"
                        disabled={!!values.id}
                        value={values.competencia}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-emissao" label="Data de emissão" />
                    <MakoCalendar id="data-emissao" name="data_emissao" disabled value={values.data_emissao} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-lancamento" label="Data de lançamento" />
                    <MakoCalendar id="data-lancamento" name="data_lancamento" value={values.data_lancamento} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="vencimento" label="Data vencimento" />
                    <MakoCalendar id="vencimento" name="vencimento" value={values.vencimento} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_recebimento" label="Data recebimento" />
                    <MakoCalendar
                        id="data_recebimento"
                        name="data_recebimento"
                        value={values.data_recebimento}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="template-rateio" label="Template de rateio" />
                    <Dropdown
                        id="template-rateio"
                        name="template_rateio"
                        buscar={!!values.empresa}
                        url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${values.empresa}`}
                        disabled
                        optionValue="id"
                        optionLabel="descricao"
                        value={values.template_rateio}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="origem-fracionamento" label="Origem fracionamento" />
                    <InputText
                        id="origem-fracionamento"
                        name="origem_fracionamento"
                        disabled
                        value={values.origem_fracionamento}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="plano-orcamentario" label="Plano orçamentário" />
                    <Dropdown
                        id="plano-orcamentario"
                        name="plano_orcamentario"
                        buscar={!!values.empresa}
                        disabled
                        url={`/financeiro/planos-orcamentarios/?query={id,descricao}&perfil=${values.empresa}`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={values.plano_orcamentario}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={values.valor} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor_recebido" label="Recebido" />
                    <MakoInputMoeda
                        id="valor_recebido"
                        name="valor_recebido"
                        disabled
                        valueMoeda={values.valor_recebido}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="para_receber" label="A receber" />
                    <MakoInputMoeda id="para_receber" name="para_receber" disabled valueMoeda={values.para_receber} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="juros" label="Juros" />
                    <MakoInputMoeda id="juros" name="juros" disabled valueMoeda={values.juros} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="multa" label="Multa" />
                    <MakoInputMoeda id="multa" name="multa" disabled valueMoeda={values.multa} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="somatorios_taxas" label="Multa + Juros" />
                    <MakoInputMoeda
                        id="somatorios_taxas"
                        name="somatorios_taxas"
                        disabled
                        valueMoeda={values.somatorios_taxas}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="dias_atraso" label="Dias" />
                    <InputText id="dias_atraso" name="dias_atraso" disabled value={values.dias_atraso} />
                </div>
            </div>
        </div>
    );
};
