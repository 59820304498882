import React, { forwardRef, memo, useCallback, useRef, useState } from "react";
import { formatarCasasDecimais } from "@/assets/util/util";
import MakoListagem from "@/components/MakoListagem";
import { useFormik } from "formik";
import classNames from "classnames";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import useToast from "@/hooks/useToast";
import useTransferencia from "@/hooks/useTransferencia";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";
import { Delete } from "@/components/Delete";
import { RequisicaoTransferenciaModal } from "./modal/requisicaoTransferencia";
import { BlockUI } from "@/components/BlockUI";
import useEmpresa from "@/hooks/useEmpresa";
import { Menu } from "primereact/menu";
import { MakoHistoricoCustos } from "@/components/MakoHistoricoCustos";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";
import { ModalLoteSKU } from "./modal/ModalLoteSKU";
import { ModalSaldoRegulador } from "./modal/SaldoRegulador";

export const ItensTransferenciaForm = () => {
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const listagemRef = useRef(null);
    const modalReq = useRef(null);
    const saldoRef = useRef(null);
    const { showError, showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { handleItens, transferencia } = useTransferencia();
    const [max, setMax] = useState(null);
    const menuRef = useRef(null);
    const deleteRef = useRef(null);
    const modalLoteSkuRef = useRef(null);
    const { verifyPermission } = useAuth();
    const { httpGet } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            sku: null,
            quantidade: 1,
            valor_gerencial: 0,
            valor_fiscal: 0,
            operacao_fiscal: null,
            inf_complementares: "",
            cubagem: 1,
            requisicao: null,
            largura: 1,
            altura: 1,
            comprimento: 1,
            unidade_medida: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object().required("O campo é obrigatório."),
                unidade_medida: Yup.number().required("O campo é obrigatório."),
                valor_fiscal: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um 'custo fiscal' válido."),
                valor_gerencial: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um 'custo gerencial' válido."),
                quantidade: Yup.number().min(0).required("O campo é obrigatório."),
                inf_complementares: Yup.string().max(100).nullable(),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleItens({
                ...values,
                valor_gerencial: parseFloat(values.valor_gerencial).toFixed(2),
                valor_fiscal: parseFloat(values.valor_fiscal).toFixed(2),
            });

            if (status !== 200 && status !== 201)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            else {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O item foi cadastrado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                resetForm();
                setMax(null);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    const editarProduto = (data) => {
        if (data.requisicao) setMax(data.quantidade);
        setValues({ ...data, unidade_medida: data.unidade_medida.id });
    };

    const itensMenu = [
        {
            label: "Remover",
            icon: "pi pi-trash",
            disabled: !verifyPermission([permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EXCLUIR]),
            command: () => deleteRef.current?.handleClick(),
        },
        {
            label: "Lote / Nº de série",
            icon: "pi pi-tags",
            disabled: itemSelecionado?.sku.lote_serial === "-",
            command: () => modalLoteSkuRef.current?.abrirModal(itemSelecionado),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => editarProduto(rowData)}
                />
                <Button
                    icon="pi pi-cog"
                    rounded
                    className="p-mb-1"
                    onClick={(e) => {
                        menuRef.current?.toggle(e);
                        setItemSelecionado(rowData);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { minWidth: "100px" } },
        { field: "requisicao", header: "Requisição", style: { minWidth: "50px" } },
        { field: "sku.descricao_reduzida", header: "Descrição da mercadoria ou produto", style: { minWidth: "300px" } },
        {
            field: "unidade_medida.nome",
            header: "Unidade",
            style: { minWidth: "100px" },
        },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { minWidth: "100px" },
            action: (e) => formatarCasasDecimais(e.cubagem, 5),
        },
        { field: "quantidade", header: "Qtd.", style: { minWidth: "100px" } },
        {
            field: "valor_gerencial",
            header: "Valor gerencial (R$)",
            style: { minWidth: "150px" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "100px" },
            action: (rowData) => actionBodyTemplate(rowData),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const formatarUnidades = useCallback(
        (results) => {
            if (results?.length === 1) setFieldValue("unidade_medida", results[0].unidade.id);
            return results.map((e) => e.unidade);
        },
        [setFieldValue]
    );

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
    };

    const selecionaItem = useCallback(
        async (e) => {
            setFieldValue("sku", e);
            if (e instanceof Object) {
                const normalHandlers = {
                    200: ({ data }) => {
                        if (data?.results?.length > 0) {
                            const { quantidade, valor } = data.results[0];
                            const custo = Math.abs(parseFloat(valor) / parseFloat(quantidade));
                            if (custo) {
                                setFieldValue("valor_fiscal", custo);
                            } else {
                                setFieldValue("valor_fiscal", 0);
                            }
                        } else {
                            setFieldValue("valor_fiscal", 0);
                        }
                    },
                };

                await httpGet(
                    {
                        url: `/produtos/saldos-sku?sku=${e.id}&centro_estocagem=${transferencia.ce_origem}&centro_estocagem__tipo=N&valor_exclude=0&limit=1`,
                    },
                    normalHandlers
                );

                const consolidadoHandlers = {
                    200: async ({ data }) => {
                        let custo = 0;
                        if (data?.results?.length > 0) {
                            const { quantidade, valor } = data.results[0];
                            if (quantidade !== 0) custo = Math.abs(parseFloat(valor) / parseFloat(quantidade));
                        }
                        if (custo === 0) {
                            const handlers = {
                                200: ({ data }) => {
                                    if (data?.results?.length > 0) custo = data.results[0].custo_base;
                                },
                            };

                            await httpGet(
                                { url: `/produtos/precos-sku?sku=${e.id}&order_by=-ultimo_calculo` },
                                handlers
                            );
                        }
                        setFieldValue("valor_gerencial", custo);
                    },
                };

                await httpGet(
                    {
                        url: `/produtos/saldos-sku?sku=${e.id}&centro_estocagem=${transferencia.ce_origem}&centro_estocagem__tipo=C&limit=1&consolidado=true&valor_exclude=0`,
                    },
                    consolidadoHandlers
                );
            }
        },
        [setFieldValue, transferencia.ce_origem, httpGet]
    );

    return (
        <div className="p-col-12">
            <BlockUI
                blocked={
                    transferencia?.status?.id === "E" ||
                    transferencia?.status?.id === "F" ||
                    transferencia?.status?.id === "C" ||
                    transferencia?.status?.id === "R"
                }
                template={
                    <b style={{ fontSize: "3rem" }}>
                        A transferência foi{" "}
                        {transferencia?.status?.id === "E"
                            ? "enviada"
                            : transferencia?.status?.id === "F"
                            ? "finalizada"
                            : "cancelada"}
                        .
                    </b>
                }
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <MakoBuscaSkuPersonalizada
                                id="sku"
                                name="sku"
                                skuValue={formik.values.sku}
                                skuChange={(e) => selecionaItem(e)}
                                skuError={formik.errors.sku}
                                exibeDimensoes
                                alturaValue={formik.values.altura}
                                disabledBusca={formik.values.id}
                                onChangeAltura={(e) => setFieldValue("altura", e)}
                                larguraValue={formik.values.largura}
                                onChangeLargura={(e) => setFieldValue("largura", e)}
                                comprimentoValue={formik.values.comprimento}
                                onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                                dimensaoValue={formik.values.cubagem}
                                onChangeDimensao={onChangeDimensao}
                                exibirVisualizacaoSku
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="unidade_medida">Unidade de medida *</label>
                            <Dropdown
                                id="unidade_medida"
                                name="unidade_medida"
                                url={`/produtos/unidades-medida-sku?${
                                    formik.values.sku?.id
                                        ? `sku__id=${formik.values.sku?.id}&tipo_mov_und_medida=T`
                                        : "sku__id=0"
                                }`}
                                optionValue="id"
                                optionLabel="nome"
                                aposBuscar={formatarUnidades}
                                value={formik.values.unidade_medida}
                                onChange={formik.handleChange}
                                disabled={!formik.values.sku?.id}
                                className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                            />
                            {formik.errors.unidade_medida && (
                                <small className="p-error">{formik.errors.unidade_medida}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="quantidade">Quantidade *</label>
                            <MakoInputQuantidadeSku
                                id="quantidade"
                                name="quantidade"
                                permiteFracionario={!!formik.values.sku?.permite_fracionamento}
                                value={formik.values.quantidade}
                                max={max}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.quantidade })}
                            />
                            {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_fiscal">Custo contábil *</label>
                            <div className="p-inputgroup">
                                <MakoInputMoeda
                                    id="valor_fiscal"
                                    name="valor_fiscal"
                                    valueMoeda={formik.values.valor_fiscal}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_fiscal })}
                                />
                                <MakoHistoricoCustos
                                    sku={formik.values?.sku?.id}
                                    tipo="N"
                                    centro_estocagem={transferencia.ce_origem}
                                />
                            </div>
                            {formik.errors.valor_fiscal && (
                                <small className="p-error">{formik.errors.valor_fiscal}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_gerencial">Custo gerencial *</label>
                            <div className="p-inputgroup">
                                <MakoInputMoeda
                                    id="valor_gerencial"
                                    name="valor_gerencial"
                                    valueMoeda={formik.values.valor_gerencial}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_gerencial })}
                                />
                                <MakoHistoricoCustos
                                    sku={formik.values?.sku?.id}
                                    tipo="C"
                                    centro_estocagem={transferencia.ce_origem}
                                />
                            </div>

                            {formik.errors.valor_gerencial && (
                                <small className="p-error">{formik.errors.valor_gerencial}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="operacao_fiscal">Operação fiscal</label>
                            <Dropdown
                                id="operacao_fiscal"
                                name="operacao_fiscal"
                                placeholder={"Selecione uma operação fiscal"}
                                url={`/fiscal/operacoes-fiscais?limit=1000&empresa=${empresaSelecionadaId}`}
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="id,descricao"
                                value={formik.values.operacao_fiscal}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="inf_complementares">Informações complementares</label>
                            <InputTextarea
                                id="inf_complementares"
                                name="inf_complementares"
                                value={formik.values.inf_complementares}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.inf_complementares })}
                                rows={2}
                                autoComplete="off"
                                autoResize
                            />
                            {formik.errors.inf_complementares && (
                                <small className="p-error">{formik.errors.inf_complementares}</small>
                            )}
                        </div>
                    </div>
                    <b>* Campos obrigatórios</b>
                    <div className="p-grid p-mt-4 p-ml-1">
                        <Button icon="pi pi-save" type="submit" label="Gravar item" />
                        <Button
                            label="Requisições de transferência"
                            icon="pi pi-book"
                            type="button"
                            className="p-button-help p-ml-2"
                            onClick={() => modalReq.current?.abrirModal()}
                        />
                        <Button
                            label="Incluir conforme saldo regulador"
                            icon="pi pi-bolt"
                            type="button"
                            className="p-button-help p-ml-2"
                            onClick={() => saldoRef.current?.abrirModal()}
                        />
                    </div>
                </form>
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={`/transferencias/itens-transferencia-mercadoria?transferencia=${transferencia.id}`}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                            scrollHeight: "400px",
                            frozenWidth: "0.5vw",
                        }}
                    />
                </div>
            </BlockUI>
            <ModalLoteSKU ref={modalLoteSkuRef} centroEstocagem={transferencia?.ce_origem} />
            <RequisicaoTransferenciaModal aposSalvar={aposSalvar} ref={modalReq} />
            <ModalSaldoRegulador
                onFechar={() => {
                    listagemRef.current?.buscarDados();
                }}
                ref={saldoRef}
                transferenciaId={transferencia?.id}
            />
            <Delete
                ref={deleteRef}
                url="/transferencias/itens-transferencia-mercadoria/"
                objetoId={itemSelecionado?.id}
                exigeConfirmacao
                hideLoad
                msgConfirmacao={
                    <span>
                        Deseja realmente excluir o item <b>{itemSelecionado?.sku.descricao_reduzida}</b>?
                    </span>
                }
                msgToastErroExclusao="O item da transferência não pode ser excluído."
                onDelete={() => listagemRef.current?.buscarDados()}
            />
        </div>
    );
};

export default memo(forwardRef(ItensTransferenciaForm));
