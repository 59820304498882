import { BASE_KEY } from "./storage";

const KEY = `${BASE_KEY}_FILTROS`;

export const key_filtros = {
    GESTAO_PESSOAS_AVANCADO: `${KEY}: gestao_pessoas_avancado`,
    GESTAO_PESSOAS_TABELA: `${KEY}: gestao_pessoas_tabela`,
    PRODUTOS_PRODUTOS_SKU_AVANCADO: `${KEY}: produtos_produtos_sku_avancado`,
    PRODUTOS_PRODUTOS_SKU_TABELA: `${KEY}: produtos_produtos_sku_tabela`,
    PRODUTOS_PRODUTOS_ITEM_TABELA: `${KEY}: produtos_produtos_item_tabela`,
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS: `${KEY}: financeiro_financeiro_recebimentos`,
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_PERIODO: `${KEY}: financeiro_financeiro_recebimentos_periodo`,
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_AVANCADO: `${KEY}: financeiro_financeiro_pagamentos_avancado`,
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_PERIODO: `${KEY}: financeiro_financeiro_pagamentos_periodo`,
    VENDAS_VENDA_AVANCADO: `${KEY}: vendas_venda_avancado`,
    VENDAS_VENDA_TABELA: `${KEY}: vendas_venda_tabela`,
    VENDAS_VENDA_CREDIARIO: `${KEY}: vendas_venda_crediario`,
    FISCAL_NOTA_FISCAL: `${KEY}: fiscal_nota_fiscal`,
    MOVIMENTACOES_REQUISICAO_TRANSFERENCIA: `${KEY}: movimentacoes_requisicao_transferencia`,
    SERVICOS_CLAUSULAS_CONTRATO: `${KEY}: servicos_clausulas_contrato`,
};
