import React, { useCallback, useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import useRequisicao from "@/hooks/useRequisicao";

export const FormCancelamento = () => {
    const { requisicao, updateRequisicao } = useRequisicao();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            motivo_cancelamento: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                motivo_cancelamento: Yup.string().max(60, "Informe no máximo 60 caracteres."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateRequisicao({ ...requisicao, ...values });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleReset = useCallback(() => {
        resetForm();
        if (requisicao?.motivo_cancelamento) {
            updateRequisicao({
                ...requisicao,
                motivo_cancelamento: null,
            });
        }
    }, [requisicao, resetForm, updateRequisicao]);

    useEffect(() => {
        if (requisicao?.motivo_cancelamento) setValues({ motivo_cancelamento: requisicao.motivo_cancelamento });
    }, [requisicao, setValues]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="motivo_cancelamento">Motivo cancelamento</label>
                        <InputTextarea
                            id="motivo_cancelamento"
                            name="motivo_cancelamento"
                            value={formik.values.motivo_cancelamento}
                            onChange={formik.handleChange}
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.motivo_cancelamento })}
                            autoResize
                            rows={3}
                            maxLength={60}
                        />
                        {formik.errors.motivo_cancelamento && (
                            <small className="p-error">{formik.errors.motivo_cancelamento}</small>
                        )}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
