import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Calendar } from "primereact/calendar";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

import { dataToStr, parseData } from "@/assets/util/datas";
import { isAfter } from "date-fns";
import useHttp from "@/hooks/useHttp";

export const FormFechar = ({ caixaMov, empresaId, successCalback = () => {}, cancelCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            data_fechamento: new Date(),
            hora_fechamento: new Date(),
            conferido_por: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_fechamento: Yup.date()
                    .required("O campo 'data fechamento' é obrigatório")
                    .typeError("Seleciona um data de fechamento."),
                hora_fechamento: Yup.date()
                    .required("O campo 'hora fechamento' é obrigatório")
                    .typeError("Seleciona uma hora de fechamento."),
                conferido_por: Yup.object()
                    .required("O campo 'conferido por' é obrigatório.")
                    .typeError("Informe quem conferiu o caixa."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const _dateFechamento = parseData(
                `${dataToStr(values.data_fechamento, "yyyy-MM-dd")} ${dataToStr(values.hora_fechamento, "HH:mm:ss")}`
            );
            const _dateAbertura = parseData(`${caixaMov.data_abertura} ${caixaMov.hora_abertura}`);

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Caixa fechado com sucesso!",
                        life: 1500,
                    });
                    successCalback();
                },
            };

            if (isAfter(_dateFechamento, _dateAbertura)) {
                const caixaMovi = {
                    conferido_por: values.conferido_por.id,
                    datahora_fechamento: dataToStr(_dateFechamento, "yyyy-MM-dd HH:mm:ss"),
                };

                showLoading();
                await httpPost(
                    {
                        url: `/financeiro/caixas/${caixaMov.id}/operar/fechar`,
                        body: caixaMovi,
                    },
                    handlers
                );
                hideLoading();
            } else throw new Error("Erro data fechamento e posteior a de abertura");
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError();
            }
        }
    }

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="data_fechamento" label="Data Fechamento" obrigatorio />
                    <MakoCalendar
                        id="data_fechamento"
                        name="data_fechamento"
                        value={formik.values.data_fechamento}
                        minDate={caixaMov?.data_abertura}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_fechamento })}
                    />
                    {formik.errors.data_fechamento && (
                        <small className="p-error">{formik.errors.data_fechamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="hora_fechamento" label="Hora Fechamento" obrigatorio />
                    <Calendar
                        id="hora_fechamento"
                        name="hora_fechamento"
                        timeOnly
                        showTime
                        hourFormat="24"
                        value={formik.values.hora_fechamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora_fechamento })}
                    />
                    {formik.errors.hora_fechamento && (
                        <small className="p-error">{formik.errors.hora_fechamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="conferido_por" label="Conferido por" obrigatorio />
                    <MakoInputPerfil
                        id="conferido_por"
                        name="conferido_por"
                        empresa={empresaId}
                        value={formik.values.conferido_por}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conferido_por })}
                    />
                    {formik.errors.conferido_por && <small className="p-error">{formik.errors.conferido_por}</small>}
                </div>
            </div>
            <MakoActionsButtons>
                <Button label="Gravar" className="p-button-success" type="submit" loading={loading} />
                <Button label="Cancelar" className="p-button-danger" onClick={cancelCallback} loading={loading} />
            </MakoActionsButtons>
        </form>
    );
};
