import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";

import { ModalFiltroAvancadoPessoas } from "./ModalFiltroAvancado";
import { ModalMotivoBaixa } from "./ModalMotivoBaixa";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { PageBase } from "@/components/PageBase";
import { BUTTON_LISTAGEM_EDITAR } from "@/assets/constants/presets";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const BASE_URL = "/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,ativo}";

export const PessoasPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.GESTAO_PESSOAS_AVANCADO);
    const [perfil, setPerfil] = useState(null);
    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}&${filtroString}`;
        return BASE_URL;
    });

    const history = useHistory();
    const listagemRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const motivobaixaRef = useRef(null);
    const menuRef = useRef(null);

    const { httpPatch } = useHttp();
    const { showWarning, showError, showSuccess } = useToast();

    useClearRefs(listagemRef, modalFiltroAvancadoRef, motivobaixaRef, menuRef);

    const totalizadorFiltros = useMemo(() => {
        if (!filtros) return 0;
        return Object.keys(filtros).length;
    }, [filtros]);

    const [formatDocumento, limparDocumento] = useFormatCnpjCpf();

    const TIPO_PESSOA = [
        { value: "PF", label: "Pessoa Física" },
        { value: "PJ", label: "Pessoa Jurídica" },
        { value: "PE", label: "Pessoa Estrangeira" },
    ];

    const STATUS_PERFIL = [
        { value: true, label: "Ativo" },
        { value: false, label: "Desativado" },
    ];

    const itens = [
        {
            label: "Escolha o tipo de pessoa para cadastrar",
            items: [
                {
                    label: "Pessoa Física",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Física" },
                        }),
                },
                {
                    label: "Pessoa Jurídica",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Jurídica" },
                        }),
                },
                {
                    label: "Pessoa Estrangeira",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Estrangeira" },
                        }),
                },
            ],
        },
    ];

    const formatarDadosPessoa = useCallback(
        (data) => {
            let pessoas = [];
            data.forEach((pessoa) => {
                if (pessoa.tipo_pessoa === "PF") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Física",
                        nome: pessoa.nome,
                        identificacao: formatDocumento(pessoa.identificacao),
                        ativo: pessoa.ativo,
                    });
                } else if (pessoa.tipo_pessoa === "PJ") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Jurídica",
                        nome: pessoa.nome,
                        identificacao: formatDocumento(pessoa.identificacao),
                        ativo: pessoa.ativo,
                    });
                } else if (pessoa.tipo_pessoa === "PE") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Estrangeira",
                        nome: pessoa.nome,
                        identificacao: pessoa.identificacao,
                        ativo: pessoa.ativo,
                    });
                }
            });

            return pessoas;
        },
        [formatDocumento]
    );

    const clearFiltros = () => {
        setUrl(BASE_URL);
        removerFiltro();
        modalFiltroAvancadoRef.current?.limparFiltros();
    };

    const painelEsquerdoTabela = (
        <>
            <Menu model={itens} popup ref={menuRef} id="popup_menu" />
            <Button
                label="Nova"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                aria-controls="popup_menu"
                aria-haspopup
                onClick={(e) => menuRef.current.toggle(e)}
            />
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtros"
                icon={MAKO_ICONS.LIMPAR_FORM}
                className="p-button-warning p-mr-2"
                disabled={!filtros}
                onClick={clearFiltros}
            />
        </>
    );

    const desativarPerfil = async (perfil) => {
        setPerfil(perfil);
        motivobaixaRef.current?.abrirModal();
    };

    const validarCadastro = useCallback(
        async (cadastro) => {
            const handlers = {
                200: () => {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Cadastro validado com sucesso!",
                        life: 3000,
                    });
                },
                400: ({ err }) => {
                    const { cadastro_validado, erros } = err;
                    if (cadastro_validado[0]) {
                        showWarning({
                            summary: "Informações pendentes",
                            detail: `O cadastro não pode ser validado. Motivo(s): ${erros.join("; ")}`,
                            life: 10000,
                        });
                    } else showError();
                },
            };

            await httpPatch({ url: `/pessoas/perfis/${cadastro.id}/`, body: { situacao: "C" } }, handlers);
        },
        [httpPatch, showError, showWarning, showSuccess]
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_PESSOAS_PESSOAS_EDITAR]}
                    componente={Button}
                    {...BUTTON_LISTAGEM_EDITAR}
                    onClick={() => editarCadastroPessoa(rowData)}
                />
                <ButtonListagemOpcoes
                    url="/pessoas/perfis/"
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="o"
                    entidade="perfil"
                    desativarOptions={{ onClick: () => desativarPerfil(rowData) }}
                    deleteOptions={false}
                    visualizarOptions={null}
                    onAction={() => listagemRef.current?.buscarDados()}
                    extraOptionProps={{
                        label: "Validar",
                        icon: "pi pi-check-square",
                        command: () => validarCadastro(rowData),
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const IdentificacaoFiltroTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                keyfilter="num"
                onChange={(e) => options.filterCallback(limparDocumento(e.target.value))}
            />
        );
    };

    const TipoPessoaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_PESSOA,
                }}
                options={options}
            />
        );
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS_PERFIL,
                }}
                options={options}
            />
        );
    };

    const editarCadastroPessoa = (pessoa) => {
        if (pessoa.tipo_pessoa === "Pessoa Física") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Física",
                    idPerfil: pessoa.id,
                },
            });
        } else if (pessoa.tipo_pessoa === "Pessoa Jurídica") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Jurídica",
                    idPerfil: pessoa.id,
                },
            });
        } else if (pessoa.tipo_pessoa === "Pessoa Estrangeira") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Estrangeira",
                    idPerfil: pessoa.id,
                },
            });
        }
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "6%" },
        },
        {
            field: "nome",
            header: "Nome / Razão Social",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "tipo_pessoa",
            header: "Tipo",
            style: { width: "12%" },
            filter: true,
            filterElement: TipoPessoaFiltroTemplate,
        },
        {
            field: "identificacao",
            header: "CPF / CNPJ / Passaporte",
            filter: true,
            filterElement: IdentificacaoFiltroTemplate,
            style: { width: "16%" },
        },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "8%" },
            align: "center",
            filter: true,
            filterElement: StatusFiltroTemplate,
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
        },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const onFilter = (e) => {
        setUrl(e);
    };

    const onFinish = () => {
        listagemRef?.current.buscarDados();
    };

    return (
        <PageBase>
            <ModalFiltroAvancadoPessoas
                ref={modalFiltroAvancadoRef}
                onFilter={onFilter}
                baseUrl={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ModalMotivoBaixa ref={motivobaixaRef} perfil={perfil} onFinish={onFinish} />
            <MakoListagem
                ref={listagemRef}
                titulo="Cadastro de Pessoas"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={url}
                aposPesquisar={formatarDadosPessoa}
                keyFiltrosStorage={key_filtros.GESTAO_PESSOAS_TABELA}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    tipo_pessoa: { value: null, matchMode: "equals" },
                    identificacao: { value: null, matchMode: "equals" },
                    ativo: { value: true, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
