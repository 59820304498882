import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const EstagioVendaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/cadastros/estagios-vendas/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/estagios-vendas/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_SEQUENCIA_MANUTENCAO]}
                    componente={Button}
                    icon="pi pi-sort"
                    className="p-button-rounded p-button-info"
                    tooltip="Alterar fluxo."
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/estagios-vendas/sequencia",
                            state: {
                                estagioVenda: rowData,
                            },
                        })
                    }
                />
                <ButtonListagemOpcoes
                    url={"/vendas/estagios-vendas"}
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="o"
                    entidade="estágio de venda"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja mesmo apagar o estágio de venda <b>{rowData.descricao}</b>?
                            </span>
                        ),
                    }}
                    visualizarOptions={false}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const ativoBodyTemplate = (rowData) => {
        if (rowData.ativo) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "descricao", header: "Descrição" },
        { field: "ativo", header: "Ativo", style: { width: "15%" }, action: (e) => ativoBodyTemplate(e) },
        { field: "action", header: "Ações", style: { width: "15%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Fluxos de vendas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/vendas/estagios-vendas/"
                configTabela={{ lazy: true, paginator: true }}
            />
        </PageBase>
    );
};
