import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { dataToStr } from "@/assets/util/datas";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { SIM_NAO_BOOLEAN, TIPO_ESCOPO_COMISSAO_CHOICE } from "@/assets/constants/constants";

import * as Yup from "yup";
import classNames from "classnames";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { SelectButton } from "primereact/selectbutton";

const url = "/vendas/comissoes-vendas/";
const urlvoltar = "/vendas/cadastros/comissao-vendas";

export const ComissaoVendasForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPatch, httpPost } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();

    const comissaoVazia = {
        percent_mercadoria: 0,
        percent_servico: 0,
        percent_encargo: 0,
        descricao: "",
        vigencia_final: null,
        escopo: null,
        aplica_perfil: false,
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state ? props.location.state.comissaoVendas : comissaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                vigencia_final: Yup.date().nullable(),
                escopo: Yup.string()
                    .required("O campo 'escopo' é obrigatório.")
                    .typeError("Informe um 'escopo' válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Comissão de vendas cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Comissão de vendas cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    useEffect(() => {
        if (formik.values.escopo?.id) setFieldValue("escopo", formik.values.escopo.id);
    }, [setFieldValue, formik.values.escopo?.id]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova comissão de vendas" : "Manutenção de comissão de vendas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            tooltip="Descrição que será dada a comissão de vendas."
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="percent_mercadoria" label="% Mercadoria" obrigatorio />
                        <InputNumber
                            id="percent_mercadoria"
                            name="percent_mercadoria"
                            mode="decimal"
                            prefix="%"
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={5}
                            value={formik.values.percent_mercadoria}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.percent_mercadoria })}
                            autoComplete="off"
                        />
                        {formik.errors.percent_mercadoria && (
                            <small className="p-error">{formik.errors.percent_mercadoria}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="percent_servico" label="% Serviço" obrigatorio />
                        <InputNumber
                            id="percent_servico"
                            name="percent_servico"
                            mode="decimal"
                            prefix="%"
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={5}
                            value={formik.values.percent_servico}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.percent_servico })}
                            autoComplete="off"
                        />
                        {formik.errors.percent_servico && (
                            <small className="p-error">{formik.errors.percent_servico}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="percent_encargo" label="% Encargo" obrigatorio />
                        <InputNumber
                            id="percent_encargo"
                            name="percent_encargo"
                            mode="decimal"
                            prefix="%"
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={5}
                            value={formik.values.percent_encargo}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.percent_encargo })}
                            autoComplete="off"
                        />
                        {formik.errors.percent_encargo && (
                            <small className="p-error">{formik.errors.percent_encargo}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vigencia_final" label="Vigência final" />
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            value={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.vigencia_final,
                            })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="escopo" label="Escopo" />
                        <Dropdown
                            id="escopo"
                            placeholder="Selecione um escopo"
                            name="escopo"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.escopo}
                            optionLabel="label"
                            optionValue="id"
                            options={TIPO_ESCOPO_COMISSAO_CHOICE}
                            autoComplete="off"
                        />
                        {formik.errors.escopo && <small className="p-error">{formik.errors.escopo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aplica_perfil">Aplica em perfil</label>
                        <SelectButton
                            id="aplica_perfil"
                            name="aplica_perfil"
                            value={formik.values.aplica_perfil}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
