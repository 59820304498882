import React, { useCallback, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { ProdutoProvider } from "@/contexts/produtoContext";
import CustomFieldsForm from "./formCustomField";
import useProduto from "@/hooks/useProduto";
import DadosBasicosForm from "./formDadosBasicos";
import DetalhesForm from "./formDetalhes";
import CategoriaForm from "./formCategorias";
import CodigosForm from "./formCodigos";
import PrecosForm from "./formPrecos";
import UnidadeMedidaSkuForm from "./formUnidadeMedidaSku";
import FotosForm from "./formFotos";
import { ConfirmDialog } from "primereact/confirmdialog";

const urlvoltar = "/produto/produto/produtos";

const TabProdutos = ({ id, url, flagDescricao }) => {
    const { handlePreencherProduto, dadosBasicos, verificarCadastroGenerico } = useProduto();
    const [activeTab, setActiveTab] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const history = useHistory();

    const carregarProduto = useCallback(async () => {
        if (id) await handlePreencherProduto(id);
    }, [id, handlePreencherProduto]);

    useEffect(() => {
        carregarProduto();
    }, [carregarProduto, id]);

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const handleVoltar = async () => {
        if (await verificarCadastroGenerico()) setConfirm(true);
        else voltar();
    };

    const voltar = () => {
        history.push({
            pathname: urlvoltar,
            url: url,
            flagDescricao: flagDescricao,
        });
    };

    const fecharConfirm = () => {
        setConfirm(false);
    };

    return (
        <div className="p-grid">
            <ConfirmDialog
                visible={confirm}
                onHide={fecharConfirm}
                header="Confirmação"
                message={
                    <div>
                        <span>
                            Deseja realmente sair da interface sem ter cadastrado produtos, apenas dados genéricos?
                        </span>
                        <br />
                        <span className="p-error">
                            Caso queira visualizar este cadastro na listagem, deve-se utilizar a opção{" "}
                            <b>'Exibir Agrupado'</b>
                        </span>
                    </div>
                }
                icon="pi pi-info-circle p-mr-3"
                accept={voltar}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={fecharConfirm}
                rejectLabel="Não"
            />
            <div className="p-col-12">
                <h5>
                    Produtos e Mercadorias:
                    <b>{dadosBasicos?.id ? ` (Cód. Agrupador: ${dadosBasicos.id}) ${dadosBasicos.nome}` : " - Novo"}</b>
                </h5>
                <div className="card">
                    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                        <TabPanel header="Agrupador" leftIcon="pi pi-calendar">
                            <DadosBasicosForm />
                        </TabPanel>
                        <TabPanel header="Produtos" leftIcon="pi pi-book" disabled={!dadosBasicos?.id}>
                            <DetalhesForm />
                        </TabPanel>
                        <TabPanel header="Categorias" leftIcon="pi pi-th-large" disabled={!dadosBasicos?.id}>
                            <CategoriaForm />
                        </TabPanel>
                        <TabPanel header="Códigos" leftIcon="pi pi-align-justify" disabled={!dadosBasicos?.id}>
                            <CodigosForm />
                        </TabPanel>
                        <TabPanel header="Preços" leftIcon="pi pi-money-bill" disabled={!dadosBasicos?.id}>
                            <PrecosForm />
                        </TabPanel>
                        <TabPanel
                            header="Unidade de medida"
                            leftIcon="pi pi-sort-numeric-up"
                            disabled={!dadosBasicos?.id}
                        >
                            <UnidadeMedidaSkuForm />
                        </TabPanel>
                        <TabPanel leftIcon="pi pi-image" header="Fotos" disabled={!dadosBasicos?.id}>
                            <FotosForm />
                        </TabPanel>
                        <TabPanel
                            leftIcon="pi pi-align-center"
                            header="Campos personalizados"
                            disabled={!dadosBasicos?.id}
                        >
                            <CustomFieldsForm />
                        </TabPanel>
                    </TabView>

                    <div className="p-grid p-fluid p-justify-start" style={{ paddingTop: "3%" }}>
                        {activeTab > 0 ? (
                            <div className="p-col-6 p-md-2">
                                <Button
                                    label="Anterior"
                                    icon="pi pi-angle-left"
                                    className="p-button-info"
                                    disabled={!dadosBasicos?.id}
                                    onClick={() => alterarTab(-1)}
                                />
                            </div>
                        ) : null}
                        {activeTab < 6 ? (
                            <div className="p-col-6 p-md-2">
                                <Button
                                    label="Próximo"
                                    disabled={!dadosBasicos?.id}
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    className="p-button-info"
                                    onClick={() => alterarTab(1)}
                                />
                            </div>
                        ) : null}
                        <div className="p-col-4 p-md-2">
                            <Button label="Voltar" className="p-button-danger" onClick={() => handleVoltar()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TabProdutoContext = (props) => {
    const { id, flagDescricao, url } = props.location?.state;
    return (
        <ProdutoProvider>
            <TabProdutos id={id} flagDescricao={flagDescricao} url={url} />
        </ProdutoProvider>
    );
};
