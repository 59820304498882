import React, { forwardRef, useImperativeHandle } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Label } from "@/components/Label";
import { InputIdentificacao } from "@/components/InputIdentificacao";
import { DropdownEndereco } from "@/components/DropdownEndereco";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";

const Form = ({ perfilId, apenasLeitura, infoEmpresa, onFinish, onDelete }, ref) => {
    const { httpPost } = useHttp();
    const { showSuccess, showCustom } = useToast();

    const formik = useFormik({
        initialValues: {
            identificacao: "",
            nome: "",
            inscricao_estadual: "",
            endereco: null,
        },
        onSubmit: handleSubmit,
    });

    const setValues = (info) => {
        formik.setFieldValue("identificacao", info.identificacao);
        formik.setFieldValue("nome", info.nome_razao_social);
        formik.setFieldValue("inscricao_estadual", info.inscricao_estadual);
        formik.setFieldValue("endereco", info.endereco);
    };

    useImperativeHandle(ref, () => ({
        setValues,
        resetForm: formik.resetForm,
    }));

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'CPF / CNPJ' é obrigatório."),
                nome: Yup.string().required("O campo 'nome / razão social' é obrigatório."),
                endereco: Yup.number()
                    .required("O campo 'endereço' é obrigatório.")
                    .typeError("Selecione um endereço."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { endereco: endereco_id, identificacao, ...rest } = values;
            const body = {
                ...rest,
                endereco_id,
                cpf_cnpj: identificacao.replace(/[^\d]+/g, ""),
            };
            const handlers = {
                201: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Dados do certificado salvos com sucesso.",
                        life: 1500,
                    });
                    if (typeof onFinish === "function") onFinish(data);
                },
                error: ({ status, err }) => {
                    showCustom({
                        severity: status <= 499 ? "warn" : "error",
                        summary: status <= 499 ? "Opsss" : "Erro :(",
                        detail: err?.msg || "Desculpe, não foi possível processar a sua requisição.",
                        life: 3000,
                    });
                },
            };
            await httpPost({ url: `/pessoas/${perfilId}/certificado-digital/`, body }, handlers);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="identificacao" label="CPF / CNPJ" />
                    <InputIdentificacao
                        id="identificacao"
                        name="identificacao"
                        disabled={apenasLeitura}
                        value={formik.values.identificacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.identificacao })}
                    />
                    {formik.errors.identificacao && <small className="p-error">{formik.errors.identificacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="nome" label="Nome / Razão Social" />
                    <InputText
                        id="nome"
                        name="nome"
                        disabled={apenasLeitura}
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.nome })}
                    />
                    {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="inscricao-estadual" label="IE" />
                    <InputText
                        id="inscricao-estadual"
                        name="inscricao_estadual"
                        disabled={apenasLeitura}
                        value={formik.values.inscricao_estadual}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.inscricao_estadual })}
                    />
                    {formik.errors.inscricao_estadual && (
                        <small className="p-error">{formik.errors.inscricao_estadual}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="endereco" label="Endereço" />
                    <DropdownEndereco
                        id="endereco"
                        name="endereco"
                        perfilId={perfilId}
                        disabled={apenasLeitura}
                        value={formik.values.endereco}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.endereco })}
                    />
                    {formik.errors.endereco && <small className="p-error">{formik.errors.endereco}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="situacao" label="Situação" />
                    <InputText
                        id="situacao"
                        name="situacao"
                        disabled
                        value={infoEmpresa ? "Cadastrada" : "Não cadastrada"}
                    />
                </div>
            </div>
            {!apenasLeitura && (
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        icon="pi pi-plus"
                        label="Cadastrar"
                        severity="success"
                        disabled={!!infoEmpresa}
                    />
                    <BotaoDelete
                        label="Excluir"
                        url="/pessoas/:id:/certificado-digital/"
                        objetoId={perfilId}
                        disabled={!infoEmpresa || infoEmpresa.certificado_enviado}
                        exigeConfirmacao
                        rounded={false}
                        msgConfirmacao={<span>Deseja realmente desvincular a empresa da nuvem fiscal?</span>}
                        msgToastErroExclusao="A empresa não pôde ser desvinculada da nuvem fiscal."
                        onDelete={onDelete}
                    />
                </MakoActionsButtons>
            )}
        </form>
    );
};

export const FormEmpresa = forwardRef(Form);
