import React, { useEffect, memo, forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import useModeloContrato from "@/hooks/useModeloContrato";

const DadosBasicosForm = () => {
    const { handleDadosBasicos, dadosBasicos } = useModeloContrato();
    const { user } = useAuth();
    const { showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            grupo: "",
            titulo: "",
            data_criacao: null,
            usuario_criou: { id: user.id, nome: user.nome },
            situacao: { id: "E", descricao: "Elaboração" },
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                grupo: Yup.string().required("O campo 'grupo' é obrigatório."),
                titulo: Yup.string().required("O campo 'título' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleDadosBasicos(values);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        if (dadosBasicos?.id) setValues(dadosBasicos);
    }, [dadosBasicos, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label label="Grupo" obrigatorio />
                    <Dropdown
                        value={formik.values.grupo}
                        onChange={(e) => {
                            setFieldValue("grupo", e.value);
                            formik.handleChange(e);
                        }}
                        url="/servicos/grupos-modelos-contratos/"
                        optionValue="grupo"
                        optionLabel="grupo"
                        editable
                        showClear={false}
                        placeholder="Digite ou selecione um grupo"
                        autoFocus
                        className={classNames({ "p-invalid": formik.errors.grupo })}
                    />
                    {formik.errors.grupo && <small className="p-error">{formik.errors.grupo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <Label label="Título" obrigatorio />
                    <InputText
                        id="titulo"
                        name="titulo"
                        value={formik.values.titulo}
                        onChange={formik.handleChange}
                        placeholder="Informe o título do modelo de contrato"
                        className={classNames({ "p-invalid": formik.errors.titulo })}
                        autoFocus
                    />
                    {formik.errors.titulo && <small className="p-error">{formik.errors.titulo}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label label="Data de criação" />
                    <MakoCalendar
                        id="data_criacao"
                        name="data_criacao"
                        value={formik.values.data_criacao}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label label="Criado por" />
                    <InputText
                        id="usuario_criou"
                        name="usuario_criou"
                        value={formik.values.usuario_criou?.nome}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label label="Data de alteração" />
                    <MakoCalendar
                        id="data_alteracao"
                        name="data_alteracao"
                        value={formik.values.data_alteracao}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label label="Alterado por" />
                    <InputText
                        id="usuario_alterou"
                        name="usuario_alterou"
                        value={formik.values.usuario_alterou?.nome}
                        readOnly
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label label="Data de validação" />
                    <MakoCalendar
                        id="data_validacao"
                        name="data_validacao"
                        value={formik.values.data_validacao}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label label="Validado por" />
                    <InputText
                        id="usuario_validou"
                        name="usuario_validou"
                        value={formik.values.usuario_validou?.nome}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label label="Situação" />
                    <InputText
                        id="situacao"
                        name="situacao"
                        value={formik.values.situacao?.descricao}
                        readOnly
                        disabled
                    />
                </div>
            </div>
            <CamposObrigatorios />
            {!dadosBasicos?.id ? (
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Incluir dados básicos"
                            className="p-button-info"
                        />
                    </div>
                </div>
            ) : (
                <FormikAutoSave intervalo={500} autosave={dadosBasicos?.id} formik={formik} />
            )}
        </form>
    );
};

export default memo(forwardRef(DadosBasicosForm));
