import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { Calendar as MakoCalendar } from "@/components/Calendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { DJANGO_FILTERS_MATCHMODE, SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const INITIAL_VALUES = {
    id: "",
    credor: null,
    historico_padrao: null,
    documento: "",
    valor_minimo: null,
    valor_maximo: null,
    lancamento_inicial: null,
    lancamento_final: null,
    emissao_inicial: null,
    emissao_final: null,
    vencimento_inicial: null,
    vencimento_final: null,
    liquidacao_inicial: null,
    liquidacao_final: null,
    conta_financeira: null,
    forma_pagamento: null,
    competencia: null,
    cancelado: null,
    quitado: null,
    entrada: null,
};

const Modal = ({ onFilter, baseUrl, filtros, setFiltro, removerFiltro }, ref) => {
    const [visible, setVisible] = useState(false);

    const { setFieldValue, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                id: Yup.string().nullable().default(null),
                credor: Yup.object().nullable().default(null),
                historico_padrao: Yup.object().nullable().default(""),
                documento: Yup.string().nullable().default(""),
                valor_minimo: Yup.number().nullable().default(null),
                valor_maximo: Yup.number().nullable().default(null),
                lancamento_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                lancamento_final: Yup.date().nullable().typeError("Informe uma data válida."),
                emissao_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                emissao_final: Yup.date().nullable().typeError("Informe uma data válida."),
                vencimento_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                vencimento_final: Yup.date().nullable().typeError("Informe uma data válida."),
                liquidacao_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                liquidacao_final: Yup.date().nullable().typeError("Informe uma data válida."),
                conta_financeira: Yup.number().nullable().default(null),
                forma_pagamento: Yup.number().nullable().default(null),
                competencia: Yup.number().nullable().default(null),
            });

            await formSchema.validate(values, { abortEarly: false });

            const matchModes = {
                id: DJANGO_FILTERS_MATCHMODE.equals,
                credor: DJANGO_FILTERS_MATCHMODE.equals,
                historico_padrao: DJANGO_FILTERS_MATCHMODE.equals,
                documento: DJANGO_FILTERS_MATCHMODE.icontains,
                valor_minimo: DJANGO_FILTERS_MATCHMODE.gte,
                valor_maximo: DJANGO_FILTERS_MATCHMODE.lte,
                lancamento_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                lancamento_final: DJANGO_FILTERS_MATCHMODE.lte,
                emissao_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                emissao_final: DJANGO_FILTERS_MATCHMODE.lte,
                vencimento_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                vencimento_final: DJANGO_FILTERS_MATCHMODE.lte,
                liquidacao_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                liquidacao_final: DJANGO_FILTERS_MATCHMODE.lte,
                conta_financeira: DJANGO_FILTERS_MATCHMODE.equals,
                forma_pagamento: DJANGO_FILTERS_MATCHMODE.equals,
                competencia: DJANGO_FILTERS_MATCHMODE.equals,
                cancelado: DJANGO_FILTERS_MATCHMODE.equals,
            };

            let filtros = {};
            let params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    if (key === "credor") {
                        filtros[key] = values[key];
                        return params.push(`${key}${matchModes[key]}=${values[key].id}`);
                    }

                    if (key === "historico_padrao") {
                        filtros[key] = values[key];
                        return params.push(`${key}${matchModes[key]}=${values[key].id}`);
                    }

                    filtros[key] = values[key];
                    return typeof matchModes[key] != "undefined"
                        ? params.push(`${key}${matchModes[key]}=${values[key]}`)
                        : params.push(`${key}=${values[key]}`);
                }
            });

            if (params.length > 0) {
                onFilter(`${baseUrl}&${params.join("&")}`, filtros);
                setFiltro(filtros);
            } else onFilter(baseUrl);
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompleteHistoricoTemplate = (item) => {
        return <div>{`${item.descricao}`}</div>;
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const limparFiltros = () => {
        formik.resetForm();
    };

    const limparForm = () => {
        removerFiltro();
        limparFiltros();
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltros }));

    return (
        <Dialog
            header="Filtro avançado"
            visible={visible}
            style={{ width: "65vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="credor" label="Credor" />
                        <MakoInputPerfil
                            id="credor"
                            name="credor"
                            value={formik.values.credor}
                            onChange={formik.handleChange}
                            placeholder="Comece a digitar o nome do credor para buscar... (min 4 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.credor })}
                        />
                        {formik.errors.credor && <small className="p-error">{formik.errors.credor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="historico" label="Historico" />
                        <MakoAutoComplete
                            id="historico_padrao"
                            name="historico_padrao"
                            value={formik.values.historico_padrao}
                            onChange={formik.handleChange}
                            field="descricao"
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteHistoricoTemplate}
                            urlSearch="/financeiro/historico-padrao?tipo=P&descricao_icontains="
                            placeholder="Digite para começar a buscar... (min 4 caracteres)"
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="id" label="Pagamento" />
                        <InputText
                            id="id"
                            name="id"
                            placeholder="Digite o código do pagamento"
                            value={formik.values.id}
                            onInput={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="documento" label="Documento" />
                        <InputText
                            id="documento"
                            name="documento"
                            placeholder="Digite um documento"
                            value={formik.values.documento}
                            onInput={formik.handleChange}
                            autoFocus
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="valor_minimo" label="Valor mínimo" />
                        <InputNumber
                            id="valor_minimo"
                            name="valor_minimo"
                            min={0}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            onValueChange={formik.handleChange}
                            value={formik.values.valor_minimo}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="valor_maximo" label="Valor máximo" />
                        <InputNumber
                            id="valor_maximo"
                            name="valor_maximo"
                            min={0}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            onValueChange={formik.handleChange}
                            value={formik.values.valor_maximo}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="lancamento_inicial" label="Lançamento inicial" />
                        <MakoCalendar
                            id="lancamento_inicial"
                            name="lancamento_inicial"
                            value={formik.values.lancamento_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="lancamento_final" label="Lançamento final" />
                        <MakoCalendar
                            id="lancamento_final"
                            name="lancamento_final"
                            value={formik.values.lancamento_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="emissao_inicial" label="Emissão inicial" />
                        <MakoCalendar
                            id="emissao_inicial"
                            name="emissao_inicial"
                            value={formik.values.emissao_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="emissao_final" label="Emissão final" />
                        <MakoCalendar
                            id="emissao_final"
                            name="emissao_final"
                            value={formik.values.emissao_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="vencimento_inicial" label="Vencimento inicial" />
                        <MakoCalendar
                            id="vencimento_inicial"
                            name="vencimento_inicial"
                            value={formik.values.vencimento_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="vencimento_final" label="Vencimento final" />
                        <MakoCalendar
                            id="vencimento_final"
                            name="vencimento_final"
                            value={formik.values.vencimento_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="liquidacao_inicial" label="Liquidação inicial" />
                        <MakoCalendar
                            id="liquidacao_inicial"
                            name="liquidacao_inicial"
                            value={formik.values.liquidacao_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="liquidacao_final" label="Liquidação final" />
                        <MakoCalendar
                            id="liquidacao_final"
                            name="liquidacao_final"
                            value={formik.values.liquidacao_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="financeira" label="Conta financeira" />
                        <Dropdown
                            id="conta-financeira"
                            name="conta_financeira"
                            url="/financeiro/contas-financeiras/?query={id,descricao}"
                            placeholder="Selecione uma conta financeira"
                            emptyMessage="Não existem contas cadastradas."
                            showClear
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="forma_pagamento" label="Forma de pagamento" />
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            placeholder="Selecione o pagamento"
                            url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="competencia" label="Competência" />
                        <Dropdown
                            id="competencia"
                            name="competencia"
                            placeholder="Selecione uma competencia"
                            url={"/financeiro/competencias?limit=1000"}
                            filter
                            filterBy="grupo_competencia"
                            showClear
                            optionValue="id"
                            optionLabel="grupo_competencia"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="entrada" label="Número entrada" />
                        <InputText
                            id="entrada"
                            name="entrada"
                            placeholder="Digite o número da entrada"
                            value={formik.values.entrada}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cancelado" label="Cancelado" />
                        <Dropdown
                            id="cancelado"
                            name="cancelado"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.cancelado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cancelado })}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="quitado" label="Quitado" />
                        <Dropdown
                            id="quitado"
                            name="quitado"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.quitado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quitado })}
                        />
                    </div>
                </div>
                <br />
                <div className="p-grid p-justify-end">
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2"
                        onClick={limparForm}
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroPagamentosModalForm = forwardRef(Modal);
