import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Calendar as MakoCalendar } from "@/components/Calendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useBoleto from "@/hooks/useBoleto";

export const BoletoPagamentoForm = (props) => {
    const { handlePagamento } = useBoleto();

    const formik = useFormik({
        initialValues: {
            data_pagamento: null,
            descontos_aplicados: 0,
            juros_aplicados: 0,
            multa_aplicada: 0,
            valor_pago: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_pagamento: Yup.date().nullable.default(null),
                descontos_aplicados: Yup.number().notRequired().default(0),
                juros_aplicados: Yup.number().notRequired().default(0),
                multa_aplicada: Yup.number().notRequired().default(0),
                valor_pago: Yup.number().notRequired().default(0),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            handlePagamento(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="data-pagamento">Data de pagamento</label>
                    <MakoCalendar
                        id="data-pagamento"
                        name="data_pagamento"
                        value={formik.values.data_pagamento}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.data_pagamento && <small className="p-error">{formik.errors.data_pagamento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="descontos-aplicados">Descontos aplicados</label>
                    <MakoInputMoeda
                        id="descontos-aplicados"
                        name="descontos_aplicados"
                        valueMoeda={formik.values.descontos_aplicados}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="juros-aplicados">Juros aplicados</label>
                    <MakoInputMoeda
                        id="juros-aplicados"
                        name="juros_aplicados"
                        valueMoeda={formik.values.juros_aplicados}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="multa-aplicada">Valor da multa aplicada</label>
                    <MakoInputMoeda
                        id="multa-aplicada"
                        name="multa_aplicada"
                        valueMoeda={formik.values.multa_aplicada}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-pago">Valor pago</label>
                    <MakoInputMoeda
                        id="valor-pago"
                        name="valor_pago"
                        valueMoeda={formik.values.valor_pago}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2 p-mb-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};
